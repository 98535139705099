import { string, func, shape, arrayOf } from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Keyboard } from 'swiper';
import cn from 'classnames';
import { ChipsButton } from 'Components';
import 'swiper/css';
import 'swiper/css/navigation';
import classes from './NavRelease.module.scss';
import './SwipperNav.scss';

const NavRelease = ({
  language = 'uk',
  onClickChips = () => null,
  handleDelRelease = () => null,
  handleRename = () => null,
  modeSetting = '',
  dataNav = []
}) => (
  <div
    className={cn(classes.navRelease)}
  >
    {!!dataNav?.length && (
    <Swiper
      allowTouchMove={false}
      slidesPerView="auto"
      navigation={{ disabledClass: 'swiperNavDisabled' }}
      modules={[Navigation, Keyboard]}
      keyboard={{ enabled: true, pageUpDown: false }}
      className={classes.chipsContainer_modeUser}
      containerModifierClass="swiperUser-"
    >

      {dataNav.map((dataNavItem, index) => (
        <SwiperSlide key={dataNavItem.id}>
          <ChipsButton
            {...dataNavItem}
            index={index}
            modeSetting={modeSetting}
            onClickChips={onClickChips}
            id={dataNavItem.id}
            handleDelRelease={handleDelRelease}
            handleRename={handleRename}
            language={language}
            color="#9D96B8"
          />
        </SwiperSlide>
      ))}
    </Swiper>
    )}

  </div>
);

NavRelease.propTypes = {
  dataNav: arrayOf(shape({})),
  modeSetting: string,
  language: string,
  onClickChips: func,
  handleDelRelease: func,
  handleRename: func
};

export default NavRelease;
