import { string, number } from 'prop-types';
import cn from 'classnames';

export const ArrowDown = ({ width = 8, height = 5, color = '#C9FCD7', className = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={cn(className, 'icon icon-arrow-down')} viewBox="0 0 8 5" fill="none">
    <path d="M0.710051 1.71L3.30005 4.3C3.69005 4.69 4.32005 4.69 4.71005 4.3L7.30005 1.71C7.93005 1.08 7.48005 0 6.59005 0H1.41005C0.520051 0 0.0800515 1.08 0.710051 1.71Z" fill={color || '#C9FCD7'} />
  </svg>
);

ArrowDown.propTypes = {
  width: number,
  height: number,
  className: string,
  color: string
};
