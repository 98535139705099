import { useMemo, useRef } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { CSSTransition } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetUserKeywords, useGetSimpleAlerts, useGetNegativeAlerts, useGetActivityAlerts, useChangeSimpleAlert,
  useChangeNegativeAlert, useChangeActivityAlert, useCreateSimpleAlert, useCreateNegativeAlert, useCreateActivityAlert, useHideAlertCard
} from 'storage/queryHooks';
import { openModal, editAlertDetails, openAlertDetails, closeAlertDetails, editAlertsSimple, editAlertsNegative, editAlertsActivity, toggleRefetchTelegram } from 'storage/actions';
import t from 'utils/translateFunc';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './AlertsSettings.module.scss';
import AlertDetails from './AlertDetails';
import AlertsTable from './AlertsTable';
import AlersStatistics from './AlersStatistics';

function AlertsSettings() {
  const dispatch = useDispatch();
  const detailsRef = useRef(null);
  const nodeRef = useRef(null);
  const { data: keywords, hasNextPage: hasKeywordsNextPage, fetchNextPage: keywordsNextPage } = useGetUserKeywords();
  const { isLoading: simpleAlertsLoading, isRefetching: simpleAlertsRefetching } = useGetSimpleAlerts();
  const { isLoading: negativeAlertsLoading, isRefetching: negativeAlertsRefetching } = useGetNegativeAlerts();
  const { isLoading: activityAlertsLoading, isRefetching: activityAlertsRefetching } = useGetActivityAlerts();
  const { mutateAsync: editSimpleAlert, isLoading: editSimpleLoading, error: editAlertSimpleError } = useChangeSimpleAlert();
  const { mutateAsync: editNegativeAlert, isLoading: editNegativeLoading, error: editNegativeAlertError } = useChangeNegativeAlert();
  const { mutateAsync: editActivityAlert, isLoading: editActivityLoading, error: editActivityAlertError } = useChangeActivityAlert();
  const { mutateAsync: createSimpleAlert, isLoading: createSimpleLoading, error: createAlertSimpleError } = useCreateSimpleAlert();
  const { mutateAsync: createNegativeAlert, isLoading: createNegativeLoading, error: createAlertNegativeError } = useCreateNegativeAlert();
  const { mutateAsync: createActivityAlert, isLoading: createActivityLoading, error: createAlertActivityError } = useCreateActivityAlert();
  const { mutateAsync: hideAlertCard } = useHideAlertCard();

  const errorRequest = editAlertSimpleError || editNegativeAlertError || editActivityAlertError || createAlertSimpleError || createAlertNegativeError || createAlertActivityError;
  const alerts = useSelector(state => state?.alerts);
  const { refetchTelegram, telegramData, whatsappData, user } = useSelector(state => state?.userInfo);
  const { alertDetails, alertDetailsVisible, alertsList, alertsSimple, alertsNegative, alertsActivity, alertsSimpleCount, alertsNegativeCount, alertsActivityCount } = alerts;
  const alertsSimpleFiltered = useMemo(() => alertsSimple.filter(item => !!item?.id), [alertsSimple, alertsList]);
  const alertsNegativeFiltered = useMemo(() => alertsNegative.filter(item => !!item?.id), [alertsNegative, alertsList]);
  const alertsActivityFiltered = useMemo(() => alertsActivity.filter(item => !!item?.id), [alertsActivity, alertsList]);

  const onEditAlertsSimple = (newAlertsList) => { !isEqual(alertsActivity, newAlertsList) && dispatch(editAlertsSimple(newAlertsList)); };
  const onEditAlertsNegative = (newAlertsList) => { !isEqual(alertsNegative, newAlertsList) && dispatch(editAlertsNegative(newAlertsList)); };
  const onEditAlertsActivity = (newAlertsList) => { !isEqual(alertsActivity, newAlertsList) && dispatch(editAlertsActivity(newAlertsList)); };

  const emptyAlert = {
    editable: true,
    is_enabled: true,
    emails: user?.email ? [user.email] : null,
    telegram_users: [],
    whatsapp_accounts: [],
    name: '',
    query: '',
    query_builder: null,
    keyword: null
  };
  function checkUnsavedChanges(value) {
    if (!alertDetails) return false;
    const alertId = alertDetails?.id;
    const detailsAlert = { ...alertDetails };
    !!detailsAlert?.searchByTab && (delete detailsAlert.searchByTab);
    !!detailsAlert?.last_send_date && (delete detailsAlert.last_send_date);

    if (detailsAlert && !alertId) {
      const { telegram_users, query, query_builder, keyword } = alertDetails;
      return !!telegram_users?.length || !!query?.length || !!query_builder || !!keyword;
    }

    if (detailsAlert && alertId) {
      const findAlert = {
        'alerts-simple': alertsSimple?.find(item => item?.id === alertId),
        'alerts-negative': alertsNegative?.find(item => item?.id === alertId),
        'alerts-activity': alertsActivity?.find(item => item?.id === alertId),
        default: {}
      };
      const alert = findAlert[alertsList] || findAlert.default;
      const alertEtalon = { ...alert, name: detailsAlert?.name };
      !!alertEtalon?.last_send_date && (delete alertEtalon.last_send_date);
      if (isEqual(detailsAlert, alertEtalon) && alert?.name !== detailsAlert?.name) {
        value === 'only_close' ? onClose() : editAlertWithoutConfirm(detailsAlert);
      }
      return !isEqual(detailsAlert, alertEtalon);
    }
    return false;
  }

  function onOpenDetailsEditor({ alerts_list, alert_data }) {
    if (checkUnsavedChanges()) {
      detailsRef?.current?.warningMessage();
      return;
    }
    if (alert_data?.id === alertDetails?.id && alertDetailsVisible && alerts_list === alertsList) return;

    if (!alert_data?.id && alerts_list === 'alerts-simple') {
      onEditAlertsSimple([emptyAlert, ...alertsSimpleFiltered]);
    }
    if (!alert_data?.id && alerts_list === 'alerts-negative') {
      onEditAlertsNegative([emptyAlert, ...alertsNegativeFiltered]);
    }
    if (!alert_data?.id && alerts_list === 'alerts-activity') {
      onEditAlertsActivity([emptyAlert, ...alertsActivityFiltered]);
    }
    clearOtherEmptyAlerts(!alert_data?.id ? alerts_list : null);
    dispatch(openAlertDetails({ alertDetails: alert_data || emptyAlert, alertsList: alerts_list }));
  }

  function clearOtherEmptyAlerts(alerts_list) {
    (!alerts_list || alerts_list === 'alerts-negative' || alerts_list === 'alerts-activity')
      && alertsSimple?.length > 1 && onEditAlertsSimple(alertsSimpleFiltered);
    (!alerts_list || alerts_list === 'alerts-simple' || alerts_list === 'alerts-activity')
      && alertsNegative?.length > 1 && onEditAlertsNegative(alertsNegativeFiltered);
    (!alerts_list || alerts_list === 'alerts-simple' || alerts_list === 'alerts-negative')
      && alertsActivity?.length > 1 && onEditAlertsActivity(alertsActivityFiltered);
  }
  function onChangeToggleAlert({ alerts_list, alert_data }) {
    const editedData = {
      id: alert_data?.id,
      body: { is_enabled: !alert_data?.is_enabled }
    };
    alerts_list === 'alerts-simple' && editSimpleAlert(editedData);
    alerts_list === 'alerts-negative' && editNegativeAlert(editedData);
    alerts_list === 'alerts-activity' && editActivityAlert(editedData);
  }

  function editAlertWithoutConfirm(data) {
    const alertBody = {
      id: data?.id,
      body: {
        ...data,
        name: data?.name || t('alerts', 'NO_NAME'),
        emails: data?.emails?.length ? data?.emails : null,
        keyword: data?.keyword?.id || null,
        telegram_users: data?.telegram_users?.length ? data?.telegram_users.map(item => item?.id) : [],
        whatsapp_accounts: data?.whatsapp_accounts?.length ? data?.whatsapp_accounts.map(item => item.id) : []
      }
    };
    alertsList === 'alerts-simple' && editSimpleAlert(alertBody);
    alertsList === 'alerts-negative' && editNegativeAlert(alertBody);
    alertsList === 'alerts-activity' && editActivityAlert(alertBody);
  }

  function onEditAlert({ alerts_list, alert_data, isToggle }) {
    isToggle && sendAlertsEvent({ category: 'Manage alert', event: 'Clicked_Switcher_In_Alerts' });
    !isToggle ? onOpenDetailsEditor({ alerts_list, alert_data }) : onChangeToggleAlert({ alerts_list, alert_data });
  }

  function onChangeAlert(newAlertDetailsData) {
    !isEqual(alertDetails, newAlertDetailsData) && dispatch(editAlertDetails(newAlertDetailsData));
  }

  function onRemoveEmptyAlerts() {
    alertsList === 'alerts-simple' && !isEqual(alertsActivity, alertsSimpleFiltered) && dispatch(editAlertsSimple(alertsSimpleFiltered?.length ? alertsSimpleFiltered : [emptyAlert]));
    alertsList === 'alerts-negative' && !isEqual(alertsNegative, alertsNegativeFiltered) && dispatch(editAlertsNegative(alertsNegativeFiltered?.length ? alertsNegativeFiltered : [emptyAlert]));
    alertsList === 'alerts-activity' && !isEqual(alertsActivity, alertsActivityFiltered) && dispatch(editAlertsActivity(alertsActivityFiltered?.length ? alertsActivityFiltered : [emptyAlert]));
  }

  function onClose() {
    dispatch(closeAlertDetails());
    onRemoveEmptyAlerts();
  }

  function onCreateAlert(data) {
    const alertBody = {
      ...data,
      name: data?.name || t('alerts', 'NO_NAME'),
      keyword: data?.keyword?.id || null,
      emails: data?.emails?.length ? data?.emails : null,
      telegram_users: data?.telegram_users?.length ? data?.telegram_users.map(item => item?.id) : [],
      whatsapp_accounts: data?.whatsapp_accounts?.length ? data?.whatsapp_accounts.map(item => item.id) : []
    };
    !!alertBody?.searchByTab && delete alertBody.searchByTab;
    alertsList === 'alerts-simple' && createSimpleAlert({ body: alertBody });
    alertsList === 'alerts-negative' && createNegativeAlert({ body: alertBody });
    alertsList === 'alerts-activity' && createActivityAlert({ body: alertBody });
  }

  const onOpenModal = (modalData) => { dispatch(openModal(modalData)); };

  const onStartRefetchTelegram = (value) => { dispatch(toggleRefetchTelegram(value)); };
  return (
    <div className={classes.alertsSettindsWrap}>
      <div className={cn(classes.main, alertDetailsVisible && classes.main_detailsOn)}>
        <AlersStatistics
          alertDetailsVisible={alertDetailsVisible}
          onEditAlert={onEditAlert}
          counts={{ alertsSimpleCount, alertsNegativeCount, alertsActivityCount }}
          fetchings={{
            simple: simpleAlertsLoading || simpleAlertsRefetching,
            negative: negativeAlertsLoading || negativeAlertsRefetching,
            activity: activityAlertsLoading || activityAlertsRefetching
          }}
        />
        <AlertsTable
          detailsVisible={alertDetailsVisible}
          onEditAlert={onEditAlert}
          alerts={{ alertsSimple, alertsNegative, alertsActivity }}
          alertData={alertDetails}
          alertsListEdit={alertsList}
          removeCustomCard={({ alerts_list, id, name }) => { hideAlertCard({ id, alertsList: alerts_list, name }); }}
          userContacts={{ email: user?.email || '', telegram_accounts: telegramData?.accounts || [], whatsapp_accounts: whatsappData?.accounts || [] }}
          fetchings={{
            simple: simpleAlertsLoading || simpleAlertsRefetching,
            negative: negativeAlertsLoading || negativeAlertsRefetching,
            activity: activityAlertsLoading || activityAlertsRefetching
          }}
        />
      </div>
      <div className={cn(classes.details, alertDetailsVisible && classes.details_activeDetails)}>
        <CSSTransition
          in={alertDetailsVisible}
          timeout={350}
          classNames={{ enter: classes.enter, enterActive: classes.enterActive, exit: classes.exit, exitActive: classes.exitActive }}
          unmountOnExit
          nodeRef={nodeRef}
        >
          <AlertDetails
            ref={detailsRef}
            visible={alertDetailsVisible}
            data={alertDetails}
            refetchTelegram={refetchTelegram}
            unsavedChanges={value => checkUnsavedChanges(value || null)}
            errorRequest={errorRequest?.response?.data || null}
            onClose={onClose}
            isLoading={editSimpleLoading || editNegativeLoading || editActivityLoading || createSimpleLoading || createNegativeLoading || createActivityLoading}
            onCreate={onCreateAlert}
            onChangeAlert={onChangeAlert}
            onOpenModal={onOpenModal}
            onStartRefetchTelegram={onStartRefetchTelegram}
            telegramData={telegramData || null}
            whatsappData={whatsappData || null}
            keywords={keywords?.pages?.length ? keywords?.pages?.map(item => item?.results).flat(1) : null}
            hasKeywordsNextPage={hasKeywordsNextPage}
            keywordsNextPage={keywordsNextPage}
            alertsList={alertsList}
          />
        </CSSTransition>
      </div>
    </div>
  );
}

AlertsSettings.propTypes = {};

export default AlertsSettings;
