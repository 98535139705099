import { useMemo, useCallback } from 'react';
import { array, func, number } from 'prop-types';
import { Pagination } from 'antd';
import { Icon } from 'Components';
import cn from 'classnames';
import { Trans } from 'react-i18next';
import classes from './SearchResultsPaggination.module.scss';

function SearchResultsPaggination({ articles = [], count = 20, pageSize = 20, page = 1, onChangePage = () => null }) {
  const isLastPage = useMemo(() => !!count && page >= count / pageSize, [page, count, pageSize]);

  function itemRender(_, type, originalElement) {
    if (type === 'prev') {
      return (
        <span>
          <Icon
            type="arrow_forward"
            width={20}
            height={20}
            color={page === 1 ? '#8B96B2' : '#5A5E79'}
            className={cn(
              classes.iconPaggination,
              classes.iconForwardBack,
              page === 1 && classes.iconPaggination_disabled
            )}
          />
        </span>
      );
    }
    if (type === 'next') {
      return (
        <span>
          <Icon
            type="arrow_forward"
            width={20}
            height={20}
            color={isLastPage ? '#8B96B2' : '#5A5E79'}
            className={cn(
              classes.iconPaggination,
              isLastPage && classes.iconPaggination_disabled
            )}
          />
        </span>
      );
    }
    if (type === 'jump-prev' || type === 'jump-next') {
      return <strong className={classes.jumpDots}>...</strong>;
    }

    return originalElement;
  }

  const showTotal = useCallback((total, range) => (
    <Trans
      i18nKey="showTotalArticles"
      defaults="{{range1}} – {{range2}} of {{total}} report(s)"
      values={{ range1: range[0], range2: range[1], total }}
      components={{ span: <span style={{ color: '#8086ab' }} /> }}
    />
  ), []);
  return !!articles?.length && (
  <div className={classes.pagginationWrap}>
    <Pagination
      className={classes.paggination}
      showSizeChanger={false}
      hideOnSinglePage
      defaultPageSize={pageSize}
      pageSize={pageSize}
      defaultCurrent={page}
      current={page}
      total={count}
      showQuickJumper={false}
      itemRender={itemRender}
      onChange={(value) => { onChangePage(value); }}
      showTotal={showTotal}
    />
  </div>
  );
}

SearchResultsPaggination.propTypes = { articles: array, count: number, pageSize: number, page: number, onChangePage: func };

export default SearchResultsPaggination;
