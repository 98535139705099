import { useState } from 'react';
import cn from 'classnames';
import { BodyHtml } from 'Components';
import t from 'utils/translateFunc';
import { string } from 'prop-types';
import classes from './BodyWhatsNew.module.scss';

const fakeUpdateInfo = {
  date: '20 січня 2023',
  title: 'METRICOM вибір №1 серед українських піарників',
  content: `<h3>Серед платних сервісів медіамоніторингу найпопулярнішим став METRICOM із результатом 36,8%.</h3>
  <p>
    Українська компанія PointeR Agency провела дослідження серед представників маркетингової галузі. За результатами опитування, 60,5% українських піарників використовують у своїй роботі платні сервіси для відстеження та аналізу згадки в інфополі. Половина опитаних використовує у роботі Google (50%).
  </p>
  <p>
  Серед платних сервісів медіамоніторингу найпопулярнішим став METRICOM із результатом 36,8%. До топ-моніторингових сервісів теж увійшов наш MyNews bot з показником 11,8%.METRICOM серед 15 найкращих аналітичних компаній України
  </p>
  <img alt="блок под картинку или скриншот" src="https://uploads-ssl.webflow.com/6304f407692989e8cf58e801/630e84091791dd50ff253bf1__20-p-800.jpg" />`
};

const fakeAllUpdates = [
  {
    id: 1, date: '8 бер. 2023', title: 'Навіщо IT-компаніям медіамоніторинг', content: 'Від початку війни ІT-галузі в Україні вдалося стійко вистояти: зберегти більшу частину зарубіжних клієнтів та робочих місць...'
  },
  {
    id: 2, date: '21 лют. 2023', title: 'Моніторинг медіа для маркетологів: що він дає', content: 'Так склалося, що моніторингом медіа і соцмереж активніше користуються PR-спеціалісти, ніж...'
  },
  {
    id: 3, date: '7 лют. 2023', title: 'Звіти — must have інструмент. Пояснюємо чому', content: 'За даними State of PR 2022 інструментами звітності регулярно користуються 39% українських спеціалістів...'
  },
  {
    id: 4, date: '22 груд. 2022', title: 'Яким 2022-ий був для METRICOM: 5 основних подій', content: 'Цей рік показав, ким ми є. Виявилося, ми сильніші, ніж здавалося. Більш згуртовані, ніж...'
  }
];

function BodyWhatsNew({ language = 'uk' }) {
  const [visited, setVisited] = useState([fakeAllUpdates[0].id]);

  function onVisited(id) {
    setVisited([...visited, id]);
  }

  return (
    <div className={cn(classes.bodyWhatsNewModal)}>
      <div className={cn(classes.allUpdates)}>
        {fakeAllUpdates.map(data => (
          <div
            key={data.id}
            className={cn(classes.updates, visited.includes(data.id) && classes.updates_visited)}
            onClick={() => onVisited(data.id)}
          >
            <div className={classes.updates_date}>{data.date}</div>
            <h3 className={classes.updates_title}>
              {visited.includes(data.id) && <div className={classes.updates_visited_circle} />}
              {data.title}
            </h3>
            <div className={classes.updates_content}>
              {data.content}
              <span className={classes.updates_content_more}>{t('bodyWhatsNewModal', 'LEARN_MORE', language)}</span>
            </div>
          </div>
        ))}
      </div>
      <div className={cn(classes.updateInfo)}>
        <div className={classes.updateInfo_date}>{fakeUpdateInfo.date}</div>
        <h2>{fakeUpdateInfo.title}</h2>
        <BodyHtml html={fakeUpdateInfo.content} />
      </div>
    </div>
  );
}

BodyWhatsNew.propTypes = {
  language: string
};
export default BodyWhatsNew;
