import { string, number } from 'prop-types';

export function Charts({ width = 16, height = 16, color = 'white', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none">
      <mask id="mask0_557_14455" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_557_14455)">
        <path d="M2.00065 14C1.81176 14 1.65343 13.9361 1.52565 13.8083C1.39787 13.6806 1.33398 13.5222 1.33398 13.3333C1.33398 13.1444 1.39787 12.9861 1.52565 12.8583C1.65343 12.7306 1.81176 12.6667 2.00065 12.6667H14.0007C14.1895 12.6667 14.3479 12.7306 14.4756 12.8583C14.6034 12.9861 14.6673 13.1444 14.6673 13.3333C14.6673 13.5222 14.6034 13.6806 14.4756 13.8083C14.3479 13.9361 14.1895 14 14.0007 14H2.00065ZM3.00065 12C2.72287 12 2.48676 11.9028 2.29232 11.7083C2.09787 11.5139 2.00065 11.2778 2.00065 11V8.33333C2.00065 8.05556 2.09787 7.81944 2.29232 7.625C2.48676 7.43056 2.72287 7.33333 3.00065 7.33333C3.27843 7.33333 3.51454 7.43056 3.70898 7.625C3.90343 7.81944 4.00065 8.05556 4.00065 8.33333V11C4.00065 11.2778 3.90343 11.5139 3.70898 11.7083C3.51454 11.9028 3.27843 12 3.00065 12ZM6.33398 12C6.05621 12 5.8201 11.9028 5.62565 11.7083C5.43121 11.5139 5.33398 11.2778 5.33398 11V5C5.33398 4.72222 5.43121 4.48611 5.62565 4.29167C5.8201 4.09722 6.05621 4 6.33398 4C6.61176 4 6.84787 4.09722 7.04232 4.29167C7.23676 4.48611 7.33398 4.72222 7.33398 5V11C7.33398 11.2778 7.23676 11.5139 7.04232 11.7083C6.84787 11.9028 6.61176 12 6.33398 12ZM9.66732 12C9.38954 12 9.15343 11.9028 8.95898 11.7083C8.76454 11.5139 8.66732 11.2778 8.66732 11V7C8.66732 6.72222 8.76454 6.48611 8.95898 6.29167C9.15343 6.09722 9.38954 6 9.66732 6C9.9451 6 10.1812 6.09722 10.3757 6.29167C10.5701 6.48611 10.6673 6.72222 10.6673 7V11C10.6673 11.2778 10.5701 11.5139 10.3757 11.7083C10.1812 11.9028 9.9451 12 9.66732 12ZM13.0007 12C12.7229 12 12.4868 11.9028 12.2923 11.7083C12.0979 11.5139 12.0007 11.2778 12.0007 11V3C12.0007 2.72222 12.0979 2.48611 12.2923 2.29167C12.4868 2.09722 12.7229 2 13.0007 2C13.2784 2 13.5145 2.09722 13.709 2.29167C13.9034 2.48611 14.0007 2.72222 14.0007 3V11C14.0007 11.2778 13.9034 11.5139 13.709 11.7083C13.5145 11.9028 13.2784 12 13.0007 12Z" fill={color || 'white'} />
      </g>
    </svg>

  );
}

Charts.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
