import t from 'utils/translateFunc';
import { useSelector } from 'react-redux';
import classes from './Maintenance.module.scss';

const Maintenance = () => {
  const language = useSelector(state => state?.userInfo?.language);
  return (
    <div className={classes.maintenance}>
      <div className={classes.maintenance_image} />
      <div className={classes.maintenance_text}>
        {t('maintenance', 'TITLE', language)}
      </div>
      <div className={classes.maintenance_text_subtitle}>
        {t('maintenance', 'SUBTITLE', language)}
      </div>
    </div>
  );
};

export default Maintenance;
