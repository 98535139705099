import { memo, useState } from 'react';
import { addSearchFilterMediaTypes, addSearchFilterPostTypes, addSearchFilterCountries, addSearchFilterLanguages, addSearchFilterLevels, addSearchFilterSources,
  addSearchFilterSnAttendance, addSearchFilterSourceAttendance, openModal, addSearchSources } from 'storage/actions';
import { useDispatch } from 'react-redux';
import { useGetSearchCountries, useGetSearchMediaTypes, useGetSearchSourcesLanguages, useGetSearchPostTypes,
  useGetSearchSourcesLevel } from 'storage/queryHooks.js';
import t from 'utils/translateFunc';
import { array, bool, oneOfType, shape, string, number } from 'prop-types';
import { mediaTypeOption, postTypeOption } from 'data/selectOptions';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { hasFlag } from 'country-flag-icons';
import { Switcher, Animated } from 'Components';
import classes from './FiltersList.module.scss';
import Sources from './Sources';
import CustomList from './CustomList';
import CustomRage from './CustomRage';

const FiltersList = memo(({ media_type = null, media_type_exclude = null, post_type = null, post_type_exclude = null,
  country = null, country_exclude = null, language = null, language_exclude = null,
  source = null, source_exclude = null, level = null, level_exclude = null, source_attendance_gte = null, sn_attendance_gte = null }) => {
  const [additional, setAddditional] = useState(false);
  const { mutateAsync: getCountries } = useGetSearchCountries();
  const { mutateAsync: getMediaTypes } = useGetSearchMediaTypes();
  const { mutateAsync: getLanguages } = useGetSearchSourcesLanguages();
  const { mutateAsync: getPostTypes } = useGetSearchPostTypes();
  const { mutateAsync: getLevels } = useGetSearchSourcesLevel();

  const dispatch = useDispatch();
  function handleOpenModal(value) {
    dispatch(openModal(value));
  }

  const mediaTypes = media_type?.map(item => ({ ...mediaTypeOption(item), isExclude: false })) || [];
  const mediaTypesExclude = media_type_exclude?.map(item => ({ ...mediaTypeOption(item), isExclude: true })) || [];
  const mediaTypesUnited = mediaTypes?.length || mediaTypesExclude?.length ? [...mediaTypes, ...mediaTypesExclude] : null;

  const countries = country?.map(({ id: value, label, value: flag }) => ({
    value, label, flag: hasFlag(flag) ? getUnicodeFlagIcon(flag) : flag, isExclude: false })) || [];
  const countriesExclude = country_exclude?.map(({ id: value, label, value: flag }) => ({
    value, label, flag: hasFlag(flag) ? getUnicodeFlagIcon(flag) : flag, isExclude: true })) || [];
  const countriesUnited = countries?.length || countriesExclude?.length ? [...countries, ...countriesExclude] : null;

  const languages = language?.map(({ id: value, name: label, code }) => ({ value, label, code, isExclude: false })) || [];
  const languagesExclude = language_exclude?.map(({ id: value, name: label, code }) => ({ value, label, code, isExclude: true })) || [];
  const languagesUnited = languages?.length || languagesExclude?.length ? [...languages, ...languagesExclude] : null;

  const sources = source?.map(({ id: value, name: label, icon }) => ({ value, label, icon, isExclude: false })) || [];
  const sourcesExclude = source_exclude?.map(({ id: value, name: label, icon }) => ({ value, label, icon, isExclude: true })) || [];
  const sourcesUnited = sources?.length || sourcesExclude?.length ? [...sources, ...sourcesExclude] : null;

  const levels = level?.map(({ id: value, name: label }) => ({ value, label, isExclude: false })) || [];
  const levelsExclude = level_exclude?.map(({ id: value, name: label }) => ({ value, label, isExclude: true })) || [];
  const levelsUnited = levels?.length || levelsExclude?.length ? [...levels, ...levelsExclude] : null;

  const postTypes = post_type?.map(item => ({ ...postTypeOption(item), isExclude: false })) || [];
  const postTypesExclude = post_type_exclude?.map(item => ({ ...postTypeOption(item), isExclude: true })) || [];
  const postTypesUnited = postTypes?.length || postTypesExclude?.length ? [...postTypes, ...postTypesExclude] : null;

  return (
    <div className={classes.filtersList}>
      <CustomList
        list={mediaTypesUnited}
        label={t('search', 'TYPE_SOURCE')}
        placeholder={t('search', 'ALL_SOURCES')}
        endingPlaceholder={t('search', mediaTypesUnited?.length === 1 ? 'TYPE_SOURCE_END' : 'TYPE_SOURCES_END')}
        onChange={(values) => {
          dispatch(addSearchFilterMediaTypes({
            media_type: values?.filter(item => !item?.isExclude)?.map(({ value }) => value) || null,
            media_type_exclude: values?.filter(item => !!item?.isExclude)?.map(({ value }) => value) || null }));
        }}
        getListData={getMediaTypes}
      />
      <CustomList
        list={countriesUnited}
        label={t('search', 'GEOGRAPHY')}
        placeholder={t('search', 'COUNTY_SEARCH')}
        endingPlaceholder={t('search', countriesUnited?.length === 1 ? 'COUNTRY_END' : 'COUNTRIES_END')}
        onChange={(values) => {
          dispatch(addSearchFilterCountries({
            country: values?.filter(item => !item?.isExclude)?.map(({ value: id, label, flag: value }) => ({ id, label, value })) || null,
            country_exclude: values?.filter(item => !!item?.isExclude)?.map(({ value: id, label, flag: value }) => ({ id, label, value })) || null }));
        }}
        getListData={getCountries}
      />
      <CustomList
        list={languagesUnited}
        label={t('search', 'LANGUAGE')}
        placeholder={t('search', 'SEARCH_LANGUAGE')}
        endingPlaceholder={t('search', languagesUnited?.length === 1 ? 'LANGUAGE_END' : 'LANGUAGES_END')}
        onChange={(values) => {
          dispatch(addSearchFilterLanguages({
            language: values?.filter(item => !item?.isExclude)?.map(({ value: id, label: name, code }) => ({ id, name, code })) || null,
            language_exclude: values?.filter(item => !!item?.isExclude)?.map(({ value: id, label: name, code }) => ({ id, name, code })) || null }));
        }}
        getListData={getLanguages}
      />
      <Sources
        list={sourcesUnited || []}
        label={t('search', 'SOURCES')}
        placeholder={t('search', 'SOURCE_SEARCH')}
        endingPlaceholder={t('search', sourcesUnited?.length === 1 ? 'SOURCE_END' : 'SOURCES_END')}
        onChange={(values) => {
          dispatch(addSearchFilterSources({
            source: values?.filter(item => !item?.isExclude)?.map(({ value: id, label: name, icon }) => ({ id, name, icon })) || null,
            source_exclude: values?.filter(item => !!item?.isExclude)?.map(({ value: id, label: name, icon }) => ({ id, name, icon })) || null }));
        }}
        onSortSources={(values) => { dispatch(addSearchSources(values)); }}
        onOpenModal={handleOpenModal}
      />

      <CustomList
        list={postTypesUnited}
        label={t('search', 'TYPE_MENTIONS')}
        placeholder={t('search', 'SEARCH_MENTION_TYPE')}
        endingPlaceholder={t('search', postTypesUnited?.length === 1 ? 'MENTION_TYPE_END' : 'MENTION_TYPES_END')}
        onChange={(values) => {
          dispatch(addSearchFilterPostTypes({
            post_type: values?.filter(item => !item?.isExclude)?.map(({ value }) => value) || null,
            post_type_exclude: values?.filter(item => !!item?.isExclude)?.map(({ value }) => value) || null }));
        }}
        getListData={getPostTypes}
        iconColor="#24252D"
      />
      <Switcher
        className={classes.customSwitcher}
        onСhange={(value) => { setAddditional(value); }}
        value={additional}
        labelText={t('search', 'ADDITIONAL_FILTERS')}
      />
      <Animated
        visible={additional}
        duration={350}
        className={classes.additionalWrap}
      >
        <CustomList
          list={levelsUnited}
          label={t('search', 'LEVEL')}
          placeholder={t('search', 'SEARCH_SOURCE_LEVEL')}
          endingPlaceholder={t('search', levelsUnited?.length === 1 ? 'LEVEL_END' : 'LEVELS_END')}
          onChange={(values) => {
            dispatch(addSearchFilterLevels({
              level: values?.filter(item => !item?.isExclude)?.map(({ value: id, label: name }) => ({ id, name })) || null,
              level_exclude: values?.filter(item => !!item?.isExclude)?.map(({ value: id, label: name }) => ({ id, name })) || null }));
          }}
          getListData={getLevels}
        />
        <CustomRage
          label={t('search', 'SOCIAL_MEDIA_AUDIENCE')}
          range={sn_attendance_gte || ''}
          onChange={(value) => { dispatch(addSearchFilterSnAttendance(value)); }}
        />
        <CustomRage
          label={t('search', 'SOURCE_AUDIENCE')}
          range={source_attendance_gte || ''}
          onChange={(value) => { dispatch(addSearchFilterSourceAttendance(value)); }}
        />
      </Animated>
    </div>
  );
});

FiltersList.propTypes = {
  media_type: oneOfType([shape({}), array, bool]),
  media_type_exclude: oneOfType([shape({}), array, bool]),
  post_type: oneOfType([shape({}), array, bool]),
  post_type_exclude: oneOfType([shape({}), array, bool]),
  country: oneOfType([shape({}), array, bool]),
  country_exclude: oneOfType([shape({}), array, bool]),
  language: oneOfType([shape({}), array, bool]),
  language_exclude: oneOfType([shape({}), array, bool]),
  source: oneOfType([shape({}), array, bool]),
  source_exclude: oneOfType([shape({}), array, bool]),
  level: oneOfType([shape({}), array, bool]),
  level_exclude: oneOfType([shape({}), array, bool]),
  source_attendance_gte: oneOfType([shape({}), array, bool, string, number]),
  sn_attendance_gte: oneOfType([shape({}), array, bool, string, number])
};

export default FiltersList;
