import { useRef } from 'react';
import { string, func, bool, shape, oneOfType } from 'prop-types';
import { IMaskInput, IMask } from 'react-imask';
import cn from 'classnames';
import t from 'utils/translateFunc';
import classes from './TimeInput.module.scss';

const TimeInput = ({ value = '', onChange = () => null, onBlur = () => null, className = '', classNameForInput = '', hasError = '' }) => {
  const ref = useRef(null);
  const inputRef = useRef(null);

  return (
    <div className={cn(classes.timeField, className)}>
      <div className={classes.inputWrap}>
        <IMaskInput
          className={cn(
            classes.textInput,
            classNameForInput,
            hasError && classes.textInput_error
          )}
          ref={ref}
          inputRef={inputRef}
          value={value}
          unmask={false}
          overwrite
          autofix
          mask="HH:MM"
          blocks={{
            HH: {
              mask: IMask.MaskedRange,
              placeholderChar: 'HH',
              from: 0,
              to: 23,
              maxLength: 2
            },
            MM: {
              mask: IMask.MaskedRange,
              placeholderChar: 'MM',
              from: 0,
              to: 59,
              maxLength: 2
            }
          }}
          onBlur={onBlur}
          onAccept={(time) => { onChange(time); }}
          // onComplete={(time) => { onChange(time); }}
          placeholder={t('reports', 'TIME_PLACEHOLDER')}
        />
      </div>
    </div>
  );
};

TimeInput.propTypes = {
  value: oneOfType([string, shape({})]),
  onChange: func,
  onBlur: func,
  className: string,
  classNameForInput: string,
  hasError: oneOfType([string, bool])

};

export default TimeInput;
