import { api } from 'api/apiInstance';

export const getUserInfo = async () => {
  const response = await api.get('auth_user/');
  return response?.data;
};

export const sendUserEvent = async ({ portal_slug, body }) => {
  const response = await api.post(`events/${portal_slug}/`, body);
  return response?.data;
};

export const getTimeZones = async () => {
  const response = await api.get('timezones/');
  return response?.data;
};

export const getInterfaceLanguages = async () => {
  const response = await api.get('interface-languages/');
  return response?.data;
};

export const uploadImage = async ({ image }) => {
  const form = new FormData();
  form.append('file', image);
  const response = await api.post('upload/', form, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response?.data;
};

export const updateUserInfo = async ({ body }) => {
  const response = await api.patch('auth_user/update/', body);
  return response?.data;
};

export const uploadFeedbackImage = async ({ image }) => {
  const form = new FormData();
  form.append('file', image);
  const response = await api.post('upload/', form);
  return response?.data;
};

export const sendFeedback = async ({ body }) => {
  const response = await api.post('auth_user/feedback/', body);
  return response?.data;
};

export const getTelegramAccounts = async () => {
  const response = await api.get('telegram-accounts/current/');
  return response?.data;
};

export const getSlackAccounts = async () => {
  const response = await api.get('slack-accounts/current/');
  return response?.data;
};
export const getSlackAccountRedirect = async () => {
  const response = await api.get('slack-accounts/oauth_redirect/');
  return response?.data;
};

export const getWhatsappAccounts = async () => {
  const response = await api.get('whatsapp-accounts/current/');
  return response?.data;
};

export const addWhatsappAccounts = async (phone) => {
  const response = await api.post('whatsapp-accounts/', { phone });
  return response?.data;
};
