import { string, func } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button } from 'Components';
import classes from './BodyConfirmEditingAdvanced.module.scss';

const BodyConfirmEditingAdvanced = ({
  className = '',
  onCancel = () => null,
  onClickOk = () => null,
  language = 'uk'
}) => (
  <div className={cn(classes.bodyConfirmEditingAdvanced, className)}>
    <div className={classes.discription}>
      {t('bodyRenamePageModal', 'SURE_EDIT_3', language)}
    </div>
    <div className={classes.buttonBox}>
      <Button
        onClick={onClickOk}
        textButton={t('btn', 'SURE_CHANGE', language)}
        className={classes.buttonCancel}
        green32
      />
      <Button
        onClick={onCancel}
        textButton={t('btn', 'CANCEL', language)}
        purpleTransparent32
      />
    </div>
  </div>
);

BodyConfirmEditingAdvanced.propTypes = {
  className: string,
  onCancel: func,
  onClickOk: func,
  language: string
};

export default BodyConfirmEditingAdvanced;
