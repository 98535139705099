/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { useIntercom } from 'hooks/useIntercom';
// import { useWootric } from 'hooks/useWootric';
import 'normalize.css';
import classes from 'App.module.scss';
import Routes from 'routes';
import { updateToken, updateLanguage, apiToken } from 'api/apiInstance';
import { useGetPeriod } from 'storage/queryHooks';
import { loadAntdLang, changeFavicon } from 'utils/common';
import { ConfigProvider, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles/styles.scss';

ConfigProvider.config({ holderRender: children => <ConfigProvider prefixCls="newsfeed">{children}</ConfigProvider> });
notification.config({ prefixCls: 'newsfeed', placement: 'bottomLeft', bottom: 10 });

function App() {
  const { language, user } = useSelector(state => state?.userInfo);
  const count = useSelector(state => state?.news?.count);
  const { accessToken, isAuthenticated } = useSelector(state => state?.auth);
  // const { openIntercom } = useIntercom();
  // const { openWootric } = useWootric();
  const { i18n } = useTranslation();
  const periodData = useGetPeriod();

  // useEffect(() => {
  //   if (user?.id) {
  //     openWootric();
  //     openIntercom();
  //   }
  // }, [user?.id]);

  useEffect(() => {
    changeFavicon(count);
  }, [count]);

  useEffect(() => {
    if (isAuthenticated && language) {
      updateLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [isAuthenticated, language]);

  if (accessToken && !apiToken) {
    updateToken(accessToken);
  }

  return (
    <div className={classes.app}>
      <ConfigProvider
        locale={loadAntdLang(language)}
        prefixCls="newsfeed"
        theme={{
          components: {
            Table: {
              fontFamily: 'Roboto',
              headerBorderRadius: 8,
              headerColor: '#5A5E79',
              headerBg: '#EBEFF4',
              borderColor: '#dbe1ea',
              rowHoverBg: 'rgba(210, 246, 220, 1)',
              headerSortActiveBg: 'rgba(210, 246, 220, 1)',
              headerSortHoverBg: 'rgba(210, 246, 220, 1)',
              headerSplitColor: '#8086AB'
            },
            Pagination: {
              fontFamily: 'Roboto',
              fontSize: 14,
              colorBgContainer: '#ffffff',
              colorBgTextActive: 'rgba(210, 246, 220, 1)',
              colorBgTextHover: 'rgba(210, 246, 220, 0.7)',
              colorBorder: 'rgba(119, 169, 142, 1)',
              colorPrimary: undefined,
              colorPrimaryBorder: 'rgba(21, 58, 40, 1)',
              colorPrimaryHover: 'rgba(45, 95, 80, 1)',
              colorText: '#5A5E79',
              colorTextDisabled: '#8B96B2'
            },
            Progress: {
              remainingColor: '#FFFFFF',
              defaultColor: 'rgba(45, 95, 80, 1)'
            },
            Radio: {
              colorPrimary: '#00CC87'
            }
          }
        }}
      >
        <Routes />
      </ConfigProvider>
    </div>
  );
}

export default App;
