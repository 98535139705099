import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import cn from 'classnames';
import { string, node, bool } from 'prop-types';
import classes from './SkeletonDetails.module.scss';

export function SkeletonDetails({ fetching = false, children = null, className = '' }) {
  return (fetching ? (
    <div className={cn(classes.detailsSkeletonWrapper, className)}>
      <SkeletonTheme baseColor="#f6f7fa" highlightColor="#e0e0f3">
        <Skeleton containerClassName={classes.dateWrapper} className={classes.date} inline />
        <Skeleton count={2} containerClassName={classes.headerWrapper} className={classes.header} inline />
        <Skeleton count={1} containerClassName={classes.tagsWrapper} className={classes.tags} inline />
        <Skeleton count={10} containerClassName={classes.textWrapper} className={classes.text} inline />
        <Skeleton count={1} containerClassName={classes.footerWrapper} className={classes.footer} inline />
      </SkeletonTheme>
    </div>
  ) : children);
}

SkeletonDetails.propTypes = {
  fetching: bool,
  children: node,
  className: string
};
export default SkeletonDetails;
