/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import { PureComponent } from 'react';
import { any } from 'prop-types';
import { FallbackComponent } from 'Components';

export default class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log('componentDidCatch', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <FallbackComponent {...this.props} />
      );
    }

    return this.props?.children || null;
  }
}

ErrorBoundary.propTypes = {
  children: any
};
