import { useRef, useState, memo } from 'react';
import { roleMenu } from 'data/dropDownConfig';
import { string, func, shape, oneOfType, arrayOf, number, bool } from 'prop-types';
import t from 'utils/translateFunc';
import { PopoverWindow, BodyMenu, Icon } from 'Components';
import cn from 'classnames';
import classes from './RolePopover.module.scss';

const RolePopover = memo(({
  onSelect = () => null,
  info = null,
  language = 'uk',
  idBind = '',
  className = '',
  placement = '',
  offset = false
}) => {
  const bodyRef = useRef();
  const [visible, setVisible] = useState(false);
  const popoversMenuConfig = roleMenu.map(el => ({ ...el, name: t('characterFilter', el?.name, language) }));

  function afterChange(value) {
    value && !visible && setVisible(true);
    !value && visible && setTimeout(() => { setVisible(false); }, 350);
  }
  function onClose() {
    visible && bodyRef?.current?.onClosePopover();
    visible && setTimeout(() => { setVisible(false); }, 350);
  }
  function handleSubmit({ action, value }) {
    onSelect({ action, info, body: { role: value } });
    onClose();
  }

  return (
    <PopoverWindow
      ref={bodyRef}
      className={classes.roleBody}
      body={visible && (
        <BodyMenu
          active={info?.role ? t('characterFilter', info.role.toUpperCase(), language) : null}
          className={classes.roleBodySelect}
          menuConfig={popoversMenuConfig}
          selectOption={handleSubmit}
        />
      )}
      offset={offset}
      overflow={{ adjustX: true, adjustY: true }}
      idBind={idBind}
      placement={placement}
      afterChange={afterChange}
    >
      <div className={cn(classes.roleWrapper, className)}>
        {info?.role && <span>{ t('characterFilter', info.role.toUpperCase(), language) }</span>}
        <Icon
          type="arrow_drop_down"
          className={cn(classes.iconClass, classes.iconClass_arrow)}
        />
      </div>
    </PopoverWindow>
  );
});

RolePopover.propTypes = {
  onSelect: func,
  info: shape({}),
  language: string,
  idBind: string,
  className: string,
  placement: string,
  offset: oneOfType([arrayOf(number), bool])
};

export default RolePopover;
