/* eslint-disable  */
import { dispatch } from 'd3-dispatch';

const SPIRALS = {
  archimedean: archimedeanSpiral,
  rectangular: rectangularSpiral
};

export default function Cloud() {
  let size = [256, 256];
  let text = cloudText;
  let font = cloudFont;
  let fontSize = cloudFontSize;
  let fontStyle = cloudFontNormal;
  let fontWeight = cloudFontNormal;
  let rotate = cloudRotate;
  let padding = cloudPadding;
  let spiral = archimedeanSpiral;
  let words = [];
  let timeInterval = Infinity;
  const event = dispatch('word', 'end');
  let random = Math.random;
  const cloud = {};
  let canvas = cloudCanvas;

  cloud.canvas = function (_) {
    return arguments.length ? (canvas = functor(_), cloud) : canvas;
  };

  cloud.start = function () {
  };

  cloud.stop = function () {
  };

  cloud.timeInterval = function (_) {
    return arguments.length ? (timeInterval = _ == null ? Infinity : _, cloud) : timeInterval;
  };

  cloud.words = function (_) {
    return arguments.length ? (words = _, cloud) : words;
  };

  cloud.size = function (_) {
    return arguments.length ? (size = [+_[0], +_[1]], cloud) : size;
  };

  cloud.font = function (_) {
    return arguments.length ? (font = functor(_), cloud) : font;
  };

  cloud.fontStyle = function (_) {
    return arguments.length ? (fontStyle = functor(_), cloud) : fontStyle;
  };

  cloud.fontWeight = function (_) {
    return arguments.length ? (fontWeight = functor(_), cloud) : fontWeight;
  };

  cloud.rotate = function (_) {
    return arguments.length ? (rotate = functor(_), cloud) : rotate;
  };

  cloud.text = function (_) {
    return arguments.length ? (text = functor(_), cloud) : text;
  };

  cloud.spiral = function (_) {
    return arguments.length ? (spiral = SPIRALS[_] || _, cloud) : spiral;
  };

  cloud.fontSize = function (_) {
    return arguments.length ? (fontSize = functor(_), cloud) : fontSize;
  };

  cloud.padding = function (_) {
    return arguments.length ? (padding = functor(_), cloud) : padding;
  };

  cloud.random = function (_) {
    return arguments.length ? (random = _, cloud) : random;
  };

  cloud.on = function () {
    const value = event.on.apply(event, arguments);
    return value === event ? cloud : value;
  };

  return cloud;
}

function cloudText(d) {
  return d.text;
}

function cloudFont() {
  return 'serif';
}

function cloudFontNormal() {
  return 'normal';
}

function cloudFontSize(d) {
  return Math.sqrt(d.value);
}

function cloudRotate() {
  return (~~(random() * 6) - 3) * 30;
}

function cloudPadding() {
  return 1;
}

function archimedeanSpiral(size) {
  const e = size[0] / size[1];
  return function (t) {
    return [e * (t *= 0.1) * Math.cos(t), t * Math.sin(t)];
  };
}

function rectangularSpiral(size) {
  const dy = 4;
  const dx = (dy * size[0]) / size[1];
  let x = 0;
  let y = 0;
  return function (t) {
    const sign = t < 0 ? -1 : 1;
    switch ((Math.sqrt(1 + 4 * sign * t) - sign) & 3) {
      case 0: x += dx; break;
      case 1: y += dy; break;
      case 2: x -= dx; break;
      default: y -= dy; break;
    }
    return [x, y];
  };
}

function cloudCanvas() {
  return document.createElement('canvas');
}

function functor(d) {
  return typeof d === 'function' ? d : function () { return d; };
}
