import { createSlice } from '@reduxjs/toolkit';
import { randomHexColor } from 'utils/common';
import hasOwn from 'lodash/has';

const initialState = {
  keywords: [],
  media_types: [],
  tones: [],
  tags: [],
  post_types: [],
  countries: [],
  regions: [],
  cities: [],
  sources: [],
  levels: [],
  subjects: [],
  publication_places: [],
  creators: [],
  creators_gender: [],
  creators_age: [{ id: 'creator_age_from' }, { id: 'creator_age_to' }],
  creator_age_from: '',
  creator_age_to: '',
  languages: [],
  characters: [],
  search: '',
  contexts: [],
  words: [],
  links: [],
  hashtags: [],
  audiences: [{ id: 'audience_from' }, { id: 'audience_to' }],
  audience_from: '',
  audience_to: '',
  duplicates: false,
  settings: null,
  filterList: [],
  filtersQuery: { duplicates: 'false', ordering: '-date' },
  ordering: '-date',
  articles_per_page: 10,
  tabItem: null
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addKeywords(state, { payload }) {
      const uniqKeywordList = payload?.length && payload.reduce((acc, curr) => (acc?.some(art => art?.id === curr?.id) ? acc : [...acc, curr]), []);
      state.keywords = uniqKeywordList || [];
    },
    addKeyword(state, { payload }) {
      state.keywords = state.keywords.concat([payload]);
    },
    editKeyword(state, { payload }) {
      state.keywords = state.keywords.map(item => (item?.id === payload?.id ? payload : item));
    },
    removeKeywordFromList(state, { payload }) {
      state.keywords = state.keywords.filter(el => el?.id !== payload?.keyword_id);
    },
    addMediaTypes(state, { payload }) {
      state.media_types = payload || [];
    },
    addTones(state, { payload }) {
      state.tones = payload || [];
    },
    addTags(state, { payload }) {
      state.tags = payload || [];
    },
    removeTagFromList(state, { payload }) {
      state.tags = state.tags.filter(el => el?.id !== payload?.tag_id);
    },
    addPostTypes(state, { payload }) {
      state.post_types = payload || [];
    },
    addCountries(state, { payload }) {
      state.countries = payload || [];
    },
    addRegions(state, { payload }) {
      state.regions = payload || [];
    },
    addCities(state, { payload }) {
      state.cities = payload || [];
    },
    addSources(state, { payload }) {
      state.sources = payload?.results || [];
    },
    addLevels(state, { payload }) {
      state.levels = payload || [];
    },
    addSubjects(state, { payload }) {
      state.subjects = payload || [];
    },
    addPublicationPlaces(state, { payload }) {
      state.publication_places = payload?.results || [];
    },
    addCreators(state, { payload }) {
      state.creators = payload?.results || [];
    },
    addCreatorsGender(state, { payload }) {
      state.creators_gender = payload || [];
    },
    addCreatorsAge(state, { payload }) {
      state.creators_age = payload || [];
    },
    addLanguages(state, { payload }) {
      state.languages = payload || [];
    },
    addCharacters(state, { payload }) {
      state.characters = payload || [];
    },
    addSearch(state, { payload }) {
      state.search = payload || [];
    },
    addContexts(state, { payload }) {
      state.contexts = payload || [];
    },
    addWords(state, { payload }) {
      if (!state.words?.length && !!payload?.length) {
        state.words = payload.map(el => ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
      if (!!state.words?.length && !!payload?.length) {
        state.words = payload.map(el => state.words.find(item => item.text === el.value) || ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
    },
    addLinks(state, { payload }) {
      if (!state.links?.length && !!payload?.length) {
        state.links = payload.map(el => ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
      if (!!state.links?.length && !!payload?.length) {
        state.words = payload.map(el => state.links.find(item => item.text === el.value) || ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
    },
    addHashtags(state, { payload }) {
      if (!state.hashtags?.length && !!payload?.length) {
        state.hashtags = payload.map(el => ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
      if (!!state.hashtags?.length && !!payload?.length) {
        state.words = payload.map(el => state.hashtags.find(item => item.text === el.value) || ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
    },
    addAudiences(state, { payload }) {
      state.audiences = payload || [];
    },
    addSettings(state, { payload }) {
      state.settings = payload || null;
      if (payload && hasOwn(payload, 'doubles')) {
        state.duplicates = payload.doubles;
        state.filtersQuery.duplicates = String(payload.doubles);
      }
      if (payload && hasOwn(payload, 'sort_by')) {
        state.ordering = payload.sort_by;
        state.filtersQuery.ordering = payload.sort_by;
      }
      if (payload && hasOwn(payload, 'articles_per_page')) {
        state.articles_per_page = payload.articles_per_page;
      }
    },
    addFilterItem(state, { payload }) {
      const key = payload?.filterName;
      const id = payload?.id;
      const idString = id === 'icontains' || id === 'creator_age_from' || id === 'creator_age_to' || id === 'audience_from' || id === 'audience_to';
      if (idString) {
        state.filtersQuery[key] = payload?.name;
      } else {
        const filterDuplicatesIds = state.filtersQuery[key]?.filter(keyIds => keyIds !== String(id));
        state.filtersQuery[key] ? (state.filtersQuery[key] = filterDuplicatesIds?.concat(String(id))) : (state.filtersQuery[key] = [String(id)]);
      }
      const filterDuplicatesItems = state?.filterList?.filter(listItem => listItem?.id !== id);
      state.filterList = filterDuplicatesItems?.concat(payload);
    },
    removeFilterItem(state, { payload }) {
      const key = payload?.filterName;
      const id = payload?.id;
      const idString = id === 'icontains' || id === 'creator_age_from' || id === 'creator_age_to' || id === 'audience_from' || id === 'audience_to';
      if (state.filtersQuery[key] && !idString) {
        const removeValue = state.filtersQuery[key].filter(el => el !== String(payload.id));
        removeValue?.length ? (state.filtersQuery[key] = removeValue) : delete state.filtersQuery[key];
      } else {
        delete state.filtersQuery[key];
      }
      state.filterList = state?.filterList?.filter(listItem => listItem.id !== payload.id);
    },
    removeFilterItemByName(state, { payload }) {
      state.filterList = state?.filterList?.filter(listItem => listItem.filterName !== payload);
      delete state.filtersQuery[payload];
    },
    clearFilterList(state) {
      state.filterList = [];
      state.filtersQuery = { ordering: '-date', duplicates: 'false' };
      const tab = state.tabItem?.action;
      tab && (state.filtersQuery[tab] = 'true');
      state.duplicates = false;
      state.ordering = '-date';
    },
    changeOrdering(state, { payload }) {
      state.ordering = payload;
      state.filtersQuery.ordering = payload;
    },
    toggleDuplicates(state, { payload }) {
      state.duplicates = payload;
      state.filtersQuery.duplicates = String(payload);
    },
    onChangeTab(state, { payload }) {
      !!state.filtersQuery?.bookmarked && delete state.filtersQuery.bookmarked;
      !!state.filtersQuery?.processed && delete state.filtersQuery.processed;
      !!state.filtersQuery?.deleted && delete state.filtersQuery.deleted;
      if (payload?.action) {
        payload?.action === 'unprocessed' ? (state.filtersQuery.processed = 'false') : (state.filtersQuery[payload?.action] = 'true');
      }
      state.tabItem = payload;
    }
  }
});

export const { addKeywords, addKeyword, editKeyword, addMediaTypes, addTones, addTags, removeTagFromList, addPostTypes, addCountries, addRegions, addCities, addSources,
  addLevels, addSubjects, addCreators, addCreatorsGender, addCreatorsAge, addLanguages, addCharacters, addSearch, addContexts, addWords, toggleDuplicates, addPublicationPlaces,
  addLinks, addHashtags, addAudiences, addSettings, addFilterItem, removeFilterItem, removeFilterItemByName, clearFilterList, changeOrdering, onChangeTab,
  removeKeywordFromList
} = filtersSlice.actions;
export default filtersSlice.reducer;
