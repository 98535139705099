import { func, string, bool } from 'prop-types';
import cn from 'classnames';
import { Icons } from 'Components';
import classes from './SearchField.module.scss';

const SearchField = ({
  value = '',
  placeholder = '',
  className = '',
  name = '',
  disabled = false,
  setValue = () => null
}) => {
  const handleSearch = (e) => {
    setValue(e.target.value);
  };

  const onClearSearch = () => {
    setValue('');
  };

  return (
    <div className={cn(classes.searchField, className)}>
      <input
        type="text"
        name={name}
        value={value}
        onChange={handleSearch}
        placeholder={placeholder}
        className={cn(classes.searchInput, value && classes.searchInput_value, disabled && classes.searchInput_disabled)}
        disabled={disabled}
      />
      <Icons icon="icn_Search" className={classes.iconSearch} />

      {value && (
        <button onClick={onClearSearch} className={classes.searchButton} type="button">
          <Icons icon="Icn_Clear_Search" className={classes.iconClear} />
        </button>
      )}
    </div>
  );
};

SearchField.propTypes = {
  value: string,
  name: string,
  className: string,
  placeholder: string,
  setValue: func,
  disabled: bool
};

export default SearchField;
