import { forwardRef } from 'react';
import { Icon, Button, CodeMirror, ErrorBoundarySentry, Animated } from 'Components';
import t from 'utils/translateFunc';
import { bool, string, func, shape, oneOfType, number } from 'prop-types';
import cn from 'classnames';
import classes from './SearchQuery.module.scss';
import Errors from './Errors';

const SearchQuery = forwardRef(({
  onChangeEditor = () => null,
  onFocus = () => null,
  onBlur = () => null,
  onSearch = () => null,
  onOpenHistory = () => null,
  onChangeInstructions = () => null,
  focusEditor = false,
  showInstructions = false,
  showSearchQuery = true,
  editable = true,
  query = '',
  errorQuery = '',
  errorValidate = null
}, ref) => (
  <Animated
    className={classes.searchQueryWrapp}
    visible={showSearchQuery}
    duration={300}
  >
    <div className={classes.searchQuery}>
      <div className={classes.searchQueryComponent} onClick={onFocus} onBlur={onBlur}>
        <ErrorBoundarySentry>
          <CodeMirror
            code={query}
            onPressEnter={() => {
              onBlur();
              onSearch();
            }}
            onChange={onChangeEditor}
            editable={editable}
            errorValidate={errorValidate}
            errorQuery={errorQuery}
            exampleTitle={t('error', 'EXAMPLE')}
            assignValue=""
            customStyles={{ padding: '16px 16px 60px 24px' }}
            customProps={{ height: 'auto', maxHeight: focusEditor ? 'calc(90vh - 76px)' : '28px', minHeight: '28px', placeholder: t('search', 'ENTER_QUERY') }}
            ref={ref}
          />
        </ErrorBoundarySentry>
        <Errors errorValidate={errorValidate} errorQuery={errorQuery} />
        {/* {(!!errorValidate || errorQuery) && (
        <div className={classes.errorMessage}>
          {errorQuery && !errorValidate ? errorQuery : ''}
          {errorValidate && t('advancedBuilder', 'QUERY_ERRORS')}
        </div>
        )} */}
      </div>
      <div className={classes.buttonBox}>
        <Icon
          type="history"
          role="button"
          tooltipText={t('search', 'SEARCH_HISTORY')}
          onClick={onOpenHistory}
        />
        <Button
          onClick={onChangeInstructions}
          textButton={t('search', 'INSTRUCTION')}
          disabled={errorValidate}
          className={cn(classes.instructionBtn, showInstructions && classes.instructionBtn_active)}
        />
        <Button
          icon="icn_Search"
          onClick={onSearch}
          disabled={errorValidate}
          green32
          className={classes.searchButon}
        />
      </div>
      { (!errorValidate && !errorQuery) && <div className={classes.searchBackContainerBlur} />}
      <div className={cn(classes.searchBackContainer, !!(errorValidate || errorQuery) && classes.searchBackContainer_error)} />
    </div>
  </Animated>

));

SearchQuery.propTypes = {
  onFocus: func,
  onBlur: func,
  onSearch: func,
  onOpenHistory: func,
  focusEditor: bool,
  showSearchQuery: bool,
  showInstructions: bool,
  onChangeEditor: func,
  onChangeInstructions: func,
  editable: bool,
  query: string,
  errorQuery: string,
  errorValidate: oneOfType([shape({}), number])

};

export default SearchQuery;
