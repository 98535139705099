export function colSizes(detailsVisible) {
  const sizes = {
    xs: { span: 24, order: 4 },
    md: { span: detailsVisible ? 24 : 12, order: 4 },
    xl: { span: detailsVisible ? 12 : 8, order: 4 }
  };
  const sizeOrder1 = {
    xs: { span: sizes.xs.span, order: 2 },
    md: { span: sizes.md.span, order: detailsVisible ? 2 : 1 },
    xl: { span: sizes.xl.span, order: 1 }
  };
  const sizeOrder2 = {
    xs: { span: sizes.xs.span, order: 3 },
    md: { span: sizes.md.span, order: 3 },
    xl: { span: sizes.xl.span, order: detailsVisible ? 3 : 2 }
  };
  const sizeOrder3 = {
    xs: { span: sizes.xs.span, order: 1 },
    md: { span: sizes.md.span, order: detailsVisible ? 1 : 2 },
    xl: { span: sizes.xl.span, order: detailsVisible ? 2 : 3 }
  };
  const sizeOrder4 = {
    xs: { span: sizes.xs.span, order: 5 },
    md: { span: sizes.md.span, order: 5 },
    xl: { span: sizes.xl.span, order: detailsVisible ? 5 : 6 }
  };
  const sizeOrder5 = {
    xs: { span: sizes.xs.span, order: 6 },
    md: { span: sizes.md.span, order: 6 },
    xl: { span: sizes.xl.span, order: detailsVisible ? 6 : 7 }
  };
  const sizeOrder6 = {
    xs: { span: sizes.xs.span, order: 7 },
    md: { span: sizes.md.span, order: 7 },
    xl: { span: sizes.xl.span, order: detailsVisible ? 7 : 8 }
  };
  return { sizes, sizeOrder1, sizeOrder2, sizeOrder3, sizeOrder4, sizeOrder5, sizeOrder6 };
}
