import { List } from 'react-virtualized';
import { func, shape, arrayOf } from 'prop-types';
import classes from './MenuList.module.scss';

const menuListStyle = {
  width: '100%'
};

const menuListContainerStyle = {
  width: '100%',
  maxWidth: '100%'
};

const MenuList = ({ children = [], options = [], getValue = () => null }) => {
  const rows = children;
  const [value] = getValue();
  const height = Math.min(45 * (rows.length || 0), 315);
  const initialOffset = options.indexOf(value) * height;

  const rowRenderer = ({
    key, index, style
  }) => (
    <div
      key={key}
      style={style}
      className={classes.menuRow}
    >
      {rows[index]}
    </div>
  );

  return (
    <List
      width={1}
      rowHeight={45}
      rowCount={rows.length || 0}
      rowRenderer={rowRenderer}
      initialScrollOffset={initialOffset}
      height={height}
      itemCount={children?.length}
      itemSize={height}
      containerStyle={menuListContainerStyle}
      style={menuListStyle}
    />
  );
};

MenuList.propTypes = {
  children: arrayOf(shape({})),
  options: arrayOf(shape({})),
  getValue: func
};

export default MenuList;
