import { node, string, oneOfType } from 'prop-types';
import { motion } from 'framer-motion';

export function AnimatePage({ children = '' }) {
  return (
    <motion.div
      initial={{ x: -300, opacity: 0.5, width: '100%' }}
      animate={{ x: 0, opacity: 1, width: '100%' }}
      exit={{ x: 300, opacity: 0, width: '100%' }}
      transition={{ duration: 0.5, type: 'spring' }}
    >
      {children}
    </motion.div>
  );
}

AnimatePage.propTypes = {
  children: oneOfType([string, node])
};
