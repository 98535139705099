import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageData: [],
  currentPage: null,
  savedFilters: null,
  responseFilters: null
};

const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    addPages(state, { payload }) {
      state.pageData = payload?.length ? payload : [];
    },
    addNewPage(state, { payload }) {
      state.pageData = state.pageData.concat(payload);
    },
    addCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    deletePage(state, { payload }) {
      state.pageData = state.pageData.filter(page => page?.id !== payload);
    },
    changePage(state, { payload }) {
      state.pageData = state.pageData.map(page => (page?.id === payload?.id ? { ...page, name: payload?.name } : page));
      payload?.filter && (state.currentPage = payload);
    },
    savedFilters(state, { payload }) {
      const filtersArr = Object.entries(payload)?.filter(el => !!el[1]?.length || typeof el[1] === 'boolean');
      const pageFilters = filtersArr?.map(el => (Array.isArray(el[1]) ? [el[0], el[1].map(item => String(item?.id || item))] : [el[0], String(el[1])]));
      state.savedFilters = Object.fromEntries(pageFilters);
      state.responseFilters = filtersArr;
    },
    clearPages(state) {
      state.pageData = [];
      state.currentPage = null;
      state.savedFilters = null;
      state.responseFilters = null;
    },
    clearCurrentPage(state) {
      state.currentPage = null;
      state.savedFilters = null;
      state.responseFilters = null;
    }
  }
});

export const { addPages, addNewPage, deletePage, changePage, addCurrentPage, clearCurrentPage, clearPages, savedFilters } = pagesSlice.actions;
export default pagesSlice.reducer;
