import { string, number } from 'prop-types';

export function Html({ width = 16, height = 16, color = '#420C8D', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none">
      <mask id="mask0_1442_20660" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1442_20660)">
        <path d="M6.4 10.4L7.33333 9.45L5.88333 8L7.33333 6.55L6.4 5.6L4 8L6.4 10.4ZM9.6 10.4L12 8L9.6 5.6L8.66667 6.55L10.1167 8L8.66667 9.45L9.6 10.4ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333Z" fill={color || '#420C8D'} />
      </g>
    </svg>
  );
}

Html.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
