import { useState } from 'react';
import { string, func } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, TextField } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyProposeSource.module.scss';

const BodyProposeSource = ({
  className = '',
  language = 'uk',
  onCancel = () => null,
  onClickOk = () => null
}) => {
  const [sourceUrl, setSourceUrl] = useState('');
  const [error, setError] = useState('');

  const onChangeSourceName = (e) => {
    e.target.value.length > 500 && !error && setError('MAX_LENGTH');
    e.target.value.length < 4 && !error && setError('MIN_LENGTH');
    e.target.value.length <= 500 && e.target.value.length >= 4 && error && setError('');
    setSourceUrl(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedEvent({ category: 'Filters', event: 'Clicked_Apply_Button_Suggest_Source_Modal' });
    onClickOk({ suggested_source_url: sourceUrl });
    onCancel();
  };
  const disabled = !sourceUrl?.length || !!error;

  return (
    <form
      onSubmit={onSubmit}
      className={cn(classes.bodyProposeSource, className)}
    >
      <div className={cn(classes.inputBox)}>
        <TextField
          labelText={t('bodyProposeSource', 'ENTER_URL', language)}
          classNameLabelText={classes.label}
          value={sourceUrl}
          className={classes.modalInput}
          onChange={onChangeSourceName}
          placeholder={t('bodyProposeSource', 'ENTER_LINK', language)}
          errorText={error ? t('bodyProposeSource', error, language) : ''}
        />
      </div>

      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />

        <Button
          type="submit"
          disabled={disabled}
          onClick={onSubmit}
          className={classes.buttonOk}
          textButton={t('btn', 'TRIMITE', language)}
          green32
        />
      </div>
    </form>
  );
};

BodyProposeSource.propTypes = {
  className: string,
  onCancel: func,
  onClickOk: func,
  language: string
};

export default BodyProposeSource;
