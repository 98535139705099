import { string, number, oneOfType } from 'prop-types';
import cn from 'classnames';
import { Icon } from 'Components';
import classes from './SearchResultsHeader.module.scss';

function RadioReport({ value = '', label = '', icon = '', labelInfo = '', count = '', iconClass, ...rest }) {
  const isMedia = (value === 'media' || value === 'socialnetwork');
  const iconProps = isMedia ? {
    width: 14,
    height: 14,
    color: '#fff',
    className: cn(classes.radioIcon, value === 'media' && classes.radioIcon_media)
  } : {
    width: 20,
    height: 20
  };
  return (
    <div className={cn(classes.radioReport, rest?.checked && classes.radioReport_checked)}>
      <input
        id={`radio-option-${value}`}
        className={classes.radioInput}
        type="radio"
        value={value}
        {...rest}
      />
      <label
        className={classes.radioLabel}
        htmlFor={`radio-option-${value}`}
      >
        {icon && value !== 'full' && (
        <Icon
          type={icon}
          className={iconClass}
          {...iconProps}
        />
        )}
        {value === 'full' && <div className={cn(classes.radioIcon, classes.radioIcon_full)}><div /></div>}
        {labelInfo ? (
          <div className={classes.radioLabelInfo}>
            <div>{label}</div>
            <div>{labelInfo}</div>
          </div>
        ) : label}
        { (!!count || count === 0) && <div className={classes.radioCount}>{count}</div>}
      </label>
    </div>
  );
}

RadioReport.propTypes = {
  value: oneOfType([string, number]),
  label: string,
  icon: string,
  iconClass: string,
  labelInfo: string,
  count: oneOfType([string, number])

};

export default RadioReport;
