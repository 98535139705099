import { api } from 'api/apiInstance';

export const editPeriod = async (body) => {
  const response = await api.put('date/period/', body);
  return response?.data;
};

export const getPeriod = async () => {
  const response = await api.get('date/period/');
  return response?.data;
};
