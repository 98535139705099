/* eslint-disable no-unused-vars */
import { string, func, shape, number, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button } from 'Components';
import { useSendUserEvent } from 'storage/queryHooks.js';
import classes from './BodyIncreaseKeywords.module.scss';

const BodyIncreaseKeywords = ({
  className = '',
  onClickOk = () => null,
  language = 'uk',
  data = { name: '', id: 0 },
  usedKeywords = { limit: 0, saved: 0 }
}) => {
  const eventData = useSendUserEvent({ enabled: !data?.id, body: { event: 'open_add_keyword' } });
  const onSubmit = (e) => {
    e.preventDefault();
    onClickOk({ topic: 'additional_keyword' });
  };
  const limit = usedKeywords?.limit || 0;
  const saved = usedKeywords?.saved || 0;
  return (
    <form onSubmit={onSubmit} className={cn(classes.bodyDelModal, className)}>
      <div className={classes.discription}>
        <div className={classes.image} />
        {t('bodyAddTagModal', 'USED_KEYWORDS1', language)}
        <strong>
          {saved}
          /
          {limit}
        </strong>
        {t('bodyAddTagModal', 'USED_KEYWORDS2', language)}
      </div>
      <div className={classes.buttonBox}>
        <Button
          type="submit"
          textButton={t('btn', 'CONTACT_MANAGER', language)}
          className={classes.buttonIncrease}
          green32
        />
      </div>
    </form>
  );
};

BodyIncreaseKeywords.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onClickOk: func,
  language: string,
  usedKeywords: shape({})
};

export default BodyIncreaseKeywords;
