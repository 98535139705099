import { useState, useEffect, useMemo } from 'react';
import t from 'utils/translateFunc';
import { SearchField, SkeletonFilterList, NoSearchData, FilterList } from 'Components';
import { useGetCountries, useGetRegions, useGetCities } from 'storage/queryHooks';
import { useDispatch, useSelector } from 'react-redux';
import { removeFilterItem, addFilterItem, addCountries, addRegions, addCities } from 'storage/actions';
import useDebounceValue from 'hooks/useDebounce';
import classes from './FilterGeography.module.scss';

const FilterGeography = () => {
  const [filterSearchCountry, setFilterSearchCountry] = useState('');
  const [filterSearchRegion, setFilterSearchRegion] = useState('');
  const [filterSearchCity, setFilterSearchCity] = useState('');
  const dispatch = useDispatch();
  const { filterList, countries, regions, cities } = useSelector(state => state?.filters);
  const { language } = useSelector(state => state?.userInfo);

  const icontainsCountry = useDebounceValue(filterSearchCountry, 1000);
  const icontainsRegion = useDebounceValue(filterSearchRegion, 1000);
  const icontainsCity = useDebounceValue(filterSearchCity, 1000);

  const getCountriesId = filterList?.filter(filter => filter.filterName === 'country')
    ?.reduce((acc, el) => (!acc ? acc = `${el.id}` : acc = `${acc},${el.id}`), '');
  const getRegionsId = filterList?.filter(filter => filter.filterName === 'region')
    ?.reduce((acc, el) => (!acc ? acc = `${el.id}` : acc = `${acc},${el.id}`), '');

  const { isLoading: countriesLoading } = useGetCountries({ query: { name: icontainsCountry }, enabled: !!icontainsCountry });
  const { isLoading: regionsLoading } = useGetRegions({ query: { country: getCountriesId, name: icontainsRegion }, enabled: !!icontainsRegion });
  const { isLoading: citiesLoading } = useGetCities({ query: { country: getCountriesId, region: getRegionsId, name: icontainsCity }, enabled: !!icontainsCity });

  const itemTypeCountry = t('filtrationPanel', 'COUNTRY', language);
  const itemTypeRegion = t('filtrationPanel', 'REGION', language);
  const itemTypeCity = t('filtrationPanel', 'CITY', language);

  useEffect(() => {
    if (!filterSearchCountry?.length) {
      dispatch(addCountries([]));
    }
    if (!filterSearchRegion?.length) {
      dispatch(addRegions([]));
    }
    if (!filterSearchCity?.length) {
      dispatch(addCities([]));
    }
  }, [filterSearchCountry?.length, filterSearchRegion?.length, filterSearchCity?.length]);

  const selectDataCountries = useMemo(() => filterList?.filter(listItem => listItem?.type === itemTypeCountry), [filterList?.length]);
  const selectDataRegions = useMemo(() => filterList?.filter(listItem => listItem?.type === itemTypeRegion), [filterList?.length]);
  const selectDataCities = useMemo(() => filterList?.filter(listItem => listItem?.type === itemTypeCity), [filterList?.length]);

  const disableCountry = !selectDataCountries?.length && (!!selectDataRegions?.length || !!selectDataCities?.length);
  const disableRegion = (!selectDataCountries?.length && !selectDataRegions?.length) && !!selectDataCities?.length;

  const addItem = ({ id, name, type, filterName }) => { dispatch(addFilterItem({ id, name, type, filterName })); };
  const removeItem = ({ id, filterName }) => { dispatch(removeFilterItem({ id, filterName })); };

  return (
    <div className={classes.filterGeography}>
      <section className={classes.box}>
        <div className={classes.searchWrap}>
          <p className={classes.name}>
            {t('filterGeography', 'COUNTRY', language)}
          </p>
          <SearchField
            placeholder={t('filterGeography', 'ENTER_COUNTRY', language)}
            value={filterSearchCountry}
            setValue={setFilterSearchCountry}
            disabled={disableCountry}
          />
        </div>
        <SkeletonFilterList fetching={countriesLoading}>
          {(!!countries.length && !disableCountry) ? (
            <FilterList
              dataFilters={countries?.map(({ id, title, label }) => ({ id, title: label || title }))}
              filterSearch={filterSearchCountry}
              selectData={selectDataCountries}
              addItem={addItem}
              removeItem={removeItem}
              filterType={itemTypeCountry}
              filterName="country"
              language={language}
            />
          ) : !!filterSearchCountry && <NoSearchData language={language} />}
        </SkeletonFilterList>
      </section>

      <section className={classes.box}>
        <div className={classes.searchWrap}>
          <p className={classes.name}>{t('filterGeography', 'REGION', language)}</p>
          <SearchField
            placeholder={t('filterGeography', 'ENTER_REGION', language)}
            value={filterSearchRegion}
            setValue={(value) => { setFilterSearchRegion(value); setFilterSearchCountry(''); }}
            disabled={disableRegion}
          />
        </div>
        <SkeletonFilterList fetching={regionsLoading}>
          {!!regions?.length && !disableRegion ? (
            <FilterList
              dataFilters={regions?.map(({ id, title, label }) => ({ id, title: label || title }))}
              filterSearch={filterSearchRegion}
              selectData={selectDataRegions}
              addItem={addItem}
              removeItem={removeItem}
              filterType={itemTypeRegion}
              filterName="region"
              language={language}
            />
          ) : !!filterSearchRegion && <NoSearchData language={language} />}
        </SkeletonFilterList>

      </section>

      <section className={classes.box}>
        <div className={classes.searchWrap}>
          <p className={classes.name}>{t('filterGeography', 'CITY', language)}</p>
          <SearchField
            placeholder={t('filterGeography', 'ENTER_CITY', language)}
            value={filterSearchCity}
            setValue={(value) => { setFilterSearchCity(value); setFilterSearchRegion(''); setFilterSearchCountry(''); }}
          />
        </div>
        <SkeletonFilterList fetching={citiesLoading}>
          {cities.length ? (
            <FilterList
              dataFilters={cities?.map(({ id, title, label }) => ({ id, title: label || title }))}
              filterSearch={filterSearchCity}
              selectData={selectDataCities}
              addItem={addItem}
              removeItem={removeItem}
              filterType={itemTypeCity}
              filterName="city"
              language={language}
            />
          ) : !!filterSearchCity && <NoSearchData language={language} />}
        </SkeletonFilterList>

      </section>
    </div>
  );
};

export default FilterGeography;
