export const stylesOrdering = isMobile => ({
  control: (provided, state) => ({
    ...provided,
    width: 'fit-content',
    minWidth: '224px',
    minHeight: '28px',
    maxHeight: '28px',
    backgroundColor: state.menuIsOpen ? 'var(--green25metricom)' : '#FFFFFF',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '4px',
    opacity: state.isDisabled ? '0.8' : '1',
    cursor: 'pointer',
    margin: 0n
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 8px'
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '4px',
    minWidth: '280px',
    boxShadow: '0px 2px 10px rgba(221, 226, 241, 0.6)',
    width: 'auto',
    height: 'auto',
    zIndex: 9999,
    ...(isMobile ? { left: 0 } : { right: 0 })
  }),
  menuList: provided => ({
    ...provided,
    paddingBottom: '3px',
    overflow: 'hidden'

  }),
  singleValue: provided => ({
    ...provided,
    margin: '0',
    fontSize: '12px',
    lineHeight: '21px',
    fontWeight: '400',
    color: '#5A5E79'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    svg: {
      fill: '#5A5E79 !important'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    marginBottom: '1px',
    padding: '10px 16px',
    height: '39px',
    fontSize: ' 14px',
    lineHeight: '24px',
    fontWeight: state.isSelected ? '500' : '400',
    cursor: 'pointer',
    color: '#31254b',
    ':nth-of-type(2)': {
      borderBottom: '1px solid #E0E0F3',
      height: '43px'
    },
    ':nth-of-type(3)': {
      borderBottom: '1px solid #E0E0F3',
      height: '43px'
    },
    borderBottom: '1px solid transparent',
    backgroundColor:
      state.isFocused || state.isSelected ? '#F4F6F9' : '#FFFFFF',
    ':active': {
      backgroundColor: '#F4F6F9'
    }
  })
});

export const stylesTabs = isMobile => ({
  control: (provided, state) => ({
    ...provided,
    width: 'fit-content',
    minWidth: '50px',
    minHeight: '28px',
    maxHeight: '28px',
    backgroundColor: '#F6F7FA',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '4px',
    opacity: state.isDisabled ? '0.8' : '1',
    cursor: 'pointer',
    margin: 0
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 2px 0 8px'
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: '0px 2px 10px rgba(221, 226, 241, 0.6)',
    width: 'auto',
    height: 'auto',
    minWidth: '60px',
    zIndex: 9999,
    ...(isMobile ? { left: 0 } : { right: 0 })
  }),
  menuList: provided => ({
    ...provided,
    paddingBottom: '3px'
  }),
  singleValue: provided => ({
    ...provided,
    margin: '0',
    fontSize: '12px',
    lineHeight: '21px',
    fontWeight: '400',
    color: '#24252D'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    svg: {
      fill: '#5A5E79 !important'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    marginBottom: '1px',
    padding: '6px 12px',
    height: '30px',
    fontSize: ' 12px',
    lineHeight: '28px',
    fontWeight: state.isSelected ? '500' : '400',
    cursor: 'pointer',
    color: '#31254b',
    borderBottom: '1px solid transparent',
    backgroundColor:
      state.isFocused || state.isSelected ? '#F4F6F9' : '#FFFFFF',
    ':active': {
      backgroundColor: '#F4F6F9'
    }
  })
});
