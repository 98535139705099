import { BrowserRouter } from 'react-router-dom';

import RoutesWithAnimation from './RoutesWithAnimation';

const RoutesComponent = () => (
  <BrowserRouter>
    <RoutesWithAnimation />
  </BrowserRouter>
);

export default RoutesComponent;
