import { string, number } from 'prop-types';

export const ArrowForward = ({ width = 24, height = 24, color = '#1C1B1F', className = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 24 25" fill="none">
    <mask id="mask0_316_6255" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
      <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_316_6255)">
      <path d="M16.175 13.5H4V11.5H16.175L10.575 5.9L12 4.5L20 12.5L12 20.5L10.575 19.1L16.175 13.5Z" fill={color || '#1C1B1F'} />
    </g>
  </svg>

);

ArrowForward.propTypes = {
  width: number,
  height: number,
  className: string,
  color: string
};
