import axios from 'axios';
import store from 'storage';
import Bowser from 'bowser';
import MobileDetect from 'mobile-detect';
import { AMPLITUDE_SLUG, AMPLITUDE_URL } from 'config';
import { getSessionId } from 'utils/amplitude';

const amplitudeInstance = axios.create({
  baseURL: AMPLITUDE_URL,
  headers: { 'Content-Type': 'application/json' }
});

amplitudeInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const deviceType = new MobileDetect(window.navigator.userAgent);
    const { id, username, email, isStaff, isSuperuser, company, companyInfo } = state?.userInfo?.user || {};
    const user = {
      id: id || '',
      email: email || '',
      is_personal: isStaff || isSuperuser,
      company: company || '',
      project: companyInfo?.project || '',
      tariff_plan: companyInfo?.plan || '',
      device_type: deviceType.mobile() ? 'mobile' : 'desktop',
      browser: Bowser.getParser(window.navigator.userAgent)?.parsedResult?.browser
    };
    config.data.events[0].user_id = `${AMPLITUDE_SLUG}_${email || ''}_${username}`;
    config.data.events[0].user_properties = user;
    config.data.events[0].ip = '$remote';
    config.data.events[0].language = state?.userInfo?.language;
    config.data.events[0].app_version = window.location.hostname;
    config.data.events[0].session_id = getSessionId();
    config.data.events[0].os_name = navigator?.platform;
    config.data.events[0].device_type = user.device_type;

    return config;
  },
  error => Promise.reject(error)
);

export default amplitudeInstance;
