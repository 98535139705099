import Immutable from 'seamless-immutable';

const R = require('ramda');

const isImmutable = R.has('asMutable');
const convertToJs = state => state.asMutable({ deep: true });
const fromImmutable = R.when(isImmutable, convertToJs);
const toImmutable = raw => Immutable(raw);

export default {
  out: state => toImmutable(state),
  in: raw => fromImmutable(raw)
};
