import { useMemo, memo } from 'react';
import t from 'utils/translateFunc';
import { Row, Col } from 'antd';
import { Button, Icon, SkeletonAlertsList, ErrorBoundarySentry } from 'Components';
import cn from 'classnames';
import { bool, shape, func, array, string } from 'prop-types';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './AlertsTable.module.scss';
import CustomCard from '../OnboardingCards/CustomCard';
import ExampleCard from '../OnboardingCards/ExampleCard';
import ReportIssueCard from '../OnboardingCards/ReportIssueCard';
import OtherChannelCard from '../OnboardingCards/OtherChannelCard';
import AlertCard from '../AlertCard';
import { colSizes } from './colSizes';

const AlertsTable = memo(({ alerts = { alertsSimple: [], alertsNegative: [], alertsActivity: [] }, userContacts = null, detailsVisible = false, alertData = null, fetchings = null, onEditAlert = () => null, alertsListEdit = '' }) => {
  const { sizes, sizeOrder1, sizeOrder2, sizeOrder3 } = colSizes(detailsVisible);
  const { alertsSimple, alertsNegative, alertsActivity } = alerts;
  const hasEmpty = useMemo(() => alertsSimple.some(item => !item?.id), [alertsSimple]);
  const hasAlertsSimple = useMemo(() => alertsSimple.some(item => typeof item?.id === 'number'), [alertsSimple]);
  const hasAlertsNegative = useMemo(() => alertsNegative.some(item => typeof item?.id === 'number'), [alertsNegative]);
  const hasAlertsActivity = useMemo(() => alertsActivity.some(item => typeof item?.id === 'number'), [alertsActivity]);

  function sizeFunc(name, id) {
    if (!id) return sizeOrder1;
    if (name === 'keep_hand') return sizeOrder1;
    if (name === 'example_alert') return sizeOrder2;
    if (name === 'try') return sizeOrder3;
    return sizes;
  }

  const sendClickWidgetEvent = () => sendAlertsEvent({ category: 'Alerts', event: 'Clicked_On_Widget_In_Alerts' });

  return (
    <div className={classes.alertsTableWrap}>
      <div
        className={cn(classes.alertsWrapper, detailsVisible && classes.alertsWrapper_panelOn)}
        id="alerts-simple-cards"
      >
        <div className={classes.alertsHeader}>
          <div className={classes.headerText}>
            <Icon type="notifications" role="icon" />
            {t('alerts', 'ALERTS') }
          </div>
          <Button
            onClick={() => {
              sendAlertsEvent({ category: 'Alerts', event: 'Clicked_Add_Alert_Button' });
              !fetchings?.simple && onEditAlert({ alerts_list: 'alerts-simple' });
            }}
            textButton={t('btn', 'ADD')}
            className={classes.addButton}
            {...(hasAlertsSimple ? { purpleTransparent32: true } : { green32: true })}
          />
        </div>
        <SkeletonAlertsList sizes={sizes} fetching={fetchings?.simple}>
          <Row gutter={[16, 16]} align="start" className={classes.alertsList}>
            {!!alertsSimple?.length && alertsSimple.map((alertItem, i) => (
              <Col key={alertItem?.id || i} {...(hasEmpty ? sizes : sizeFunc(alertItem?.name, alertItem?.id))}>
                {alertItem?.type === 'keep_hand' && (
                <CustomCard
                  isBlur={detailsVisible && alertsListEdit === 'alerts-simple'}
                  className={classes.keepHandCard}
                  data={{ title: t('alerts', 'KEEP_HAND'), subtitle: t('alerts', 'KEEP_HAND_TEXT') }}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings?.simple && onEditAlert({ alerts_list: 'alerts-simple' });
                  }}
                />
                )}
                {alertItem?.type === 'example_alert' && (
                <ExampleCard
                  isBlur={detailsVisible && alertsListEdit === 'alerts-simple'}
                  purple
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings?.simple && onEditAlert({ alerts_list: 'alerts-simple' });
                  }}
                />
                )}
                {alertItem?.type === 'try' && (
                <CustomCard
                  isBlur={detailsVisible && alertsListEdit === 'alerts-simple'}
                  className={classes.tryCard}
                  data={{ title: t('alerts', 'LETS_TRY'), subtitle: t('alerts', 'FEW_MINUTES') }}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings?.simple && onEditAlert({ alerts_list: 'alerts-simple' });
                  }}
                />
                )}
                {!alertItem?.type && (
                  <ErrorBoundarySentry>
                    <AlertCard
                      data={alertsListEdit === 'alerts-simple' && alertData?.id === alertItem?.id ? alertData : alertItem}
                      userContacts={userContacts}
                      isEnabled={alertItem?.is_enabled}
                      onEditAlert={({ alert_data, isToggle }) => onEditAlert({ alert_data: isToggle ? alertItem : alert_data, isToggle, alerts_list: 'alerts-simple' })}
                      isSelected={alertsListEdit === 'alerts-simple' && alertData?.id === alertItem?.id}
                    />
                  </ErrorBoundarySentry>
                )}
              </Col>
            )) }
          </Row>
        </SkeletonAlertsList>
      </div>
      <div
        className={cn(classes.alertsWrapper, detailsVisible && classes.alertsWrapper_panelOn)}
        id="alerts-negative-cards"
      >
        <div className={classes.alertsHeader}>
          <div className={classes.headerText}>
            <Icon type="sad" role="icon" />
            {t('alerts', 'ALERTS_NEGATIVE') }
          </div>
          <Button
            onClick={() => {
              sendAlertsEvent({ category: 'Alerts', event: 'Clicked_Add_Alert_By_Negative_Button' });
              !fetchings?.negative && onEditAlert({ alerts_list: 'alerts-negative' });
            }}
            textButton={t('btn', 'ADD')}
            className={classes.addButton}
            {...(hasAlertsNegative ? { purpleTransparent32: true } : { green32: true })}
          />
        </div>
        <SkeletonAlertsList sizes={sizes} fetching={fetchings?.negative}>
          <Row gutter={[16, 16]} align="start" className={classes.alertsList}>
            {!!alertsNegative?.length && alertsNegative.map((alertItem, i) => (
              <Col {...sizes} key={alertItem?.id || i}>
                {alertItem?.type === 'can_search' && (
                <CustomCard
                  isBlur={detailsVisible && alertsListEdit === 'alerts-negative'}
                  className={classes.preventCard}
                  data={{ title: t('alerts', 'PREVENT_CRISIS'), subtitle: t('alerts', 'PREVENT_CRISIS_DESCRIPTION') }}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings?.negative && onEditAlert({ alerts_list: 'alerts-negative' });
                  }}
                />
                )}
                { alertItem?.type === 'report_issue' && (
                <ReportIssueCard
                  isBlur={detailsVisible && alertsListEdit === 'alerts-negative'}
                  purple
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings?.negative && onEditAlert({ alerts_list: 'alerts-negative' });
                  }}
                />
                )}
                {alertItem?.type === 'keep_hand' && (
                <CustomCard
                  isBlur={detailsVisible && alertsListEdit === 'alerts-negative'}
                  className={classes.competitorsCard}
                  data={{ title: t('alerts', 'COMPETITORS_ALERTS'), subtitle: t('alerts', 'COMPETITORS_ALERTS_TEXT') }}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings?.negative && onEditAlert({ alerts_list: 'alerts-negative' });
                  }}
                />
                )}
                {!alertItem?.type && (
                  <ErrorBoundarySentry>
                    <AlertCard
                      data={alertsListEdit === 'alerts-negative' && alertData?.id === alertItem?.id ? alertData : alertItem}
                      userContacts={userContacts}
                      isSelected={alertsListEdit === 'alerts-negative' && alertData?.id === alertItem?.id}
                      isEnabled={alertItem?.is_enabled}
                      onEditAlert={({ alert_data, isToggle }) => onEditAlert({ alert_data: isToggle ? alertItem : alert_data, isToggle, alerts_list: 'alerts-negative' })}
                    />
                  </ErrorBoundarySentry>
                )}
              </Col>
            )) }
          </Row>
        </SkeletonAlertsList>
      </div>
      <div className={cn(classes.alertsWrapper, detailsVisible && classes.alertsWrapper_panelOn)} id="alerts-activity-cards">
        <div className={classes.alertsHeader}>
          <div className={classes.headerText}>
            <Icon type="flash" role="icon" />
            {t('alerts', 'ALERTS_ACTIVITY') }
          </div>
          <Button
            onClick={() => {
              sendAlertsEvent({ category: 'Alerts', event: 'Clicked_Add_Alert_By_Activity_Button' });
              !fetchings?.activity && onEditAlert({ alerts_list: 'alerts-activity' });
            }}
            textButton={t('btn', 'ADD')}
            className={classes.addButton}
            {...(hasAlertsActivity ? { purpleTransparent32: true } : { green32: true })}
          />
        </div>
        <SkeletonAlertsList sizes={sizes} fetching={fetchings?.activity}>
          <Row gutter={[16, 16]} align="start" className={classes.alertsList}>
            {!!alertsActivity?.length && alertsActivity.map((alertItem, i) => (
              <Col key={alertItem?.id || i} {...sizes}>
                {alertItem?.type === 'surge_activity' && (
                <CustomCard
                  isBlur={detailsVisible && alertsListEdit === 'alerts-activity'}
                  className={classes.activityCard}
                  data={{ title: t('alerts', 'SURGE_ACTIVITY'), subtitle: t('alerts', 'SURGE_ACTIVITY_TEXT') }}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings?.activity && onEditAlert({ alerts_list: 'alerts-activity' });
                  }}
                />
                )}
                {alertItem?.type === 'involvement' && (
                <CustomCard
                  isBlur={detailsVisible && alertsListEdit === 'alerts-activity'}
                  className={classes.involvementCard}
                  data={{ title: t('alerts', 'INVOLVEMENT'), subtitle: t('alerts', 'INVOLVEMENT_TEXT') }}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings?.activity && onEditAlert({ alerts_list: 'alerts-activity' });
                  }}
                />
                )}
                {alertItem?.type === 'other_channel' && (
                <OtherChannelCard
                  isBlur={detailsVisible && alertsListEdit === 'alerts-activity'}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings?.activity && onEditAlert({ alerts_list: 'alerts-activity' });
                  }}
                />
                )}
                {!alertItem?.type && (
                  <ErrorBoundarySentry>
                    <AlertCard
                      isSelected={alertsListEdit === 'alerts-activity' && alertData?.id === alertItem?.id}
                      data={alertsListEdit === 'alerts-activity' && alertData?.id === alertItem?.id ? alertData : alertItem}
                      userContacts={userContacts}
                      isEnabled={alertItem?.is_enabled}
                      onEditAlert={({ alert_data, isToggle }) => onEditAlert({ alert_data: isToggle ? alertItem : alert_data, isToggle, alerts_list: 'alerts-activity' })}
                    />
                  </ErrorBoundarySentry>
                )}
              </Col>
            )) }
          </Row>
        </SkeletonAlertsList>
      </div>
    </div>
  );
});

AlertsTable.propTypes = {
  detailsVisible: bool,
  onEditAlert: func,
  alertData: shape({}),
  fetchings: shape({}),
  userContacts: shape({}),
  alerts: shape({
    alertsSimple: array,
    alertsNegative: array,
    alertsActivity: array
  }),
  alertsListEdit: string
};

export default AlertsTable;
