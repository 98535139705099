import { useState, useEffect } from 'react';
import moment from 'moment';
import { bool, func, string } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { InfoBtn, Loader } from 'Components';
import { isFilledArray } from 'utils/common';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import { useSelector } from 'react-redux';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './Datepicker.module.scss';
import DateBox from './parts/DateBox/DateBox';
import Calendar from './parts/Calendar/Calendar';

const Datepicker = ({ onCancel = () => null, setDatePicker = () => null, disabledPeriod = false, isLoading = false, language = 'uk' }) => {
  const dateObj = useSelector(state => state?.datePicker?.dateObj);

  const isMobile = useCheckMobileScreen();
  const periodData = [
    { value: 'quarter', label: t('datepicker', 'QUARTER', language) },
    { value: 'month', label: t('datepicker', 'MONTH', language) },
    { value: 'week', label: t('datepicker', 'WEEK', language) },
    { value: 'yesterday', label: t('datepicker', 'YESTERDAY', language) },
    { value: 'today', label: t('datepicker', 'TODAY', language) }
  ];

  const [period, setPeriod] = useState('');
  const [byCreated, setByCreated] = useState(dateObj?.by_created);
  const [trigerClick, setTrigerClick] = useState(false);

  const [start, set_start] = useState(dateObj?.start);
  const [end, set_end] = useState(dateObj?.end);

  const [timeStart, set_timeStart] = useState({
    hour: moment(dateObj?.start)?.format('HH'),
    min: moment(dateObj?.start)?.format('mm'),
    day: moment(dateObj?.start)?.format('DD'),
    month: moment(dateObj?.start)?.format('MM'),
    year: moment(dateObj?.start)?.format('YYYY')
  });

  const [timeEnd, set_timeEnd] = useState({
    hour: moment(dateObj?.end)?.format('HH'),
    min: moment(dateObj?.end)?.format('mm'),
    day: moment(dateObj?.end)?.format('DD'),
    month: moment(dateObj?.end)?.format('MM'),
    year: moment(dateObj?.end)?.format('YYYY')
  });

  useEffect(() => {
    switch (period) {
      case 'quarter':
        set_start(moment()?.startOf('day')?.subtract(3, 'month')?.format('YYYY-MM-DD HH:mm'));
        set_end(moment()?.endOf('minute')?.format('YYYY-MM-DD HH:mm'));
        break;
      case 'month':
        set_start(moment()?.startOf('day')?.subtract(1, 'month')?.format('YYYY-MM-DD HH:mm'));
        set_end(moment()?.endOf('minute')?.format('YYYY-MM-DD HH:mm'));
        break;
      case 'week':
        set_start(moment()?.startOf('day')?.subtract(1, 'week')?.format('YYYY-MM-DD HH:mm'));
        set_end(moment()?.endOf('minute')?.format('YYYY-MM-DD HH:mm'));
        break;
      case 'yesterday':
        set_start(moment()?.startOf('day')?.subtract(1, 'day')?.format('YYYY-MM-DD HH:mm'));
        set_end(moment()?.endOf('day')?.subtract(1, 'day').format('YYYY-MM-DD HH:mm'));
        break;
      case 'today':
        set_start(moment()?.startOf('day')?.format('YYYY-MM-DD HH:mm'));
        set_end(moment()?.endOf('minute')?.format('YYYY-MM-DD HH:mm'));
        break;
      default:
        break;
    }
  }, [period]);

  useEffect(() => {
    set_timeStart({
      hour: moment(start)?.format('HH'),
      min: moment(start)?.format('mm'),
      day: moment(start)?.format('DD'),
      month: moment(start)?.format('MM'),
      year: moment(start)?.format('YYYY')
    });
    set_timeEnd({
      hour: moment(end)?.format('HH'),
      min: moment(end)?.format('mm'),
      day: moment(end)?.format('DD'),
      month: moment(end)?.format('MM'),
      year: moment(end)?.format('YYYY')
    });
  }, [start, end]);

  useEffect(() => {
    setPeriod('');
  }, [trigerClick]);

  const hadleRangeSelection = ({ selection }) => {
    setPeriod('');
    if (selection) {
      set_start(moment(selection.startDate).format('YYYY-MM-DD HH:mm'));
      set_end(moment(selection.endDate).endOf('day').format('YYYY-MM-DD HH:mm'));
    }
  };

  const handleStart = (value) => {
    const starValue = `${value?.year}-${(`0${value?.month}`).slice(-2)}-${value?.day} ${value?.hour}:${value?.min}`;
    set_start(moment(starValue)?.format('YYYY-MM-DD HH:mm'));
  };
  const handleEnd = (value) => {
    const endValue = `${value?.year}-${(`0${value?.month}`).slice(-2)}-${value?.day} ${value?.hour}:${value?.min}`;
    set_end(moment(endValue)?.format('YYYY-MM-DD HH:mm'));
  };

  const handlePeriod = (e) => {
    if (e.target.value !== period) {
      setPeriod(e?.target?.value);
    }
  };

  const handleByCreated = () => {
    setByCreated(!byCreated);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const startValue = moment(start)?.format('YYYY-MM-DD HH:mm');
    const endValue = moment(end)?.format('YYYY-MM-DD HH:mm');

    sendFeedEvent({
      category: 'Date Picker',
      event: 'Clicked_Apply_Button_Date_Picker',
      properties: {
        start: startValue,
        end: endValue,
        byCreated: byCreated || false,
        period
      }
    });

    setDatePicker({ start: startValue, end: endValue, by_created: byCreated });
  };

  return (
    <div className={classes.datepickerWrap}>
      <Loader size={45} fetching={isLoading} color="#A7B0C8">
        <div className={classes.leftPart}>
          <Calendar
            state={[{ startDate: moment(start).toDate(), endDate: moment(end).toDate(), key: 'selection' }]}
            hadleRangeSelection={hadleRangeSelection}
            language={language}
          />
          {!isMobile && (
          <div className={classes.description}>
            <div className={classes.byCreated}>
              <InfoBtn
                text={(
                  <>
                    <p className={classes.textTooltip}>
                      {t('tooltip', 'BY_DATE', language)}
                      {' '}
                      <span className={classes.boldText}>
                        {t('tooltip', 'RELEASE', language)}
                      </span>
                      :
                      {' '}
                      {t('tooltip', 'AT_SOURCE', language)}
                      .
                    </p>
                    <p className={classes.textTooltip}>
                      {t('tooltip', 'BY_DATE', language)}
                      {' '}
                      <span className={classes.boldText}>
                        {t('tooltip', 'PRODUCTION', language)}
                      </span>
                      :
                      {' '}
                      {t('tooltip', 'AT_LOOQME', language)}
                      .
                    </p>
                  </>
              )}
                className={classes.infoButton}
              />
              <p className={classes.byCreatedText}>
                {t('datepicker', 'BY_DATE', language)}
                :
              </p>
              <label className={classes.checkboxLabel}>
                <input
                  className={classes.visually_hidden}
                  type="checkbox"
                  name="byCreated"
                  checked={byCreated}
                  onChange={handleByCreated}
                />
                {t('datepicker', 'RELEASE', language)}
                <div
                  className={cn(
                    classes.checkbox,
                    classes.checkbox_byCreated,
                    byCreated ? classes.checkbox_true : classes.checkbox_false
                  )}
                />
                {t('datepicker', 'PRODUCTION', language)}
              </label>
            </div>
          </div>
          )}
        </div>
        <form onSubmit={onSubmit} className={classes.rightPart}>
          <section className={classes.infoSection}>
            <div className={classes.periodBox}>
              {isFilledArray(periodData) && periodData.map(periodItem => (
                <label
                  key={periodItem.value}
                  className={cn(
                    classes.period,
                    period === periodItem.value && classes.period_checked,
                    disabledPeriod && classes.period_disabled
                  )}
                >
                  <input
                    className={classes.visually_hidden}
                    type="radio"
                    name="periodName"
                    value={periodItem.value}
                    checked={period === periodItem.value}
                    onChange={handlePeriod}
                    disabled={disabledPeriod}
                  />
                  {periodItem.label}
                </label>
              ))}
            </div>

            <DateBox
              start={start}
              end={end}
              timeStart={timeStart}
              timeEnd={timeEnd}
              handleStart={handleStart}
              handleEnd={handleEnd}
              trigerClick={trigerClick}
              setTrigerClick={setTrigerClick}
              language={language}
            />
          </section>
          {isMobile && (
          <section className={classes.description}>
            <div className={classes.byCreated}>
              <InfoBtn
                text={(
                  <>
                    <p className={classes.textTooltip}>
                      {t('tooltip', 'BY_DATE', language)}
                      {' '}
                      <span className={classes.boldText}>
                        {t('tooltip', 'RELEASE', language)}
                      </span>
                      :
                      {' '}
                      {t('tooltip', 'AT_SOURCE', language)}
                    </p>
                    <p className={classes.textTooltip}>
                      {t('tooltip', 'BY_DATE', language)}
                      {' '}
                      <span className={classes.boldText}>
                        {t('tooltip', 'PRODUCTION', language)}
                      </span>
                      :
                      {' '}
                      {t('tooltip', 'AT_LOOQME', language)}
                    </p>
                  </>
              )}
                className={classes.infoButton}
              />
              <p className={classes.byCreatedText}>
                {t('datepicker', 'BY_DATE', language)}
                :
              </p>
              <label className={classes.checkboxLabel}>
                <input
                  className={classes.visually_hidden}
                  type="checkbox"
                  name="byCreated"
                  checked={byCreated}
                  onChange={handleByCreated}
                />
                {t('datepicker', 'RELEASE', language)}
                <div
                  className={cn(
                    classes.checkbox,
                    classes.checkbox_byCreated,
                    byCreated ? classes.checkbox_true : classes.checkbox_false
                  )}
                />
                {t('datepicker', 'PRODUCTION', language)}
              </label>
            </div>
          </section>
          )}
          <section className={classes.btnSection}>
            <button
              className={cn(classes.btn, classes.btnCancel)}
              onClick={onCancel}
              type="button"
            >
              {t('btn', 'CANCEL', language)}
            </button>
            <button
              type="submit"
              className={cn(classes.btn, classes.btnApply)}
              onClick={onSubmit}
            >
              {t('btn', 'APPLY', language)}
            </button>
          </section>
        </form>
      </Loader>
    </div>
  );
};

Datepicker.propTypes = {
  disabledPeriod: bool,
  setDatePicker: func,
  onCancel: func,
  language: string,
  isLoading: bool
};

export default Datepicker;
