import cn from 'classnames';
import { Icon } from 'Components';
import { func, shape, arrayOf, string } from 'prop-types';
import classes from './PopularList.module.scss';

function PopularList({ list = [], className = '', onRemove = () => null }) {
  return !!list?.length && (
    <div className={cn(classes.popularList, className)}>
      { list.map(word => (
        <div
          key={word?.text}
          className={cn(classes.popularItem)}
          style={{ background: word?.color }}
        >
          <span>{word?.text}</span>
          <Icon
            type="close_arrow"
            className={classes.btnClose}
            onClick={() => {
              onRemove({ text: word?.text });
            }}
          />
        </div>
      ))}
    </div>
  );
}

PopularList.propTypes = {
  list: arrayOf(shape({})),
  className: string,
  onRemove: func
};

export default PopularList;
