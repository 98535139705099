import { BtnDropDown } from 'Components';
import { func, shape, arrayOf, string, bool, oneOfType } from 'prop-types';
import classes from './MenuDropDown.module.scss';

const MenuDropDown = ({ onChange = () => null, active = '', menuConfig = [] }) => (
  !!menuConfig?.length && (
  <BtnDropDown
    icon="more"
    menuConfig={menuConfig}
    selectOption={onChange}
    className={classes.btnMore}
    selectClass={classes.selectClass}
    iconClass={classes.iconClass}
    active={active}
    color="#00C784"
  />
  )

);

MenuDropDown.propTypes = {
  active: string,
  onChange: func,
  menuConfig: arrayOf(shape({ name: string, icon: string, info: oneOfType([bool, string]) }))
};
export default MenuDropDown;
