import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { interceptor } from 'api/apiInstance';
import { reduxBatch } from '@manaflair/redux-batch';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import immutablePersistenceTransform from 'utils/ImmutablePersistenceTransform';
import authReducer from 'storage/auth/authReducer';
import localStorage from 'redux-persist/lib/storage';
import portalsReducer from 'storage/portals/portalsReducer';
import newsReducer from 'storage/news/newsReducer';
import * as Sentry from '@sentry/react';
import datePickerReducer from './datePicker/datePickerReducer';
import pagesReducer from './pages/pagesReducer';
import userInfoReducer from './userInfo/userReducer';
import modalsReducer from './modals/modalsReducer';
import filtersReducer from './filters/filtersReducer';
import downloadsReducer from './downloads/downloadsReducer';
import alertsReducer from './alerts/alertsReducer';
import searchReducer from './search/searchReducer';
import copilotReducer from './copilot/copilotReducer';

const persistConfig = {
  key: 'root',
  storage: localStorage,
  version: 1.02,
  transforms: [immutablePersistenceTransform],
  whitelist: ['auth', 'userInfo', 'portals']
};

const appReducer = combineReducers({
  userInfo: userInfoReducer,
  portals: portalsReducer,
  datePicker: datePickerReducer,
  auth: authReducer,
  pages: pagesReducer,
  news: newsReducer,
  filters: filtersReducer,
  modals: modalsReducer,
  downloads: downloadsReducer,
  alerts: alertsReducer,
  search: searchReducer,
  copilot: copilotReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/clearState') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      ignoredPaths: ['datePicker']
    }
  }),
  enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(reduxBatch).concat(sentryReduxEnhancer),
  devTools: process.env.NODE_ENV === 'development',
  debug: process.env.NODE_ENV === 'development'
});
interceptor(store);
export const persistor = persistStore(store);

export default store;
