import cn from 'classnames';
import t from 'utils/translateFunc';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import classes from './NoSearchData.module.scss';

function NoSearchData({ className = '' }) {
  const language = useSelector(state => state?.userInfo?.language);
  return (
    <div className={cn(classes.nosearch, className)}>
      <p className={classes.text_nosearch}>
        {t('kSTegFilter', 'NO_SEARCH', language)}
      </p>
    </div>
  );
}

NoSearchData.propTypes = {
  className: string
};

export default NoSearchData;
