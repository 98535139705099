import { useState, useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';
import hasOwn from 'lodash/has';
import { string, func, number, shape, bool, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import {
  Button, TextField, ArticlesListPreview, SelectCustom, PopoverColorPicker, Icons, Loader, ErrorBoundarySentry, InfoBtn, EditableLabel } from 'Components';
import useDebounceValue from 'hooks/useDebounce';
import { useGetTag, useGetPreviewAricles } from 'storage/queryHooks';
import moment from 'moment';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyAddEditTegModal.module.scss';

const BodyAddEditTegModal = ({
  className = '',
  data = { name: '', id: 0 },
  onClickOk = () => null,
  onCancel = () => null,
  onManyMentions = () => null,
  language = 'uk',
  isLoading = false
}) => {
  const history = useNavigate();
  const fishOr = [{ label: t('bodyAddTagModal', 'EXAMPLE_APPLE', language), value: 'example_1' }];
  const fishAnd = [{ label: t('bodyAddTagModal', 'EXAMPLE_iPHONE', language), value: 'example_1' }];
  const fishNot = [{ label: t('bodyAddTagModal', 'EXAMPLE_APPLEFRUIT', language), value: 'example_1' }];
  const setFor = useMemo(() => [
    { value: '', label: t('sourceAssignTeg', 'CURRENT_MOMENT', language) },
    { value: 'today', label: t('sourceAssignTeg', 'TODAY', language) },
    { value: 'yesterday', label: t('sourceAssignTeg', 'YESTERDAY', language) },
    { value: 'week', label: t('sourceAssignTeg', 'LAST_WEEK', language) },
    { value: 'month', label: t('sourceAssignTeg', 'LAST_MONTH', language) },
    { value: '3month', label: t('sourceAssignTeg', 'LAST_3MONTH', language) },
    { value: '6month', label: t('sourceAssignTeg', 'LAST_6MONTH', language) },
    { value: 'year', label: t('sourceAssignTeg', 'LAST_YEAR', language) },
    { value: '2year', label: t('sourceAssignTeg', 'LAST_2YEARS', language) }
  ], []);

  const [name, setName] = useState('');
  const [tag, setTag] = useState('');
  const [query, setQuery] = useState('');
  const [color, setColor] = useState('#420c8d');
  const [builder, setBuilder] = useState('simple');
  const [selectedBuilder, setSelectedBuilder] = useState(false);
  const [assignFrom, setAssignFrom] = useState(setFor[0]);
  const [assignAuto, setAssignAuto] = useState(false);
  const [selected, setSelected] = useState(false);
  const [preview, setPreview] = useState(false);
  const [operationOr, setOperationOr] = useState(fishOr);
  const [operationAnd, setOperationAnd] = useState(fishAnd);
  const [operationNot, setOperationNot] = useState(fishNot);
  const [errorOr, setErrorOr] = useState('');
  const [errorAnd, setErrorAnd] = useState('');
  const [errorNot, setErrorNot] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorTag, setErrorTag] = useState('');
  const [period, setPeriod] = useState({ value: 'lastWeek', label: t('bodyAddTagModal', 'LAST_WEEK', language) });
  const [tagDuplicate, setTagDuplicate] = useState(false);
  const tagDebounce = useDebounceValue(tag, 1000);
  const isAddOrEdit = data?.id ? 'Edit' : 'Add';

  const lastWeekDayIso = useMemo(() => moment().startOf('day').subtract(1, 'week').toISOString(), [period]);
  const last2WeekDayIso = useMemo(() => moment().startOf('day').subtract(2, 'week').toISOString(), [period]);
  const todayIso = useMemo(() => moment().startOf('day').toISOString(), [period]);

  const { data: tagData } = useGetTag(data?.id);

  const { data: previewAllData, isLoading: isPreviewLoading } = useGetPreviewAricles({
    body: !!preview && createPreviewBody(),
    enabled: !!preview && !!createPreviewBody(),
    period: {
      start: period?.value === 'lastWeek' ? lastWeekDayIso : last2WeekDayIso,
      end: todayIso
    } });

  const usedOrWords = useMemo(() => clearExamples(operationOr)?.length, [operationOr]);
  const previewData = useMemo(() => ({
    articles: previewAllData?.articles || [],
    all_count: previewAllData?.all_count || 0,
    originals_count: previewAllData?.originals_count || 0
  }), [previewAllData, isPreviewLoading]);

  const optionsPeriod = [
    { value: 'lastWeek', label: t('bodyAddTagModal', 'LAST_WEEK', language) },
    { value: 'last2Weeks', label: t('bodyAddTagModal', 'LAST_2WEEK', language) }
  ];

  const query_builder = useMemo(() => ({
    tag,
    operation_or: clearExamples(operationOr),
    operation_and: clearExamples(operationAnd),
    operation_not: clearExamples(operationNot)
  }), [tag, operationOr, operationNot, operationAnd]);

  useEffect(() => {
    if (data) {
      data?.name && setName(data?.name);
      data?.query && setQuery(data?.query);
      data?.color && setColor(`#${data?.color}`);
      hasOwn(data, 'tag_duplicate') && setTagDuplicate(data.tag_duplicate);
      if (data?.query_builder) {
        setSelectedBuilder(true);
        setAssignAuto(true);
      }
      data?.query_builder?.tag?.length && setTag(data?.query_builder?.tag);
      data?.query_builder?.operation_or?.length && setOperationOr(data?.query_builder?.operation_or.map(item => createOption(item)));
      data?.query_builder?.operation_and?.length && setOperationAnd(data?.query_builder?.operation_and.map(item => createOption(item)));
      data?.query_builder?.operation_not?.length && setOperationNot(data?.query_builder?.operation_not.map(item => createOption(item)));
    }
  }, [data]);

  useEffect(() => {
    if (tagData && !data?.query_builder) {
      tagData?.name && setName(tagData?.name);
      tagData?.query && setQuery(tagData?.query);
      tagData?.color && setColor(`#${data?.color}`);
      hasOwn(tagData, 'tag_duplicate') && setTagDuplicate(tagData.tag_duplicate);
      if (tagData?.query_builder) {
        setSelectedBuilder(true);
        setAssignAuto(true);
      }
      tagData?.query_builder?.tag?.length && setTag(tagData?.query_builder?.tag);
      tagData?.query_builder?.operation_or?.length && setOperationOr(tagData?.query_builder?.operation_or.map(item => createOption(item)));
      tagData?.query_builder?.operation_and?.length && setOperationAnd(tagData?.query_builder?.operation_and.map(item => createOption(item)));
      tagData?.query_builder?.operation_not?.length && setOperationNot(tagData?.query_builder?.operation_not.map(item => createOption(item)));
    }
  }, [tagData]);

  function sendAmplitudeInfo(event, category = `${isAddOrEdit} Tag Basic QB`) {
    sendFeedEvent({ category, event });
  }

  function addNewWordOR(e) {
    e.preventDefault();
    sendAmplitudeInfo('Clicked_Add_Word_Button_OR_Operator');
    if (operationOr?.length >= 10) return;
    setOperationOr(prev => [...prev, { label: '', value: `example_${nanoid()}` }]);
  }

  function addNewWordAND(e) {
    e.preventDefault();
    sendAmplitudeInfo('Clicked_Add_Word_Button_AND_Operator');
    setOperationAnd(prev => [...prev, { label: '', value: `example_${nanoid()}` }]);
  }

  function addNewWordNOT(e) {
    e.preventDefault();
    sendAmplitudeInfo('Clicked_Add_Word_Button_NOT_Operator');
    setOperationNot(prev => [...prev, { label: '', value: `example_${nanoid()}` }]);
  }

  function removeNewWordOR(item) {
    errorOr && setErrorOr('');
    setOperationOr(prev => prev.filter(el => el?.value !== item?.value));
  }

  function removeNewWordAND(item) {
    errorAnd && setErrorAnd('');
    setOperationAnd(prev => prev.filter(el => el?.value !== item?.value));
  }

  function removeNewWordNOT(item) {
    errorNot && setErrorNot('');
    setOperationNot(prev => prev.filter(el => el?.value !== item?.value));
  }

  const onChangeName = (e) => {
    !e.target.value.length && !errorName && setErrorName(t('error', 'ERR_REQUIRED', language));
    e.target.value.length && errorName && setErrorName('');
    setName(e.target.value);
  };

  const onChangeTag = (e) => {
    !e?.target?.value?.length && !errorTag && setErrorTag(t('error', 'ERR_REQUIRED', language));
    e.target.value.length && errorTag && setErrorTag('');
    setTag(e.target.value);
  };

  const onSubmit = (e, route) => {
    e?.preventDefault();
    !route && sendFeedEvent({ category: `${isAddOrEdit} tag Basic QB`, event: `Clicked_${data?.id ? 'Save' : 'Add'}_Button` });
    const requiredFields = checkRequiredFields();
    let assignInfo = null;
    assignAuto && (assignInfo = { period: assignFrom.value ? 'last' : 'current', notify: selected });
    const body = {
      name,
      color: color.substring(1),
      tag_duplicate: tagDuplicate,
      ...(data?.id ? { id: data?.id } : {}),
      ...(assignFrom?.value ? { set_for: assignFrom?.value } : {}),
      ...(assignFrom?.value && selected ? { notify: true } : {}),
      ...(assignAuto && tag?.length ? { query_builder } : {}),
      ...(assignAuto && query?.length ? { query } : {})
    };
    if (requiredFields) {
      const manyMentions = previewData.all_count >= 10000;
      (manyMentions && !route)
        ? onManyMentions({ tag_id: data?.id, body, info: assignInfo, route })
        : onClickOk({ tag_id: data?.id, body, info: assignInfo, route });
    }
  };

  function createOption(label) {
    return ({ label, value: label });
  }

  const onPreview = () => {
    if (!selectedBuilder && builder === 'simple') {
      sendFeedEvent({ category: `${isAddOrEdit} Tag Basic QB`, event: 'Selected_Basic_Query_Builder_For_Add_Tag' });
      setSelectedBuilder(true);
    } else if (!selectedBuilder && builder === 'advanced') {
      sendFeedEvent({ category: `${isAddOrEdit} Tag Advanced QB`, event: 'Selected_Advanced_Query_Builder_For_Add_Tag' });
      history(`/advanced_builder/tag/${data?.id || ''}`, { state: name ? { name } : null });
      onCancel();
    } else {
      sendFeedEvent({ category: `${isAddOrEdit} Tag Basic QB`, event: 'Clicked_Next_After_Search_Query' });
      tag.length >= 2 && !preview && setPreview(true);
      tag.length < 2 && !preview && setErrorTag(t('error', 'MIN_SYMBOLS', language));
      preview && setPreview(false);
    }
  };

  function checkRequiredFields() {
    const fieldLength = tag.length;
    name.length < 2 && setErrorName(t('error', 'MIN_SYMBOLS', language));
    !name.length && setErrorName(t('error', 'ERR_REQUIRED', language));
    tag.length < 2 && setErrorTag(t('error', 'MIN_SYMBOLS', language));
    !tag.length && setErrorTag(t('error', 'ERR_REQUIRED', language));

    return assignAuto ? (name.length >= 2 && fieldLength >= 2) : name.length >= 2;
  }

  function createPreviewBody() {
    return !!tagDebounce && {
      tag: tagDebounce,
      operation_or: clearExamples(operationOr),
      operation_and: clearExamples(operationAnd),
      operation_not: clearExamples(operationNot),
      limit: '50'
    };
  }

  function clearExamples(operationArr) {
    return operationArr?.length ? operationArr?.filter(item => !item.value.includes('example'))?.map(item => item?.value?.trim()) : [];
  }

  const highlights = useMemo(() => {
    const higlightTag = tagDebounce ? [tagDebounce] : [];
    const higlightOr = clearExamples(operationOr);
    const higlightAnd = clearExamples(operationAnd);
    return [...higlightTag, ...higlightOr, ...higlightAnd]?.join(',').replaceAll(',', '|');
  }, [tagDebounce, operationOr, operationAnd]);

  const tagsCount = useMemo(() => tag.split(' ').filter(item => !!item)?.length || 0, [tag]);
  return (
    <>
      <div className={cn(classes.bodyWrapper, !!preview && classes.bodyWrapper_preview)}>
        <form
          onKeyDown={(e) => { (e.key === 'Enter' || e.keyCode === 13) && e.preventDefault(); }}
          className={cn(
            classes.bodyTegModal,
            !!assignAuto && selectedBuilder && classes.bodyTegModal_assign,
            !!assignAuto && !selectedBuilder && classes.bodyTegModal_select,
            className
          )}
        >
          {data?.id && tag && !!assignAuto && (
          <div
            className={classes.switchAdvanced}
            onClick={(e) => {
              sendFeedEvent({ category: 'Edit tag Basic QB', event: 'Clicked_Switch_to_Advanced_Mode_Button' });
              onSubmit(e, `/advanced_builder/tag/${data?.id}`);
            }}
          >
            {t('bodyAddTagModal', 'TO_ADVANCED_MODE', language)}
          </div>
          )}
          <div className={cn(classes.inputBox, classes.inputBoxFlex)}>
            <TextField
              labelText={t('bodyAddTagModal', 'NAME', language)}
              value={name}
              onChange={onChangeName}
              placeholder={t('bodyAddTagModal', 'ENTER_NAME_TAG', language)}
              boxShadow
              errorText={errorName ? t('error', 'ERR_REQUIRED', language) : ''}
            />
            <div className={classes.colorWrap}>
              <div className={classes.colorsText}>{t('bodyAddTagModal', 'COLOR', language)}</div>
              <PopoverColorPicker
                color={color}
                onChange={setColor}
                className={classes.colorPickerPopover}
                sendEvent={() => {
                  sendFeedEvent({
                    category: data?.id ? 'Edit Tag Basic QB' : 'Add tag',
                    event: 'Clicked_Select_Color_Button'
                  });
                }}
              />
            </div>
          </div>
          <div className={classes.option}>
            <span className={classes.colorsText}>{t('bodyAddTagModal', 'ASSIGN_AUTO', language)}</span>
            <label className={cn(classes.switch)}>
              <input
                type="checkbox"
                onChange={() => {
                  sendFeedEvent({
                    category: data?.id ? 'Edit Tag Basic QB' : 'Add tag',
                    event: `Clicked_Switcher_Tag_With${assignAuto ? 'out' : ''}_Autotagging`
                  });
                  !!assignAuto && setAssignFrom(setFor[0]);
                  setSelected(false);
                  setAssignAuto(!assignAuto);
                  !!assignAuto && setPreview(false);
                }}
                checked={!!assignAuto}
              />
              <span className={cn(classes.slider, classes.round)} />
            </label>
          </div>
          {assignAuto && !selectedBuilder && (
          <div className={classes.selectBuilderWrap}>
            <div className={classes.title}>
              {t('bodyAddTagModal', 'SELECT_CONSTRUCTOR', language)}
            </div>
            <div className={classes.selectCardsWrapper}>
              <div className={classes.selectCardWrap}>
                <div
                  className={cn(classes.selectCard, builder === 'simple' && classes.selectCard_selected)}
                  onClick={() => {
                    data?.id && sendFeedEvent({ category: 'Edit Tag Basic QB', event: 'Clicked_Switch_to_Basic_Mode_Button' });
                    builder !== 'simple' && setBuilder('simple');
                  }}
                >
                  <div className={classes.selectCard_simpleImage} />
                  <div className={classes.selectCard_text}>{t('bodyAddTagModal', 'BASIC', language)}</div>
                </div>
                <div className={classes.selectText}>
                  {t('bodyAddTagModal', 'SIMPLE_TEXT', language)}
                </div>
              </div>
              <div className={classes.selectCardWrap}>
                <div
                  className={cn(classes.selectCard, builder === 'advanced' && classes.selectCard_selected)}
                  onClick={() => {
                    data?.id && sendFeedEvent({ category: 'Edit Tag Basic QB', event: 'Clicked_Switch_to_Advanced_Mode_Button' });
                    builder !== 'advanced' && setBuilder('advanced');
                  }}
                >
                  <div className={classes.selectCard_advancedImage} />
                  <div className={classes.selectCard_text}>{t('bodyAddTagModal', 'ADVANCED', language)}</div>
                </div>
                <div className={classes.selectText}>
                  {t('bodyAddTagModal', 'ADVANCED_TEXT', language)}
                </div>
              </div>
            </div>
          </div>
          )}
          {assignAuto && selectedBuilder && builder === 'simple' && (
          <div className={cn(classes.builderWrapper)}>
            <TextField
              labelText={t('bodyAddTagModal', 'TAG', language)}
              value={tag}
              onChange={onChangeTag}
              placeholder={t('bodyAddTagModal', 'ENTER_EXAMPLE', language)}
              boxShadow
              errorText={errorTag}
              className={cn(classes.tagField, tagsCount > 6 && classes.tagField_many)}
              additionalInfo={tagsCount > 6 ? t('bodyAddTagModal', 'RECOMEND_TEXT', language) : ''}
              labelInfo={t('bodyAddTagModal', 'SET_INTERVAL_TAG', language)}
            />
            <div className={classes.creatableWrapper}>
              <div className={classes.wordButton}>OR</div>
              <ErrorBoundarySentry language={language}>
                <div className={classes.selectedList}>
                  {operationOr.map(item => (
                    <EditableLabel
                      key={item?.value}
                      dataItem={item}
                      onChange={(newOperation) => { setOperationOr(newOperation); }}
                      error={errorOr}
                      onChangeError={(errorValue) => { setErrorOr(errorValue ? t('error', errorValue, language) : ''); }}
                      onRemove={() => { removeNewWordOR(item); }}
                      operation={operationOr}
                    />
                  ))}
                  <button
                    className={cn(classes.addButton)}
                    onClick={addNewWordOR}
                  >
                    {t('bodyAddTagModal', 'ADD_WORD', language)}
                  </button>
                </div>
              </ErrorBoundarySentry>
              {errorOr && <div className={cn(classes.infoText, classes.infoText_error)}>{errorOr}</div>}
              <div className={cn(classes.infoText)}>
                {t('bodyAddTagModal', 'USED', language)}
                <b>{` ${usedOrWords} / 10`}</b>
              </div>
            </div>
            <div className={classes.creatableWrapper}>
              <div className={classes.wordButton}>AND</div>
              <ErrorBoundarySentry language={language}>
                <div className={classes.selectedList}>
                  {operationAnd.map(item => (
                    <EditableLabel
                      key={item?.value}
                      dataItem={item}
                      onChange={(newOperation) => { setOperationAnd(newOperation); }}
                      error={errorOr}
                      onChangeError={(errorValue) => { setErrorAnd(errorValue ? t('error', errorValue, language) : ''); }}
                      onRemove={() => { removeNewWordAND(item); }}
                      operation={operationAnd}
                    />
                  ))}
                  <button
                    className={cn(classes.addButton)}
                    onClick={addNewWordAND}
                  >
                    {t('bodyAddTagModal', 'ADD_WORD', language)}
                  </button>
                </div>
              </ErrorBoundarySentry>
              {errorAnd && <div className={cn(classes.infoText, classes.infoText_error)}>{errorAnd}</div>}
            </div>
            <div className={classes.creatableWrapper}>
              <div className={classes.wordButton}>NOT</div>
              <ErrorBoundarySentry language={language}>
                <div className={classes.selectedList}>
                  {operationNot.map(item => (
                    <EditableLabel
                      key={item?.value}
                      dataItem={item}
                      onChange={(newOperation) => { setOperationNot(newOperation); }}
                      error={errorOr}
                      onChangeError={(errorValue) => { setErrorNot(errorValue ? t('error', errorValue, language) : ''); }}
                      onRemove={() => { removeNewWordNOT(item); }}
                      operation={operationNot}
                    />
                  ))}
                  <button
                    className={cn(classes.addButton)}
                    onClick={addNewWordNOT}
                  >
                    {t('bodyAddTagModal', 'ADD_WORD', language)}
                  </button>
                </div>
              </ErrorBoundarySentry>
              {errorNot && <div className={cn(classes.infoText, classes.infoText_error)}>{errorNot}</div>}
            </div>
            <div className={classes.inputBox}>
              <SelectCustom
                selectTitle={t('bodyAddTagModal', 'ASSIGN', language)}
                value={assignFrom}
                placeholder={t('bodyAddTagModal', 'ASSIGN', language)}
                onChange={(val) => {
                  sendFeedEvent({ category: `${isAddOrEdit} Tag Basic QB`, event: 'Selected_Tagging_Period' });
                  setAssignFrom(val);
                }}
                options={setFor}
                menuPlacement="top"
              />
              {!!assignAuto && !!assignFrom?.value && (
                <label htmlFor="enable_filters" className={classes.checkboxLabel}>
                  <input
                    type="checkbox"
                    className={classes.visually_hidden}
                    onChange={() => {
                      sendFeedEvent({ category: `${isAddOrEdit} Tag Basic QB`, event: 'Toggled_Notify_Tags' });
                      setSelected(!selected);
                    }}
                    value={!!selected}
                    id="enable_filters"
                  />
                  <div className={cn(classes.checkbox, selected && classes.checkbox_selected)}>
                    {selected && (<Icons icon="check" className={classes.iconCheck} />)}
                  </div>
                  <div className={classes.checkedText}>{t('bodyAddTagModal', 'INFORM', language)}</div>
                </label>
              )}
            </div>
          </div>
          )}
          {!assignAuto && (
          <div className={classes.optionsBox}>
            <div className={cn(classes.radioText, classes.radioText_header)}>
              {t('modalWindow', 'ADD_TEG', language)}
            </div>
            <label className={classes.option}>
              <div className={cn(classes.radioBtn, !tagDuplicate && classes.radioBtn_checked)}>
                {!tagDuplicate && (
                <div className={classes.radioBtnInside_checked} />
                )}
              </div>
              <input
                className={classes.visually_hidden}
                type="radio"
                name="optionTag"
                value={tagDuplicate}
                checked={!tagDuplicate}
                onChange={() => {
                  sendFeedEvent({
                    category: data?.id ? 'Edit Tag Basic QB' : 'Add tag',
                    event: 'Selected_Only_For_Selected_News'
                  });
                  setTagDuplicate(false);
                }}
              />
              <div className={cn(classes.radioText, classes.radioText_dark)}>{t('bodyAddTagModal', 'ONLY_SELECTED', language)}</div>
            </label>
            <label className={classes.option}>
              <div className={cn(classes.radioBtn, tagDuplicate && classes.radioBtn_checked)}>
                {tagDuplicate && (<div className={classes.radioBtnInside_checked} />)}
              </div>
              <input
                className={classes.visually_hidden}
                type="radio"
                name="optionTag"
                value={tagDuplicate}
                checked={tagDuplicate}
                onChange={() => {
                  sendFeedEvent({
                    category: data?.id ? 'Edit Tag Basic QB' : 'Add tag',
                    event: 'Selected_The_News_And_All_Of_Its_Duplicates'
                  });
                  setTagDuplicate(true);
                }}
              />
              <div className={cn(classes.radioText, classes.radioText_dark)}>{t('bodyAddTagModal', 'ADD_ALL', language)}</div>
            </label>
          </div>
          )}
        </form>
        <div className={cn(classes.previewWrapp, preview && classes.previewWrapp_opened)}>
          <Loader size={65} fetching={isPreviewLoading} color="#A7B0C8">
            <ErrorBoundarySentry language={language}>
              {preview && (
              <div className={classes.previewBody}>
                <div className={classes.previewHeader}>
                  {t('bodyAddTagModal', 'RESULT_HEADER', language)}
                  <InfoBtn
                    className={cn(classes.infoButton)}
                    text={t('bodyAddTagModal', 'RESULT_TAG_HEADER_INFO', language)}
                    icon="error_outline"
                  />
                </div>
                <ArticlesListPreview
                  previewData={previewData}
                  highlights={highlights || ''}
                  language={language}
                  name={tagDebounce}
                  className={classes.articlesKeywords}
                  onChangePeriod={(params) => {
                    const tagFilterEvents = ['lastWeek', 'last2Weeks'];
                    tagFilterEvents.includes(params?.value) && period?.value !== params?.value && sendFeedEvent({
                      category: `${isAddOrEdit} tag Basic QB`,
                      event: `tag_${params?.value}`
                    });
                    setPeriod(params);
                  }}
                  optionsPeriod={optionsPeriod}
                  period={period}
                />
              </div>
              )}
            </ErrorBoundarySentry>
          </Loader>
        </div>
      </div>
      <div className={cn(classes.buttonBox)}>
        {assignAuto && !preview && (
        <Button
          onClick={onPreview}
          textButton={t('btn', 'NEXT', language)}
          green32
        />
        )}
        {(preview || !assignAuto) && (
        <Button
          type="submit"
          disabled={isPreviewLoading || isLoading}
          onClick={onSubmit}
          textButton={t('btn', data?.id ? 'SAVE' : 'ADD', language)}
          green32
        />
        )}
      </div>
    </>
  );
};

BodyAddEditTegModal.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onClickOk: func,
  onManyMentions: func,
  onCancel: func,
  language: string,
  isLoading: bool
};

export default BodyAddEditTegModal;
