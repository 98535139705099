import { func } from 'prop-types';
import { useSelector } from 'react-redux';
import t from 'utils/translateFunc';

import classes from './BodyLink.module.scss';

const BodyLink = ({ selectOption = () => null }) => {
  const language = useSelector(state => state?.userInfo?.language);
  return (
    <div className={classes.bodyLink}>
      <p className={classes.titleLink}>{t('bodyLink', 'SHARE', language)}</p>
      <button
        type="button"
        className={classes.btnLink}
        onClick={() => {
          selectOption('html');
        }}
      >
        {t('bodyLink', 'CLICK_COPY', language)}
      </button>
    </div>
  );
};

BodyLink.propTypes = {
  selectOption: func
};

export default BodyLink;
