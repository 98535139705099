import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Row } from 'antd';
import { SkeletonReport } from 'Components';
import cn from 'classnames';
import { string, node, bool, shape } from 'prop-types';
import classes from './SkeletonReportsList.module.scss';

export function SkeletonReportsList({ className = '', fetching = false, children = null, sizes = null }) {
  const arr = new Array(6).fill(null);
  return (fetching ? (
    <SkeletonTheme baseColor="#f6f7fa" highlightColor="#e0e0f3">
      <Row gutter={[16, 16]} align="start" className={cn(classes.reportsList, className)}>
        {arr.map((_, i) => <Col {...sizes} key={i}><SkeletonReport /></Col>)}
      </Row>
    </SkeletonTheme>
  ) : children);
}

SkeletonReportsList.propTypes = {
  fetching: bool,
  children: node,
  className: string,
  sizes: shape({})
};
export default SkeletonReportsList;
