import { string } from 'prop-types';

export const Support = ({ color = '#8B96B2' }) => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d={'M6.33331 12.5V11.1667H11.6666V6.43333C11.6666 5.78889 11.5444 5.18333 11.3 4.61667C11.0555 4.05 10.7222 3.55556 10.3 3.13333C9.87776 2.71111 9.38331 2.37778 8.81665 2.13333C8.24998 1.88889 7.64442 1.76667 6.99998 1.76667C6.35554 1.76667 5.74998 1.88889 5.18331 2.13333C4.61665 2.37778 4.1222 2.71111 3.69998 3.13333C3.27776 3.55556 2.94442 4.05 2.69998 4.61667C2.45554 5.18333 2.33331 5.78889 2.33331 6.43333V10.5H1.66665C1.29998 10.5 0.986091 10.3694 0.72498 10.1083C0.463869 9.84722 0.333313 9.53333 0.333313 9.16667V7.83333C0.333313 7.57778 0.394424 7.35278 0.516646 7.15833C0.638869 6.96389 0.79998 6.80556 0.99998 6.68333L1.04998 5.8C1.14998 4.98889 1.38054 4.25556 1.74165 3.6C2.10276 2.94444 2.55276 2.38889 3.09165 1.93333C3.63054 1.47778 4.23609 1.125 4.90831 0.875C5.58054 0.625 6.27776 0.5 6.99998 0.5C7.73331 0.5 8.43609 0.625 9.10831 0.875C9.78054 1.125 10.3833 1.48056 10.9166 1.94167C11.45 2.40278 11.8972 2.95833 12.2583 3.60833C12.6194 4.25833 12.85 4.98333 12.95 5.78333L13 6.65C13.2 6.75 13.3611' +
        '6.89722 13.4833 7.09167C13.6055 7.28611 13.6666 7.5 13.6666 7.73333V9.26667C13.6666 9.51111 13.6055 9.72778 13.4833 9.91667C13.3611 10.1056 13.2 10.25 13 10.35V11.1667C13 11.5333 12.8694 11.8472 12.6083 12.1083C12.3472 12.3694 12.0333 12.5 11.6666 12.5H6.33331ZM4.99998 7.83333C4.81109 7.83333 4.65276 7.76944 4.52498 7.64167C4.3972 7.51389 4.33331 7.35556 4.33331 7.16667C4.33331 6.97778 4.3972 6.81944 4.52498 6.69167C4.65276 6.56389 4.81109 6.5 4.99998 6.5C5.18887 6.5 5.3472 6.56389 5.47498 6.69167C5.60276 6.81944 5.66665 6.97778 5.66665 7.16667C5.66665 7.35556 5.60276 7.51389 5.47498 7.64167C5.3472 7.76944 5.18887 7.83333 4.99998 7.83333ZM8.99998 7.83333C8.81109 7.83333 8.65276 7.76944 8.52498 7.64167C8.3972 7.51389 8.33331 7.35556 8.33331' +
      '7.16667C8.33331 6.97778 8.3972 6.81944 8.52498 6.69167C8.65276 6.56389 8.81109 6.5 8.99998 6.5C9.18887 6.5 9.3472 6.56389 9.47498 6.69167C9.60276 6.81944 9.66665 6.97778 9.66665 7.16667C9.66665 7.35556 9.60276 7.51389 9.47498 7.64167C9.3472 7.76944 9.18887 7.83333 8.99998 7.83333ZM3.01665 6.8C2.9722 6.14444 3.06387 5.55 3.29165 5.01667C3.51942 4.48333 3.82498 4.03056 4.20831 3.65833C4.59165 3.28611 5.03331 3 5.53331 2.8C6.03331 2.6 6.53331 2.5 7.03331 2.5C8.04442 2.5 8.91942 2.81944 9.65831 3.45833C10.3972 4.09722 10.8444 4.89444 11 5.85C9.95554 5.83889 9.01109 5.55833 8.16665 5.00833C7.3222 4.45833 6.68331 3.74444 6.24998 2.86667C6.0722 3.76667 5.6972 4.56389 5.12498 5.25833C4.55276 5.95278 3.84998 6.46667 3.01665 6.8Z'}
      fill={color}
    />
  </svg>
);

Support.propTypes = {
  color: string
};
