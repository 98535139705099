import { customAlphabet } from 'nanoid';
import t from 'utils/translateFunc';

const nanoid = customAlphabet('0123456789', 5);

export function optionsCommon() {
  const daysOfWeek = [{
    id: 0,
    day: 0,
    label: t('reports', 'MONDAY')
  },
  {
    id: 1,
    day: 1,
    label: t('reports', 'TUESDAY')
  },
  {
    id: 2,
    day: 2,
    label: t('reports', 'WEDNESDAY')
  },
  {
    id: 3,
    day: 3,
    label: t('reports', 'THURSDAY')
  },
  {
    id: 4,
    day: 4,
    label: t('reports', 'FRIDAY')
  },
  {
    value: 5,
    day: 5,
    label: t('reports', 'SATURDAY')
  },
  {
    value: 6,
    day: 6,
    label: t('reports', 'SUNDAY')
  }];

  const periodicitiesOptions = [
    {
      value: 'multiple_times_per_day',
      label: t('reports', 'EVERY_DAY')
    },
    {
      value: 'multiple_per_week',
      label: t('reports', 'BY_WEEK_DAYS')
    },
    {
      value: 'multiple_per_month',
      label: t('reports', 'BY_MONTH_DAYS')
    }
  ];

  const someHoursOptions = [
    {
      value: 'one_hour',
      label: t('reports', 'ONE_HOUR')
    },
    {
      value: 'two_hours',
      label: t('reports', 'TWO_HOURS')
    },
    {
      value: 'four_hours',
      label: t('reports', 'FOUR_HOURS')
    }
  ];

  const frequenciesOptions = [
    {
      value: 'multiple_times_per_day',
      label: t('reports', 'SEVERAL_TIMES_DAY')
    },
    {
      value: 'once_per_day',
      label: t('reports', 'ONCE_A_DAY')
    },
    {
      value: 'one_hour',
      label: t('reports', 'ONE_HOUR')
    },
    {
      value: 'two_hours',
      label: t('reports', 'TWO_HOURS')
    },
    {
      value: 'four_hours',
      label: t('reports', 'FOUR_HOURS')
    },
    {
      value: 'once_per_week',
      label: t('reports', 'ONCE_A_WEEK')
    },
    {
      value: 'multiple_per_week',
      label: t('reports', 'THROUGH_WEEK_DAYS')
    },
    {
      value: 'multiple_per_month',
      label: t('reports', 'THROUGH_MONTH_DAYS')
    }
  ];

  const periodicityFrequency = {
    multiple_times_per_day: 'multiple_times_per_day',
    once_per_day: 'multiple_times_per_day',
    one_hour: 'multiple_times_per_day',
    two_hours: 'multiple_times_per_day',
    four_hours: 'multiple_times_per_day',
    once_per_week: 'multiple_per_week',
    multiple_per_week: 'multiple_per_week',
    multiple_per_month: 'multiple_per_month',
    default: 'multiple_times_per_day'
  };

  const daysOfMonth = Array.from({ length: 31 }, (_, index) => ({
    day: index + 1,
    id: nanoid(),
    title: ''
  }));

  const nHoursText = {
    one_hour: t('reports', 'EVERY_HOUR'),
    two_hours: t('reports', 'EVERY_2HOURS'),
    four_hours: t('reports', 'EVERY_4HOURS')
  };
  return { daysOfWeek, periodicitiesOptions, someHoursOptions, frequenciesOptions, periodicityFrequency, daysOfMonth, nHoursText };
}

export function replaceWeekDaysByName(day) {
  if (day === 0) return t('reports', 'MONDAY_AT');
  if (day === 1) return t('reports', 'TUESDAY_AT');
  if (day === 2) return t('reports', 'WEDNESDAY_AT');
  if (day === 3) return t('reports', 'THURSDAY_AT');
  if (day === 4) return t('reports', 'FRIDAY_AT');
  if (day === 5) return t('reports', 'SATURDAY_AT');
  if (day === 6) return t('reports', 'SUNDAY_AT');
  return '';
}

export function replaceWeekDays(day) {
  if (day === 0) return t('reports', 'MONDAY');
  if (day === 1) return t('reports', 'TUESDAY');
  if (day === 2) return t('reports', 'WEDNESDAY');
  if (day === 3) return t('reports', 'THURSDAY');
  if (day === 4) return t('reports', 'FRIDAY');
  if (day === 5) return t('reports', 'SATURDAY');
  if (day === 6) return t('reports', 'SUNDAY');
  return '';
}
