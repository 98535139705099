import { node, string, oneOfType } from 'prop-types';
import { motion } from 'framer-motion';

function AnimatedTab({ children = '' }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, type: 'spring' }}
    >
      {children}
    </motion.div>
  );
}

AnimatedTab.propTypes = {
  children: oneOfType([string, node]).isRequired
};

export default AnimatedTab;
