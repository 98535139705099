/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, Icon } from 'Components';
import { useUploadFeedbackImage } from 'storage/queryHooks';
import classes from './BodyFeedback.module.scss';

const BodyFeedback = ({ className = '',
  onClickOk = () => null,
  onCancel = () => null,
  language = 'uk'
}) => {
  const [editComment, setEditComment] = useState('');
  const [image, setImage] = useState('');
  const [imageName, setImageName] = useState('');
  const [loadingErr, setLoadingErr] = useState('');
  const { mutateAsync: uploadImage, data: imageData } = useUploadFeedbackImage();

  const onChangeComment = (e) => {
    setEditComment(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onClickOk({
      body: {
        feedback: editComment,
        image: imageData?.file_path ? imageData?.file_path : image
      }
    });
  };

  useEffect(() => {
    !image ? setImageName('') : setImageName(image?.name);
  }, [image]);

  useEffect(() => {
    if (loadingErr) {
      setTimeout(() => {
        setLoadingErr('');
      }, 3000);
    }
  }, [loadingErr]);

  const beforeUpload = (file) => {
    if (file?.type && file?.size) {
      const isJpgOrPng = file.type === 'image/jpeg' || file?.type === 'image/png';
      if (!isJpgOrPng) {
        setLoadingErr(t('error', 'ERR_FORMAT_PHOTO', language));
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        setLoadingErr(t('error', 'ERR_BIG_PHOTO', language));
      }
      return isJpgOrPng && isLt2M;
    }
    return false;
  };

  function onImageChange(event) {
    if (event) {
      const file = event.target.files[0];
      setTimeout(() => {
        if (beforeUpload(file)) {
          setImage(file);
          uploadImage({ image: file });
        }
      }, 500);
    }
  }
  return (
    <form
      onSubmit={onSubmit}
      className={cn(classes.bodyFeedBackModal, className)}
    >
      <div className={cn(classes.commentBox)}>
        <textarea
          placeholder={t('bodySendCommentModal', 'ENTER_COMMENT', language)}
          className={classes.comment}
          value={editComment}
          onChange={onChangeComment}
        />
        <div className={classes.commentBtn}>
          <Icon type="attach" />
          <span className={classes.commentBtn_text}>
            {imageName || (
            <label htmlFor="inputFeedback">
              {t('bodySendCommentModal', 'ATTACH_SCRIN', language)}
              <input type="file" id="inputFeedback" multiple={false} accept="image/jpeg, image/png" onChange={onImageChange} />
            </label>
            )}
          </span>
          {imageName && <span className={classes.delete} onClick={() => setImage(null)}><Icon type="delete" /></span>}
        </div>
      </div>

      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />
        <Button
          type="submit"
          disabled={!(editComment.length || image)}
          onClick={onSubmit}
          textButton={t('btn', 'SEND', language)}
          green32
        />
      </div>
    </form>
  );
};

BodyFeedback.propTypes = {
  className: string,
  onCancel: func,
  onClickOk: func,
  language: string
};

export default BodyFeedback;
