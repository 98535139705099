export const MEDIA_TYPES = ['internet', 'agency', 'forum', 'press', 'radio', 'tv', 'socialnetwork', 'total'];
export const SOCIAL_NETWORKS = [
  'facebook', 'facebook.com',
  'telegram', 'telegram.me',
  'tiktok', 'tiktok.com',
  'linkedin', 'linkedin.com',
  'google_maps', 'google.com',
  'twitter', 'x.com',
  'instagram',
  'whatsapp',
  'youtube'
];

export const iconConfig = {
  facebook: ['facebook', 'facebook.com'],
  telegram: ['telegram', 'telegram.me'],
  tiktok: ['tiktok', 'tiktok.com'],
  linkedin: ['linkedin', 'linkedin.com'],
  twitter: ['twitter', 'x.com'],
  google_maps: ['google_maps', 'google', 'google.com']
};

export const getIconName = (sourceName) => {
  for (const [key, values] of Object.entries(iconConfig)) {
    if (values.includes(sourceName)) return key;
  }
  return sourceName;
};
