import { memo } from 'react';
import { shape, bool } from 'prop-types';
import cn from 'classnames';
import { Icons } from 'Components';
import classes from './DropdownIndicatorPart.module.scss';

const DropdownIndicatorPart = memo(({ selectProps = { menuIsOpen: false, isDisabled: false } }) => {
  const { menuIsOpen, isDisabled } = selectProps;
  const rotateDeg = {
    transform: `rotate(${180}deg)`
  };
  return (
    <div className={classes.indicatorWrap}>
      <Icons
        icon="Icn_Arrow-1_nofill"
        style={menuIsOpen && rotateDeg}
        className={cn(classes.icon, isDisabled && classes.icon_disabled)}
      />
    </div>
  );
});

DropdownIndicatorPart.propTypes = {
  selectProps: shape({ menuIsOpen: bool, isDisabled: bool })
};

export default DropdownIndicatorPart;
