import { useState } from 'react';
import { AnimatedTab } from 'Components';
import { func, string } from 'prop-types';
import classes from './Phone.module.scss';

function PhoneComponent({ onChange = () => null, error = '' }) {
  const [phone, setPhone] = useState('');
  function handleChange(value) {
    const mod_value = value.replaceAll(/[^\-|_|(|)|\s|\d]/g, '');
    onChange(mod_value);
    setPhone(`+${mod_value}`);
  }
  return (
    <AnimatedTab>
      <div className={classes.phoneWrapp}>
        <div>
          <input
            type="text"
            placeholder="+38(012)345-67-89"
            value={phone}
            onChange={e => handleChange(e.target.value)}
            className={classes.phoneInput}
          />
        </div>
        <div className={classes.error}>
          {error}
        </div>
      </div>
    </AnimatedTab>
  );
}

PhoneComponent.propTypes = {
  onChange: func,
  error: string
};

export default PhoneComponent;
