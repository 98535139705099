import { memo } from 'react';
import { string, oneOfType, node, bool, shape } from 'prop-types';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { BodyHtml } from 'Components';
import './Tooltip.scss';
import classes from './Tooltip.module.scss';

const TooltipComponent = memo(({ text = false, truncate = false, className = '', children = '', background = '', trigger = 'hover', placement = 'bottom', ...rest }) => (
  text ? (
    <Tooltip
      align={{ align: 'center' }}
      title={typeof text === 'string' ? <BodyHtml html={text} /> : text}
      color={background || '#31254b'}
      placement={placement || 'bottom'}
      trigger={trigger || 'hover'}
      zIndex={9947483009}
      overlayClassName={cn(classes.tooltipCard)}
      destroyTooltipOnHide
      mouseEnterDelay={0.3}
      mouseLeaveDelay={0.3}
      {...rest}
    >
      <span className={cn(className, truncate && classes.truncate)}>{children || <> </>}</span>
    </Tooltip>
  ) : <span className={cn(className, truncate && classes.truncate)}>{children || <> </>}</span>
));

TooltipComponent.propTypes = {
  className: string,
  truncate: bool,
  text: oneOfType([node, bool]),
  children: oneOfType([node, string]),
  mode: oneOfType([string, bool]),
  trigger: oneOfType([string, bool]),
  background: string,
  placement: string,
  rest: shape({})
};

export default TooltipComponent;
