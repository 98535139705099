import { string, number } from 'prop-types';

export function Press({ width = 14, height = 14, color = '#FFBF00', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '14'} height={height || '14'} viewBox="0 0 14 14" fill="none">
      <mask id="mask0_317_30803" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_317_30803)">
        <path d="M2.66659 14.2431C2.24677 14.2431 1.88905 14.0953 1.5934 13.7997C1.29774 13.504 1.1499 13.1463 1.1499 12.7265V4.6069H2.66659V12.7265H12.7901V14.2431H2.66659ZM5.51659 11.3931C5.09679 11.3931 4.73906 11.2453 4.4434 10.9496C4.14775 10.654 3.99992 10.2963 3.99992 9.87647V1.87646H15.5166V9.87647C15.5166 10.2963 15.3688 10.654 15.0731 10.9496C14.7775 11.2453 14.4197 11.3931 13.9999 11.3931H5.51659ZM5.51659 9.87647H13.9999V3.39313H5.51659V9.87647ZM6.79014 8.00002H9.39304V4.66668H6.79014V8.00002ZM10.0597 8.00002H12.7264V6.66668H10.0597V8.00002ZM10.0597 6.00002H12.7264V4.66668H10.0597V6.00002Z" fill={color || '#FFBF00'} />
      </g>
    </svg>
  );
}

Press.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
