/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo, memo } from 'react';
import { nanoid } from 'nanoid';
import { string, func, number, shape, oneOfType } from 'prop-types';
import cn from 'classnames';
import { useGetPreviewAricles, useGetPreviewCountries } from 'storage/queryHooks.js';
import t from 'utils/translateFunc';
import { Button, TextField, FilterOption, ArticlesListPreview, Loader, InfoBtn, EditableLabel, ErrorBoundarySentry } from 'Components';
import Select from 'react-select';
import { mediaTypes as mediaTypesFunc } from 'data/persistentData';
import useDebounceValue from 'hooks/useDebounce';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './BodyAddEditSearchQuery.module.scss';
import { customSelectStyles } from './selectStyles';
import { MultiValueLabel, DropdownIndicator } from './SelectComponents';

const BodyAddEditSearchQuery = memo(({
  className = '',
  data = { name: '', id: 0 },
  onCreateEdit = () => null,
  onCreateQuery = () => null,
  onManyMentions = () => null,
  onCancel = () => null,
  language = 'uk'
}) => {
  const history = useNavigate();
  const fishOr = [{ label: t('bodyAddTagModal', 'EXAMPLE_APPLE', language), value: 'example_1' }];
  const fishAnd = [{ label: t('bodyAddTagModal', 'EXAMPLE_iPHONE', language), value: 'example_1' }];
  const fishNot = [{ label: t('bodyAddTagModal', 'EXAMPLE_APPLEFRUIT', language), value: 'example_1' }];
  const [preview, setPreview] = useState(false);
  const [name, setName] = useState('');
  const [querySearch, setQuerySearch] = useState('');
  const [countries, setCountries] = useState([]);
  const [mediaType, setMediaType] = useState([]);
  const [operationOr, setOperationOr] = useState(fishOr);
  const [operationAnd, setOperationAnd] = useState(fishAnd);
  const [operationNot, setOperationNot] = useState(fishNot);
  const [errorName, setErrorName] = useState('');
  const [errorQuerySearch, setErrorQuerySearch] = useState('');
  const [errorOr, setErrorOr] = useState('');
  const [errorAnd, setErrorAnd] = useState('');
  const [errorNot, setErrorNot] = useState('');
  const [inputCountry, setInputCountry] = useState('');
  const [geographyData, setGeographyData] = useState(false);
  const [period, setPeriod] = useState({ value: 'lastWeek', label: t('bodyAddTagModal', 'LAST_WEEK', language) });
  const querySearchDebounce = useDebounceValue(querySearch, 1000);
  const counryDebounce = useDebounceValue(inputCountry, 1000);

  const lastWeekDayIso = useMemo(() => moment().startOf('day').subtract(1, 'week').toISOString(), [period]);
  const last2WeekDayIso = useMemo(() => moment().startOf('day').subtract(2, 'week').toISOString(), [period]);
  const todayIso = useMemo(() => moment().startOf('day').toISOString(), [period]);
  const { data: countriesData, isLoading: countriesLoading } = useGetPreviewCountries({ query: { name: counryDebounce }, enabled: true });
  const { data: previewAllData, isLoading: isPreviewLoading } = useGetPreviewAricles({
    body: !!preview && createPreviewBody(),
    enabled: !!preview && querySearchDebounce?.length >= 2 && !!createPreviewBody(),
    period: {
      start: period?.value === 'lastWeek' ? lastWeekDayIso : last2WeekDayIso,
      end: todayIso
    } });
  const usedOrWords = useMemo(() => operationOr?.filter(item => !item.value.includes('example'))?.length, [operationOr]);

  const previewData = useMemo(() => ({
    articles: previewAllData?.articles || [],
    all_count: previewAllData?.all_count || 0,
    originals_count: previewAllData?.originals_count || 0
  }), [previewAllData, isPreviewLoading]);

  const optionsPeriod = [
    { value: 'lastWeek', label: t('bodyAddTagModal', 'LAST_WEEK', language) },
    { value: 'last2Weeks', label: t('bodyAddTagModal', 'LAST_2WEEK', language) }
  ];
  const query_builder = useMemo(() => ({
    keyword: querySearch,
    operation_or: operationOr?.length ? operationOr?.filter(item => !item.value.includes('example')).map(item => item?.label) : [],
    operation_and: operationAnd?.length ? operationAnd?.filter(item => !item.value.includes('example')).map(item => item?.label) : [],
    operation_not: operationNot?.length ? operationNot?.filter(item => !item.value.includes('example')).map(item => item?.label) : [],
    countries: countries?.length ? countries.map(item => (item?.value === 'not_defined' ? item?.value : item?.label)) : [],
    media_types: mediaType?.length ? mediaType.map(item => item?.id) : []
  }), [querySearch, operationOr, operationNot, operationAnd, countries, mediaType]);

  useEffect(() => {
    querySearchDebounce?.length < 2 && !!preview && setPreview(false);
  }, [querySearchDebounce]);

  useEffect(() => {
    if (data) {
      data?.name && setName(data?.name);
      data?.query_builder?.keyword && setQuerySearch(data.query_builder.keyword);
      data?.query_builder?.operation_or?.length && setOperationOr(data?.query_builder?.operation_or.map(item => createOption(item)));
      data?.query_builder?.operation_and?.length && setOperationAnd(data?.query_builder?.operation_and.map(item => createOption(item)));
      data?.query_builder?.operation_not?.length && setOperationNot(data?.query_builder?.operation_not.map(item => createOption(item)));
      data?.query_builder?.countries?.length && setCountries(data?.query_builder?.countries.map(item => createOption(item)));
      data?.query_builder?.media_types?.length && setMediaType(data?.query_builder?.media_types.map(item => createMediaTypeOption(item)));
    }
  }, [data]);

  useEffect(() => {
    if (countriesData?.specific_countries && countriesData?.data?.length) {
      setCountries(geographyData ? countriesData?.data?.filter(item => item?.value !== 'not_defined') : countriesData?.data);
    }
  }, [geographyData, countriesData]);

  const sendAmplitudeEvent = (event, category = `${data?.query_builder ? 'Edit' : 'Add'} Query Basic QB`) => {
    sendAlertsEvent({ category, event });
  };

  const onChangeName = (e) => {
    !e?.target?.value?.length && !errorName && setErrorName(t('error', 'ERR_REQUIRED', language));
    const wordsCount = !!e?.target?.value && e.target.value.split(' ').length;
    wordsCount >= 4 && setErrorName(t('error', 'ERR_TAG_INTERVAL', language));
    wordsCount < 4 && errorName && setErrorName('');
    setName(e.target.value);
  };

  const onChangeQuerySearch = (e) => {
    !e?.target?.value?.length && !errorQuerySearch && setErrorQuerySearch(t('error', 'ERR_REQUIRED', language));
    e.target.value.length && errorQuerySearch && setErrorQuerySearch('');
    setQuerySearch(e.target.value);
  };

  const onSwitchToAdvnced = () => {
    sendAmplitudeEvent('Clicked_Switch_to_Advanced_Mode_Button');
    if (query_builder?.keyword) {
      onCreateQuery(query_builder);
    } else {
      history('/advanced_builder/alert/');
      onCancel();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    sendAmplitudeEvent(`Clicked_${data?.query_builder ? 'Save' : 'Add'}_Button`);
    const alertDetails = { ...data, name, keyword: null, query: '', query_builder };
    if (checkRequiredFields()) {
      const manyMentions = previewData.all_count >= 10000;
      if (manyMentions) {
        onManyMentions({ body: alertDetails, alert_id: data?.id });
        return;
      }
      onCreateEdit(alertDetails);
    }
  };
  function createOption(label) {
    return ({ label: label === 'not_defined' ? t('nodata', 'UNDEFINED', language) : label, value: label });
  }
  function createMediaTypeOption(id) {
    return mediaTypesFunc(language).find(item => item.id === id);
  }

  function checkIsEqualFields() {
    if (data?.id) {
      const insideFields = { name, query_builder };
      const outsideFields = {
        name: data?.name,
        query_builder: data?.query_builder
      };
      return isEqual(insideFields, outsideFields);
    }
    return false;
  }

  function addNewWordOR(e) {
    e.preventDefault();
    sendAmplitudeEvent('Clicked_Add_Word_Button_OR_Operator');
    if (operationOr?.length >= 10) return;
    setOperationOr(prev => [...prev, { label: '', value: `example_${nanoid()}` }]);
  }

  function addNewWordAND(e) {
    e.preventDefault();
    sendAmplitudeEvent('Clicked_Add_Word_Button_AND_Operator');
    setOperationAnd(prev => [...prev, { label: '', value: `example_${nanoid()}` }]);
  }

  function addNewWordNOT(e) {
    e.preventDefault();
    sendAmplitudeEvent('Clicked_Add_Word_Button_NOT_Operator');
    setOperationNot(prev => [...prev, { label: '', value: `example_${nanoid()}` }]);
  }

  const onPreview = () => {
    sendAmplitudeEvent(`Clicked_Next_Button_in_${data?.query_builder ? 'Edit' : 'Add'}_Search_Query_Modal`);
    querySearch.length >= 2 && !preview && setPreview(true);
    querySearch.length < 2 && !preview && setErrorQuerySearch(t('error', 'MIN_SYMBOLS', language));
    preview && setPreview(false);
  };

  const onToggle = (filter, isSelect) => {
    isSelect
      ? setMediaType(prev => prev.filter(item => item?.id !== filter.id))
      : setMediaType(prev => prev.concat([{ id: filter.id, title: filter?.title }]));
  };

  function checkRequiredFields() {
    name.length < 2 && setErrorName(t('error', 'MIN_SYMBOLS', language));
    !name.length && setErrorName(t('error', 'ERR_REQUIRED', language));
    querySearch.length < 2 && setErrorQuerySearch(t('error', 'MIN_SYMBOLS', language));
    !querySearch.length && setErrorQuerySearch(t('error', 'ERR_REQUIRED', language));
    return name.length >= 2 && querySearch.length >= 2;
  }

  function createPreviewBody() {
    return !!preview && !!querySearchDebounce && {
      keyword: querySearchDebounce,
      operation_or: operationOr?.length ? operationOr.filter(item => !item.value.includes('example'))?.map(item => item?.value) : [],
      operation_and: operationAnd?.length ? operationAnd.filter(item => !item.value.includes('example'))?.map(item => item?.value) : [],
      operation_not: operationNot?.length ? operationNot.filter(item => !item.value.includes('example'))?.map(item => item?.value) : [],
      countries: countries?.length ? countries.map(item => (item?.value === 'not_defined' ? item?.value : item?.label)) : [],
      media_types: mediaType?.length ? mediaType.map(item => item?.id) : [],
      limit: '50'
    };
  }

  const onChangePrevPeriod = (params) => {
    const alertFilterEvents = ['lastWeek', 'last2Weeks'];
    alertFilterEvents.includes(params?.value) && params?.value !== period?.value && sendAmplitudeEvent(`alert_${params?.value}`);
    setPeriod(params);
  };

  const highlights = useMemo(() => {
    const higlightKeyword = querySearchDebounce ? [querySearchDebounce] : [];
    const higlightOr = operationOr?.length ? operationOr?.filter(item => !item.value.includes('example')).map(item => item?.value.trim()) : [];
    const higlightAnd = operationAnd?.length ? operationAnd?.filter(item => !item.value.includes('example')).map(item => item?.value.trim()) : [];
    return [...higlightKeyword, ...higlightOr, ...higlightAnd]?.join(',').replaceAll(',', '|');
  }, [querySearchDebounce, operationOr, operationAnd]);

  const keywordsCount = useMemo(() => querySearch.split(' ').filter(item => !!item)?.length || 0, [querySearch]);

  return (
    <div className={classes.bodyWrapper}>
      <form
        onKeyDown={(e) => { (e.key === 'Enter' || e.keyCode === 13) && e.preventDefault(); }}
        className={cn(classes.bodyKwModal, className)}
      >
        <TextField
          labelText={t('bodyAddTagModal', 'ALERT_NAME', language)}
          value={name}
          onChange={onChangeName}
          placeholder={t('bodyAddTagModal', 'ENTER_NAME_ALERT', language)}
          boxShadow
          errorText={errorName}
        />
        <div
          className={classes.switchAdvanced}
          onClick={onSwitchToAdvnced}
        >
          {t('bodyAddTagModal', 'ADVANCED_MODE', language)}
        </div>
        <TextField
          labelText={t('bodyAddTagModal', 'QUERY_SEARCH', language)}
          value={querySearch}
          onChange={onChangeQuerySearch}
          placeholder={t('bodyAddTagModal', 'ENTER_EXAMPLE', language)}
          boxShadow
          errorText={errorQuerySearch}
          className={cn(classes.kwField, keywordsCount > 6 && classes.kwField_many)}
          additionalInfo={keywordsCount > 6 ? t('bodyAddTagModal', 'RECOMEND_TEXT', language) : ''}
          labelInfo={t('bodyAddTagModal', 'SET_INTERVAL_KW', language)}
        />
        <div className={classes.creatableWrapper}>
          <div className={classes.wordButton}>OR</div>
          <ErrorBoundarySentry language={language}>
            <div className={classes.selectedList}>
              {operationOr.map(item => (
                <EditableLabel
                  key={item?.value}
                  dataItem={item}
                  onChange={(newOperation) => { setOperationOr(newOperation); }}
                  error={errorOr}
                  onChangeError={(errorValue) => { setErrorOr(errorValue ? t('error', errorValue, language) : ''); }}
                  onRemove={() => {
                    errorOr && setErrorOr('');
                    setOperationOr(prev => prev.filter(el => el?.value !== item?.value));
                  }}
                  operation={operationOr}
                />
              ))}
              <button className={cn(classes.addButton)} onClick={addNewWordOR}>
                {t('bodyAddTagModal', 'ADD_WORD', language)}
              </button>
            </div>
          </ErrorBoundarySentry>
          {errorOr && <div className={cn(classes.infoText, classes.infoText_error)}>{errorOr}</div>}
          <div className={cn(classes.infoText)}>
            {t('bodyAddTagModal', 'USED', language)}
            <b>{` ${usedOrWords} / 10`}</b>
          </div>
        </div>
        <div className={classes.creatableWrapper}>
          <div className={classes.wordButton}>AND</div>
          <ErrorBoundarySentry language={language}>
            <div className={classes.selectedList}>
              {operationAnd.map(item => (
                <EditableLabel
                  key={item?.value}
                  dataItem={item}
                  onChange={(newOperation) => { setOperationAnd(newOperation); }}
                  error={errorOr}
                  onChangeError={(errorValue) => { setErrorAnd(errorValue ? t('error', errorValue, language) : ''); }}
                  onRemove={() => {
                    errorAnd && setErrorAnd('');
                    setOperationAnd(prev => prev.filter(el => el?.value !== item?.value));
                  }}
                  operation={operationAnd}
                />
              ))}
              <button className={cn(classes.addButton)} onClick={addNewWordAND}>
                {t('bodyAddTagModal', 'ADD_WORD', language)}
              </button>
            </div>
          </ErrorBoundarySentry>
          {errorAnd && <div className={cn(classes.infoText, classes.infoText_error)}>{errorAnd}</div>}
        </div>
        <div className={classes.creatableWrapper}>
          <div className={classes.wordButton}>NOT</div>
          <ErrorBoundarySentry language={language}>
            <div className={classes.selectedList}>
              {operationNot.map(item => (
                <EditableLabel
                  dataItem={item}
                  key={item?.value}
                  onChange={(newOperation) => { setOperationNot(newOperation); }}
                  error={errorOr}
                  onChangeError={(errorValue) => { setErrorNot(errorValue ? t('error', errorValue, language) : ''); }}
                  onRemove={() => {
                    errorNot && setErrorNot('');
                    setOperationNot(prev => prev.filter(el => el?.value !== item?.value));
                  }}
                  operation={operationNot}
                />
              ))}
              <button className={cn(classes.addButton)} onClick={addNewWordNOT}>
                {t('bodyAddTagModal', 'ADD_WORD', language)}
              </button>
            </div>
          </ErrorBoundarySentry>
          {errorNot && <div className={cn(classes.infoText, classes.infoText_error)}>{errorNot}</div>}
        </div>
        {countriesData?.specific_countries ? (
          <div className={classes.countriesSwitcher}>
            <label className={cn(classes.switch)}>
              <input
                type="checkbox"
                onChange={() => { setGeographyData(!geographyData); }}
                checked={!geographyData}
              />
              <span className={cn(classes.slider, classes.round)} />
            </label>
            <span>
              {t('bodyAddTagModal', 'DISPLAY_MENTIONS', language)}
            </span>
          </div>
        ) : (
          <div className={cn(classes.creatableWrapper, classes.creatableWrapper_label)}>
            <p className={classes.labelText}>
              {t('bodyAddTagModal', 'COUNTRIES', language)}
            </p>
            <ErrorBoundarySentry language={language}>
              <Select
                noOptionsMessage={() => t('nodata', 'NOT_FOUND', language)}
                isClearable
                isMulti
                loadingMessage={() => t('bodyAddTagModal', 'LOADING', language)}
                isLoading={countriesLoading}
                inputValue={inputCountry}
                value={countries?.length ? countries.filter(item => item?.value !== 'all') : [{ value: 'all', label: t('bodyAddTagModal', 'ALL_COUNTRIES', language) }]}
                options={countriesData?.data || [{ value: 'all', label: t('bodyAddTagModal', 'ALL_COUNTRIES', language) }]}
                onChange={(value) => { setCountries(() => value.filter(item => item?.value !== 'all')); }}
                onInputChange={(inputText, e) => { e.action !== 'input-blur' && e.action !== 'menu-close' && setInputCountry(inputText); }}
                placeholder={t('bodyAddTagModal', 'SELECT_COUNTRIES', language)}
                components={{ DropdownIndicator, IndicatorSeparator: null, MultiValueLabel }}
                styles={customSelectStyles}
                maxMenuHeight={130}
              />
            </ErrorBoundarySentry>
          </div>
        )}
        <div className={cn(classes.creatableWrapper, classes.creatableWrapper_options)}>
          <p className={classes.labelText}>
            {t('bodyAddTagModal', 'MEDIA_TYPE', language)}
          </p>
          <div className={cn(classes.filterWithOptions)}>
            <ul className={classes.listOptions}>
              <ErrorBoundarySentry language={language}>
                <li className={classes.option}>
                  <FilterOption
                    text={t('bodyAddTagModal', 'ALL_SOURCES', language)}
                    checked={!mediaType?.length}
                    onChange={() => { setMediaType([]); }}
                    mode32={false}
                  />
                </li>
                {mediaTypesFunc(language).map((option) => {
                  const isSelect = mediaType?.some(selectDataItem => selectDataItem.id === option.id);
                  return (
                    <li key={option.id} className={classes.option}>
                      <FilterOption
                        text={option?.title}
                        icon={option.icon}
                        checked={isSelect}
                        onChange={() => { onToggle(option, isSelect); }}
                        mode32={false}
                      />
                    </li>
                  );
                })}
              </ErrorBoundarySentry>
            </ul>
          </div>
        </div>
      </form>
      <div className={cn(classes.previewWrapp, preview && classes.previewWrapp_opened)}>
        <Loader size={65} fetching={isPreviewLoading} color="#A7B0C8">
          {preview && (
            <div className={classes.previewBody}>
              <div className={classes.previewHeader}>
                {t('bodyAddTagModal', 'RESULT_HEADER', language)}
                <InfoBtn
                  className={cn(classes.infoButton)}
                  text={t('bodyAddTagModal', 'RESULT_KEYWORD_HEADER_INFO', language)}
                  icon="error_outline"
                />
              </div>
              <ArticlesListPreview
                previewData={previewData}
                name={querySearchDebounce}
                highlights={highlights || ''}
                language={language}
                className={classes.articlesKeywords}
                onChangePeriod={onChangePrevPeriod}
                optionsPeriod={optionsPeriod}
                period={period}
              />
            </div>
          )}
        </Loader>
      </div>
      <div className={classes.buttonBox}>
        {data?.query_builder && (
        <div
          onClick={() => {
            sendAmplitudeEvent('Clicked_Delete_Query_Button');
            onCreateEdit({ ...data, query_builder: null, keyword_id: data?.id, name: data?.name });
          }}
          className={classes.buttonDelete}
        >
          {t('btn', 'DELETE', language)}
        </div>
        )}
        {!preview && (
        <Button
          onClick={onPreview}
          textButton={t('btn', 'NEXT', language)}
          green32
        />
        )}
        {preview && (
          <Button
            type="submit"
            onClick={onSubmit}
            disabled={isPreviewLoading || checkIsEqualFields()}
            textButton={t('btn', data?.query_builder ? 'SAVE' : 'ADD', language)}
            green32
          />
        )}
      </div>
    </div>
  );
});

BodyAddEditSearchQuery.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCreateEdit: func,
  onCreateQuery: func,
  onManyMentions: func,
  onCancel: func,
  language: string
};

export default BodyAddEditSearchQuery;
