import { useState, useEffect, useRef } from 'react';
import { shape, bool, func, node, object, string, oneOfType, arrayOf } from 'prop-types';
import cn from 'classnames';

import BodyDropDown from './parts/BodyDropDown/BodyDropDown';

import classes from './BtnWithDropDown.module.scss';

const BtnWithDropDown = ({
  BodyToggleBtn = false,
  setTrigerClick = false,
  trigerClick = false,
  noCloseOnClickDropDown = false,
  arrow = false,
  children = false,
  rightDrop = false,
  classNameBodyDropDown = '',
  isChecked = false,
  bodyToggle = false,
  className = '',
  selectOption = () => null,
  menuConfig = [],
  language = 'uk'

}) => {
  const [windowVisible, setWindowVisible] = useState(false);

  const nodes = useRef();
  const buttonList = useRef();

  useEffect(() => {
    if (windowVisible) {
      document.addEventListener('mousedown', handleClick);
      document.addEventListener('keydown', escAndTabFunction);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keydown', escAndTabFunction);
    };
  }, [windowVisible]);

  function escAndTabFunction(event) {
    if (event.code === 'Escape' || event.code === 'Tab') {
      setWindowVisible(false);
    }
  }

  function handleClick(e) {
    if (!nodes.current) {
      return;
    }
    if (
      nodes.current.contains(e.target)
      || buttonList.current.contains(e.target)
    ) {
      return;
    }
    setWindowVisible(false);
  }

  const hahdleButton = () => {
    setWindowVisible(!windowVisible);
  };

  const onClickChildren = () => {
    if (setTrigerClick) {
      setTrigerClick(!trigerClick);
    }
    !noCloseOnClickDropDown && setWindowVisible(false);
  };

  return (
    <div className={cn(className && className)}>
      <div ref={buttonList} className={classes.container}>
        <div>
          {bodyToggle && (
            <button
              type="button"
              onClick={hahdleButton}
              className={classes.btnToggle}
              tabIndex="-1"
            >
              {bodyToggle}
            </button>
          )}

          {BodyToggleBtn && (
            <div type="button" className={classes.btnToggle}>
              <BodyToggleBtn onClick={hahdleButton} isChecked={isChecked} />
            </div>
          )}

          {(menuConfig.length !== 0 || children) && windowVisible && (
            <div
              className={cn(
                classes.windowWrap,
                arrow && classes.windowWrap_arrow,
                rightDrop
                  ? classes.windowWrap_right
                  : classes.windowWrap_center,
                classNameBodyDropDown
              )}
              ref={nodes}
              onClick={onClickChildren}
            >
              {children || (
                <BodyDropDown
                  menuConfig={menuConfig}
                  selectOption={selectOption}
                  language={language}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BtnWithDropDown.propTypes = {
  bodyToggle: oneOfType([bool, object]),
  BodyToggleBtn: oneOfType([bool, func]),
  isChecked: bool,
  menuConfig: arrayOf(shape({ name: string, icon: string, info: oneOfType([bool, string]) })),
  className: string,
  classNameBodyDropDown: string,
  selectOption: func,
  rightDrop: bool,
  children: oneOfType([bool, node]),
  arrow: bool,
  noCloseOnClickDropDown: bool,
  trigerClick: bool,
  setTrigerClick: oneOfType([bool, func]),
  language: string
};

export default BtnWithDropDown;
