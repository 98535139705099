import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrentPortal, clearFilterList, clearPages, openModal } from 'storage/actions';
import { useLogOut, useCopilotHistory, useCopilotAnswer } from 'storage/queryHooks';
// import { useIntercom } from 'hooks/useIntercom';
import { useQueryClient } from 'react-query';
import { sendFeedEvent } from 'services/amplitude/events';
import UserDropdown from '../UserDropdown';
import PortalsDropDown from '../PortalsDropDown';
import DatePickerButton from '../DatePickerButton';
import HelpDropDown from '../HelpDropDown';
import classes from './AdditionalNavigation.module.scss';

export default function AdditionalNavigation() {
  const { currentPortal, portalsList } = useSelector(state => state.portals);
  const portalsWithLinks = useMemo(() => portalsList.map(item => ({ ...item, href: `/${item?.slug}/mentions/all` })), [portalsList]);
  const datePicker = useSelector(state => state.datePicker.dateObj);
  const copilot_in_process = useSelector(state => state?.copilot?.in_process);
  const copilot_unread = useSelector(state => state?.copilot?.unread);
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { mutateAsync: logOut } = useLogOut();
  // const { show } = useIntercom();
  const queryClient = useQueryClient();
  const isShowAll = location.pathname?.includes('downloads') || location?.pathname?.includes('alerts');
  const isShowPortal = location.pathname?.includes('search');

  useCopilotHistory();
  useCopilotAnswer();

  function onChangePortal(value) {
    sendFeedEvent({ category: 'Header', event: 'Clicked_Portal_Name_In_Dropdown_Header' });

    dispatch(clearFilterList());
    dispatch(clearPages());
    queryClient.removeQueries({ queryKey: ['getNewsList'] });
    queryClient.removeQueries({ queryKey: ['getPages'] });
    history(`${value?.slug}/mentions/all`);
    dispatch(changeCurrentPortal(value));
  }

  function onChangeHelp({ action }) {
    const name = action?.toUpperCase();
    const helpEvents = ['HELP', 'BLOG', 'CHAT'];
    helpEvents.includes(name) && sendFeedEvent({ category: 'Header', event: `help_${action.toLowerCase()}` });

    const redirect = ['BLOG', 'HELP'];
    // name === 'CHAT' && show();
    if (redirect?.includes(name)) {
      const path = {
        BLOG: `${process.env.REACT_APP_LOOQME_BLOG_URL}`,
        HELP: `${process.env.REACT_APP_LOOQME_HELP_URL}`
      };
      window.open(path[name], '_blank');
    }
    if (action === 'feedback' || action === 'whatsNew') {
      dispatch(openModal({ action }));
    }
  }

  function onChangeUser({ action }) {
    let event; let
      category;
    action === 'account' && (event = 'Clicked_Profile_Settings_In_Dropdown');
    action === 'feed' && (event = 'Clicked_Newsfeed_Settings_In_Dropdown');
    action === 'feed' && (category = 'News feed settings');
    action === 'exit' && (event = 'Clicked_Logout_In_Dropdown');
    event && sendFeedEvent({ category: category || 'Profile Settings', event });

    action === 'account' && history('/userprofile');
    action === 'feed' && history('/feedsettings');
    action === 'exit' && logOut();
  }

  function onChangeDatePicker() {
    sendFeedEvent({ category: 'Date Picker', event: 'Clicked_Date_Picker_Button' });
    dispatch(openModal({ action: 'datePicker' }));
  }

  return (
    <div className={classes.container}>
      {(copilot_in_process || copilot_unread) && (
      <div
        className={copilot_unread ? classes.copilotUnread : classes.copilotLoader}
        onClick={() => {
          if (copilot_unread) dispatch(openModal({ action: 'copilotFullScreen' }));
        }}
      />
      )}
      {!isShowAll && (
      <div className={classes.info}>
        {!isShowPortal && <PortalsDropDown portals={portalsWithLinks} onChange={onChangePortal} active={currentPortal?.name || ''} />}
        <DatePickerButton onChange={onChangeDatePicker} datePicker={datePicker} />
      </div>
      )}

      <UserDropdown className={classes.userBtn} onChange={onChangeUser} />
    </div>
  );
}
