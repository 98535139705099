import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header, Footer } from 'Containers';
import t from 'utils/translateFunc';
import classes from './layout.module.scss';

function Layout() {
  const location = useLocation();

  function getPageTitle(path) {
    if (path.includes('search')) return t('pages', 'SEARCH');
    if (path.includes('downloads')) return t('pages', 'DOWNLOADS');
    if (path.includes('alerts')) return t('pages', 'ALERTS');
    return t('pages', 'MENTIONS');
  }

  useEffect(() => {
    document.title = getPageTitle(location?.pathname || '');
  }, [location?.pathname]);

  return (
    <section className={classes.main} id="global-wrap">
      <header className={classes.header}>
        <Header />
      </header>
      <main className={classes.body}>
        <Outlet />
      </main>
      <Footer />
    </section>
  );
}
export default Layout;
