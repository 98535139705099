import { useState, useEffect, useMemo } from 'react';
import { string, func } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, Loader, PopularList, NoSearchData, Wordcloud, ErrorBoundarySentry } from 'Components';
import { useGetPopularWords, useGetPopularLinks, useGetPopularHashtags } from 'storage/queryHooks';
import { useSelector } from 'react-redux';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyAddPopular.module.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const BodyAddPopular = ({ className = '', language = 'uk', type = '', onCancel = () => null, onClickOk = () => null }) => {
  const savedList = useSelector(state => state?.filters?.filtersQuery[type]);
  const popularCloud = useSelector(state => state?.filters?.[type]);
  const [list, setList] = useState([]);
  const [update, setUpdate] = useState(true);
  const params = list?.length ? list.reduce((acc, curr) => {
    acc.words ? acc.words.push(curr?.text) : (acc.words = [curr?.text]);
    return acc;
  }, {}) : null;
  const { isLoading: words } = useGetPopularWords({ enabled: type === 'words', params });
  const { isLoading: links } = useGetPopularLinks({ enabled: type === 'links', params });
  const { isLoading: hashtags } = useGetPopularHashtags({ enabled: type === 'hashtags', params });
  const popularIsLoading = { words, links, hashtags };
  const isLoading = popularIsLoading[type];

  useEffect(() => {
    if (update && savedList?.length && popularCloud?.length) {
      setList(savedList.map(text => ({ text, color: popularCloud?.find(el => el?.text === text)?.color })));
    }
  }, [savedList?.length, popularCloud?.length, update]);

  function onWordClick({ text, color }) {
    sendFeedEvent({ category: 'Filters', event: 'Clicked_Word_In_Filter_Modal' });
    setList(state => (state?.some(el => el?.text === text) ? state : state?.concat([{ text, color }])));
  }

  const callbacks = {
    getWordColor: word => word.color,
    onWordClick
  };
  const size0 = 20;
  const options = {
    fontSizes: [size0, size0 * 2, size0 * 4, size0 * 6, size0 * 8, size0 * 12, size0 * 14],
    fontFamily: ['Roboto', 'sans-serif'],
    spiral: 'archimedean',
    rotations: 2,
    rotationAngles: [-90, 0]
  };

  const onRemoveItem = ({ text }) => {
    setList(state => state.filter(el => el?.text !== text));
  };

  const onClear = () => {
    sendFeedEvent({ category: 'Filters', event: 'Clicked_Clear_Button_In_Filter_Modal' });
    setUpdate(false);
    setList([]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedEvent({ category: 'Filters', event: 'Clicked_Apply_Button_In_Filter_Modal' });
    onClickOk({ info: params?.words, action: 'addPopular' });
    onCancel();
  };

  const wordCloud = useMemo(() => (
    <ErrorBoundarySentry>
      <Wordcloud
        callbacks={callbacks}
        options={options}
        words={popularCloud}
      />
    </ErrorBoundarySentry>
  ), [popularCloud]);

  return (
    <div className={cn(classes.bodyAddPopular, className)}>
      <PopularList
        className={cn(classes.popularContainer)}
        onRemove={onRemoveItem}
        list={list}
      />
      <div className={cn(classes.wordsContainer)}>
        <Loader fetching={isLoading} color="#A7B0C8">
          {popularCloud?.length ? wordCloud : <NoSearchData className={classes.popularNodata} />}
        </Loader>
      </div>
      <div className={classes.buttonBox}>
        <Button
          onClick={onClear}
          textButton={t('btn', 'CLEAR', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />
        <Button
          type="submit"
          disabled={!list?.length}
          onClick={onSubmit}
          textButton={t('btn', 'APPLY', language)}
          green32
        />
      </div>
    </div>
  );
};

BodyAddPopular.propTypes = {
  className: string,
  type: string,
  onCancel: func,
  onClickOk: func,
  language: string
};

export default BodyAddPopular;
