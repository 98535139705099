import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  downloadReports: [],
  tableDownloadReports: [],
  downloadReportsCount: 0,
  hasDownloadReports: false
};

const downloadsSlice = createSlice({
  name: 'downloads',
  initialState,
  reducers: {
    addDownloadReports(state, { payload }) {
      state.downloadReports = payload.results || [];
      state.tableDownloadReports = payload?.results?.length ? payload?.results.map(item => ({
        key: item?.id,
        name: item?.name,
        file_type: item?.file_type,
        period: { start: item?.period_start, end: item?.period_end },
        sent_at: item?.sent_at,
        status: item?.status,
        url: item?.file
      })) : [];
      state.downloadReportsCount = payload.count || 0;
      state.hasDownloadReports = payload.user_has_downloads;
    },
    changeDownloadReports(state, { payload }) {
      state.downloadReports = payload;
    },
    changeDownloadReport(state, { payload }) {
      state.downloadReports = state.downloadReports.map(item => (item?.id === payload?.id && payload?.status !== item?.status ? payload : item));
      state.tableDownloadReports = state.tableDownloadReports.map(item => (item?.key === payload?.id && payload?.status !== item?.status
        ? { status: payload?.status, url: payload?.file, ...item } : item));
    }
  }
});

export const { addDownloadReports, changeDownloadReports, changeDownloadReport } = downloadsSlice.actions;
export default downloadsSlice.reducer;
