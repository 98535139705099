import t from 'utils/translateFunc';
import { isFilledArray } from 'utils/common';
import { Icons } from 'Components';
import { arrayOf, func, string, shape, bool, oneOfType } from 'prop-types';

import classes from './BodyDropDown.module.scss';

const BodyDropDown = ({
  language = 'uk',
  selectOption = () => null,
  menuConfig = []
}) => (
  <ul className={classes.bodyDropDown}>
    {isFilledArray(menuConfig) && menuConfig.map(({ name, icon, action }) => (
      <li className={classes.chipsWrap} key={action}>
        <button
          type="button"
          className={classes.chips}
          onClick={() => {
            setTimeout(() => {
              selectOption(action);
            }, 0);
          }}
        >
          {icon && <Icons icon={icon} className={classes.icon} />}
          <p className={classes.text}>{t('menuConfig', name, language)}</p>
        </button>
      </li>
    ))}
  </ul>
);

BodyDropDown.propTypes = {
  menuConfig: arrayOf(shape({ name: string, icon: string, info: oneOfType([bool, string]) })),
  selectOption: func,
  language: string
};

export default BodyDropDown;
