import { string, number, oneOfType } from 'prop-types';

export function SaveDisk({ width = 20, height = 20, color = '#8086AB', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '20'} height={height || '20'} viewBox="0 0 20 20" fill="none">
      <mask id="mask0_393_95408" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_393_95408)">
        <path d="M17.5 5.83333V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H14.1667L17.5 5.83333ZM15.8333 6.54167L13.4583 4.16667H4.16667V15.8333H15.8333V6.54167ZM10 15C10.6944 15 11.2847 14.7569 11.7708 14.2708C12.2569 13.7847 12.5 13.1944 12.5 12.5C12.5 11.8056 12.2569 11.2153 11.7708 10.7292C11.2847 10.2431 10.6944 10 10 10C9.30556 10 8.71528 10.2431 8.22917 10.7292C7.74306 11.2153 7.5 11.8056 7.5 12.5C7.5 13.1944 7.74306 13.7847 8.22917 14.2708C8.71528 14.7569 9.30556 15 10 15ZM5 8.33333H12.5V5H5V8.33333Z" fill={color || '#8086AB'} />
      </g>
    </svg>
  );
}

SaveDisk.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  color: string,
  className: string
};
