import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  maxBreadcrumbs: 50,
  // debug: process.env.NODE_ENV === 'development',
  debug: false,
  environment: process.env.NODE_ENV,
  release: '1.0.1',
  autoSessionTracking: process.env.NODE_ENV === 'production',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Sentry.Replay()
  ],
  tracePropagationTargets: ['localhost', /^https:\/\/test-hub\.looqme\.io\/api/],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});
export default Sentry;
