import { useState, useMemo } from 'react';
import {
  func, arrayOf, shape, string, number, oneOfType, bool
} from 'prop-types';
import t from 'utils/translateFunc';
import { SearchField, FilterList, NoSearchData, Button, SkeletonFilterList } from 'Components';
import cn from 'classnames';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './KSTegFilter.module.scss';

const KSTegFilter = ({
  filterType = '',
  placeholder = '',
  filterName = '',
  filterList = [],
  dataFilters = [],
  language = 'uk',
  onApply = () => null,
  addItem = () => null,
  removeItem = () => null,
  onOpenModal = () => null,
  isLoading = false,
  assignId = '',
  usedKeywords = { limit: 0, saved: 0 },
  className = ''
}) => {
  const [filterSearch, setFilterSearch] = useState('');
  const selectedData = filterList?.filter(listItem => listItem.filterName === filterName)
    .map(item => ({ id: item?.id, name: item?.name, count: item?.count || 0 }));

  const isSearchData = useMemo(
    () => (dataFilters?.length ? dataFilters?.filter(({ title, name }) => ((name.length || title.length) ? (name || title)?.toLowerCase()?.includes(filterSearch?.toLowerCase()) : '')) : []),
    [dataFilters?.length, filterSearch]
  );
  const limit = usedKeywords?.limit || 0;
  const saved = usedKeywords?.saved || 0;

  const modalKeywordName = () => {
    const finishedLimit = limit && saved >= limit;
    return (!limit || finishedLimit) ? 'increaseKeywords' : 'selectConstructor';
  };

  const modalKW = modalKeywordName();

  const onAddButtonClick = () => {
    const filterEvents = {
      keyword: {
        category: 'Add keyword',
        event: 'Clicked_Add_Keyword_Button'
      },
      tag: {
        category: 'Add tag',
        event: 'Clicked_Add_Tag_Button'
      }
    };

    filterName && filterEvents?.[filterName] && sendFeedEvent(filterEvents[filterName]);
    onOpenModal({ action: filterName === 'keyword' ? modalKW : 'addTag', info: null });
  };

  return (
    <div className={cn(classes.kSTegFilter, className)}>
      {dataFilters?.length >= 8 && (
      <div className={classes.search}>
        <SearchField
          placeholder={placeholder}
          value={filterSearch}
          setValue={setFilterSearch}
        />
      </div>
      )}
      {filterName === 'keyword' && !!usedKeywords?.limit && (
      <div className={classes.usedKeywords}>
        {t('kSTegFilter', 'USED', language)}
          { saved}
        /
        {limit}
        {t('kSTegFilter', 'KEYWORDS', language)}
      </div>
      )}
      <SkeletonFilterList fetching={isLoading}>
        {!!dataFilters?.length && !!isSearchData?.length ? (
          <FilterList
            dataFilters={dataFilters}
            filterSearch={filterSearch}
            selectData={selectedData}
            fixedHeight={!!filterSearch}
            type={filterType}
            onEdit={(modalInfo) => {
              sendFeedEvent({
                category: filterName === 'keyword' ? 'Edit Keyword' : 'Edit Tag Basic QB',
                event: filterName === 'keyword' ? 'Clicked_Edit_Keyword_Button' : 'Clicked_Edit_Tag_Button'
              });
              onOpenModal({ action: filterName === 'keyword' ? 'editKeyword' : 'editTag', info: modalInfo });
            }}
            onRemove={modalInfo => onOpenModal({ action: 'removeTag', info: modalInfo })}
            addItem={addItem}
            removeItem={removeItem}
            filterType={filterType}
            filterName={filterName}
            language={language}
          />
        ) : <NoSearchData />}
      </SkeletonFilterList>
      {filterName !== 'tagPopoup' && (
      <button
        type="button"
        onClick={onAddButtonClick}
        className={classes.btnAdd}
      >
        <div className={classes.iconAdd}>+</div>
        <p>{t('kSTegFilter', filterName === 'keyword' ? 'ADD_KEYWORD' : 'ADD_TEG', language)}</p>
      </button>
      )}
      {filterName === 'tagPopoup' && (
      <div className={classes.buttonBox}>
        {!!dataFilters?.length && !!isSearchData?.length && (
          <Button
            onClick={() => {
              sendFeedEvent({ category: 'News_Control_Panel', event: 'Tag_Added_and_Removed' });
              onApply();
            }}
            textButton={t('btn', 'APPLY', language)}
            green32
          />
        )}
        <Button
          onClick={() => {
            sendFeedEvent({ category: 'News_Control_Panel', event: 'Clicked_Add_New_Tag_Button' });
            onOpenModal({ action: 'addTag', info: null, openTagsPopover: assignId });
          }}
          textButton={t('btn', 'ADD_NEW_TAG', language)}
          purpleTransparent32
        />
      </div>
      )}
    </div>
  );
};

KSTegFilter.propTypes = {
  filterType: string,
  filterName: string,
  placeholder: string,
  dataFilters: arrayOf(
    shape({
      id: oneOfType([string, number]),
      name: string
    })
  ),
  addItem: func,
  removeItem: func,
  filterList: arrayOf(shape({
    id: oneOfType([string, number]),
    name: string,
    type: string
  })),
  onApply: func,
  onOpenModal: func,
  language: string,
  isLoading: bool,
  assignId: string,
  usedKeywords: shape({}),
  className: oneOfType([string, bool])
};

export default KSTegFilter;
