import { useState, useMemo, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { string, func, node, bool, shape, oneOfType, arrayOf, number } from 'prop-types';
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from 'rc-tooltip';
import cn from 'classnames';
import { Icon } from 'Components';
import classes from './Popover.module.scss';
import './Popover.css';

const PopoverWindow = forwardRef(
  ({
    className = '',
    childrenClassName = '',
    placement = 'bottom',
    trigger = 'click',
    body = false,
    idBind = '',
    children = <div />,
    afterChange = () => null,
    afterClose = () => null,
    infoBtn = null,
    headerText = '',
    headerUrl = '',
    offset = false,
    overflow = { adjustX: false, adjustY: false }
  }, ref) => {
    const nodes = useRef();
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
      onClosePopover() {
        setVisible(false);
      }
    }));

    useEffect(() => {
      if (visible) {
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('keydown', escAndTabFunction);
      }
      return () => {
        document.removeEventListener('mousedown', handleClick);
        document.removeEventListener('keydown', escAndTabFunction);
      };
    }, [visible]);

    function escAndTabFunction(event) {
      if (event.code === 'Escape' || event.code === 'Tab') {
        onVisibleChange();
      }
    }
    function handleClick(e) {
      if (nodes?.current?.contains(e.target)) {
        return;
      }
      onVisibleChange();
    }

    function onVisibleChange() {
      setVisible(!visible);
      !visible && afterClose();
    }

    const overlay = useMemo(() => (
      <div className={cn(classes.overlayWrapper, className)} ref={nodes} onClick={handleClick}>
        {!!headerText && (
        <div className={classes.titleWrap}>
          <a className={classes.title} target="_blank" rel="noopener noreferrer" href={headerUrl || ''}>{headerText}</a>
          {!!infoBtn?.type && (
          <Icon
            {...infoBtn}
            className={cn(classes.infoButton, classes.infoButton_card)}
            btnType={cn(classes.infoIcon, classes.infoIcon_card)}
          />
          )}
        </div>
        )}
        {!!body && <div className={classes.body}>{body}</div>}
      </div>
    ), [body, headerText, infoBtn]);
    const alignConfig = {
      offset: offset || [0, 5],
      targetOffset: ['0%', '0%'],
      overflow: overflow || { adjustX: false, adjustY: false }
    };

    return (
      <Tooltip
        trigger={trigger || 'click'}
        placement={placement || 'bottom'}
        showArrow
        mouseEnterDelay={0}
        mouseLeaveDelay={0.1}
        align={alignConfig}
        visible={visible}
        afterVisibleChange={afterChange}
        overlay={overlay}
        overlayClassName={cn(classes.popover, placement === 'bottomRight' && classes.popover_bottomRight)}
        destroyTooltipOnHide
        getTooltipContainer={() => document.getElementById(idBind || 'mentions-content')}
        onVisibleChange={onVisibleChange}
      >
        <span className={cn(classes.childrenClass, childrenClassName)}>{children}</span>
      </Tooltip>
    );
  }
);

PopoverWindow.propTypes = {
  className: string,
  placement: string,
  trigger: string,
  body: oneOfType([bool, node]),
  idBind: string,
  children: node,
  offset: oneOfType([arrayOf(number), bool]),
  afterChange: func,
  afterClose: func,
  infoBtn: shape({}),
  headerText: string,
  headerUrl: oneOfType([bool, string]),
  overflow: shape({ adjustX: bool, adjustY: bool }),
  childrenClassName: string
};

export default PopoverWindow;
