import { string, bool, number, oneOfType } from 'prop-types';
import cn from 'classnames';
import classes from './RadioButton.module.scss';

function RadioButton({ value = '', small = false, radioClass = '', disabled = false, label = '', ...rest }) {
  return (
    <div className={cn(
      classes.radioButtonWrapper,
      small && classes.radioButtonWrapper_small,
      radioClass
    )}
    >
      <input
        id={`radio-option-${value}`}
        className={classes.radioInput}
        type="radio"
        disabled={disabled}
        value={value}
        {...rest}
      />
      <label
        className={classes.radioLabel}
        htmlFor={`radio-option-${value}`}
        disabled={disabled}
      >
        {label}
      </label>
    </div>
  );
}

RadioButton.propTypes = {
  value: oneOfType([string, number]),
  small: bool,
  disabled: bool,
  label: string,
  radioClass: string
};

export default RadioButton;
