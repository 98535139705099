import { string } from 'prop-types';

export const Help = ({ className = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="16" fill="#01816f" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 13.0508C12 10.8122 13.8129 9 16.0523 9C18.2562 9 20.0691 10.8122 19.998 13.0508C19.998 14.6497 18.7538 16.3198 17.1542 16.8883C16.9765 16.9594 16.8343 17.1371 16.8343 17.3503V18.2741C16.8343 18.736 16.4433 19.1269 15.9812 19.1269C15.5191 19.1269 15.1281 18.736 15.1281 18.2741V17.3503C15.1281 16.4264 15.7324 15.5736 16.621 15.2538C17.6519 14.8985 18.3273 13.8325 18.3273 13.0508C18.3273 11.7716 17.2964 10.7411 16.0168 10.7411C14.7371 10.7411 13.7062 11.7716 13.7062 13.0508C13.7062 13.5127 13.3152 13.9036 12.8531 13.9036C12.391 13.9036 12 13.5127 12 13.0508ZM17.1543 21.8985C17.1543 22.5068 16.6609 23 16.0523 23C15.4437 23 14.9504 22.5068 14.9504 21.8985C14.9504 21.2901 15.4437 20.797 16.0523 20.797C16.6609 20.797 17.1543 21.2901 17.1543 21.8985Z" fill="#95F9BA" />
  </svg>
);

Help.propTypes = {
  className: string
};
