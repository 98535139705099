import { string, number } from 'prop-types';

export function Verified({ width = 20, height = 19, color = '#7746B1', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '20'} height={height || '19'} viewBox="0 0 20 19" fill="none">
      <path d="M7.08445 18.5066L5.43945 15.7582L2.31362 15.0564L2.608 11.8797L0.501953 9.50011L2.608 7.12053L2.31362 3.94386L5.43945 3.24199L7.08445 0.493652L10.0201 1.75199L12.9557 0.493652L14.6007 3.24199L17.7265 3.94386L17.4322 7.12053L19.5382 9.50011L17.4322 11.8797L17.7265 15.0564L14.6007 15.7582L12.9557 18.5066L10.0201 17.2482L7.08445 18.5066ZM8.95758 12.6297L14.0455 7.56261L12.8415 6.3824L8.95758 10.2455L7.19862 8.48386L5.99466 9.68761L8.95758 12.6297Z" fill={color || '#7746B1'} />
    </svg>
  );
}

Verified.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
