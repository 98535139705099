import { string, shape, bool, func } from 'prop-types';
import { components } from 'react-select';
import { RadioButton } from 'Components';
import classes from './OptionRadio.module.scss';

const OptionRadio = ({ isSelected = false, data = { label: '' }, innerProps = { onMouseMove: () => null, onMouseOver: () => null }, ...restProps }) => {
  const { onMouseMove, onMouseOver, ...restInnerProps } = innerProps;
  const newProps = { ...restProps, innerProps: restInnerProps };
  return (
    <components.Option {...newProps}>
      <div className={classes.optionRadio}>
        <RadioButton
          value={data?.value}
          label={data.label}
          checked={isSelected}
          radioClass={classes.radio}
          onChange={() => null}
        />
      </div>
    </components.Option>
  );
};

OptionRadio.propTypes = {
  data: shape({ label: string }),
  isSelected: bool,
  innerProps: shape({ onMouseMove: func, onMouseOver: func })
};

export default OptionRadio;
