import { bool, node, number, oneOfType, shape, string } from 'prop-types';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

export function Animated({ children = '', className = '', visible = true, styles = {}, duration = 350, ...rest }) {
  const ref = useRef();
  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    ...styles
  };
  return (
    <Transition
      in={visible}
      timeout={duration}
      unmountOnExit
      nodeRef={ref}
    >
      {state => (
        <div
          ref={ref}
          className={className}
          style={{ transition: `all ${duration}ms ease-in`, opacity: 0, ...transitionStyles[state] }}
          {...rest}
        >
          {children}
        </div>
      )}
    </Transition>
  );
}

Animated.propTypes = {
  children: oneOfType([node, string]),
  className: string,
  visible: bool,
  styles: shape({}),
  duration: number
};
