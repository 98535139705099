import { string, number } from 'prop-types';
import cn from 'classnames';

export const Chat = ({ width = 16, height = 16, className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={cn(className, 'icon icon-chat')}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path d="M14 0.5H2C1.175 0.5 0.5 1.175 0.5 2V15.5L3.5 12.5H14C14.825 12.5 15.5 11.825 15.5 11V2C15.5 1.175 14.825 0.5 14 0.5ZM14 11H2.8775L2 11.8775V2H14V11ZM4.25 5.75H5.75V7.25H4.25V5.75ZM10.25 5.75H11.75V7.25H10.25V5.75ZM7.25 5.75H8.75V7.25H7.25V5.75Z" fill="#9D96B8" />
  </svg>
);

Chat.propTypes = {
  width: number,
  height: number,
  className: string
};
