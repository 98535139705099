import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalType: '',
  modalInfo: null,
  isOpen: false
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal(state, { payload }) {
      state.isOpen = true;
      state.modalType = payload.action;
      state.modalInfo = payload;
    },
    closeModal(state) {
      state.modalType = '';
      state.modalInfo = null;
      state.isOpen = false;
    }
  }
});

export const { openModal, closeModal } = modalsSlice.actions;
export default modalsSlice.reducer;
