import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as requests from 'storage/search/requests';
import { openNotification } from 'Components';
import t from 'utils/translateFunc';
import { useSelector, useDispatch } from 'react-redux';
import { toggleShowInstructions, addUserSearchFilters, addSearchFilterItem,
  removeSearchFilterItem, clearSearchFilterList, editSearchCounts, addMediaArticles, addSnArticles, addSearchSources,
  addFavoriteMediaArticles, addFavoriteSnArticles, editSearchArticle, addSearchCharts,
  editFavoriteManySearchArticle } from 'storage/actions';
import { searchQuerySelector } from 'storage/selectors';
import omit from 'lodash/omit';

export const useGetSearchMediaTypes = () => {
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'getSearchMediaTypes',
    mutationFn: () => requests.getMediaTypes(language),
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); } });
};

export const useGetSearchPostTypes = () => {
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'getSearchPostTypes',
    mutationFn: () => requests.getPostTypes(language),
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); } });
};

export const useGetSearchCountries = () => useMutation({
  mutationKey: 'getSearchCountries',
  mutationFn: inputValue => requests.getCountries({ query: { name: inputValue } }),
  onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); } });

export const useGetSearchSources = (inputValue = '') => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getSearchHistoryDay', inputValue],
    queryFn: () => requests.getSourcesOptions({ query: { icontains: inputValue } }),
    onSuccess: (resp) => { resp && dispatch(addSearchSources(resp)); },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
  });
};

export const useGetPagginationSearchSources = ({ query }) => useQuery({
  queryKey: ['getPagginationSources', query],
  queryFn: ({ signal }) => requests.getSources({ query, signal }),
  onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
});

export const useGetSearchSourcesLevel = () => useMutation({
  mutationKey: 'getSearchSourcesLevel',
  mutationFn: inputValue => requests.getSourcesLevel({ query: { icontains: inputValue } }),
  onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
});

export const useGetSearchSourcesSubject = () => useMutation({
  mutationKey: 'getSearchSourcesSubject',
  mutationFn: inputValue => requests.getSourcesSubject({ query: { icontains: inputValue } }),
  onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
});

export const useGetSearchSourcesLanguages = () => useMutation({
  mutationKey: 'getSearchSourcesLanguages',
  mutationFn: inputValue => requests.getSourcesLanguages({ query: { icontains: inputValue } }),
  onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
});

export const useGetSearchHistory = () => useQuery({
  queryKey: ['getSearchHistory'],
  queryFn: () => requests.getSearchHistory(),
  onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
});

export const useGetSearchHistoryDay = day => useQuery({
  queryKey: ['getSearchHistoryDay', day],
  enabled: !!day,
  queryFn: () => requests.getSearchHistoryDay(day),
  onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
});

export const useTranslateSearchArticleText = ({ enabled, body }) => useQuery({
  enabled,
  queryKey: ['translateSearchArticleText', body],
  queryFn: () => requests.translateSearchArticleText(body)
});

export const useGetSearchAnalyticsCharts = () => {
  const { duplicates, filters, searchTab, articlesTab } = useSelector(state => state?.search);
  const filtersQuery = useSelector(searchQuerySelector);
  const dispatch = useDispatch();
  const date = useSelector(state => state?.datePicker?.dateObj);

  return useQuery({
    enabled: !!filters?.query && searchTab === 'search' && articlesTab === 'analitycs',
    queryKey: ['getSearchAnalyticsCharts', filtersQuery, duplicates, date],
    queryFn: ({ signal }) => requests.getSearchAnalyticsCharts({ body: { ...filtersQuery, duplicates }, signal }),
    onSuccess: (resp) => {
      dispatch(addSearchCharts(resp));
      dispatch(toggleShowInstructions(false));
    },
    onError: () => {
      dispatch(toggleShowInstructions(true));
      openNotification({ message: t('notif', 'SOMETHING_WRONG') });
    }
  });
};

export const useGetMediaArticles = (page = 1) => {
  const { page_size, ordering, duplicates, filters, searchTab, articlesTab } = useSelector(state => state?.search);
  const filtersQuery = useSelector(searchQuerySelector);
  const date = useSelector(state => state?.datePicker?.dateObj);
  const dispatch = useDispatch();
  return useQuery({
    enabled: !!filters?.query && searchTab === 'search' && articlesTab === 'media',
    queryKey: ['getMediaArticles', filtersQuery, page_size, ordering, duplicates, page, date],
    queryFn: ({ signal }) => requests.getMediaArticles({ body: { ...filtersQuery, duplicates, ordering }, query: { page_size, page }, signal }),
    onSuccess: (resp) => {
      dispatch(toggleShowInstructions(false));
      resp?.objects && dispatch(addMediaArticles(resp?.objects));
    },
    onError: () => {
      dispatch(toggleShowInstructions(true));
      openNotification({ message: t('notif', 'SOMETHING_WRONG') });
    }
  });
};

export const useGetSocialnetworkArticles = (page = 1) => {
  const { page_size, ordering, duplicates, filters, searchTab, articlesTab } = useSelector(state => state?.search);
  const date = useSelector(state => state?.datePicker?.dateObj);
  const filtersQuery = useSelector(searchQuerySelector);
  const dispatch = useDispatch();
  return useQuery({
    enabled: !!filters?.query && searchTab === 'search' && articlesTab === 'socialnetwork',
    queryKey: ['getSocialnetworkArticles', filtersQuery, page_size, ordering, duplicates, page, date],
    queryFn: ({ signal }) => requests.getSocialnetworkArticles({ body: { ...filtersQuery, duplicates, ordering }, query: { page_size, page }, signal }),
    onSuccess: (resp) => {
      dispatch(toggleShowInstructions(false));
      resp?.objects && dispatch(addSnArticles(resp?.objects));
    },
    onError: () => {
      dispatch(toggleShowInstructions(true));
      openNotification({ message: t('notif', 'SOMETHING_WRONG') });
    }
  });
};

export const useGetSearchCounts = () => {
  const dispatch = useDispatch();
  const filtersQuery = useSelector(searchQuerySelector);
  const date = useSelector(state => state?.datePicker?.dateObj);
  const { filters, duplicates, searchTab } = useSelector(state => state?.search);
  return useQuery({
    enabled: !!filters?.query && searchTab === 'search',
    queryKey: ['getSearchCounts', filtersQuery, duplicates, date],
    queryFn: ({ signal }) => requests.getSearchCounts({ body: { ...filtersQuery, duplicates }, signal }),
    onSuccess: (resp) => { resp && dispatch(editSearchCounts(resp)); },
    onError: () => {
      dispatch(editSearchCounts({ media: 0, sicialnetwork: 0 }));
      openNotification({ message: t('notif', 'SOMETHING_WRONG') });
    }
  });
};

export const useGetFavoriteMediaArticles = (page = 1) => {
  const dispatch = useDispatch();
  const { page_size, ordering, searchTab, articlesTab } = useSelector(state => state?.search);
  const filters = useSelector(searchQuerySelector);
  const filtersQuery = omit(filters, ['query']);
  return useQuery({
    queryKey: ['getFavoriteMediaArticles', filtersQuery, page_size, ordering, page],
    enabled: searchTab === 'favorite' && articlesTab === 'media',
    queryFn: ({ signal }) => requests.getFavoriteMediaArticles({ body: { ...filtersQuery, ordering }, query: { page_size, page }, signal }),
    onSuccess: (resp) => {
      resp?.objects && dispatch(addFavoriteMediaArticles(resp?.objects));
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
  });
};

export const useGetFavoriteSocialnetworkArticles = (page = 1) => {
  const dispatch = useDispatch();
  const { page_size, ordering, searchTab, articlesTab } = useSelector(state => state?.search);
  const filters = useSelector(searchQuerySelector);
  const filtersQuery = omit(filters, ['query']);
  return useQuery({
    queryFn: ({ signal }) => requests.getFavoriteSocialnetworkArticles({ body: { ...filtersQuery, ordering }, query: { page_size, page }, signal }),
    queryKey: ['getFavoriteSocialnetworkArticles', filtersQuery, page_size, ordering, page],
    enabled: searchTab === 'favorite' && articlesTab === 'socialnetwork',
    onSuccess: (resp) => {
      resp?.objects && dispatch(addFavoriteSnArticles(resp?.objects));
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
  });
};

export const useGetFavoriteSearchCounts = () => {
  const { searchTab } = useSelector(state => state?.search);
  const filters = useSelector(searchQuerySelector);
  const filtersQuery = omit(filters, ['query']);
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getFavoriteSearchCounts', filtersQuery],
    enabled: searchTab === 'favorite',
    queryFn: ({ signal }) => requests.getFavoriteSearchCounts({ body: filtersQuery, signal }),
    onSuccess: (resp) => { resp && dispatch(editSearchCounts(resp)); },
    onError: () => { dispatch(editSearchCounts({ media: 0, sicialnetwork: 0 })); }
  });
};

export const useGetAllFavoriteSearchCount = () => useQuery({
  queryKey: ['getAllFavoriteSearchCount'],
  queryFn: ({ signal }) => requests.getAllFavoriteSearchCounts({ signal }),
  onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
});

export const useGetSearchFilters = () => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getSearchFilters'],
    queryFn: ({ signal }) => requests.getSearchFilters({ signal }),
    onSuccess: (resp) => { resp?.length && dispatch(addUserSearchFilters(resp)); },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
  });
};

export const useCreateEditSearchFilter = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: 'createEditSearchFilter',
    mutationFn: body => (body?.id ? requests.editSearchFilter(body) : requests.createSearchFilter(body)),
    onSuccess: (resp) => { dispatch(addSearchFilterItem(resp)); },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
  });
};
export const useDeleteSearchFilter = () => {
  const dispatch = useDispatch();
  const selectedSearchFilter = useSelector(state => state?.search?.selectedSearchFilter);
  return useMutation({
    mutationKey: 'deleteSearchFilter',
    mutationFn: id => requests.deleteSearchFilter(id),
    onSuccess: (_, id) => {
      openNotification({ message: t('notif', 'FILTER_REMOVED') });
      if (id) {
        dispatch(removeSearchFilterItem(({ id })));
        id === selectedSearchFilter?.id && dispatch(clearSearchFilterList());
      }
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
  });
};

export const useDowloadSearchReport = () => {
  const { searchTab, duplicates } = useSelector(state => state?.search);
  const filters = useSelector(searchQuerySelector);
  const dowloadRequest = searchTab === 'favorite' ? requests.dowloadFavoriteSearchReport : requests.dowloadSearchReport;
  return useMutation({
    mutationKey: 'dowloadSearchReport',
    mutationFn: body => dowloadRequest({ ...body, ...filters, duplicates }),
    onSuccess: ({ message, url }) => {
      const defaultMessage = t('notif', 'SEARCH_RESULTS_EMAIL');
      message && openNotification({ message: defaultMessage });
      url && window.open(url, '_blank');
    },
    onError: ({ response }) => {
      const defaultMessage = t('notif', 'SOMETHING_WRONG');
      const messageError = typeof response?.data?.message === 'string' && response.data.message;
      const detailError = typeof response?.data?.detail === 'string' && response.data.detail;
      openNotification({ message: messageError || detailError || defaultMessage });
    }

  });
};

export const useToggleSystemArticle = () => {
  // const dispatch = useDispatch();
  const { articlesTab } = useSelector(state => state?.search);
  return useMutation({
    mutationKey: 'toggleArticleToSystem',
    mutationFn: body => (body?.article_id ? requests.addToSystem({ ...body, is_socialnetwork: articlesTab === 'socialnetwork' }) : requests.removeFromSystem(body?.id)),
    onSuccess: (resp) => {
      resp?.edit_url && window.open(resp.edit_url, '_blank');
      // dispatch(editSearchArticle({ id: req?.article_id || req?.id, field: 'added' }));
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
  });
};

export const useToggleFavoriteArticle = () => {
  const dispatch = useDispatch();
  const { articlesTab, searchTab } = useSelector(state => state?.search);
  const filters = useSelector(searchQuerySelector);
  const filtersQuery = omit(filters, ['query']);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'toggleArticleFavorite',
    mutationFn: body => (body?.solr_id ? requests.addToFavorite({ ...body, from_lsn: articlesTab === 'socialnetwork' }) : requests.removeFromFavorite(body?.id)),
    onSuccess: (_, req) => {
      queryClient.refetchQueries({ queryKey: ['getAllFavoriteSearchCount'] });
      searchTab === 'favorite' && queryClient.refetchQueries({ queryKey: ['getFavoriteSearchCounts', filtersQuery] });
      dispatch(editSearchArticle({ id: req?.solr_id || req?.id, field: 'favorited' }));
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
  });
};

export const useToggleFavoriteManyArticle = () => {
  const dispatch = useDispatch();
  const filters = useSelector(searchQuerySelector);
  const filtersQuery = omit(filters, ['query']);
  const { page_size, ordering, checkedArticles, searchTab, articlesTab } = useSelector(state => state?.search);
  const removIds = checkedArticles?.filter(item => !!item?.favorited).map(({ solr_id }) => solr_id);
  const addIds = checkedArticles.filter(item => !item?.favorited)?.map(item => omit(item, ['favorited']));
  const needAdded = addIds?.length >= removIds?.length;
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'toggleArticleFavoriteMany',
    mutationFn: () => {
      if (searchTab === 'favorite') { return requests.removeFromFavoriteMany(removIds); }
      return (needAdded ? requests.addToFavoriteMany(addIds) : requests.removeFromFavoriteMany(removIds));
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['getAllFavoriteSearchCount'] });
      dispatch(editFavoriteManySearchArticle({
        favorited: !(searchTab === 'favorite' || !needAdded),
        ids: searchTab === 'favorite' || !needAdded ? removIds : addIds?.map(({ solr_id }) => solr_id)
      }));
      const textMessage = searchTab === 'favorite' || !needAdded ? 'SELECTED_ARTICLES_REMOVED' : 'SELECTED_ARTICLES_ADDED';
      if (searchTab === 'favorite') {
        queryClient.refetchQueries({ queryKey: [articlesTab === 'media' ? 'getFavoriteMediaArticles' : 'getFavoriteSocialnetworkArticles', filtersQuery, page_size, ordering, 1] });
        queryClient.refetchQueries({ queryKey: ['getFavoriteSearchCounts', filtersQuery] });
      }
      openNotification({ message: t('notif', textMessage) });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG') }); }
  });
};
