import t from 'utils/translateFunc';
import cn from 'classnames';
import { bool, func } from 'prop-types';
import classes from './OtherChannelCard.module.scss';

function OtherChannelCard({ isBlur = false, onClick = () => null }) {
  return (
    <div className={cn(classes.alertsCard, isBlur && classes.alertsCard_blur)} onClick={onClick}>
      <div className={classes.alertBody}>
        <div className={classes.textWrap}>
          <div className={classes.textTitle}>
            {t('alerts', 'INFORM')}
          </div>
          <div className={classes.textSubTitle}>
            {t('alerts', 'INFORM_TEXT')}
          </div>
        </div>
      </div>
      <div className={classes.imageWrap} />
      <div className={classes.alertFooter}>
        <div className={classes.postItem}>
          <span className={classes.postAvar}>
            <div className={classes.imageEmail} />
          </span>
          <span className={classes.postText}>{t('alerts', 'EMAIL')}</span>
        </div>
        <div className={classes.postItem}>
          <span className={classes.postAvar}>
            <div className={classes.imageTelegram} />
          </span>
          <span className={classes.postText}>Telegram</span>
        </div>
      </div>
    </div>
  );
}

OtherChannelCard.propTypes = {
  isBlur: bool,
  onClick: func
};

export default OtherChannelCard;
