import { api } from '../../api/apiInstance';

export const copilotCreate = async ({ portal_slug, body }) => {
  const response = await api.post(`portals/${portal_slug}/articles/copilot_chat/ask/`, body);
  return response?.data;
};

export const copilotNewQuestion = async ({ portal_slug, chat_id, body }) => {
  const response = await api.post(`portals/${portal_slug}/articles/copilot_chat/ask/${chat_id}/new_question/`, body);
  return response?.data;
};

export const copilotDetail = async ({ portal_slug, chat_id }) => {
  const response = await api.get(`portals/${portal_slug}/copilot_chat_process/${chat_id}/`);
  return response?.data;
};

export const copilotHistory = async ({ portal_slug }) => {
  const response = await api.get(`portals/${portal_slug}/copilot_chat_process/`);
  return response?.data;
};

export const copilotHistoryNextPage = async ({ portal_slug, params }) => {
  const response = await api.get(`portals/${portal_slug}/copilot_chat_process/`, { params });
  return response?.data;
};

export const copilotAnswer = async ({ portal_slug, chat_id }) => {
  const response = await api.get(`portals/${portal_slug}/copilot_chat_process/${chat_id}/`);
  return response?.data;
};

export const copilotUpdate = async ({ portal_slug, chat_id, body }) => {
  console.log('Update body: ', body);
  const response = await api.patch(`portals/${portal_slug}/copilot_chat_process/${chat_id}/`, body);
  return response?.data;
};

export const copilotDelete = async ({ portal_slug, chat_id }) => {
  const response = await api.delete(`portals/${portal_slug}/copilot_chat_process/${chat_id}/`);
  return response;
};
