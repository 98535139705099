import { useState } from 'react';
import { string, func, shape, number, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, TextField, TextAreaField, SelectCustom } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyProblemModal.module.scss';

const BodyProblemModal = ({
  className = '',
  data = { name: '', id: 0 },
  onClickOk = () => null,
  onCancel = () => null,
  language = 'uk'
}) => {
  const [editName, setEditName] = useState(data?.name || '');
  const [problem, setProblem] = useState(null);
  const [problemText, setProblemText] = useState('');
  const onChangeName = (e) => {
    setEditName(e.target.value);
  };

  const onChangeProblemText = (e) => {
    setProblemText(e.target.value);
  };

  function onClear() {
    setEditName('');
    setProblem(null);
    setProblemText('');
  }

  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedEvent({ category: 'Source Settings', event: 'Report_Source_Problem_Button_Clicked' });
    onClickOk({ source: data?.id, issue_type: problem?.value, text: problemText });
    onClear();
    onCancel();
  };

  const optionsProblem = [
    { value: 'name', label: t('sourceProblem', 'WRONG_NAME', language) },
    { value: 'subject', label: t('sourceProblem', 'WRONG_TOPIC', language) },
    { value: 'geo', label: t('sourceProblem', 'WRONG_GEOGRAPHY', language) },
    { value: 'audience', label: t('sourceProblem', 'WRONG_AUDIENCE', language) },
    { value: 'date', label: t('sourceProblem', 'WRONG_DATE', language) },
    { value: 'missed_article', label: t('sourceProblem', 'MISSED_ARTICLE', language) },
    { value: 'another', label: t('sourceProblem', 'ANOTHER', language) }
  ];

  return (
    <form
      onSubmit={onSubmit}
      className={cn(classes.bodyProblem, className)}
    >
      <div className={cn(classes.inputBox)}>
        <TextField
          labelText={t('sourceProblem', 'SOURCE', language)}
          value={editName}
          onChange={onChangeName}
          placeholder={t('sourceProblem', 'CHOOSE', language)}
          boxShadow
          readonly={!editName}
        />
      </div>
      <div className={classes.inputBox}>
        <SelectCustom
          selectTitle={t('sourceProblem', 'SOURCE_PROBLEM', language)}
          value={problem}
          placeholder={t('sourceProblem', 'CHOOSE_PROBLEM', language)}
          onChange={setProblem}
          options={optionsProblem}
        />
      </div>
      {problem?.value === 'another' && (
      <div className={cn(classes.inputBox)}>
        <TextAreaField
          value={problemText}
          onChange={onChangeProblemText}
          placeholder={t('sourceProblem', 'SPECIFY_PROBLEM', language)}
          boxShadow
        />
      </div>
      )}

      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />

        <Button
          type="submit"
          disabled={!problem?.value}
          onClick={onSubmit}
          textButton={t('btn', 'SEND', language)}
          green32
        />
      </div>
    </form>
  );
};

BodyProblemModal.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCancel: func,
  onClickOk: func,
  language: string
};

export default BodyProblemModal;
