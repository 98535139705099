import { string, func, shape, number, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button } from 'Components';

import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './BodyConfirmDelAlert.module.scss';

const BodyConfirmDelAlert = ({
  className = '',
  confirmText = '',
  name = '',
  type = '',
  onCancel = () => null,
  onDelete = () => null,
  language = 'uk',
  data = { name: '', id: 0 }
}) => {
  const onSubmit = (e) => {
    e.preventDefault();
    type && sendAlertsEvent({ category: `Delete ${type}`, event: `delete_${type}` });
    onDelete(data?.id);
    onCancel();
  };

  return (
    <form onSubmit={onSubmit} className={cn(classes.bodyDelModal, className)}>
      <div className={classes.discription}>
        <p>
          {confirmText}
          {name && (
          <span className={classes.bold}>
            {data?.name || data?.title || ''}
            ?
          </span>
          ) }
        </p>
      </div>

      <div className={classes.buttonBox}>
        <Button
          type="submit"
          onClick={onSubmit}
          textButton={t('btn', name ? 'DELETE' : 'CONFIRM', language)}
          className={classes.buttonDelete}
          green32
        />
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          purpleTransparent32
        />
      </div>
    </form>
  );
};

BodyConfirmDelAlert.propTypes = {
  className: string,
  confirmText: string,
  name: string,
  type: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCancel: func,
  onDelete: func,
  language: string
};

export default BodyConfirmDelAlert;
