import { string, func, shape, oneOfType } from 'prop-types';
import { Button } from 'Components';
import classes from './BodySelectTelegram.module.scss';

const BodySelectTelegram = ({ data = { telegramBotLinkWeb: '', telegramBotLink: '' }, onCancel = () => null }) => {
  const onSubmit = (e, link) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(link, '_blank');
    onCancel();
  };

  return (
    <div className={classes.bodySelectTelegram}>
      <div className={classes.buttonBox}>
        <Button
          onClick={(e) => {
            onSubmit(e, data?.telegramBotLink);
          }}
          className={classes.button}
          textButton="Telegram Desktop"
          green32
        />
        <Button
          type="button"
          className={classes.button}
          onClick={e => onSubmit(e, data?.telegramBotLinkWeb)}
          textButton="Telegram Web"
          green32
        />
      </div>
    </div>
  );
};

BodySelectTelegram.propTypes = {
  data: oneOfType([shape({ telegramBotLinkWeb: string, telegramBotLink: string })]),
  onCancel: func
};

export default BodySelectTelegram;
