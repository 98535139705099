import { useRef, useMemo, forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { Icons, Button, RadioButtonGroup, RadioButton } from 'Components';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { func, bool, shape } from 'prop-types';
import { validationEmail } from 'data/validation';
import isEqual from 'lodash/isEqual';
import { useGetKeywordsReports, useGetTemplates, useGetPagesReports } from 'storage/queryHooks';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './ReportDetails.module.scss';
import EmailsComponent from './components/EmailsComponent';
import NameComponent from './components/NameComponent';
import TelegramComponent from './components/TelegramComponent';
import ObjectComponent from './components/ObjectComponent';
import TemplateComponent from './components/TemplateComponent';
import PeriodictyComponent from './components/PeriodictyComponent';
import TemplateDrawer from '../TemplateDrawer';
import WhatsappComponent from './components/WhatsappComponent';

const ReportDetails = forwardRef((
  {
    visible = false,
    templateVisible = false,
    refetchTelegram = false,
    reportsPortal = null,
    reportEtalon = null,
    unsavedChanges = () => null,
    data = {},
    telegramData = null,
    whatsappData = null,
    reportTemlate = null,
    onClose = () => null,
    onCreate = () => null,
    onChangeReport = () => null,
    onOpenModal = () => null,
    onStartRefetchTelegram = () => null,
    onChangeReportTemlate = () => null,
    onOpenReportTemlate = () => null,
    onCloseTemplate = () => null,
    isLoading = false },
  ref
) => {
  const detailsRef = useRef(null);
  const detailsBodyRef = useRef(null);
  const objectRef = useRef(null);
  const deliveryRef = useRef(null);
  const periodicityRef = useRef(null);
  const { data: templates } = useGetTemplates();
  const { data: keywords } = useGetKeywordsReports();
  const { data: pages } = useGetPagesReports();
  const emptyError = { name: '', object: '', frequency: '', time_list: '', emails: '', telegrams: '' };
  const [errors, setErrors] = useState(emptyError);

  const templateId = data?.report_customization?.id && data?.report_customization?.id !== 'default' ? data?.report_customization?.id : null;
  const hasTimeError = useMemo(() => !!data?.time_list?.length && data?.time_list.some(item => !item?.time && item?.time?.length !== 5), [data?.time_list]);
  const everyHours = useMemo(() => !!data?.frequency && ['one_hour', 'two_hours', 'four_hours'].includes(data.frequency), [data?.frequency]);
  const hasEmailError = useMemo(() => !!data?.emails?.length && data.emails.some(email => !validationEmail(email)), [data?.emails]);
  const hasNameError = useMemo(() => (data?.name ? ((data?.name?.length > 100 && t('error', 'MAX_REPORT_LENGTH_100'))
    || (data?.name?.length < 2 && t('error', 'MIN_SYMBOLS'))) : ''), [data?.name]);

  useEffect(() => {
    !visible && refetchTelegram && onStartRefetchTelegram(false);
  }, [refetchTelegram, visible]);

  useEffect(() => {
    !visible && refetchTelegram && onStartRefetchTelegram(false);
  }, [refetchTelegram, visible]);

  useImperativeHandle(ref, () => ({ warningMessage }));

  useEffect(() => {
    setErrors(emptyError);
  }, [data?.id, visible]);

  useEffect(() => {
    if (data?.frequency !== reportEtalon?.frequency) {
      const element = document.getElementById('periodicity');
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [data?.frequency, reportEtalon?.frequency]);

  const errObj = useMemo(() => ({
    ...errors,
    ...(errors?.name ? !hasNameError : { name: '' }),
    ...(errors?.object && !!(data?.keyword || data?.page) ? { object: false } : {}),
    ...(errors?.telegrams && !!data?.telegram_users.length ? { telegrams: '' } : {}),
    ...(errors?.whatsapps && !!data?.whatsapp_accounts.length ? { whatsapps: '' } : {}),
    ...(errors?.emails && !!data?.emails?.length && !hasEmailError ? { emails: '' } : {}),
    ...(errors?.frequency && !!data?.frequency ? { frequency: '' } : {}),
    ...(errors?.time_list && !!data?.time_list?.length && !hasTimeError ? { time_list: '' } : {})
  }), [data, errors]);

  useEffect(() => {
    const hasError = Object.values(errors).some(Boolean);
    hasError && !isEqual(errors, errObj) && setErrors(errObj);
  }, [data, errors]);

  function warningMessage() {
    const errorObj = onValidateFields();
    setErrors(errorObj);
    const hasError = Object.values(errorObj).some(Boolean);
    const infoData = { ...data };
    hasError && (infoData.errors = true);
    onOpenModal({ action: 'editReportConfirm', info: infoData });
  }

  function handleClose() {
    sendAlertsEvent({
      category: `${!data?.id ? 'Add' : 'Edit'} report`,
      event: `Clicked_Close_Button_In_${!data?.id ? 'Add' : 'Edit'}_Report`
    });
    unsavedChanges('only_close') ? warningMessage() : onClose();
  }

  function onValidateFields() {
    return {
      ...errors,
      name: hasNameError,
      object: !(data?.keyword || data?.page) && !data?.id,
      emails: data?.delivery_type === 'email' && (!data?.emails?.length || hasEmailError),
      telegrams: data?.delivery_type === 'telegram' && !data?.telegram_users?.length,
      whatsapps: data?.delivery_type === 'whatsapp' && !data?.whatsapp_accounts?.length,
      frequency: !data?.frequency,
      time_list: !everyHours && (!data?.time_list?.length || hasTimeError)
    };
  }

  function onSubmit() {
    const errorObj = onValidateFields();
    setErrors(errorObj);
    const hasError = Object.values(errorObj).some(Boolean);
    if (hasError) {
      errorObj.object && objectRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      (errorObj.emails || errorObj.telegrams || errorObj.whatsapps) && deliveryRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      (errorObj.frequency || errorObj.time_list) && periodicityRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return;
    }
    if (isLoading) return;

    const properties = {
      isEmails: !!data?.emails?.length,
      isKeyword: !!data?.keyword,
      deliveryType: data?.delivery_type || '',
      time: data?.time || '',
      timeList: data?.time_list || '',
      isTimeListDay: data?.time_list_day || '',
      isTimeListWeek: data?.time_list_week || '',
      isTimeListMonth: data?.time_list_month || '',
      page: data?.page || '',
      reportCustomization: data?.report_customization || '',
      isTelegramUsers: !!data?.telegram_users?.length,
      isWhatsappUsers: !!data?.whatsapp_accounts?.length,
      portal: data?.portal,
      isEnabled: data?.is_enabled,
      isEditable: data?.editable,
      reportType: data?.report_type || '',
      frequency: data?.frequency || '',
      templateLanguage: data?.template_language,
      ...(data?.id ? { user: data?.user || {} } : {})
    };
    sendAlertsEvent({
      category: `${data?.id ? 'Edit' : 'Add'} report`,
      event: `Clicked_${data?.id ? 'Edit' : 'Create'}_Report_Button`,
      properties
    });
    data?.id ? (unsavedChanges() && onOpenModal({ action: 'editReport', info: data })) : onCreate(data);
  }

  const reportTypes = [
    { label: 'HTML', value: 'report_html' },
    { label: 'PDF', value: 'pdf' },
    { label: 'XLS', value: 'xls' },
    { label: 'DOCX', value: 'docx' }
  ];
  const langTypes = [
    { label: 'UA', value: 'uk' },
    { label: 'ENG', value: 'en' },
    { label: 'RU', value: 'ru' }
  ];

  const dataKeyword = !!data?.id && !data?.keyword && !data?.page ? { id: null, name: t('reports', 'ALL_OBJECTS') } : data?.keyword;

  return (
    <div className={classes.reportDetails} ref={detailsRef}>
      {visible && (
      <>
        <div className={classes.detailsHeader}>
          {t('reports', !data?.id ? 'CREATE_REPORT' : 'EDIT_REPORT')}
          <button
            className={classes.exitBtn}
            onClick={handleClose}
            type="button"
          >
            <Icons
              icon="Icn_Clear"
              className={cn(classes.icon)}
            />
          </button>
        </div>
        <div className={classes.detailsBody} id="report-details-body" ref={detailsBodyRef}>
          <NameComponent
            dataName={data?.name}
            error={errors?.name}
            onChange={(name) => {
              !!errors?.name && setErrors({ ...errors, name: '' });
              onChangeReport({ ...data, name });
            }}
          />
          <div className={classes.selectWrapper} ref={objectRef}>
            <div className={classes.selectLabel}>
              {t('reports', 'OBJECT')}
            </div>
            <ObjectComponent
              keywords={keywords}
              pages={pages}
              dataObject={dataKeyword || data?.page}
              error={errors?.object}
              onChange={(elem) => {
                const allKeywords = { id: null, name: t('reports', 'ALL_OBJECTS') };
                !!errors?.object && setErrors({ ...errors, object: '' });
                const isKeyword = !!keywords?.length && [allKeywords, ...keywords].some(item => item?.id === elem?.id);
                const isPage = !!pages?.length && pages.some(item => item?.id === elem?.id);
                onChangeReport({ ...data, keyword: isKeyword ? elem : null, page: isPage ? elem : null });
              }}
            />
          </div>
          <RadioButtonGroup
            groupName={t('reports', 'FORMAT')}
            options={reportTypes}
            defaultValue={data?.report_type || reportTypes[0].value}
            onChange={(e) => { onChangeReport({ ...data, report_type: e.target.value }); }}
          />
          <RadioButtonGroup
            groupName={t('reports', 'LANGUAGE')}
            options={langTypes}
            defaultValue={data?.template_language || langTypes[0].value}
            onChange={(e) => { onChangeReport({ ...data, template_language: e.target.value }); }}
          />
          <div className={classes.selectWrapper}>
            <div className={classes.selectLabel}>
              {t('reports', 'SEND_TEMPLATE')}
            </div>
            <TemplateComponent
              templates={templates ? [{ id: 'default', title: t('reports', 'DEFAULT') }, ...templates] : [{ id: 'default', title: t('reports', 'DEFAULT') }]}
              dataTemplate={data?.report_customization || { id: 'default', name: t('reports', 'DEFAULT') }}
              error={errors?.report_customization}
              onChange={(report_customization) => {
                !!errors?.template && setErrors({ ...errors, template: '' });
                onChangeReport({ ...data, report_customization });
                onChangeReportTemlate({ id: report_customization?.id !== 'default' ? report_customization?.id : null });
              }}
            />
            <div className={classes.buttonBoxTemplate}>
              <div
                className={classes.purpleButton}
                onClick={() => {
                  sendAlertsEvent({ category: 'Add template', event: 'Clicked_Create_A_New_Template_Button' });
                  onOpenReportTemlate(null);
                }}
              >
                {t('btn', 'CREATE_NEW_TEMPLATE')}
              </div>
              {!!templateId && (
              <div
                className={classes.purpleButton}
                onClick={() => {
                  sendAlertsEvent({ category: 'Edit template', event: 'Clicked_Edit_Template_Button' });
                  onOpenReportTemlate(data?.report_customization?.id || data?.report_customization);
                }}
              >
                {t('btn', 'EDIT')}
              </div>
              )}
            </div>
          </div>
          <div className={classes.setTabWrapper}>
            <div className={classes.setTabLabel}>
              {t('reports', 'SEND_BY')}
            </div>
          </div>
          <div className={classes.deliveryWrapper} ref={deliveryRef}>
            <RadioButton
              value="email"
              label="Email"
              checked={data?.delivery_type === 'email' || !data?.delivery_type}
              onChange={() => { data?.delivery_type !== 'email' && onChangeReport({ ...data, delivery_type: 'email' }); }}
            />
            <RadioButton
              value="telegram"
              checked={data?.delivery_type === 'telegram'}
              label="Telegram"
              onChange={() => { data?.delivery_type !== 'telegram' && onChangeReport({ ...data, delivery_type: 'telegram' }); }}
            />
            <RadioButton
              value="whatsapp"
              checked={data?.delivery_type === 'whatsapp'}
              label="WhatsApp"
              onChange={() => { data?.delivery_type !== 'whatsapp' && onChangeReport({ ...data, delivery_type: 'whatsapp' }); }}
            />
            {(data?.delivery_type === 'email' || !data?.delivery_type) && (
            <EmailsComponent
              dataEmails={data?.emails}
              onChange={(emails) => { onChangeReport({ ...data, emails }); }}
              onChangeError={emails => setErrors({ ...errors, emails })}
              error={errors?.emails}
            />
            )}
            {data?.delivery_type === 'telegram' && (
            <TelegramComponent
              telegramData={telegramData}
              dataTelegramUsers={data?.telegram_users}
              onOpenModal={onOpenModal}
              onStartRefetchTelegram={onStartRefetchTelegram}
              refetchTelegram={refetchTelegram}
              onChange={(telegram_users) => { onChangeReport({ ...data, telegram_users }); }}
              error={errors?.telegrams}
            />
            )}
            {data?.delivery_type === 'whatsapp' && (
            <WhatsappComponent
              whatsappData={whatsappData}
              dataWhatsappUsers={data?.whatsapp_accounts}
              onOpenModal={onOpenModal}
              onChange={(whatsapp_accounts) => { onChangeReport({ ...data, whatsapp_accounts }); }}
              error={errors?.whatsapps}
            />
            )}
          </div>
          <div className={classes.setTabWrapper} ref={periodicityRef}>
            <div className={classes.setTabLabel}>
              {t('reports', 'PERIODICITY')}
            </div>
          </div>
          <PeriodictyComponent
            periodicty={{
              time_list: data?.time_list || null,
              time_list_day: data?.time_list_day || null,
              time_list_week: data?.time_list_week || null,
              time_list_month: data?.time_list_month || null,
              frequency: data?.frequency || '',
              frequency_specific: data?.frequency_specific || 'one_hour'
            }}
            errorFrequency={errors?.frequency}
            errorTime={errors?.time_list}
            onChange={({ frequency, frequency_specific, time_list, time_list_day, time_list_week, time_list_month }) => {
              onChangeReport({
                ...data,
                ...((frequency || frequency === null) ? { frequency } : {}),
                ...((frequency_specific || frequency_specific === null) ? { frequency_specific } : {}),
                ...((time_list || time_list === null) ? { time_list } : {}),
                ...((time_list_day || time_list_day === null) ? { time_list_day } : {}),
                ...((time_list_week || time_list_week === null) ? { time_list_week } : {}),
                ...((time_list_month || time_list_month === null) ? { time_list_month } : { })
              });
              !!(errors?.time_list || errors?.frequency) && setErrors({ ...errors, time_list: '', frequency: '' });
            }}
          />
          <div className={classes.selectWrapper}>
            <TemplateDrawer
              id={reportTemlate?.id}
              portalId={reportsPortal?.id || null}
              dataTemplate={reportTemlate}
              onChange={onChangeReportTemlate}
              onRemove={() => {
                sendAlertsEvent({ category: 'Delete template', event: 'Clicked_Delete_Button' });
                onOpenModal({ action: 'removeReportTemplate', info: reportTemlate });
              }}
              onFullScreen={info => onOpenModal({ action: 'showFullScreen', info })}
              open={templateVisible}
              onClose={onCloseTemplate}
              title={t('btn', 'EDIT_TEMLATE')}
            />
          </div>
        </div>
        <div className={classes.detailsFooter}>
          <div className={classes.buttonBox}>
            {data?.id && (
              <Button
                onClick={() => {
                  sendAlertsEvent({ category: 'Delete alert', event: 'Clicked_Delete_Report_Button' });
                  onOpenModal({ action: 'removeReport', info: data });
                }}
                textButton={t('btn', 'DELETE')}
                className={classes.deleteBtn}
              />
            )}
            <Button
              onClick={() => {
                sendAlertsEvent({
                  category: `${!data?.id ? 'Add' : 'Edit'} report`,
                  event: 'Clicked_Cancel_Button_In_Reports'
                });
                onClose();
              }}
              textButton={t('btn', 'CANCEL')}
              className={classes.cancelBtn}
              purpleTransparent32
            />
            <Button onClick={onSubmit} disabled={isLoading} textButton={t('btn', !data?.id ? 'CREATE_REPORT' : 'EDIT_REPORT')} green32 />
          </div>
        </div>
      </>
      )}

    </div>
  );
});

ReportDetails.propTypes = {
  visible: bool,
  templateVisible: bool,
  refetchTelegram: bool,
  reportsPortal: shape({}),
  unsavedChanges: func,
  telegramData: shape({}),
  whatsappData: shape({}),
  data: shape({}),
  reportEtalon: shape({}),
  reportTemlate: shape({}),
  onClose: func,
  onCreate: func,
  onStartRefetchTelegram: func,
  onChangeReportTemlate: func,
  onChangeReport: func,
  onOpenModal: func,
  onOpenReportTemlate: func,
  onCloseTemplate: func,
  isLoading: bool
};

export default ReportDetails;
