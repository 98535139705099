import { string, func, shape } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, BodyHtml } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyConfirmEditTags.module.scss';

const BodyConfirmEditTags = ({
  data = null,
  className = '',
  onCancel = () => null,
  onClickOk = () => null,
  language = 'uk'
}) => (
  <div className={cn(classes.bodyConfirmEditTags, className)}>
    <BodyHtml
      className={classes.discription}
      html={data?.text}
    />
    <div className={classes.buttonBox}>
      <Button
        onClick={() => onClickOk({ body: data?.body, isDetails: data?.isDetails })}
        textButton={t('btn', 'APPLY_CHANGES', language)}
        className={classes.buttonCancel}
        green32
      />
      <Button
        onClick={() => {
          sendFeedEvent({ category: 'News_Control_Panel', event: 'Clicked_Go_Back_Button' });
          onCancel();
          data?.openTagsPopover && document.getElementById(data?.openTagsPopover)?.click();
        }}
        textButton={t('btn', 'GO_BACK', language)}
        purpleTransparent32
      />
    </div>
  </div>
);

BodyConfirmEditTags.propTypes = {
  data: shape({}),
  className: string,
  onCancel: func,
  onClickOk: func,
  language: string
};

export default BodyConfirmEditTags;
