import { useState } from 'react';
import t from 'utils/translateFunc';
import { string, func } from 'prop-types';
import cn from 'classnames';
import { Button } from 'Components';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './BodySelectConstructor.module.scss';

function BodySelectConstructor({ onClickOk = () => null, language = 'uk' }) {
  const [builder, setBuilder] = useState('simple');
  return (
    <div className={classes.bodySelect}>
      <div className={classes.title}>
        {t('bodySelectConstructor', 'SELECT_CONSTRUCTOR', language)}
      </div>
      <div className={classes.selectCardExamples}>
        <div className={classes.selectCardsWrapper}>
          <div className={classes.selectCardWrap}>
            <div className={cn(classes.selectCard, builder === 'simple' && classes.selectCard_selected)} onClick={() => { builder !== 'simple' && setBuilder('simple'); }}>
              <div className={classes.selectCard_simpleImage} />
              <div className={classes.selectCard_text}>{t('bodySelectConstructor', 'BASIC', language)}</div>
            </div>
            <div className={classes.selectText}>
              {t('bodySelectConstructor', 'SIMPLE_TEXT', language)}
            </div>
          </div>
          <div className={classes.selectCardWrap}>
            <div
              className={cn(classes.selectCard, builder === 'advanced' && classes.selectCard_selected)}
              onClick={() => {
                sendAlertsEvent({ category: 'Add Query Basic QB', event: 'Clicked_Switch_to_Advanced_Mode_Button' });
                builder !== 'advanced' && setBuilder('advanced');
              }}
            >
              <div className={classes.selectCard_advancedImage} />
              <div className={classes.selectCard_text}>{t('bodySelectConstructor', 'ADVANCED', language)}</div>
            </div>
            <div className={classes.selectText}>
              {t('bodySelectConstructor', 'ADVANCED_TEXT', language)}
            </div>
          </div>
        </div>
        <div className={cn(
          classes.example,
          builder === 'simple' && classes.example_simple,
          builder === 'advanced' && classes.example_advanced
        )}
        />
      </div>
      <div className={classes.buttonBox}>
        <Button
          onClick={() => {
            const builderEvents = ['simple', 'advanced'];
            builderEvents.includes(builder) && sendAlertsEvent({ category: `builder_${builder}`, event: `builder_${builder}` });
            onClickOk(builder);
          }}
          textButton={t('btn', 'NEXT', language)}
          green32
        />
      </div>
    </div>
  );
}

BodySelectConstructor.propTypes = {
  onClickOk: func,
  language: string
};

export default BodySelectConstructor;
