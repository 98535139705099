import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Row } from 'antd';
import { SkeletonAlert } from 'Components';
import cn from 'classnames';
import { string, node, bool, shape } from 'prop-types';
import classes from './SkeletonMention.module.scss';

export function SkeletonAlertsList({ fetching = false, children = null, className = '', sizes = {} }) {
  const arr = new Array(6).fill(null);
  return (fetching ? (
    <SkeletonTheme baseColor="#f6f7fa" highlightColor="#e0e0f3">
      <Row gutter={[16, 16]} align="start" className={cn(classes.alertsList, className)}>
        {arr.map((_, i) => <Col {...sizes} key={i}><SkeletonAlert /></Col>)}
      </Row>
    </SkeletonTheme>
  ) : children);
}

SkeletonAlertsList.propTypes = {
  fetching: bool,
  children: node,
  className: string,
  sizes: shape({})
};
export default SkeletonAlertsList;
