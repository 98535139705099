import React from 'react';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { node, shape, string, oneOfType, array } from 'prop-types';
import classes from './NoDataSearch.module.scss';

function NoDataSearch({ className, data, children }) {
  if (!data) return null;
  return !data?.length ? (
    <div className={cn(classes.noDataWrap, className)}>
      <div className={classes.noDataImage} />
      <div className={classes.textWrapp}>
        <span className={classes.noDataText}>{t('search', 'NO_MENTIONS_FOUND')}</span>
        <span className={classes.noDataSubText}>
          &bull;&nbsp;
          {t('search', 'NEXT_STEPS')}
        </span>
        <span className={classes.noDataSubText}>
          &bull;&nbsp;
          {t('search', 'CHECK_SEARCH_QUERY')}
        </span>
        <span className={classes.noDataSubText}>
          &bull;&nbsp;
          {t('search', 'ADJUST_FILTERS')}
        </span>
        <span className={classes.noDataSubText}>
          &bull;&nbsp;
          {t('search', 'EXTEND_SEARCH_PERIOD')}
        </span>
      </div>
    </div>
  ) : children;
}
NoDataSearch.propTypes = { className: string, data: oneOfType([array, shape({})]), children: oneOfType([node, shape({})]) };

export default NoDataSearch;
