import { useMemo } from 'react';
import { Animated, LineChart } from 'Components';
import { bool, shape } from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classes from './SearchResultsCharts.module.scss';

function SearchResultsCharts({ show = false, data = null, isLoading = false }) {
  const { start, end } = useSelector(state => state?.datePicker?.dateObj);
  const days = moment(end).diff(moment(start), 'days');
  const hours = moment(end).diff(moment(start), 'hours');
  const periodFunc = () => {
    if (days === 0 && hours < 24) return 'day';
    if (days < 8 && days > 0) return 'week';
    return 'month';
  };
  const period = useMemo(() => periodFunc(), [start, end]);

  return (
    <Animated visible={!!show} duration={300} className={classes.chartsWrapp}>
      <LineChart period={period} data={data?.by_mention_dynamic || []} isLoading={isLoading} />
    </Animated>
  );
}
SearchResultsCharts.propTypes = { show: bool, data: shape({}), isLoading: bool };

export default SearchResultsCharts;
