import { useState, useEffect } from 'react';
import { oneOfType, func, bool, string } from 'prop-types';
import t from 'utils/translateFunc';
import { TextField } from 'Components';
import isEqual from 'lodash/isEqual';
import classes from './AlertDetails.module.scss';

const NameComponent = ({ dataName = '', error = false, onChange = () => null }) => {
  const [alertName, setAlertName] = useState('');
  const onChangeName = (e) => {
    setAlertName(e.target.value);
  };

  useEffect(() => {
    !isEqual(dataName, alertName) && setAlertName(dataName);
  }, [dataName]);

  useEffect(() => {
    const handler = setTimeout(() => {
      !isEqual(dataName, alertName) && onChange(alertName || '');
    }, 500);
    return () => { clearTimeout(handler); };
  }, [alertName]);

  return (
    <TextField
      labelText={t('alerts', 'ALERT_NAME')}
      value={alertName}
      classNameForInput={classes.inputText}
      onChange={onChangeName}
      placeholder={t('alerts', 'ENTER_NAME_ALERT')}
      errorText={error}
    />
  );
};

NameComponent.propTypes = {
  dataName: string,
  error: oneOfType([bool, string]),
  onChange: func
};

export default NameComponent;
