import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Icon } from 'Components';
import { useSelector, useDispatch } from 'react-redux';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import { clearFilterList, clearCurrentPage, openModal, clearSearchFilterList, clearSearchContent } from 'storage/actions';
import t from 'utils/translateFunc';
import { sendFeedEvent } from 'services/amplitude/events';
import { getPathes } from './helpers/navigationConfig';
import MainNavigation from './MainNavigation';
import classes from './Header.module.scss';
import AdditionalNavigation from './AdditionalNavigation';

function Header() {
  const serverUpdating = useSelector(state => state?.auth?.serverUpdating);
  const portal_slug = useSelector(state => state.portals?.currentPortal?.slug) || '';
  const permissions = useSelector(state => state.userInfo?.user?.permissions) || '';
  const datePickerPeriod = useSelector(state => state.datePicker?.dateObj) || {};
  const language = useSelector(state => state?.userInfo?.language);
  const history = useNavigate();
  const location = useLocation();
  const { filterName } = useParams();
  const dispatch = useDispatch();
  const pathes = getPathes({ portal_slug, serverUpdating, datePickerPeriod });
  const isMobile = useCheckMobileScreen();

  function onChangeNavigate({ action }) {
    const name = action?.toUpperCase();
    const tabsEvents = ['DASHBOARDS', 'MENTIONS', 'SEARCH', 'ALERTS', 'DOWNLOADS'];
    tabsEvents.includes(name) && sendFeedEvent({ category: 'Header', event: `tab_${action.toLowerCase()}` });

    const redirect = ['DASHBOARDS', 'CHECKIT', 'LOGO', 'ENCODING', 'ENCODING_STAT', 'TRACKING', 'TRACKING_BOTS', 'ADMIN'];
    const outsidePath = redirect?.includes(name);
    if (location.pathname?.includes('advanced_builder') && location.hash === 'confirm') {
      dispatch(openModal({
        action: 'exitBuilder',
        info: {
          text: t('advancedBuilder', filterName === 'keyword' ? 'SURE_EXIT_KW' : 'SURE_EXIT_TAG', language),
          link: outsidePath ? pathes[name] : ''
        }
      }));
      return;
    }
    if (outsidePath) {
      window.location = pathes[name];
    } else {
      let route = `/${portal_slug}/mentions/all`;
      if (name === 'MENTIONS') {
        dispatch(clearFilterList());
        dispatch(clearCurrentPage());
      }
      name === 'ALERTS' && (route = '/alerts/');
      name === 'DOWNLOADS' && (route = '/downloads/');
      if (name === 'SEARCH') {
        route = '/search';
        dispatch(clearSearchFilterList());
        dispatch(clearSearchContent());
      }
      history(route);
    }
  }

  function goToMain() {
    if (location.pathname?.includes('advanced_builder') && location.hash === 'confirm') {
      dispatch(openModal({
        action: 'exitBuilder',
        info: { text: t('advancedBuilder', filterName === 'keyword' ? 'SURE_EXIT_KW' : 'SURE_EXIT_TAG', language) }
      }));
      return;
    }

    sendFeedEvent({ category: 'Header', event: 'Clicked_Logo_Button_Header' });
    dispatch(clearFilterList());
    dispatch(clearCurrentPage());
    history(`${portal_slug}/mentions/all`);
  }

  return (
    <>
      <Icon type={isMobile ? 'mobile_logo' : 'logo'} className={classes.logo} onClick={goToMain} />
      <MainNavigation onChange={onChangeNavigate} pathes={pathes} permissions={permissions} />
      {!serverUpdating && <AdditionalNavigation />}
    </>
  );
}

export default Header;
