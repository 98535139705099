import { useState, useMemo, useEffect } from 'react';
import { oneOfType, func, shape, bool, string, arrayOf } from 'prop-types';
import { Icon, Button, SelectCustom, AnimatedTab } from 'Components';
import cn from 'classnames';
import t from 'utils/translateFunc';
import isEqual from 'lodash/isEqual';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './AlertDetails.module.scss';

const TelegramComponent = ({
  dataTelegramUsers = [],
  telegramData = null,
  refetchTelegram = false,
  error = false,
  onOpenModal = () => null,
  onStartRefetchTelegram = () => null,
  onChange = () => null
}) => {
  const [telegramUsers, setTelegramUsers] = useState([]);
  const [telegramAccounts, setTelegramAccounts] = useState(null);
  const [telegramLinks, setTelegramLinks] = useState({ telegramBotLink: '', telegramBotLinkGroup: '' });

  useEffect(() => {
    const tgLinks = { telegramBotLink: telegramData?.telegramBotLink, telegramBotLinkGroup: telegramData?.telegramBotLinkGroup };
    !isEqual(dataTelegramUsers, telegramUsers) && setTelegramUsers(dataTelegramUsers);
    !isEqual(telegramAccounts?.accounts, telegramAccounts) && setTelegramAccounts(telegramData?.accounts);
    !isEqual(tgLinks, telegramLinks) && setTelegramLinks(tgLinks);
  }, [telegramData, dataTelegramUsers]);

  useEffect(() => {
    const handler = setTimeout(() => {
      !isEqual(telegramUsers, dataTelegramUsers) && onChange(telegramUsers);
    }, 50);
    return () => { clearTimeout(handler); };
  }, [telegramUsers]);

  const optionsTelegram = useMemo(() => (telegramAccounts?.length
    ? telegramAccounts.reduce((acc, curr) => (telegramUsers.some(el => el?.id === curr?.id) ? acc : acc.concat({ value: curr?.id, label: curr?.telegram_username })), [])
    : []), [telegramAccounts, telegramUsers]);

  function onOpenTelegramLink(link) {
    link && window.open(link, '_blank');
    if (!refetchTelegram) {
      onStartRefetchTelegram(true);
      onOpenModal({ action: 'waitingConnectTelegram', info: null });
    }
  }

  function openSelectTelegramModal() {
    sendAlertsEvent({ category: 'Recipient Tg', event: 'Clicked_Add_Recipient_Button' });
    onOpenModal({ action: 'connectTelegram', info: telegramLinks });
  }

  const styles = {
    container: provided => ({
      ...provided,
      border: '1px solid #FFFFFF',
      borderRadius: '4px',
      ...(error ? {
        borderColor: '#FF2C18'
      } : {})
    }),
    placeholder: provided => ({
      ...provided,
      color: error ? '#FF2C18' : '#5A5E79',
      fontSize: '14px',
      fontWeight: 400,
      cursor: 'pointer'
    })
  };
  return (
    <AnimatedTab>
      <div className={cn(
        classes.deliveryTelegram,
        (!dataTelegramUsers?.length && !optionsTelegram?.length) && classes.deliveryTelegram_empty
      )}
      >
        <SelectCustom
          value={null}
          placeholder={t('alerts', 'SELECT_ACCOUNTS_TELEGRAM')}
          onChange={({ value, label }) => { setTelegramUsers([...telegramUsers, { id: value, telegram_username: label }]); }}
          options={optionsTelegram}
          styles={styles}
        />
        {!telegramUsers?.length && !optionsTelegram?.length && (
        <div className={cn(classes.telegramNoUsers, error && classes.telegramNoUsers_error)}>
          <div className={classes.telegramBigImage} />
          <div className={classes.telegramText}>
            {t('alerts', 'GET_ALERTS') }
          </div>
        </div>
        )}
        {!!telegramUsers?.length && telegramUsers.map(itemTelegram => (
          <div className={classes.telegramWrap} key={itemTelegram?.id}>
            <div className={classes.telegramImage} />
            <div className={classes.telegramNameWrap}>
              <div className={classes.telegramTitle}>
                {t('btn', 'PRIVATE_MESSAGE')}
              </div>
              <div className={classes.telegramName}>
                {itemTelegram?.telegram_username}
              </div>
            </div>
            <div
              className={classes.deleteIcon}
              onClick={() => {
                sendAlertsEvent({ category: 'Recipient Tg', event: 'Clicked_Delete_Recipient' });
                setTelegramUsers(telegramUsers.filter(item => item?.id !== itemTelegram?.id));
              }}
            >
              <Icon type="delete" width={16} height={18} color="#8086AB" />
            </div>
          </div>
        ))}

        <div className={classes.telegramsButtonBox}>
          {!telegramUsers?.length && !optionsTelegram?.length && (
          <>
            <Button
              onClick={() => {
                sendAlertsEvent({ category: 'Recipient Tg', event: 'Clicked_Private_Messages_Button' });
                onOpenTelegramLink(telegramLinks?.telegramBotLink);
              }}
              textButton={t('btn', 'PRIVATE_MESSAGE')}
              purpleTransparent32
            />
            <Button
              onClick={() => {
                sendAlertsEvent({ category: 'Recipient Tg', event: 'Clicked_Private_Messages_Button' });
                onOpenTelegramLink(telegramLinks?.telegramBotLinkGroup);
              }}
              textButton={t('btn', 'GROUP_MESSAGE')}
              purpleTransparent32
            />
          </>
          )}
          {(dataTelegramUsers?.length || !!optionsTelegram?.length) && (
          <Button
            onClick={openSelectTelegramModal}
            textButton={t('btn', 'ADD_RECIPIENT')}
            className={classes.addReciever}
            purpleTransparent32
            noBorder
          />
          )}
        </div>
      </div>
    </AnimatedTab>
  );
};

TelegramComponent.propTypes = {
  dataTelegramUsers: arrayOf(shape({})),
  telegramData: shape({}),
  error: oneOfType([bool, string]),
  refetchTelegram: bool,
  onOpenModal: func,
  onStartRefetchTelegram: func,
  onChange: func
};

export default TelegramComponent;
