import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonSearchArticle } from 'Components';
import cn from 'classnames';
import { string, node, bool } from 'prop-types';
import classes from './SkeletonSearchList.module.scss';

function SkeletonSearchList({ fetching = false, children = null, className = '' }) {
  const arr = new Array(6).fill(null);
  return (fetching ? (
    <div className={cn(classes.skeletonList, className)}>
      <SkeletonTheme baseColor="#f6f7fa" highlightColor="#e0e0f3">
        <Skeleton count={2} className={classes.header} containerClassName={classes.headerWrapper} inline />
        {arr.map((_, i) => <SkeletonSearchArticle key={i} />)}
      </SkeletonTheme>
    </div>
  ) : children);
}

SkeletonSearchList.propTypes = {
  fetching: bool,
  children: node,
  className: string
};

export default SkeletonSearchList;
