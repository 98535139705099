import t from 'utils/translateFunc';
import { useSelector } from 'react-redux';
import classes from './notfound.module.scss';

const PageNotFound = () => {
  const language = useSelector(state => state?.userInfo?.language);
  return (
    <div className={classes.notFound}>
      <div className={classes.notFound_image} />
      <div className={classes.notFound_text_subtitle}>
        {t('notFound', 'SUBTITLE', language)}
      </div>

    </div>
  );
};

export default PageNotFound;
