import { useRef, useState, useEffect } from 'react';
import { func, shape } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import useWindowSize from 'hooks/use-window-size';
import classes from './MainNavigation.module.scss';
import MenuDropDown from './MenuDropDown';
import { COUNT_MENU_ITEMS, navigationConfig } from '../helpers/navigationConfig';

const MainNavigation = ({ onChange = () => null, permissions = null, pathes = null }) => {
  const { pathname } = useLocation();
  const isMobile = useCheckMobileScreen();
  const [num, setNum] = useState(isMobile ? 0 : COUNT_MENU_ITEMS);
  const language = useSelector(state => state?.userInfo?.language);
  const [width] = useWindowSize();
  const ref = useRef();

  const permissionsNav = navigationConfig.filter((item) => {
    if (item.action.includes('admin') && !permissions?.admin_permission) return false;
    if (item.action.includes('search') && !permissions?.search_permission) return false;
    if (item.action.includes('alerts') && !permissions?.alerts_permission) return false;
    if (item.action.includes('encoding') && !permissions?.coding_permission) return false;
    if (item.action.includes('tracking') && !permissions?.user_activity_permission) return false;
    return true;
  });

  useEffect(() => {
    isMobile && setNum(0);
    if (!isMobile) {
      width < 1180 && num >= 1 && setNum(num - 1);
      width >= 1180 && num < (COUNT_MENU_ITEMS - 1) && num < permissionsNav?.length && setNum(num + 1);
    }
  }, [width, ref?.current?.offsetWidth, isMobile]);

  const navMenu = permissionsNav?.map(el => ({ ...el, name: el.name }));
  const navigation = navMenu?.filter((_, i) => i <= num);
  const pathArr = value => pathname?.split('/')?.find(el => el === value);
  const active = navMenu?.find(el => pathArr(el?.action))?.name || '';
  const navigationMore = navMenu?.filter((_, i) => i > num).map(item => ({ ...item, name: t('navigationMenu', item?.name, language), href: pathes[item.name] }));
  return (
    <div className={classes.navigation} ref={ref}>
      {navigation.map(({ name, info, action }, i) => (
        <a
          key={i}
          className={cn(classes.item, (name === active) && classes.item_active)}
          onClick={(e) => {
            e.preventDefault();
            onChange({ action });
          }}
          rel="noopener noreferrer"
          href={pathes[name]}
          target="_blank"
        >
          {t('navigationMenu', name, language)}
          {info && <p className={classes.info}>{info}</p>}
        </a>
      ))}
      {!!navigationMore?.length && <MenuDropDown menuConfig={navigationMore} onChange={onChange} active={active} />}
    </div>
  );
};

MainNavigation.propTypes = {
  onChange: func,
  permissions: shape({}),
  pathes: shape({})
};
export default MainNavigation;
