import { string, number } from 'prop-types';

export function Radio({ width = 14, height = 14, color = '#B193DC', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '14'} height={height || '14'} viewBox="0 0 14 14" fill="none">
      <mask id="mask0_317_30800" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_317_30800)">
        <path d="M2.71442 14.9096C2.29461 14.9096 1.93688 14.7618 1.64122 14.4662C1.34558 14.1705 1.19775 13.8128 1.19775 13.393V5.4926C1.19775 5.15637 1.27475 4.84593 1.42875 4.56127C1.58273 4.2766 1.81274 4.07364 2.11877 3.95239L10.4844 0.542969L10.9296 1.66689L5.26624 3.97594H13.2854C13.7052 3.97594 14.063 4.12376 14.3586 4.41942C14.6543 4.71508 14.8021 5.0728 14.8021 5.4926V13.393C14.8021 13.8128 14.6543 14.1705 14.3586 14.4662C14.063 14.7618 13.7052 14.9096 13.2854 14.9096H2.71442ZM2.71442 13.393H13.2854V8.80204H2.71442V13.393ZM5.28542 12.7662C5.75209 12.7662 6.14653 12.605 6.46875 12.2828C6.79098 11.9606 6.95209 11.5662 6.95209 11.0995C6.95209 10.6328 6.79098 10.2384 6.46875 9.91615C6.14653 9.59393 5.75209 9.43282 5.28542 9.43282C4.81875 9.43282 4.42431 9.59393 4.10209 9.91615C3.77987 10.2384 3.61875 10.6328 3.61875 11.0995C3.61875 11.5662 3.77987 11.9606 4.10209 12.2828C4.42431 12.605 4.81875 12.7662 5.28542 12.7662ZM2.71442 7.4687H10.6666V6.15927H11.9999V7.4687H13.2854V5.4926H2.71442V7.4687Z" fill={color || '#B193DC'} />
      </g>
    </svg>
  );
}

Radio.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
