import t from 'utils/translateFunc';
import cn from 'classnames';
import classes from './BodyWaitingConnectTelegram.module.scss';

const BodyWaitingConnectTelegram = () => (
  <div className={cn(classes.bodyWaitingConnectTelegram)}>
    <div className={classes.imageWrapp}>
      <div className={classes.telegramLoader} />
    </div>
    <div className={classes.header}>
      {t('modalWindow', 'CONNECTING_TELEGRAM')}
    </div>
    <div className={classes.description}>
      {t('alerts', 'TELEGRAM_WAITING')}
    </div>
  </div>

);

export default BodyWaitingConnectTelegram;
