import t from 'utils/translateFunc';

const templateCompanyKeyword = language => `<<<${t('templateCompanyKeyword', 'ADD_NAME', language)}>>>
  ${t('templateCompanyKeyword', 'ADD_NAME_EXAMPLE', language)}

<<<${t('templateCompanyKeyword', 'ADD_ERRORS', language)}>>>
  ${t('templateCompanyKeyword', 'ADD_ERRORS_EXAMPLE', language)}

<<<${t('templateCompanyKeyword', 'ADD_URL', language)}>>>
  "apple"

<<<${t('templateCompanyKeyword', 'ADD_MEDIA', language)}>>>
  ) AND source_type:(tv radio press internet agency socialnetwork forum)

<<<${t('templateCompanyKeyword', 'ADD_EXEP', language)}>>>
  ${t('templateCompanyKeyword', 'ADD_EXEP_EXAMPLE', language)}
`;

const templateCompanyPerson = language => `<<<${t('templatePersonKeyword', 'ADD_NAME', language)}>>>
  ${t('templatePersonKeyword', 'ADD_NAME_EXAMPLE', language)}

<<<${t('templatePersonKeyword', 'ADD_ERRORS', language)}>>>
  ${t('templatePersonKeyword', 'ADD_ERRORS_EXAMPLE', language)}

<<<${t('templatePersonKeyword', 'ADD_MEDIA', language)}>>>
  ) AND source_type:(tv radio press internet agency socialnetwork forum)

<<<${t('templatePersonKeyword', 'ADD_EXEP', language)}>>>
  ${t('templatePersonKeyword', 'ADD_EXEP_EXAMPLE', language)}
`;

const templateCompanyTag = language => `<<<${t('templateCompanyTag', 'ADD_NAME', language)}>>>
  ${t('templateCompanyTag', 'ADD_NAME_EXAMPLE', language)}

<<<${t('templateCompanyTag', 'ADD_ERRORS', language)}>>>
  ${t('templateCompanyTag', 'ADD_ERRORS_EXAMPLE', language)}

<<<${t('templateCompanyTag', 'ADD_MEDIA', language)}>>>
  ${t('templateCompanyTag', 'ADD_MEDIA_EXAMPLE', language)}

<<<${t('templateCompanyTag', 'ADD_EXEP', language)}>>>
 ${t('templateCompanyTag', 'ADD_EXEP_EXAMPLE', language)}
`;

const templatePersonTag = language => `<<<${t('templatePersonTag', 'ADD_NAME', language)}>>>
  ${t('templatePersonTag', 'ADD_NAME_EXAMPLE', language)}

<<<${t('templatePersonTag', 'ADD_ERRORS', language)}>>>
   ${t('templatePersonTag', 'ADD_ERRORS_EXAMPLE', language)}

<<<${t('templatePersonTag', 'ADD_EXEP', language)}>>>
  ${t('templatePersonTag', 'ADD_EXEP_EXAMPLE', language)}
`;

export { templateCompanyKeyword, templateCompanyPerson, templateCompanyTag, templatePersonTag };
