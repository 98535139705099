import { useState, useEffect, useRef, useMemo } from 'react';
import { Animated, Switcher, Icon, Button } from 'Components';
import { bool, func, number, string } from 'prop-types';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { Popover } from 'antd';
import Skeleton from 'react-loading-skeleton';
import classes from './SearchResultsHeader.module.scss';
import RadioReport from './RadioReport';
import 'react-loading-skeleton/dist/skeleton.css';

function SearchResultsHeader({ show = true, onDownload = () => null, fetching = false, reportLoading = false, duplicates = false, isFavorite = false, tab = 'analytics',
  onChangeTab = () => null, onToggleDuplicates = () => null, mediaCount = 0, socialCount = 0 }) {
  const [open, setOpen] = useState(false);
  const [downloadType, setDownloadType] = useState('full');
  const [downloadFormat, setDownloadFormat] = useState('xls');
  const ref = useRef();

  useEffect(() => {
    isFavorite && tab === 'analitycs' && onChangeArticlesTab('socialnetwork');
  }, [isFavorite]);

  function onChooseType({ target }) {
    setDownloadType(target?.value);
  }
  function onChooseFormat({ target }) {
    setDownloadFormat(target?.value);
  }
  function onChangeArticlesTab(value) {
    tab !== value && onChangeTab(value);
  }

  const handleOpenChange = (newOpen) => {
    !fetching && setOpen(newOpen);
  };

  const onDowlnoadReport = () => {
    setOpen(false);
    onDownload({ download_type: downloadType, download_format: downloadFormat });
  };
  const canDownload = (downloadType === 'media' && mediaCount) || (downloadType === 'socialnetwork' && socialCount) || (downloadType === 'full' && (socialCount + mediaCount));

  const downloadMenu = useMemo(() => (
    <div className={classes.downloadMenu}>
      <RadioReport
        value="full"
        count={mediaCount + socialCount}
        label={t('search', 'ALL')}
        checked={downloadType === 'full'}
        onChange={onChooseType}
      />
      <RadioReport
        value="socialnetwork"
        icon="group"
        count={socialCount}
        label={t('search', 'SOCIAL_NETWORKS')}
        checked={downloadType === 'socialnetwork'}
        onChange={onChooseType}
      />
      <RadioReport
        icon="newspaper"
        count={mediaCount}
        value="media"
        label={t('search', 'MEDIA')}
        checked={downloadType === 'media'}
        onChange={onChooseType}
      />
      <hr />
      <RadioReport
        icon="xls"
        value="xls"
        label={t('search', 'XLS')}
        checked={downloadFormat === 'xls'}
        onChange={onChooseFormat}
      />
      <RadioReport
        icon="xls"
        value="xls_full"
        label={t('search', 'XLS+')}
        labelInfo={t('search', 'SHORT_EXPLANATION_OF_DIFFERENCE')}
        checked={downloadFormat === 'xls_full'}
        onChange={onChooseFormat}
      />
      <RadioReport
        icon="html"
        value="html"
        iconClass={classes.htmlIcon}
        label={t('search', 'HTML')}
        checked={downloadFormat === 'html'}
        onChange={onChooseFormat}
      />
      <hr />
      <Button
        onClick={onDowlnoadReport}
        className={classes.downloadBtn}
        disabled={!canDownload || !!reportLoading}
        textButton={t('btn', 'DOWNLOAD')}
        green32
      />
    </div>
  ), [downloadType, downloadFormat, mediaCount, socialCount]);
  return (
    <Animated visible={!!show || !!fetching || !!isFavorite} duration={300} className={classes.articlesHeader}>
      <Animated className={classes.head} visible={!isFavorite} duration={250}>
        <div>
          <span className={classes.count}>
            {fetching ?
              <Skeleton width={20} height={20} borderRadius={30} baseColor="#f6f7fa" highlightColor="#e0e0f3" inline />
              : mediaCount + socialCount}
          </span>
          {t('search', 'MATERIALS_FOUND')}
        </div>
        <Switcher
          className={classes.customSwitcher}
          onСhange={(value) => { onToggleDuplicates(value); }}
          value={duplicates}
          labelText={t('filtrationPanel', 'DUPLICATES')}
        />
      </Animated>
      <div className={classes.main}>
        <Animated visible={!isFavorite} duration={250} className={cn(classes.tab, tab === 'analitycs' && classes.tab_active)} onClick={() => { onChangeArticlesTab('analitycs'); }}>
          <Icon type="charts" className={classes.tabIcon} />
          <span className={classes.tabText}>
            {t('search', 'ANALYTICS')}
          </span>
        </Animated>
        <div
          className={cn(classes.tab, tab === 'socialnetwork' && classes.tab_activeSocial)}
          onClick={() => { onChangeArticlesTab('socialnetwork'); }}
        >
          <Icon type="group" className={classes.tabIcon} />
          <span className={classes.tabText}>
            {t('search', 'SOCIAL_NETWORKS')}
          </span>
          <span className={cn(classes.tabCount, fetching && classes.tabCount_fetching)}>
            {fetching ?
              <Skeleton width={20} height={19} borderRadius={30} baseColor="#f6f7fa" highlightColor="#e0e0f3" inline />
              : socialCount}
          </span>
        </div>
        <div className={cn(classes.tab, tab === 'media' && classes.tab_activeMedia)} onClick={() => { onChangeArticlesTab('media'); }}>
          <Icon type="newspaper" className={classes.tabIcon} />
          <span className={classes.tabText}>
            {t('search', 'MEDIA')}
          </span>
          <span className={cn(classes.tabCount, fetching && classes.tabCount_fetching)}>
            {fetching ?
              <Skeleton width={20} height={19} borderRadius={30} baseColor="#f6f7fa" highlightColor="#e0e0f3" inline />
              : mediaCount}
          </span>
        </div>
        <div className={cn(classes.tab, classes.download)}>
          <Popover
            content={downloadMenu}
            trigger="click"
            placement="bottomLeft"
            open={open}
            onOpenChange={handleOpenChange}
            arrow={false}
            getPopupContainer={() => document.getElementById('search-content-main')}
            destroyTooltipOnHide
          >
            <div ref={ref} className={cn(classes.downloadButton, open && classes.downloadButton_active)}>
              <Icon type="download_icon" />
              <span>{t('search', 'DOWNLOAD_REPORT')}</span>
            </div>
          </Popover>
        </div>
      </div>
    </Animated>
  );
}
SearchResultsHeader.propTypes = {
  show: bool,
  duplicates: bool,
  tab: string,
  onChangeTab: func,
  onDownload: func,
  reportLoading: bool,
  onToggleDuplicates: func,
  isFavorite: bool,
  mediaCount: number,
  socialCount: number,
  fetching: bool
};
export default SearchResultsHeader;
