import { useMutation } from 'react-query';
import * as requests from 'storage/auth/requests';
import * as actions from 'storage/actions';
import { openNotification } from 'Components';
import t from 'utils/translateFunc';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useLogOut = () => {
  const language = useSelector(state => state?.userInfo?.language);
  const dispatch = useDispatch();
  const history = useNavigate();
  return useMutation({
    mutationKey: 'logOut',
    mutationFn: () => requests.logOut(),
    onSuccess: ({ redirect_url }) => {
      if (redirect_url) {
        redirect_url && dispatch(actions.authOut(redirect_url));
        history('/redirect_login');
      }
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};
