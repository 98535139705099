import React from 'react';

export function Save() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1101_3736)">
        <path d="M11.667 8.33333H1.66699V10H11.667V8.33333ZM11.667 5H1.66699V6.66667H11.667V5ZM15.0003 11.6667V8.33333H13.3337V11.6667H10.0003V13.3333H13.3337V16.6667H15.0003V13.3333H18.3337V11.6667H15.0003ZM1.66699 13.3333H8.33366V11.6667H1.66699V13.3333Z" fill="#BDBBCC" />
      </g>
      <defs>
        <clipPath id="clip0_1101_3736">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}
