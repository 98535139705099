import { useEffect } from 'react';
import t from 'utils/translateFunc';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classes from './NotAvailable.module.scss';

const NotAvailable = () => {
  const language = useSelector(state => state?.userInfo?.language);
  const notAvailable = useSelector(state => state?.auth?.notAvailable);
  const history = useNavigate();
  useEffect(() => {
    if (!notAvailable) {
      history('/');
    }
  }, [notAvailable]);

  return (
    <div className={classes.notAvailable}>
      <div className={classes.notAvailable_text}>
        {t('notAvalailable', 'UPS', language)}
      </div>
      <div className={classes.notAvailable_image} />
      <div className={classes.notAvailable_text}>
        {t('notAvalailable', 'WE_KNOW', language)}
      </div>
      <div className={classes.notAvailable_text_subtitle}>
        {t('notAvalailable', 'WE_WORK', language)}
      </div>
    </div>
  );
};

export default NotAvailable;
