/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { useCallback, useState, useRef } from 'react';
import { shape, bool } from 'prop-types';
import { Icons, InfoBtn } from 'Components';
import { components } from 'react-select';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import t from 'utils/translateFunc';
import classes from './BodyAddEditSearchQuery.module.scss';

const DropdownIndicator = ({ selectProps }) => {
  const { menuIsOpen } = selectProps;
  const rotateDeg = { transform: `rotate(${180}deg)` };
  return (
    <div className={classes.indicatorWrap}>
      <Icons
        icon="Icn_Arrow-1_nofill"
        style={menuIsOpen && rotateDeg}
        className={classes.icon}
      />
    </div>
  );
};

const MultiValueLabel = (props) => {
  const language = useSelector(state => state?.userInfo?.language);
  return (
    <components.MultiValueLabel {...props}>
      {props.data.label}
      {props.data.value === 'not_defined' && (
      <InfoBtn
        className={cn(classes.infoButton, classes.infoButton_purple)}
        text={t('bodyAddTagModal', 'NOT_ALL_DEFINED', language)}
        icon="error_outline"
      />
      )}
    </components.MultiValueLabel>
  );
};

const MultiValueEditableLabel = ({ value: propValue, onChange }) => {
  const [editingValue, setEditingValue] = useState('');
  const inputRef = useRef();

  const handleEditChange = useCallback(
    (inputValue, data) => {
      if (!inputValue) return;
      const idx = propValue.findIndex(v => v.value === data.value);
      const newValue = [...propValue];
      inputValue.length === 0
        ? newValue.splice(idx, 1)
        : (newValue[idx] = { label: inputValue, value: inputValue });
      onChange(newValue);
      setEditingValue('');
    },
    [propValue, onChange]
  );

  const EditableLabel = useCallback(
    ({ data }) => {
      if ((editingValue && editingValue === data.value) || data.value === '') {
        return (
          <input
            type="text"
            defaultValue={data.value}
            onKeyDown={(e) => {
              e.stopPropagation();
              const inputWidth = (e.currentTarget.value.length + 2.5) * 8;
              inputRef.current.style.width = e.currentTarget.value.length ? `${inputWidth}px` : '128px';
              e.key === 'Enter' && handleEditChange(e.currentTarget.value, data);
            }}
            placeholder={data.placeholder || 'напр. Україна'}
            onBlur={(e) => { handleEditChange(e.currentTarget.value, data); }}
            ref={inputRef}
            className={classes.multiInput}
            style={{ width: inputRef.current?.value?.length ? `${(inputRef.current.value.length + 1.5) * 8}px` : '128px' }}
          />
        );
      }
      return (
        <div className={classes.multiLabel} onClick={() => { setEditingValue(data.value); }}>
          {data.value}
        </div>
      );
    },
    [handleEditChange, editingValue]
  );

  return EditableLabel;
};

DropdownIndicator.propTypes = {
  selectProps: shape({ menuIsOpen: bool }).isRequired
};

export { DropdownIndicator, MultiValueLabel, MultiValueEditableLabel };
