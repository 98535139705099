export function Vector() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_221_18678)">
        <path
          d="M11.507 15.8466C11.507 16.1236 11.3295 16.3713 11.0615 16.4677L9.47999 17.0354L8.89425 18.5682C8.79486 18.828 8.53927 19 8.25348 19C7.9677 19 7.71214 18.828 7.61272 18.5682L7.02698 17.0354L5.44551 16.4677C5.17749 16.3713 5 16.1236 5 15.8466C5 15.5697 5.17749 15.3219 5.44551 15.2256L7.02698 14.6579L7.61272 13.1251C7.71211 12.8653 7.9677 12.6933 8.25348 12.6933C8.53927 12.6933 8.79483 12.8653 8.89425 13.1251L9.47999 14.6579L11.0615 15.2256C11.3295 15.3219 11.507 15.5696 11.507 15.8466ZM19.5545 10.1351L16.01 8.86725L14.7019 5.4318C14.6025 5.17203 14.3469 5 14.0611 5C13.7753 5 13.5197 5.17203 13.4203 5.4318L12.1122 8.86725L8.56767 10.1351C8.29966 10.2315 8.12217 10.4792 8.12217 10.7562C8.12217 11.0331 8.29966 11.2809 8.56767 11.3772L12.1122 12.6502L13.4203 16.0857C13.5197 16.3454 13.7753 16.5175 14.0611 16.5175C14.3469 16.5175 14.6024 16.3454 14.7019 16.0857L16.01 12.6502L19.5545 11.3772C19.8225 11.2809 20 11.0332 20 10.7562C20 10.4792 19.8225 10.2315 19.5545 10.1351ZM7.90373 8.37009C8.60307 8.37009 9.17105 7.81959 9.17105 7.14177C9.17105 6.46396 8.60307 5.91346 7.90373 5.91346C7.2044 5.91346 6.63642 6.46396 6.63642 7.14177C6.63642 7.81959 7.2044 8.37009 7.90373 8.37009Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_221_18678"
          x="0.6"
          y="0.6"
          width="23.8"
          height="22.8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.76 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_221_18678" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_221_18678" result="shape" />
        </filter>
      </defs>
    </svg>

  );
}
