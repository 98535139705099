import { string } from 'prop-types';

export const Favorite = ({ color = '#A7B0C8' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM9.12181 10.4286L7 9.22007L4.87819 10.4238C4.48962 10.6453 4.01413 10.3178 4.11638 9.90378L4.67879 7.63119L2.80239 6.10008C2.45983 5.82082 2.6439 5.2912 3.09382 5.25749L5.5633 5.06009L6.52962 2.9175C6.70346 2.5275 7.29654 2.5275 7.47038 2.9175L8.4367 5.0649L10.9062 5.26231C11.3561 5.29601 11.5402 5.82564 11.1976 6.1049L9.32121 7.636L9.88362 9.90859C9.98587 10.3227 9.51038 10.6501 9.12181 10.4286Z" fill={color || '#A7B0C8'} />
  </svg>
);

Favorite.propTypes = {
  color: string
};
