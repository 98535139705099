import { useRef, useState, memo } from 'react';
import { string, number, func, oneOfType } from 'prop-types';
import t from 'utils/translateFunc';
import { PopoverWindow, Icon, BodySubscribe } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './SubscribeDuplicatesPopover.module.scss';

const SubscribeDuplicatesPopover = memo(({ subscription_id = 0, language = 'uk', idBind = '', headerText = '', onSubmit = () => null, article_id = '' }) => {
  const bodyRef = useRef();
  const [visible, setVisible] = useState(false);

  function afterChange(value) {
    value && !visible && setVisible(true);
    !value && visible && setTimeout(() => { setVisible(false); }, 350);
  }
  function onClose() {
    visible && bodyRef?.current?.onClosePopover();
    visible && setTimeout(() => { setVisible(false); }, 350);
  }
  function handleSubmit(body) {
    onSubmit({ action: 'changeSubscription', body });
    onClose();
  }

  return (
    subscription_id ? (
      <Icon
        type="unsubscribe"
        role="button"
        className={classes.iconContainer}
        btnType={classes.icon}
        onClick={() => {
          sendFeedEvent({ category: 'Full text', event: 'Unsubscription_To_Duplicates_Button' });
          onSubmit({ action: 'changeSubscription', body: { subscription_id } });
        }}
        tooltipText={t('footer', 'UNSUBSCRIBE', language)}
      />
    ) : (
      <PopoverWindow
        ref={bodyRef}
        className={classes.subscribeDuplicatesBody}
        body={visible && (
        <BodySubscribe
          visible={visible}
          onSubmit={handleSubmit}
          article_id={article_id}
          subscription_id={subscription_id}
          headerText={headerText}
          language={language}
        />
        )}
        afterChange={afterChange}
        overflow={{ adjustX: true, adjustY: true }}
        placement="top"
        idBind={idBind}
      >
        <Icon
          type="subscribe"
          {...(!visible && { role: 'button' })}
          className={classes.iconContainer}
          btnType={classes.icon}
          onClick={() => {
            sendFeedEvent({ category: 'Full text', event: 'Subscription_To_Duplicates_Button' });
          }}
          tooltipText={t('footer', 'SUBSCRIBE_DUBLICATES', language)}
        />
      </PopoverWindow>
    )
  );
});

SubscribeDuplicatesPopover.propTypes = {
  subscription_id: number,
  language: string,
  idBind: string,
  headerText: string,
  onSubmit: func,
  article_id: oneOfType([string, number])
};

export default SubscribeDuplicatesPopover;
