import { components } from 'react-select';
import cn from 'classnames';
import { bool, shape } from 'prop-types';
import t from 'utils/translateFunc';
import classes from './PlaceholderPart.module.scss';

const { Placeholder } = components;
const PlaceholderPart = ({ selectProps = null, isFocused = false, ...props }) => {
  const { value, menuIsOpen, labelPlaceholderPart, endingPlaceholderPart, placeholder } = selectProps;
  const placeHolderText = !value?.length ? (labelPlaceholderPart || placeholder) : `${t('filtration', 'SELECTED')} ${value?.length || ''} ${endingPlaceholderPart}`;
  return (
    <>
      {menuIsOpen || isFocused ? (
        <Placeholder isFocused={isFocused} selectProps={selectProps} {...props} />
      ) : (
        <p className={cn(
          classes.placeholderPart,
          classes.hiddenText,
          (labelPlaceholderPart || value?.length || '') && classes.placeholderPartLabel
        )}
        >
          {placeHolderText}
        </p>
      )}
    </>
  );
};

PlaceholderPart.propTypes = {
  selectProps: shape({ }),
  isFocused: bool

};

export default PlaceholderPart;
