import { useState, useRef, memo } from 'react';
import { string, func, shape, number, arrayOf } from 'prop-types';
import { PopoverWindow, BodyBookmarkMenu, Icon } from 'Components';
import cn from 'classnames';
import t from 'utils/translateFunc';
import classes from './BookmarksPopover.module.scss';

const BookmarksPopover = memo(({ onSelect = () => null,
  onOpenModal = () => null,
  placement = '',
  active = '',
  menuConfig = [],
  offset = [],
  childrenClassName = '',
  language = 'uk'
}) => {
  const bodyRef = useRef();
  const [visible, setVisible] = useState(false);
  function afterChange(value) {
    value && !visible && setVisible(true);
    !value && visible && setTimeout(() => { setVisible(false); }, 0);
  }
  function onClose() {
    visible && bodyRef?.current?.onClosePopover();
    visible && setTimeout(() => { setVisible(false); }, 0);
  }

  function handleSubmit(value) {
    onSelect(value);
    onClose();
  }

  function handleOpenModal(value) {
    onOpenModal(value);
    onClose();
  }

  return (
    <PopoverWindow
      ref={bodyRef}
      className={classes.bookmarksBody}
      childrenClassName={cn(classes.childrenClass, childrenClassName)}
      body={visible && menuConfig?.length && (
        <BodyBookmarkMenu
          activeId={active}
          className={classes.bookmarksBodySelect}
          menuConfig={menuConfig}
          selectOption={handleSubmit}
          onOpenModal={handleOpenModal}
        />
      )}
      afterChange={afterChange}
      overflow={{ adjustX: true, adjustY: false }}
      placement={placement}
      idBind="mentions-main"
      offset={offset}
    >
      <div className={cn(classes.bookmarksWrapper, classes.btnMore)}>
        <Icon
          type="arrow_drop_down"
          {...(!visible && { role: 'button' })}
          tooltipText={t('btn', 'PAGES_LIST', language)}
          width={12}
          height={12}
          className={cn(classes.iconClass, visible && classes.selectClass)}
          btnType={cn(classes.iconClassBtn)}
          color="#B9C1D4"
        />
      </div>
    </PopoverWindow>
  );
});

BookmarksPopover.propTypes = {
  onSelect: func,
  onOpenModal: func,
  active: string,
  placement: string,
  offset: arrayOf(number),
  menuConfig: arrayOf(shape({})),
  childrenClassName: string,
  language: string
};

export default BookmarksPopover;
