import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { func } from 'prop-types';

const PrivateRoute = ({ component = () => null }) => {
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const RouteComponent = component;
  return isAuthenticated ? <RouteComponent /> : <Navigate to="/redirect_login" replace />;
};

PrivateRoute.propTypes = {
  component: func
};
export default PrivateRoute;
