import { arrayOf, number, bool, shape, oneOfType, string } from 'prop-types';
import cn from 'classnames';
import { Tooltip } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './Progressbar.module.scss';

const Progressbar = ({ progressbarData = [], isPopover = false, className = '', width = 0, amplitudeCategory = '', amplitudeEvent = '' }) => {
  const total = progressbarData?.reduce((totals, item) => totals + item.value, 0);
  const percentFunc = (value) => {
    if (total === 0) {
      return 100 / progressbarData.length;
    }
    return (value / total) * 100;
  };

  const showPercent = (item) => {
    if (width) {
      const lineWidth = (width * 1.7) * (item.value / total);
      return lineWidth < 15;
    }
    return false;
  };
  return (
    <div className={cn(classes.progressbar, className)}>
      {!!progressbarData?.length && progressbarData?.map((item, i) => (
        <a
          key={`${item.color}-${i}`}
          style={{
            backgroundColor: item.color,
            width: `${percentFunc(item.value)}%`
          }}
          href={item.link}
          onClick={() => {
            amplitudeCategory && amplitudeEvent && sendFeedEvent({ category: amplitudeCategory, event: amplitudeEvent });
          }}
          target="_blank"
          className={classes.link}
          rel="noopener noreferrer"
        >
          {!!item.value && (
          <Tooltip
            text={(
              <div className={classes.tooltipContent}>
                {!isPopover && <div className={cn(classes.tooltipContent_color)} style={{ background: item?.color }} />}
                <div
                  className={cn(classes.tooltipContent_text, isPopover && classes.tooltipContent_text_popover)}
                  style={{ ...(isPopover ? { color: item?.color } : null) }}
                >
                  {item.value}
                </div>
                <div
                  className={cn(classes.tooltipContent_percent, isPopover && classes.tooltipContent_percent_popover)}
                  style={{ ...(isPopover ? { color: item?.color } : null) }}
                >
                  {isPopover ? `${Math.round(percentFunc(item.value))}%` : `(${Math.round(percentFunc(item.value))}%)`}
                </div>
              </div>
              )}
            {...(isPopover && { background: 'transparent' })}
            className={cn(
              classes.tooltip,
              isPopover && classes.tooltip_card,
              item?.value > 0 && showPercent(item) && classes.show
            )}
          />
          )}
        </a>
      ))}
    </div>
  );
};

Progressbar.propTypes = {
  progressbarData: arrayOf(
    shape({
      color: string,
      value: number,
      text: oneOfType([string, number]),
      link: string
    })
  ).isRequired,
  width: number,
  className: string,
  isPopover: bool,
  amplitudeCategory: string,
  amplitudeEvent: string
};

export default Progressbar;
