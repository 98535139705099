import { memo } from 'react';
import { SelectCustom, Icon, Tooltip } from 'Components';
import { func, bool, shape, oneOfType, array, string } from 'prop-types';
import cn from 'classnames';
import classes from '../FiltersList.module.scss';
import OptionPart from '../SelectParts/OptionPart';
import PlaceholderPart from '../SelectParts/PlaceholderPart';
import getOptions from '../options';

const CustomList = memo(({ list = null, label = '', iconColor = '', endingPlaceholder = '', placeholder = '', onChange = () => null, getListData = async () => null }) => {
  const { customStyles } = getOptions();

  function onChangeOptions(values) {
    onChange(values);
  }

  const includedList = list?.length && list.filter(({ isExclude }) => !isExclude);
  const excludedList = list?.length && list.filter(({ isExclude }) => !!isExclude);

  return (
    <div className={classes.selectWrapper}>
      <label className={classes.seletMenuTitle}>{label}</label>
      <SelectCustom
        value={list}
        placeholder={placeholder}
        onChange={onChangeOptions}
        isMulti
        closeMenuOnSelect={false}
        styles={customStyles}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        endingPlaceholderPart={endingPlaceholder}
        components={{ Option: OptionPart, Placeholder: PlaceholderPart }}
        getSearchData={getListData}
        defaultOptions
        isSearchable
        async
      />
      {!!includedList?.length && (
        <div className={classes.selectedList}>
          <Icon type="add_round" width={20} height={20} />
          {includedList.map(item => (
            <div key={item?.value} className={cn(classes.selectedItem, item?.flag && classes.selectedItem_flag)}>
              <Tooltip text={item?.label}>
                <Icon
                  type="close_arrow"
                  className={classes.closeIcon}
                  width={13}
                  height={13}
                  color="#DB1211"
                  onClick={() => { onChange(list.filter(({ value }) => item.value !== value)); }}
                />
                { (!!item?.icon && (<Icon type={item.icon} color={iconColor} />)) || item?.flag || item?.code?.toUpperCase() || item.label}
              </Tooltip>
            </div>
          ))}
        </div>
      )}
      {!!excludedList?.length && (
        <div className={classes.selectedList}>
          <Icon
            type="minus_round"
            width={20}
            height={20}
          />
          {excludedList.map(item => (
            <div key={item?.value} className={cn(classes.selectedItem, item?.flag && classes.selectedItem_flag)}>
              <Tooltip text={item?.label}>
                <Icon
                  type="close_arrow"
                  className={classes.closeIcon}
                  width={13}
                  height={13}
                  color="#DB1211"
                  onClick={() => { onChange(list.filter(({ value }) => item.value !== value)); }}
                />
                { (!!item?.icon && (
                <Icon type={item.icon} color={iconColor} />
                )) || item?.flag || item?.code?.toUpperCase() || item.label}
              </Tooltip>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

CustomList.propTypes = {
  onChange: func,
  getListData: func,
  iconColor: string,
  label: string,
  endingPlaceholder: string,
  placeholder: string,
  list: oneOfType([array, bool, shape({})]) };

export default CustomList;
