import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  searchCharts: null,
  media_articles: null,
  sn_articles: null,
  media_favorite_articles: null,
  sn_favorite_articles: null,
  searchTab: 'search',
  articlesTab: 'analitycs',
  show_instructions: true,
  checkedArticles: [],
  userSearchFilters: [],
  selectedSearchFilter: null,
  page_size: 20,
  ordering: '-created_date',
  duplicates: false,
  counts: { media: 0, socialnetwork: 0 },
  queryTemp: '',
  searchSources: null,
  filters: {
    query: '',
    media_type: [],
    media_type_exclude: [],
    post_type: [],
    post_type_exclude: [],
    country: [],
    country_exclude: [],
    language: [],
    language_exclude: [],
    source: [],
    source_exclude: [],
    level: [],
    level_exclude: [],
    sn_attendance_gte: null,
    source_attendance_gte: null
  }

};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    addSearchCharts(state, { payload }) {
      state.searchCharts = payload;
    },
    addMediaArticles(state, { payload }) {
      state.media_articles = payload;
    },
    addSnArticles(state, { payload }) {
      state.sn_articles = payload;
    },
    addFavoriteMediaArticles(state, { payload }) {
      state.media_favorite_articles = payload;
    },
    addFavoriteSnArticles(state, { payload }) {
      state.sn_favorite_articles = payload;
    },
    addSearchFilterQuery(state, { payload }) {
      state.filters.query = payload;
    },
    addSearchFilterMediaTypes(state, { payload }) {
      state.filters.media_type = payload?.media_type?.length ? payload?.media_type : [];
      state.filters.media_type_exclude = payload?.media_type_exclude?.length ? payload?.media_type_exclude : [];
    },
    addSearchFilterCountries(state, { payload }) {
      state.filters.country = payload?.country?.length ? payload?.country : [];
      state.filters.country_exclude = payload?.country_exclude?.length ? payload?.country_exclude : [];
    },
    addSearchFilterLanguages(state, { payload }) {
      state.filters.language = payload?.language?.length ? payload?.language : [];
      state.filters.language_exclude = payload?.language_exclude?.length ? payload?.language_exclude : [];
    },
    addSearchFilterSources(state, { payload }) {
      state.filters.source = payload?.source?.length ? payload?.source : [];
      state.filters.source_exclude = payload?.source_exclude?.length ? payload?.source_exclude : [];
    },
    addSearchFilterLevels(state, { payload }) {
      state.filters.level = payload?.level?.length ? payload?.level : [];
      state.filters.level_exclude = payload?.level_exclude?.length ? payload?.level_exclude : [];
    },
    addSearchFilterPostTypes(state, { payload }) {
      state.filters.post_type = payload?.post_type?.length ? payload?.post_type : [];
      state.filters.post_type_exclude = payload?.post_type_exclude?.length ? payload?.post_type_exclude : [];
    },
    addSearchFilterSnAttendance(state, { payload }) {
      state.filters.sn_attendance_gte = payload || null;
    },
    addSearchFilterSourceAttendance(state, { payload }) {
      state.filters.source_attendance_gte = payload || null;
    },
    addUserSearchFilters(state, { payload }) {
      state.userSearchFilters = payload;
    },
    editArticlesTab(state, { payload }) {
      state.articlesTab = payload;
    },
    editSearchCounts(state, { payload }) {
      state.counts.media = payload?.media || 0;
      state.counts.socialnetwork = payload?.socialnetwork || 0;
    },
    addNewSearchFilter(state, { payload }) {
      if (!state.selectedSearchFilter || state.selectedSearchFilter?.id) {
        state.selectedSearchFilter = payload;
        state.userSearchFilters = [payload, ...state.userSearchFilters];
      }
    },
    selectSavedSearchQuery(state, { payload }) {
      state.selectedSearchFilter = null;
      !payload?.query && state.searchTab === 'search' && (state.show_instructions = true);
      state.checkedArticles = [];
      state.queryTemp = payload?.query || '';
      state.filters.query = payload?.query || '';
      state.filters.media_type = payload?.media_type || [];
      state.filters.media_type_exclude = payload?.media_type_exclude || [];
      state.filters.post_type = payload?.post_type || [];
      state.filters.post_type_exclude = payload?.post_type_exclude || [];
      state.filters.language = payload?.language || [];
      state.filters.language_exclude = payload?.language_exclude || [];
      state.filters.source = payload?.source || [];
      state.filters.source_exclude = payload?.source_exclude || [];
      state.filters.level = payload?.level || [];
      state.filters.level_exclude = payload?.level_exclude || [];
      state.filters.country = payload?.country || [];
      state.filters.country_exclude = payload?.country_exclude || [];
      state.filters.sn_attendance_gte = payload?.sn_attendance_gte || null;
      state.filters.source_attendance_gte = payload?.source_attendance_gte || null;
    },
    selectSavedSearchFilter(state, { payload }) {
      if (!state.selectedSearchFilter?.id !== payload?.id) {
        state.selectedSearchFilter = { id: payload?.id, name: payload?.name };
        state.userSearchFilters = state.userSearchFilters.filter(item => !!item?.id);
        const currentFilter = state.userSearchFilters.find(item => item?.id === payload?.id);
        if (currentFilter) {
          const { query, sn_attendance_gte, source_attendance_gte, media_type, media_type_exclude, level, level_exclude,
            post_type, post_type_exclude, language, language_exclude, source, source_exclude, country, country_exclude
          } = currentFilter;
          !query && state.searchTab === 'search' && (state.show_instructions = true);
          state.checkedArticles = [];
          state.queryTemp = query;
          state.filters.query = query;
          state.filters.media_type = media_type || [];
          state.filters.media_type_exclude = media_type_exclude || [];
          state.filters.post_type = post_type || [];
          state.filters.post_type_exclude = post_type_exclude || [];
          state.filters.language = language || [];
          state.filters.language_exclude = language_exclude || [];
          state.filters.source = source || [];
          state.filters.source_exclude = source_exclude || [];
          state.filters.level = level || [];
          state.filters.level_exclude = level_exclude || [];
          state.filters.country = country || [];
          state.filters.country_exclude = country_exclude || [];
          state.filters.sn_attendance_gte = sn_attendance_gte;
          state.filters.source_attendance_gte = source_attendance_gte;
        }
      }
    },
    restoreDefaultSearchFilter(state) {
      if (state.selectedSearchFilter?.id) {
        const currentFilter = state.userSearchFilters.find(item => item?.id === state.selectedSearchFilter?.id);
        if (currentFilter) {
          const { name, query, sn_attendance_gte, source_attendance_gte, media_type, media_type_exclude, level, level_exclude,
            post_type, post_type_exclude, language, language_exclude, source, source_exclude, country, country_exclude
          } = currentFilter;
          state.selectedSearchFilter.name = name;
          !query && state.searchTab === 'search' && (state.show_instructions = true);
          state.checkedArticles = [];
          state.queryTemp = query;
          state.filters.query = query;
          state.filters.media_type = media_type || [];
          state.filters.media_type_exclude = media_type_exclude || [];
          state.filters.post_type = post_type || [];
          state.filters.post_type_exclude = post_type_exclude || [];
          state.filters.language = language || [];
          state.filters.language_exclude = language_exclude || [];
          state.filters.source = source || [];
          state.filters.source_exclude = source_exclude || [];
          state.filters.level = level || [];
          state.filters.level_exclude = level_exclude || [];
          state.filters.country = country || [];
          state.filters.country_exclude = country_exclude || [];
          state.filters.sn_attendance_gte = sn_attendance_gte;
          state.filters.source_attendance_gte = source_attendance_gte;
        }
      }
    },
    editSearchFilterName(state, { payload }) {
      if (state.selectedSearchFilter) {
        state.selectedSearchFilter.name = payload?.name;
      }
    },
    addSearchFilterItem(state, { payload }) {
      state.selectedSearchFilter = payload;
      const searchDuplicatesItems = state?.userSearchFilters?.filter(listItem => listItem?.id !== payload?.id)?.filter(listItem => !!listItem?.id) || [];
      state.userSearchFilters = [payload, ...searchDuplicatesItems];
    },
    removeSearchFilterItem(state, { payload }) {
      const removeFromUserFilters = state?.userSearchFilters?.filter(listItem => listItem.id !== payload.id);
      state.userSearchFilters = removeFromUserFilters;
      if (payload?.id === state.selectedSearchFilter?.id) {
        state.selectedSearchFilter = removeFromUserFilters?.length ? removeFromUserFilters[0] : null;
      }
    },
    clearSearchContent(state) {
      state.media_articles = null;
      state.sn_articles = null;
      state.media_favorite_articles = null;
      state.sn_favorite_articles = null;
      state.queryTemp = '';
      state.filters.query = '';
    },
    clearSearchFilterList(state) {
      state.checkedArticles = [];
      state.selectedSearchFilter = null;
      state.searchCharts = null;
      // state.media_articles = null;
      // state.sn_articles = null;
      // state.media_favorite_articles = null;
      // state.sn_favorite_articles = null;
      // state.queryTemp = '';
      // state.filters.query = '';
      state.filters.media_type = [];
      state.filters.media_type_exclude = [];
      state.filters.post_type = [];
      state.filters.post_type_exclude = [];
      state.filters.language = [];
      state.filters.language_exclude = [];
      state.filters.source = [];
      state.filters.source_exclude = [];
      state.filters.level = [];
      state.filters.level_exclude = [];
      state.filters.country = [];
      state.filters.country_exclude = [];
      state.filters.sn_attendance_gte = null;
      state.filters.source_attendance_gte = null;
    },
    changeSearchOrdering(state, { payload }) {
      state.ordering = payload;
      state.checkedArticles = [];
    },
    toggleSearchDuplicates(state, { payload }) {
      state.duplicates = payload;
      state.checkedArticles = [];
    },
    changeSearchTab(state, { payload }) {
      state.searchTab = payload;
      state.articlesTab = payload === 'favorite' ? 'socialnetwork' : 'analitycs';
      state.checkedArticles = [];
      state.queryTemp = '';
      state.filters.query = '';
      state.show_instructions = payload === 'search';
    },
    changeSearchPageSize(state, { payload }) {
      state.page_size = payload;
    },
    changeCheckedSearchArcticles(state, { payload }) {
      state.checkedArticles = payload;
    },
    editSearchQuery(state, { payload }) {
      state.queryTemp = payload || '';
    },
    toggleShowInstructions(state, { payload }) {
      state.show_instructions = payload;
    },
    editSearchArticle(state, { payload }) {
      const { id, field } = payload;
      const isMedia = state.articlesTab === 'media';
      const isSocial = state.articlesTab === 'socialnetwork';
      if (state.searchTab === 'search') {
        isMedia && (state.media_articles = state.media_articles?.map(item => (item?.id === id ? { ...item, [field]: !item[field] } : item)));
        isSocial && (state.sn_articles = state.sn_articles?.map(item => (item?.id === id ? { ...item, [field]: !item[field] } : item)));
      }
      if (state.searchTab === 'favorite') {
        if (field === 'favorited') {
          isMedia && (state.media_favorite_articles = state.media_favorite_articles?.filter(item => item?.id !== id));
          isSocial && (state.sn_favorite_articles = state.sn_favorite_articles?.filter(item => item?.id !== id));
        } else {
          isMedia && (state.media_favorite_articles = state.media_favorite_articles?.map(item => (item?.id === id ? { ...item, [field]: !item[field] } : item)));
          isSocial && (state.sn_favorite_articles = state.sn_favorite_articles?.map(item => (item?.id === id ? { ...item, [field]: !item[field] } : item)));
        }
      }
    },
    editFavoriteManySearchArticle(state, { payload }) {
      const isMedia = state.articlesTab === 'media';
      const isSocial = state.articlesTab === 'socialnetwork';
      if (payload?.ids?.length) {
        state.checkedArticles = [];
        const ids = payload?.ids;
        if (state.searchTab === 'search') {
          const replaceFavorite = value => ({ ...value, favorited: ids.some(id => value?.id === id) ? payload?.favorited : value?.favorited });
          isMedia && (state.media_articles = state.media_articles?.map(item => replaceFavorite(item)));
          isSocial && (state.sn_articles = state.sn_articles?.map(item => replaceFavorite(item)));
        }
        if (state.searchTab === 'favorite') {
          isMedia && (state.media_favorite_articles = state.media_favorite_articles?.filter(item => !ids?.some(id => item?.id === id)));
          isSocial && (state.sn_favorite_articles = state.sn_favorite_articles?.filter(item => !ids?.some(id => item?.id === id)));
        }
      }
    },
    addSearchSources(state, { payload }) {
      state.searchSources = payload;
    }
  }
});
const _filters = state => state.search.filters;

export const searchQuerySelector = createSelector(
  [_filters],
  filters => ({
    query: filters?.query,
    media_type: filters?.media_type || [],
    media_type_exclude: filters?.media_type_exclude || [],
    post_type: filters?.post_type || [],
    post_type_exclude: filters?.post_type_exclude || [],
    country: filters?.country?.map(({ id }) => id) || [],
    country_exclude: filters?.country_exclude?.map(({ id }) => id) || [],
    language: filters?.language?.map(({ id }) => id) || [],
    language_exclude: filters?.language_exclude?.map(({ id }) => id) || [],
    source: filters?.source?.map(({ id }) => id) || [],
    source_exclude: filters?.source_exclude?.map(({ id }) => id) || [],
    level: filters?.level?.map(({ id }) => id) || [],
    level_exclude: filters?.level_exclude?.map(({ id }) => id) || [],
    sn_attendance_gte: filters?.sn_attendance_gte || null,
    source_attendance_gte: filters?.source_attendance_gte || null
  })
);

export const { addSearchFilterQuery, addSearchFilterMediaTypes, addSearchFilterCountries, addSearchFilterPostTypes, addSearchFilterLanguages, addSearchFilterSources,
  addSearchFilterLevels, addSearchFilterSourceAttendance, addSearchFilterSnAttendance, addSearchFilterItem, removeSearchFilterItem,
  clearSearchFilterList, changeSearchOrdering, toggleSearchDuplicates, changeSearchTab, addNewSearchFilter,
  selectSavedSearchFilter, changeSearchPageSize, changeCheckedSearchArcticles, editSearchQuery, toggleShowInstructions,
  addUserSearchFilters, editSearchFilterName, restoreDefaultSearchFilter, editArticlesTab, editSearchCounts, selectSavedSearchQuery,
  addMediaArticles, addSnArticles, addFavoriteMediaArticles, addFavoriteSnArticles, editSearchArticle, addSearchSources, addSearchCharts,
  editFavoriteManySearchArticle, clearSearchContent
} = searchSlice.actions;
export default searchSlice.reducer;
