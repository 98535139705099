/* eslint-disable react/jsx-closing-tag-location */
import { useMemo } from 'react';
import t from 'utils/translateFunc';
import hasOwn from 'lodash/has';
import { ModalWindow, BodyDelPageModal, BodyRenamePageModal, BodyCreatePageModal, BodyCardCreator, BodyProblemModal, BodyLoadingArticleModal, BodyAddPopular,
  BodyAddEditTeg, BodySendComplain, BodySelectTelegram, BodyConnectTelegram, Datepicker, BodyFeedback, BodyWhatsNew, BodyDownloadPublications, BodyProposeSource,
  BodyAddEditKW, BodyDelKeyword, BodyConfirmEditKeyword, BodyDelTag, BodyIncreaseKeywords, BodyAddKeywordSuccess, BodySelectConstructor, ErrorBoundarySentry,
  BodyConfirmEditingAdvanced, BodyConfirmExitAdvanced, BodyConfirmIfManyMentions, BodyConfirmEditTags, BodyEmptyReport, BodyAddEditSearchQuery,
  BodyWaitingConnectTelegram, BodyConfirmEditAlert, BodyConfirmDelAlert, BodyConfirmEditReport, BodyFrame, BodyDuplicateReport, BodyConnectWhatsapp, BodyConfirmWhatsapp,
  BodySelectSources, BodySearchHistory, BodyCopilotFullScreen, BodyCopilotDelete } from 'Components';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreatePage, useChangePage, useDeletePage, useComplaintArticle, useAddEditTag, useRemoveTag,
  useSendComplaints, useSendFeedback, useEditPeriod, useAddSource, useAddEditKeyword, useRemoveKeyword, useReportManger,
  useSetManyTags, useRemoveManyTags, useDeleteSimpleAlert, useDeleteNegativeAlert, useDeleteActivityAlert, useChangeSimpleAlert,
  useChangeNegativeAlert, useChangeActivityAlert, useCreateSimpleAlert, useCreateNegativeAlert, useCreateActivityAlert, useCreateAlertQuery,
  useDeleteReport, useCreateReport, useChangeReport, useDeleteReportTemplate, useAddWhatsappAccount
} from 'storage/queryHooks';
import {
  addFilterItem, openModal, closeModal, removeFilterItemByName, editAlertDetails, toggleRefetchTelegram, clearFilterList,
  clearCurrentPage, closeAlertDetails, editAlertsSimple, editAlertsNegative, editAlertsActivity, closeReportDetails,
  editReports, addSearchFilterSources } from 'storage/actions';
import { filtersList } from 'data/persistentData';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { sendAlertsEvent, sendEvent, sendFeedEvent } from 'services/amplitude/events';
import isEqual from 'lodash/isEqual';
import classes from './Modals.module.scss';

function Modals() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { pageId, filterId, filterName } = useParams();
  const location = useLocation();
  const { pathname } = useLocation();
  const { modalType, modalInfo, isOpen } = useSelector(state => state?.modals);
  const { language, refetchTelegram } = useSelector(state => state?.userInfo);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const { filtersQuery, settings, tabItem } = useSelector(state => state?.filters);
  const alerts = useSelector(state => state?.alerts);
  const { alertDetails, alertsList, alertsSimple, alertsNegative, alertsActivity, emptyAlert, reports, reportDetails, emptyReport } = alerts;
  const newsList = useSelector(state => state?.news?.newsList);
  const searchSources = useSelector(state => state?.search?.sources);
  const allArticlesCount = newsList?.reduce((acc, curr) => acc.concat(curr?.articles), [])?.length;
  const { mutateAsync: createPage } = useCreatePage();
  const { mutateAsync: editPage } = useChangePage();
  const { mutateAsync: deletePage } = useDeletePage();
  const { mutateAsync: complaintArticle } = useComplaintArticle();
  const { mutateAsync: addEditTag, isLoading: addEditTagLoading, error: addEditTagError } = useAddEditTag();
  const { mutateAsync: addEditKeyword, isLoading: addEditKeywordLoading, error: addEditError } = useAddEditKeyword();
  const { mutateAsync: removeKeyword } = useRemoveKeyword();
  const { mutateAsync: removeTag } = useRemoveTag();
  const { mutateAsync: sendComplaint } = useSendComplaints();
  const { mutateAsync: sendFeedback } = useSendFeedback();
  const { mutateAsync: editPeriod, isLoading: periodLoading } = useEditPeriod();
  const { mutateAsync: addSource } = useAddSource();
  const { mutateAsync: reportManger } = useReportManger();
  const { mutateAsync: setManyTags } = useSetManyTags();
  const { mutateAsync: removeManyTags } = useRemoveManyTags();
  const { mutateAsync: deleteSimpleAlert } = useDeleteSimpleAlert();
  const { mutateAsync: deleteNegativeAlert } = useDeleteNegativeAlert();
  const { mutateAsync: deleteActivityAlert } = useDeleteActivityAlert();
  const { mutateAsync: deleteReport } = useDeleteReport();
  const { mutateAsync: editSimpleAlert } = useChangeSimpleAlert();
  const { mutateAsync: editNegativeAlert } = useChangeNegativeAlert();
  const { mutateAsync: editActivityAlert } = useChangeActivityAlert();
  const { mutateAsync: editReport } = useChangeReport();
  const { mutateAsync: createSimpleAlert } = useCreateSimpleAlert();
  const { mutateAsync: createNegativeAlert } = useCreateNegativeAlert();
  const { mutateAsync: createActivityAlert } = useCreateActivityAlert();
  const { mutateAsync: createAlertQuery } = useCreateAlertQuery();
  const { mutateAsync: createReport } = useCreateReport();
  const { mutateAsync: deleteReportTemplate } = useDeleteReportTemplate();
  const { mutateAsync: addWhatsappAccount } = useAddWhatsappAccount();

  const isFiltersChecked = Object.keys(filtersQuery).some(item => filtersList.includes(item));
  const onlyOredering = !isFiltersChecked && (filtersQuery.ordering !== '-date' || filtersQuery.duplicates !== 'false');
  const showCheckbox = isFiltersChecked || onlyOredering;
  const isAddOrEdit = (filterName === 'alert' && alertDetails?.id) || filterId ? 'Edit' : 'Add';
  const isBasicOrAdvanced = pathname?.includes('/advanced_builder/') ? 'Advanced' : 'Basic';
  const infoData = modalInfo?.info;
  const onCloseModal = () => { dispatch(closeModal()); };

  function onClickButton({ action, info, body }) {
    if (action === 'filterMentions') {
      const filterData = {
        id: info?.source?.id || info?.source_id,
        name: info?.source?.name || info?.source,
        type: t('filtrationPanel', 'SOURCE', language),
        filterName: 'source'
      };
      dispatch(addFilterItem(filterData));
    }
    if (action === 'addPopular') {
      dispatch(removeFilterItemByName(modalInfo?.type));
      !!info?.length && info.forEach((text) => {
        dispatch(addFilterItem({ id: text, name: text, type: t('filterContext', popularHeader(), language), filterName: modalInfo?.type }));
      });
    }
    action === 'removeKeyword' && removeKeyword(info?.keyword_id);
    action === 'removeTag' && removeTag(body?.tag_id);
    action === 'sendComplaint' && sendComplaint(body);
    onCloseModal();
  }

  function navigateToPage(page_id) {
    if (page_id === 'all') {
      dispatch(clearFilterList());
      dispatch(clearCurrentPage());
    }
    if (pageId !== String(page_id)) {
      history(`/${portal_slug}/mentions/${page_id}`);
    }
  }

  function sendAmplitudeInfo({ category, event }) {
    const modalEvents = {
      datePicker: {
        category: 'Date Picker',
        event: 'Clicked_Cancel_Button_Date_Picker'
      },
      addPopular: {
        category: 'Filters',
        event: 'Clicked_Close_Button_In_Filter_Modal'
      },
      delPage: {
        category: 'Page',
        event: 'Clicked_Cancel_Button_In_Popup_Delete_Page_Popup_Page_Options'
      },
      renamePage: {
        category: 'Page',
        event: 'Clicked_Cancel_Button_In_Popup_Edit_Page_Popup_Page_Options'
      },
      createPage: {
        category: 'Page',
        event: 'Clicked_Cancel_Button_In_Create_New_Page_Modal'
      },
      selectConstructor: {
        category: filterName && `${isAddOrEdit} ${filterName} ${isBasicOrAdvanced} QB`,
        event: 'Clicked_Close_Button_In_Add_Keyword'
      },
      addKeyword: {
        category: `Add keyword ${isBasicOrAdvanced} QB`,
        event: 'Clicked_Close_Button'
      },
      editKeyword: {
        category: `Edit keyword ${isBasicOrAdvanced} QB`,
        event: 'Clicked_Close_Button'
      },
      editingKeyword: {
        category: `Edit keyword ${isBasicOrAdvanced} QB`,
        event: 'Clicked_Cancel_Button_in_Keyword_Editing_Popup'
      },
      removeKeyword: {
        category: `Delete keyword ${isBasicOrAdvanced} QB`,
        event: 'Clicked_Cancel_Button_In_Modal_Window'
      },
      addTag: {
        category: `Add tag ${isBasicOrAdvanced} QB`,
        event: 'Clicked_Close_Button_In_Add_Tag'
      },
      editTag: {
        category: `Edit tag ${isBasicOrAdvanced} QB`,
        event: 'Clicked_Close_Button_In_Edit_Tag'
      },
      manyMentions: {
        category: category || (filterName && `${isAddOrEdit} ${filterName} ${isBasicOrAdvanced} QB`),
        event: event || 'Clicked_Change_Button_in_Keyword_Adding_Popup',
        type: filterName
      },
      exitBuilder: {
        category: filterName && `${isAddOrEdit} ${filterName} ${isBasicOrAdvanced} QB`,
        event: 'Clicked_Cancel_Button_In_Popup_After_Query_Builder_Close',
        type: filterName
      },
      proposeSource: {
        category: 'Filters',
        event: 'Clicked_Cancel_Button_In_Suggest_Source_Modal'
      },
      downloadPublications: {
        category: 'Download',
        event: 'Clicked_Cancel_Button_In_Download_Report_Modal'
      },
      reportProblem: {
        category: 'Source Settings',
        event: 'Clicked_Cancel_Button_In_Report_Problem_Modal'
      },
      sendComplaint: {
        category: 'Full text',
        event: 'Modal_Report_Problem_With_Encoding_Cancel_Clicked'
      },
      removeTag: {
        category: 'Delete tag',
        event: 'Clicked_Cancel_Button_In_Delete_Confirmation_Modal'
      },
      removeAlert: {
        category: 'Delete alert',
        event: 'Clicked_Cancel_Button_In_Modal_Remove_Alert'
      },
      editAlert: {
        category: 'Edit alert',
        event: `cancel_edit_alert_${alertsList}`
      },
      editAlertConfirm: {
        category: 'Edit alert',
        event: `cancel_confirm_edit_alert_${alertsList}`
      },
      removeReport: {
        category: 'Delete report',
        event: 'Clicked_Cancel_Button_In_Modal_Edit_Report'
      },
      editReportConfirm: {
        category: 'Edit report',
        event: 'Clicked_Cancel_Changes_Button_In_Modal_Edit_Report'
      },
      editReport: {
        category: 'Edit report',
        event: 'Clicked_Cancel_Changes_Button_In_Modal_Edit_Report'
      },
      removeReportTemplate: {
        category: 'Delete template',
        event: 'Clicked_Cancel_Button'
      },
      connectTelegram: {
        category: 'Recipient Tg',
        event: 'Clicked_Close_Button_In_Connect_Telegram_Modal'
      },
      waitingConnectTelegram: {
        category: 'Recipient Tg',
        event: 'Clicked_Close_And_Cancel_Connection_Button'
      },
      addWhatsappPhone: {
        category: 'Recipient WA',
        event: 'Clicked_Cancel_Button_In_Connect_WhatsApp_Modal'
      },
      confirmWhatsappPhone: {
        category: 'Recipient WA',
        event: 'Clicked_Cancel_Button_In_Connect_WhatsApp_Modal'
      },
      selectConstructorQuery: {
        category: 'Add Query Basic QB',
        event: 'Clicked_Close_Button'
      },
      addEditSearchQuery: {
        category: `${alertDetails?.query_builder ? 'Edit' : 'Add'} Query Basic QB`,
        event: 'Clicked_Close_Button'
      }
    };

    const alertModals = ['removeAlert', 'editAlert', 'editAlertConfirm', 'removeReport', 'editReport',
      'editReportConfirm', 'removeReportTemplate', 'connectTelegram', 'waitingConnectTelegram', 'addWhatsappPhone',
      'confirmWhatsappPhone', 'selectConstructorQuery', 'addEditSearchQuery'];
    modalType && modalEvents?.[modalType] && !alertModals.includes(modalType) && sendEvent(modalEvents[modalType]);
    modalType && modalEvents?.[modalType] && alertModals.includes(modalType) && sendAlertsEvent(modalEvents[modalType]);
  }

  function handleCloseModal({ cancel }) {
    cancel && sendFeedEvent({ category: 'Page', event: 'Clicked_Cancel_Button_Filters_Popup' });
    infoData?.page_id && navigateToPage(infoData?.page_id);
    refetchTelegram && dispatch(toggleRefetchTelegram(false));
    sendAmplitudeInfo({});
    onCloseModal();
  }

  function popularHeader() {
    if (modalInfo?.type === 'links') return 'LINKS';
    if (modalInfo?.type === 'hashtags') return 'HASHTAGS';
    return 'WORDS';
  }

  const onCloseModalWindow = ({ cancel = false } = {}) => {
    cancel && sendAmplitudeInfo({});
    onCloseModal();
  };

  const onSelectConstructor = (builder) => {
    if (builder === 'simple') {
      sendFeedEvent({ category: 'Add keyword Basic QB', event: 'Selected_Basic_Query_Builder_For_Add_Keyword' });
      dispatch(openModal({ action: 'addKeyword', info: null }));
    }

    if (builder === 'advanced') {
      sendFeedEvent({ category: 'Add keyword Advanced QB', event: 'Selected_Advanced_Query_Builder_For_Add_Keyword' });
      onCloseModal();
      history('/advanced_builder/keyword/');
    }
  };

  function onDeleteAlert(id) {
    alertsList === 'alerts-simple' && deleteSimpleAlert(id);
    alertsList === 'alerts-negative' && deleteNegativeAlert(id);
    alertsList === 'alerts-activity' && deleteActivityAlert(id);
  }
  function onCreateEditAlert(alertBody) {
    if (!alertBody?.id) {
      alertsList === 'alerts-simple' && createSimpleAlert(alertBody);
      alertsList === 'alerts-negative' && createNegativeAlert(alertBody);
      alertsList === 'alerts-activity' && createActivityAlert(alertBody);
    }
    if (alertBody?.id) {
      alertsList === 'alerts-simple' && editSimpleAlert(alertBody);
      alertsList === 'alerts-negative' && editNegativeAlert(alertBody);
      alertsList === 'alerts-activity' && editActivityAlert(alertBody);
    }
  }
  function onCreateEditReport(reportBody) {
    !reportBody?.id ? createReport(reportBody) : editReport(reportBody);
  }
  function findAlertById(id) {
    if (id && alertsList === 'alerts-simple') {
      const alert = alertsSimple.find(item => item?.id === id);
      return alert;
    }
    if (id && alertsList === 'alerts-negative') {
      const alert = alertsNegative.find(item => item?.id === id);
      return alert;
    }
    if (id && alertsList === 'alerts-activity') {
      const alert = alertsActivity.find(item => item?.id === id);
      return alert;
    }
    return null;
  }

  function onRemoveEmptyReportsAndClose(id) {
    if (!id) {
      const filteredReports = reports.filter(item => !!item?.id);
      !isEqual(reports, filteredReports) && dispatch(editReports(filteredReports?.length ? filteredReports : [emptyReport]));
    }
    onCloseModalWindow({ cancel: true });
    dispatch(closeReportDetails());
  }

  function onRemoveEmptyAlertsAndClose(id) {
    if (!id && alertsList === 'alerts-simple') {
      const filteredAlert = alertsSimple.filter(item => !!item?.id);
      !isEqual(alertsActivity, filteredAlert) && dispatch(editAlertsSimple(filteredAlert?.length ? filteredAlert : [emptyAlert]));
    }
    if (!id && alertsList === 'alerts-negative') {
      const filteredAlert = alertsNegative.filter(item => !!item?.id);
      !isEqual(alertsNegative, filteredAlert) && dispatch(editAlertsNegative(filteredAlert?.length ? filteredAlert : [emptyAlert]));
    }
    if (!id && alertsList === 'alerts-activity') {
      const filteredAlert = alertsActivity.filter(item => !!item?.id);
      !isEqual(alertsActivity, filteredAlert) && dispatch(editAlertsActivity(filteredAlert?.length ? filteredAlert : [emptyAlert]));
    }
    onCloseModalWindow({ cancel: true });
    dispatch(closeAlertDetails());
  }

  const modalHeaders = {
    datePicker: false,
    whatsNew: modalType === 'whatsNew' && t('helpMenu', 'WHATS_NEW_LOOQME', language),
    feedback: modalType === 'feedback' && t('helpMenu', 'FEEDBACK', language),
    delPage: modalType === 'delPage' && t('modalWindow', 'DEL_PAGE', language),
    renamePage: modalType === 'renamePage' && t('modalWindow', 'RENAME_PAGE', language),
    savePageChanges: modalType === 'savePageChanges' && t('modalWindow', 'SAVE_CHAGES', language),
    createPage: modalType === 'createPage' && t('modalWindow', 'CREATE_NEW_PAGE', language),
    showCard: modalType === 'showCard' && (infoData?.source?.name || infoData?.source),
    reportProblem: modalType === 'reportProblem' && t('modalWindow', 'REPORT_PROBLEM', language),
    downloadArticles: modalType === 'downloadArticles' && t('modalWindow', 'LOADING_ARTICLES', language),
    addTag: modalType === 'addTag' && t('modalWindow', 'ADD_TEG', language),
    editTag: modalType === 'editTag' && t('modalWindow', 'EDIT_TEG', language),
    addKeyword: modalType === 'addKeyword' && t('modalWindow', 'ADD_KEYWORD', language),
    editKeyword: modalType === 'editKeyword' && t('modalWindow', 'EDIT_KEYWORD', language),
    addEditSearchQuery: modalType === 'addEditSearchQuery' && t('modalWindow', alertDetails?.query_builder ? 'EDIT_QUERY_SEARCH' : 'ADD_QUERY_SEARCH', language),
    editingKeyword: modalType === 'editingKeyword' && t('modalWindow', 'EDITING_KEYWORD', language),
    editingKeywordAdvanced: modalType === 'editingKeywordAdvanced' && t('modalWindow', 'CONFIRM_EDITING', language),
    removeKeyword: modalType === 'removeKeyword' && t('modalWindow', 'REMOVE_KEYWORD', language),
    removeTag: modalType === 'removeTag' && t('modalWindow', 'REMOVE_TEG', language),
    addPopular: modalType === 'addPopular' && t('filterContext', popularHeader(), language),
    sendComplaint: modalType === 'sendComplaint' && t('modalWindow', 'REPORT_PROBLEM_ENCODING', language),
    selectTelegram: modalType === 'selectTelegram' && t('modalWindow', 'SELECT_TELEGRAM_METHOD', language),
    connectTelegram: modalType === 'connectTelegram' && t('modalWindow', 'CONNECT_TELEGRAM', language),
    waitingConnectTelegram: false,
    downloadPublications: modalType === 'downloadPublications' && t('modalWindow', 'DOWNLOAD_PUBLICATIONS', language),
    proposeSource: modalType === 'proposeSource' && t('modalWindow', 'PROPOSE_SOURCE', language),
    increaseKeywords: modalType === 'increaseKeywords' && t('modalWindow', 'ADD_KEYWORD', language),
    successAddKeyword: modalType === 'successAddKeyword' && t('modalWindow', 'ADD_KEYWORD', language),
    successReportManager: modalType === 'successReportManager' && t('modalWindow', 'CONTACT_MANAGER', language),
    selectConstructor: modalType === 'selectConstructor' && t('modalWindow', 'ADD_KEYWORD', language),
    selectConstructorQuery: modalType === 'selectConstructorQuery' && t('modalWindow', 'ADD_QUERY_SEARCH', language),
    exitBuilder: modalType === 'exitBuilder' && t('modalWindow', 'EXIT_BUILDER', language),
    manyMentions: modalType === 'manyMentions' && t('modalWindow', 'MANY_MENTIONS', language),
    confirmEditTags: modalType === 'confirmEditTags' && t('modalWindow', 'CONFIRM_EDIT_TAGS', language),
    emptyReport: modalType === 'emptyReport' && t('modalWindow', 'DOWNLOAD_PUBLICATIONS', language),
    editAlert: modalType === 'editAlert' && t('modalWindow', infoData?.id ? 'EDIT_ALERT' : 'CREATE_ALERT', language),
    editReport: modalType === 'editReport' && t('modalWindow', infoData?.id ? 'EDIT_REPORT' : 'CREATE_REPORT', language),
    editAlertConfirm: modalType === 'editAlertConfirm' && t('modalWindow', infoData?.id ? 'EDIT_ALERT' : 'CREATE_ALERT', language),
    editReportConfirm: modalType === 'editReportConfirm' && t('modalWindow', infoData?.id ? 'EDIT_REPORT' : 'CREATE_REPORT', language),
    removeAlert: modalType === 'removeAlert' && t('modalWindow', 'REMOVE_ALERT', language),
    removeReport: modalType === 'removeReport' && t('modalWindow', 'REMOVE_REPORT', language),
    removeReportTemplate: modalType === 'removeReportTemplate' && t('modalWindow', 'CONFIRM_ACTION', language),
    showFullScreen: modalType === 'showFullScreen' && '',
    duplicateReport: modalType === 'duplicateReport' && t('modalWindow', 'REPORT_DUPLICATE', language),
    addWhatsappPhone: modalType === 'addWhatsappPhone' && t('modalWindow', 'CONNECT_WHATSAPP', language),
    confirmWhatsappPhone: modalType === 'confirmWhatsappPhone' && t('modalWindow', 'CONNECT_WHATSAPP', language),
    selectSources: modalType === 'selectSources' && t('modalWindow', 'SELECT_SOURCES', language),
    searchHistory: modalType === 'searchHistory' && t('modalWindow', 'SEARCH_HISTORY', language),
    default: ''
  };

  const modalBody = {
    datePicker: modalType === 'datePicker' && (
      <ErrorBoundarySentry language={language} className={classes.datepickerWrap}>
        <Datepicker
          onCancel={() => { onCloseModalWindow({ cancel: true }); }}
          setDatePicker={(newPeriod) => { editPeriod(newPeriod); }}
          language={language}
          isLoading={periodLoading}
        />
      </ErrorBoundarySentry>
    ),
    whatsNew: modalType === 'whatsNew' && <BodyWhatsNew language={language} />,
    feedback: modalType === 'feedback' && (
      <BodyFeedback
        language={language}
        onClickOk={({ body }) => { sendFeedback({ body }); }}
        onCancel={onCloseModal}
      />
    ),
    delPage: modalType === 'delPage' && (
      <BodyDelPageModal
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        data={infoData}
        onDel={({ id }) => { deletePage(id); }}
        language={language}
      />
    ),
    savePageChanges: modalType === 'savePageChanges' && (
      <BodyDelPageModal
        onCancel={(e) => { handleCloseModal({ cancel: !!e?.target }); }}
        data={infoData}
        isSave
        onDel={({ id, name, order }) => {
          const filter = { ...filtersQuery };
          const tab = tabItem?.action;
          filter[tab] && delete filter[tab];
          editPage({ id,
            body: { filter, name, order },
            page_id: infoData?.page_id,
            navigateToPage });
        }}
        language={language}
      />
    ),
    renamePage: modalType === 'renamePage' && (
      <BodyRenamePageModal
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        data={infoData}
        onClickOk={({ name, id }) => { editPage({ id, body: { name } }); }}
        language={language}
      />
    ),
    createPage: modalType === 'createPage' && (
      <BodyCreatePageModal
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        onCreate={({ name, selected }) => { createPage({ name, selected }); }}
        language={language}
        showCheckbox={showCheckbox}
      />
    ),
    showCard: modalType === 'showCard' && (
      <BodyCardCreator
        language={language}
        info={infoData}
        onSubmit={onClickButton}
        model_key="source"
        isCard
      />
    ),
    reportProblem: modalType === 'reportProblem' && (
      <BodyProblemModal
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        onClickOk={(problemBody) => { complaintArticle(problemBody); }}
        language={language}
        data={{ name: infoData?.source?.name || infoData?.source, id: infoData?.source?.id || infoData?.source_id || 0 }}
      />
    ),
    downloadArticles: modalType === 'downloadArticles' && (
      <BodyLoadingArticleModal onCancel={onCloseModal} language={language} data={{ message: infoData?.message || '' }} />
    ),
    addTag: modalType === 'addTag' && (
      <BodyAddEditTeg
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        data={{ ...infoData, name: infoData?.name || '' }}
        onClickOk={addEditTag}
        isLoading={addEditTagLoading}
        onManyMentions={(info) => { dispatch(openModal({ action: 'manyMentions', info })); }}
        language={language}
        error={addEditTagError?.response?.data || null}
      />
    ),
    editTag: modalType === 'editTag' && (
      <BodyAddEditTeg
        onCancel={() => { onCloseModalWindow({ cancel: true }); }}
        data={infoData}
        isLoading={addEditTagLoading}
        onClickOk={addEditTag}
        onManyMentions={(info) => { dispatch(openModal({ action: 'manyMentions', info })); }}
        language={language}
        error={addEditTagError?.response?.data || null}
      />
    ),
    addKeyword: modalType === 'addKeyword' && (
    <BodyAddEditKW
      data={infoData}
      onCreateEdit={addEditKeyword}
      isLoading={addEditKeywordLoading}
      onManyMentions={(info) => { dispatch(openModal({ action: 'manyMentions', info })); }}
      respError={addEditError?.response?.data?.error || ''}
      language={language}
      onCancel={() => { onCloseModalWindow({ cancel: true }); }}
    />
    ),
    editKeyword: modalType === 'editKeyword' && (
      <BodyAddEditKW
        onCancel={() => { onCloseModalWindow({ cancel: true }); }}
        data={infoData}
        language={language}
        onManyMentions={(info) => { dispatch(openModal({ action: 'manyMentions', info })); }}
        onConfirmDel={(info) => { dispatch(openModal({ action: 'removeKeyword', info })); }}
        onConfirmEdit={(info) => { dispatch(openModal({ action: 'editingKeyword', info })); }}
        isLoading={addEditKeywordLoading}
        onCreateEdit={addEditKeyword}
      />
    ),
    addEditSearchQuery: modalType === 'addEditSearchQuery' && (
    <BodyAddEditSearchQuery
      data={{
        ...alertDetails,
        name: infoData?.name || alertDetails?.name,
        query_builder: infoData?.query_builder || alertDetails?.query_builder
      }}
      onManyMentions={(info) => { dispatch(openModal({ action: 'manyMentions', info })); }}
      onCreateEdit={(alertData) => {
        dispatch(editAlertDetails(alertData));
        onCloseModal();
      }}
      onCreateQuery={(queryBuilder) => { createAlertQuery(queryBuilder); }}
      language={language}
      onCancel={onCloseModal}
    />
    ),
    removeTag: modalType === 'removeTag' && (
      <BodyDelTag
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        data={infoData}
        onDel={({ id }) => {
          sendFeedEvent({ category: 'Delete tag', event: 'Clicked_Delete_Button_For_Delete_Tag' });
          onClickButton({ action: 'removeTag', body: { tag_id: id } });
        }}
        language={language}
      />
    ),
    removeKeyword: modalType === 'removeKeyword' && (
      <BodyDelKeyword
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        data={infoData}
        onDel={({ keyword_id }) => {
          sendFeedEvent({
            category: `Delete keyword ${isBasicOrAdvanced} QB`,
            event: 'Clicked_Delete_Button_In_Modal_Window'
          });
          onClickButton({ action: 'removeKeyword', info: { keyword_id } });
        }}
        language={language}
        isTag
      />
    ),
    addPopular: modalType === 'addPopular' && (
      <BodyAddPopular
        type={modalInfo?.type}
        onCancel={onCloseModal}
        onClickOk={onClickButton}
        language={language}
      />
    ),
    sendComplaint: modalType === 'sendComplaint' && (
      <BodySendComplain
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        data={infoData}
        onClickOk={onClickButton}
        language={language}
      />
    ),
    selectTelegram: modalType === 'selectTelegram' && <BodySelectTelegram onCancel={onCloseModal} data={infoData} />,
    downloadPublications: modalType === 'downloadPublications' && (
      <BodyDownloadPublications
        allArticlesCount={allArticlesCount}
        language={language}
        onShowEmptyReport={() => { dispatch(openModal({ action: 'emptyReport', info: null })); }}
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        data={infoData}
      />
    ),
    connectTelegram: modalType === 'connectTelegram' && (
      <BodyConnectTelegram
        onStartRefetchTelegram={() => {
          dispatch(toggleRefetchTelegram(true));
          dispatch(openModal({ action: 'waitingConnectTelegram', info: null }));
        }}
        onCancel={() => { handleCloseModal({}); }}
        data={infoData}
      />
    ),
    waitingConnectTelegram: modalType === 'waitingConnectTelegram' && (
      <BodyWaitingConnectTelegram />
    ),
    proposeSource: modalType === 'proposeSource' && (
      <BodyProposeSource
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        onClickOk={({ suggested_source_url }) => { addSource({ suggested_source_url }); }}
        language={language}
      />
    ),
    editingKeyword: modalType === 'editingKeyword' && (
      <BodyConfirmEditKeyword
        data={infoData}
        onCancel={() => { onCloseModalWindow({ cancel: true }); }}
        respError={addEditError?.response?.data?.error || ''}
        onEditKeyword={addEditKeyword}
        language={language}
      />
    ),
    manyMentions: modalType === 'manyMentions' && (
    <BodyConfirmIfManyMentions
      data={infoData}
      onCancel={() => {
        if (location.pathname.includes('mentions')) {
          hasOwn(infoData, 'keyword_id') &&
            dispatch(openModal({
              action: infoData?.keyword_id ? 'editKeyword' : 'addKeyword',
              info: { ...infoData?.body, ...(infoData?.keyword_id ? { id: infoData?.keyword_id } : {}) }
            }));
          hasOwn(infoData, 'tag_id') && dispatch(openModal({
            action: infoData?.tag_id ? 'editTag' : 'addTag',
            info: { ...infoData?.body, ...(infoData?.tag_id ? { id: infoData?.tag_id } : {}) }
          }));
        }
        location.pathname.includes('alerts') && dispatch(openModal({ action: 'addEditSearchQuery', info: infoData?.body }));
      }}
      respError={addEditError?.response?.data?.error || ''}
      onAddEditKeyword={addEditKeyword}
      onAddEditTag={addEditTag}
      onAddEditAlert={(alertData) => {
        dispatch(editAlertDetails(alertData));
        history('/alerts');
        onCloseModal();
      }}
      language={language}
    />
    ),
    editingKeywordAdvanced: modalType === 'editingKeywordAdvanced' && (
      <BodyConfirmEditingAdvanced
        data={infoData}
        onClickOk={() => {
          location.state = null;
          onCloseModal();
        }}
        onCancel={onCloseModal}
        language={language}
      />
    ),
    increaseKeywords: modalType === 'increaseKeywords' && (
      <BodyIncreaseKeywords
        data={infoData}
        usedKeywords={{ limit: settings?.limit || 0, saved: settings?.saved || 0 }}
        onClickOk={(body) => { reportManger(body); }}
        onCancel={onCloseModal}
        language={language}
      />
    ),
    successAddKeyword: modalType === 'successAddKeyword' && (
      <BodyAddKeywordSuccess
        onCancel={onCloseModal}
        language={language}
        textTitle={t('bodyAddTagModal', 'KEYWORD_CREATED', language)}
        textBody={t('bodyAddTagModal', 'NEED_TIME', language)}
      />
    ),
    successReportManager: modalType === 'successReportManager' && (
      <BodyAddKeywordSuccess
        onCancel={onCloseModal}
        language={language}
        textTitle={t('bodyAddTagModal', 'REPORT_FIXED', language)}
        textBody={t('bodyAddTagModal', 'MANAGER_WORKING', language)}
      />
    ),
    selectConstructor: modalType === 'selectConstructor' && (
      <BodySelectConstructor
        onClickOk={(builder) => { onSelectConstructor(builder); }}
        onCancel={() => { onCloseModalWindow({ cancel: true }); }}
        language={language}
      />
    ),
    selectConstructorQuery: modalType === 'selectConstructorQuery' && (
      <BodySelectConstructor
        onClickOk={(builder) => {
          builder === 'simple' && dispatch(openModal({ action: 'addEditSearchQuery', info: null }));
          if (builder === 'advanced') {
            onCloseModal();
            history('/advanced_builder/alert/');
          }
        }}
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        language={language}
      />
    ),
    exitBuilder: modalType === 'exitBuilder' && (
    <BodyConfirmExitAdvanced
      onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
      onClickOk={() => {
        sendEvent({ category: `${isAddOrEdit} ${filterName} Advanced QB`, event: 'Clicked_Exit_The_Constructor_Button', type: filterName });
        infoData?.link && (window.location = infoData?.link);
        !infoData?.link && history(infoData?.historyLink || `/${portal_slug}/mentions/all`);
        location.state = null;
        location.hash = '';
        onCloseModal();
      }}
      language={language}
      text={infoData?.text || ''}
    />
    ),
    confirmEditTags: modalType === 'confirmEditTags' && (
      <BodyConfirmEditTags
        onCancel={onCloseModal}
        onClickOk={({ body, isDetails }) => {
          sendFeedEvent({
            category: 'News_Control_Panel',
            event: 'Applied_Tag_Changes',
            properties: {
              amountRemoveTags: body?.remove_tags?.length || 0,
              amountAddTags: body?.tags?.length || 0
            }
          });
          body?.remove_tags?.length && removeManyTags({ body, isDetails });
          body?.tags?.length && setManyTags({ body, isDetails });
        }}
        language={language}
        data={infoData}
      />
    ),
    emptyReport: modalType === 'emptyReport' && (
    <BodyEmptyReport
      onCancel={onCloseModal}
      language={language}
    />
    ),
    editAlert: modalType === 'editAlert' && (
      <BodyConfirmEditAlert
        onCreateEditAlert={onCreateEditAlert}
        data={infoData}
        typeAlert={alertsList}
        findAlertById={findAlertById}
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        language={language}
      />
    ),
    editAlertConfirm: modalType === 'editAlertConfirm' && (
      <BodyConfirmEditAlert
        isConfirm
        data={infoData}
        typeAlert={alertsList}
        findAlertById={findAlertById}
        onCreateEditAlert={onCreateEditAlert}
        onClose={onRemoveEmptyAlertsAndClose}
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        language={language}
      />
    ),
    editReport: modalType === 'editReport' && (
    <BodyConfirmEditReport
      data={infoData}
      reportEtalon={reports?.length && reports.find(report => report?.id === reportDetails?.id)}
      onCreateEditReport={onCreateEditReport}
      onCancel={onCloseModal}
      language={language}
    />
    ),
    editReportConfirm: modalType === 'editReportConfirm' && (
      <BodyConfirmEditReport
        isConfirm
        data={infoData}
        reportEtalon={reports?.length && reports.find(report => report?.id === reportDetails?.id)}
        onCreateEditReport={onCreateEditReport}
        onClose={onRemoveEmptyReportsAndClose}
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        language={language}
      />
    ),
    removeAlert: modalType === 'removeAlert' && (
      <BodyConfirmDelAlert
        onDelete={onDeleteAlert}
        data={infoData}
        name={infoData?.name || infoData?.title || ''}
        type="alert"
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        language={language}
        confirmText={t('alerts', 'CONFIRM_DELETE_ALERT')}
      />
    ),
    removeReport: modalType === 'removeReport' && (
      <BodyConfirmDelAlert
        onDelete={(id) => { deleteReport(id); }}
        data={infoData}
        name={infoData?.name || infoData?.title || ''}
        type="report"
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        language={language}
        confirmText={t('reports', 'CONFIRM_DELETE_REPORT')}
      />
    ),
    removeReportTemplate: modalType === 'removeReportTemplate' && (
      <BodyConfirmDelAlert
        onDelete={(id) => { deleteReportTemplate(id); }}
        data={infoData}
        name=""
        type="template"
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        language={language}
        confirmText={t('reports', 'CONFIRM_DELETE_REPORT_TEMPLATE')}
      />
    ),
    showFullScreen: modalType === 'showFullScreen' && <BodyFrame url={infoData?.url} />,
    duplicateReport: modalType === 'duplicateReport' && (
      <BodyDuplicateReport
        onCancel={onCloseModal}
        language={language}
      />
    ),
    addWhatsappPhone: modalType === 'addWhatsappPhone' && (
      <BodyConnectWhatsapp
        onClickOk={() => {
          dispatch(openModal({ action: 'confirmWhatsappPhone', info: null }));
        }}
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        addWhatsappAccount={phone => addWhatsappAccount({ phone })}
        language={language}
      />
    ),
    confirmWhatsappPhone: modalType === 'confirmWhatsappPhone' && (
      <BodyConfirmWhatsapp
        onCancel={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
        language={language}
      />
    ),
    selectSources: modalType === 'selectSources' && (
      <BodySelectSources onCancel={onCloseModal} onApply={(value) => { dispatch(addSearchFilterSources(value)); }} data={searchSources} />
    ),
    searchHistory: modalType === 'searchHistory' && (
      <BodySearchHistory onCancel={onCloseModal} />),
    copilotFullScreen: modalType === 'copilotFullScreen' && (
      <BodyCopilotFullScreen
        onClose={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
      />
    ),
    copilotDelete: modalType === 'copilotDelete' && (
      <BodyCopilotDelete
        onClose={(e) => { onCloseModalWindow({ cancel: !!e?.target }); }}
      />
    ),
    default: <span />
  };

  const iconsList = useMemo(() => {
    if (infoData?.media_type === 'internet') {
      return [
        { type: 'people', role: 'button', tooltipText: t('tooltip', 'AUDIENCE', language), text: infoData?.source?.attendance || infoData?.attendance || 0 },
        { type: 'view', role: 'button', tooltipText: t('tooltip', 'VIEWING', language), text: infoData?.source?.average_attendance || infoData?.average_attendance || 0 },
        { type: 'ave', role: 'button', tooltipText: 'AVE', text: infoData?.ave || 0 }
      ];
    }
    if (infoData?.media_type === 'press') {
      return [{ type: 'circulation', role: 'button', tooltipText: t('tooltip', 'CIRCULATION', language), text: infoData?.circulation || 0 }];
    }
    if (infoData?.media_type === 'agency') {
      return [{ type: 'people', role: 'button', tooltipText: t('tooltip', 'AUDIENCE', language), text: infoData?.source?.attendance || infoData?.attendance || 0 },
        { type: 'view', role: 'button', tooltipText: t('tooltip', 'VIEWING', language), text: infoData?.source?.average_attendance || infoData?.average_attendance || 0 }];
    }
    return null;
  }, [infoData]);
  const notClose = ['addTag', 'editTag', 'addKeyword', 'editKeyword', 'addEditSearchQuery', 'waitingConnectTelegram'];

  const closePopoverTelegramProps = {
    onConfirm: () => handleCloseModal({}),
    onCancel: () => sendAlertsEvent({ category: 'Recipient Tg', event: 'Clicked_Continue_To_Wait_Button' }),
    okText: t('btn', 'CLOSE_CANCEL'),
    cancelText: t('btn', 'CONTINUE_WAIT'),
    cancelButtonProps: { style: { color: 'black', border: 'none', boxShadow: 'none', margin: 0, paddingBottom: '8px' } },
    okButtonProps: { style: { color: 'red', border: 'none', boxShadow: 'none', margin: 0, paddingTop: '8px' } },
    okType: 'default',
    rootClassName: classes.closePopoverTelegram
  };

  return (
    <ModalWindow
      datePicker={modalType === 'datePicker'}
      dark={modalType === 'waitingConnectTelegram'}
      stylesModal={modalType === 'searchHistory' ? { titleWrap: classes.searchHistoryTitlewrap } : {}}
      popoverConfirmProps={modalType === 'waitingConnectTelegram' && closePopoverTelegramProps}
      headerText={modalHeaders[modalType] || modalHeaders.default}
      shouldCloseOnOverlayClick={!!modalType && !notClose.includes(modalType)}
      closeModal={() => { handleCloseModal({}); }}
      isOpen={!!isOpen && !!modalType && !!modalBody[modalType]}
      headerUrl={modalType === 'showCard' && (infoData?.internetarticle?.url || infoData?.url)}
      iconsList={modalType === 'showCard' && iconsList}
      isCard={modalType === 'showCard'}
    >
      <ErrorBoundarySentry language={language}>
        {modalBody[modalType] || modalBody.default}
      </ErrorBoundarySentry>
    </ModalWindow>
  );
}

export default Sentry.withProfiler(Modals);
