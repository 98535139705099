import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { string, number, node, bool } from 'prop-types';
import cn from 'classnames';
import classes from './SkeletonSelectOptions.module.scss';

export function SkeletonSelectOptions({ count = 2, fetching = false, children = null, className = '' }) {
  return (
    fetching ? <Skeleton count={count || 2} className={classes.skeletonOptions} containerClassName={cn(classes.skeletonOptionsWrapper, className)} baseColor="#f6f7fa" highlightColor="#e0e0f3" inline /> : children
  );
}

SkeletonSelectOptions.propTypes = {
  fetching: bool,
  children: node,
  count: number,
  className: string
};
export default SkeletonSelectOptions;
