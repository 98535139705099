const SESSION_TIMEOUT_MINUTE = 30;
let sessionId = Date.now();
let lastEventTime = Date.now();

export function getSessionId() {
  if (Date.now() - lastEventTime > SESSION_TIMEOUT_MINUTE * 60 * 1000) {
    sessionId = Date.now();
  }
  lastEventTime = Date.now();

  return sessionId;
}
