import t from 'utils/translateFunc';

export function dispayOptions() {
  const textDisplayTypes = {
    first_text_block: t('reports', 'FIRST_TEXT'),
    full_text_block: t('reports', 'FULL_TEXT'),
    obj_text_block: t('reports', 'OBJ_TEXT'),
    without_text: t('reports', 'WITHOUT_TEXT'),
    without_news: t('reports', 'WITHOUT_NEWS')
  };

  const articlesGroupBy = {
    keywords__keyword: t('reports', 'BY_KEYWORDS'),
    keywords__subtopic: t('reports', 'BY_SUBTOPICS'),
    media_type: t('reports', 'BY_MEDIATYPES'),
    tags: t('reports', 'BY_TAGS'),
    source__region: t('reports', 'BY_REGIONS'),
    source__country: t('reports', 'BY_COUNTRIES'),
    source__city: t('reports', 'BY_CITIES'),
    keywords__tone: t('reports', 'BY_TONE'),
    keywords__character: t('reports', 'BY_ROLE')
  };

  const repeatingArticlesDisplays = {
    display_for_each: t('reports', 'DISPLAY_FOR_EACH_GROUP'),
    display_for_first: t('reports', 'ONLY_FIRSTGROUP_HIDE_OTHER'),
    display_look_above: t('reports', 'ONLY_FIRSTGROUP_LOOKABOVE_OTHER')
  };

  const articlesOrderBy = {
    date: t('reports', 'RELEASE_DATE'),
    created_date: t('reports', 'PROCESSING_DATE'),
    title: t('reports', 'TITLE'),
    duplicates: t('reports', 'DUPLICATES'),
    attendance: t('reports', 'SOURCE_VISITATION'),
    source__average_attendance: t('reports', 'AVERAGE_VIEWS'),
    keywords__tone: t('reports', 'TONALITY'),
    keywords__character: t('reports', 'ROLE'),
    media_type: t('reports', 'MEDIA_TYPE')
  };
  const fontScales = {
    scale_s: t('reports', 'SMALL'),
    scale_m: t('reports', 'MEDIUM'),
    scale_l: t('reports', 'LARGE')
  };

  const articlesFields = {
    date: t('reports', 'DATE'),
    time: t('reports', 'TIME'),
    source_name: t('reports', 'SOURCE'),
    duplicates_count: t('reports', 'DUPLICATES_COUNT'),
    url: t('reports', 'URL'),
    attendance: t('reports', 'VISITATION'),
    average_attendance: t('reports', 'AVERAGE_VISITATION'),
    character: t('reports', 'ROLE'),
    tone: t('reports', 'TONALITY'),
    creators: t('reports', 'CREATOR'),
    media_type: t('reports', 'MEDIA_TYPE'),
    source_country: t('reports', 'COUNTRY'),
    source_region: t('reports', 'REGION'),
    source_city: t('reports', 'CITY'),
    source_level: t('reports', 'LEVEL'),
    source_subject: t('reports', 'SUBJECT'),
    tags: t('reports', 'TAGS'),
    subtopics: t('reports', 'SUBTOPICS'),
    attendance_socialnetwork: t('reports', 'SOCIAL_MEDIA_AUDIENCE')
  };
  const articlesDirections = {
    articles_order_by_desc: t('reports', 'ASCEND'),
    articles_order_by_asc: t('reports', 'DESCEND')
  };
  const articlesFilterDate = {
    by_created_date: t('reports', 'BY_RELEASE_DATE'),
    by_production_date: t('reports', 'BY_POSTING_DATE')
  };

  const chartsSizes = {
    charts_small: t('reports', 'SMALLS'),
    charts_large: t('reports', 'LARGES')
  };

  const blocksOrders = {
    contents_block: t('reports', 'CONTENT_BLOCK'),
    charts_block: t('reports', 'CHART_BLOCK'),
    articles_block: t('reports', 'ARTICLE_BLOCK')
  };
  return {
    textDisplayTypes,
    articlesGroupBy,
    repeatingArticlesDisplays,
    articlesOrderBy,
    fontScales,
    articlesFields,
    articlesDirections,
    articlesFilterDate,
    chartsSizes,
    blocksOrders
  };
}
