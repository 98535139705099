import ReactLoading from 'react-loading';
import { string, number, node, bool } from 'prop-types';
import cn from 'classnames';
import classes from './Loader.module.scss';

const Loader = ({
  type = 'spinningBubbles',
  className = '',
  size = 64,
  color = '#8C85A8',
  fetching = false,
  children = null

}) => (fetching ? (
  <div className={cn(classes.loderWrap, className)}>
    <ReactLoading
      type={type}
      color={color}
      height={size}
      width={size}
    />
  </div>
) : children);

Loader.propTypes = {
  className: string,
  type: string,
  size: number,
  color: string,
  fetching: bool,
  children: node
};
export default Loader;
