import { string, number } from 'prop-types';

export function Notifications({ width = 32, height = 32, color = '#8086AB', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '32'} height={height || '32'} viewBox="0 0 32 32" fill="none">
      <mask id="mask0_1210_40126" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1210_40126)">
        <path d="M6.66732 25.3337C6.28954 25.3337 5.97287 25.2059 5.71732 24.9503C5.46176 24.6948 5.33398 24.3781 5.33398 24.0003C5.33398 23.6225 5.46176 23.3059 5.71732 23.0503C5.97287 22.7948 6.28954 22.667 6.66732 22.667H8.00065V13.3337C8.00065 11.4892 8.55621 9.85033 9.66732 8.41699C10.7784 6.98366 12.2229 6.04477 14.0007 5.60033V4.66699C14.0007 4.11144 14.1951 3.63921 14.584 3.25033C14.9729 2.86144 15.4451 2.66699 16.0007 2.66699C16.5562 2.66699 17.0284 2.86144 17.4173 3.25033C17.8062 3.63921 18.0007 4.11144 18.0007 4.66699V5.60033C19.7784 6.04477 21.2229 6.98366 22.334 8.41699C23.4451 9.85033 24.0007 11.4892 24.0007 13.3337V22.667H25.334C25.7118 22.667 26.0284 22.7948 26.284 23.0503C26.5395 23.3059 26.6673 23.6225 26.6673 24.0003C26.6673 24.3781 26.5395 24.6948 26.284 24.9503C26.0284 25.2059 25.7118 25.3337 25.334 25.3337H6.66732ZM16.0007 29.3337C15.2673 29.3337 14.6395 29.0725 14.1173 28.5503C13.5951 28.0281 13.334 27.4003 13.334 26.667H18.6673C18.6673 27.4003 18.4062 28.0281 17.884 28.5503C17.3618 29.0725 16.734 29.3337 16.0007 29.3337ZM10.6673 22.667H21.334V13.3337C21.334 11.867 20.8118 10.6114 19.7673 9.56699C18.7229 8.52255 17.4673 8.00033 16.0007 8.00033C14.534 8.00033 13.2784 8.52255 12.234 9.56699C11.1895 10.6114 10.6673 11.867 10.6673 13.3337V22.667Z" fill={color || '#8086AB'} />
      </g>
    </svg>
  );
}

Notifications.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
