import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationUK from './locales/uk/translation.json';
import translationRU from './locales/ru/translation.json';
import translationRO from './locales/ro/translation.json';

// the translations
const resources = {
  en: { translation: translationEN },
  uk: { translation: translationUK },
  ru: { translation: translationRU },
  ro: { translation: translationRO }
};

i18n
  // load translation using http -> see /public/locales
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: ['en', 'uk', 'ru', 'ro'],
    // debug: process.env.NODE_ENV === 'development',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: { useSuspense: true }
  });

export default i18n;
