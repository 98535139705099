function regexFunc(match) {
  const numbersColor = !match.search(/\d+|~\d+/g) && '#7A84A7';
  const fieldText = !match.search(/title:|text:/g) && '#6936A6';
  const fieldAuthor = !match.search(/author_name:|author_url:|author_gender:|author_subscribers:/g) && '#5F60FF';
  const fieldPublicationPlace = !match.search(/publication_place_name:|publication_place_url:|publication_place_subscribers:|post_type:/g) && '#00AF85';
  const fieldReactions = !match.search(/likes_count:|comments_count:|shares_count:|views_count:|source_type:|country_name:|source_country:/g) && '#FF4E93';
  const fieldCode = !match.search(/language_code:|url:/g) && '#935100';
  const symbolsColor = !match.search(/[.{0,10}]|.{a,b}|[{}]|["]/g) && '#3536F5';

  const searchColor = fieldText || fieldAuthor || fieldPublicationPlace || fieldReactions || fieldCode || numbersColor || symbolsColor;

  const color = {
    'AND NOT': '#DB1211',
    AND: '#11B78F',
    OR: '#FFA800',
    '?': '#DB8800',
    ')': '#FA248C',
    '(': '#FA248C',
    '[': '#FFB8BE',
    ']': '#FFB8BE',
    '/': '#10118E',
    '.': '#FA248C',
    '*': '#FFA800',
    '~': '#7A84A7'
  };

  return `<span style='color:${color[match] || searchColor || '#24252d'}'>${match}</span>`;
}
function regexBgColor(match) {
  const numbersColor = !match.search(/\d+|~\d+/g) && '#7A84A7';
  const fieldText = !match.search(/title:|text:/g) && '#6936A6';
  const fieldAuthor = !match.search(/author_name:|author_url:|author_gender:|author_subscribers:/g) && '#5F60FF';
  const fieldPublicationPlace = !match.search(/publication_place_name:|publication_place_url:|publication_place_subscribers:|post_type:/g) && '#00AF85';
  const fieldReactions = !match.search(/likes_count:|comments_count:|shares_count:|views_count:|source_type:|country_name:|source_country:/g) && '#FF4E93';
  const fieldCode = !match.search(/language_code:|url:/g) && '#935100';
  const symbolsColor = !match.search(/[.{0,10}]|.{a,b}|[{}]|["]/g) && '#3536F5';

  const searchColor = fieldText || fieldAuthor || fieldPublicationPlace || fieldReactions || fieldCode || numbersColor || symbolsColor;

  const color = {
    'AND NOT': '#DB1211',
    AND: '#11B78F',
    OR: '#FFA800',
    '*': '#FFA800',
    '?': '#DB8800',
    ')': '#FA248C',
    '( )': '#FA248C',
    '(': '#FA248C',
    '[': '#FFB8BE',
    ']': '#FFB8BE',
    '/': '#10118E',
    '.': '#FA248C',
    '~': '#7A84A7'
  };
  return color[match] || searchColor || '#FFF';
}

export const textReplaced = text => text
  .replaceAll(
    /\bAND\s+NOT\b|\bAND\b(?! NOT)|OR|["]|[~]|[?]|[:]|[-]|[*]|[()]|[/[\]]|[{}]|\d+|~\d+|.{a,b}|[.{0,10}]|language_code:|author_name:|publication_place_name:|publication_place_url:|publication_place_subscribers:|post_type:|source_type:|country_name:|source_country:|title:|text:|author_url:|author_gender:|author_subscribers:|likes_count:|comments_count:|shares_count:|views_count:|url:/g,
    match => regexFunc(match)
  );

export const getBgColor = text => regexBgColor(text);
