import { string, number } from 'prop-types';

export function Restart({ className = '', width = 24, height = 25, color = 'rgba(0, 118, 113, 1)' }) {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '24'} height={height || '25'} viewBox="0 0 24 25" fill="none">
      <mask id="mask0_923_15775" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_923_15775)">
        <path d="M11 21.45C8.98333 21.2 7.3125 20.3208 5.9875 18.8125C4.6625 17.3042 4 15.5333 4 13.5C4 12.4 4.21667 11.3458 4.65 10.3375C5.08333 9.32917 5.7 8.45 6.5 7.7L7.925 9.125C7.29167 9.69167 6.8125 10.35 6.4875 11.1C6.1625 11.85 6 12.65 6 13.5C6 14.9667 6.46667 16.2625 7.4 17.3875C8.33333 18.5125 9.53333 19.2 11 19.45V21.45ZM13 21.45V19.45C14.45 19.1833 15.6458 18.4917 16.5875 17.375C17.5292 16.2583 18 14.9667 18 13.5C18 11.8333 17.4167 10.4167 16.25 9.25C15.0833 8.08333 13.6667 7.5 12 7.5H11.925L13.025 8.6L11.625 10L8.125 6.5L11.625 3L13.025 4.4L11.925 5.5H12C14.2333 5.5 16.125 6.275 17.675 7.825C19.225 9.375 20 11.2667 20 13.5C20 15.5167 19.3375 17.2792 18.0125 18.7875C16.6875 20.2958 15.0167 21.1833 13 21.45Z" fill={color || 'rgba(0, 118, 113, 1)'} />
      </g>
    </svg>
  );
}

Restart.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
