import { string } from 'prop-types';
import cn from 'classnames';
import classes from './BodyFrame.module.scss';

function BodyFrame({ url = '' }) {
  return (
    <div className={cn(classes.bodyFreame, url && classes.bodyFreame_show)}>
      <iframe
        height="100%"
        width="100%"
        src={url}
        referrerPolicy="no-referrer"
        sandbox="allow-same-origin"
        title="Preview"
      />
    </div>
  );
}

BodyFrame.propTypes = {
  url: string
};

export default BodyFrame;
