import { string, func, shape, number, bool, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyDelPageModal.module.scss';

const BodyDelPageModal = ({
  className = '',
  isSave = false,
  onCancel = () => null,
  onDel = () => null,
  language = 'uk',
  data = { name: '', id: 0 },
  isTag = false

}) => {
  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedEvent({
      category: 'Page',
      event: isSave ? 'Clicked_Save_Button_Filters_Popup' : 'Clicked_Delete_Button_In_Popup_Delete_Page_Popup_Page_Options'
    });

    onDel({ id: data?.id, name: data?.name, order: data?.order || 0 });
    onCancel();
  };

  let deleteText = 'SURE_DEL_PAGE';
  isSave && (deleteText = data?.page_id ? 'SAVE_CHANGES' : 'SURE_SAVE_CHANGES');
  isTag && (deleteText = 'SURE_DEL_TAG');

  return (
    <form onSubmit={onSubmit} className={cn(classes.bodyDelModal, className)}>
      <div className={classes.discription}>
        <p>{t('bodyDelPageModal', deleteText, language)}</p>
        <p>
          <span className={classes.bold}>{data?.name}</span>
          ?
        </p>
      </div>

      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />

        <Button
          type="submit"
          onClick={onSubmit}
          textButton={t('btn', isSave ? 'SAVE' : 'DELETE', language)}
          green32
        />
      </div>
    </form>
  );
};

BodyDelPageModal.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCancel: func,
  isSave: bool,
  isTag: bool,
  onDel: func,
  language: string
};

export default BodyDelPageModal;
