import { useState } from 'react';
import { string, func, number, shape, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, TextField } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyRenamePageModal.module.scss';

const BodyRenamePageModal = ({
  className = '',
  data = { name: '', id: 0 },
  onClickOk = () => null,
  onCancel = () => null,
  language = 'uk'
}) => {
  const [editName, setEditName] = useState(data?.name || '');

  const onChangeName = (e) => {
    setEditName(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedEvent({
      category: 'Page',
      event: 'Clicked_Save_Button_In_Popup_Edit_Page_Popup_Page_Options'
    });

    onClickOk({ name: editName, id: data?.id });
    onCancel();
  };
  const disabled = (data?.name === editName) || !editName?.length;

  return (
    <form
      onSubmit={onSubmit}
      className={cn(classes.bodyRenameModal, className)}
    >
      <TextField
        value={editName}
        onChange={onChangeName}
        placeholder={t('bodyRenamePageModal', 'ENTER_NEW_NAME_PAGE', language)}
      />
      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />
        <Button
          type="submit"
          disabled={disabled}
          onClick={onSubmit}
          textButton={t('btn', 'SAVE', language)}
          green32
        />
      </div>
    </form>
  );
};

BodyRenamePageModal.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCancel: func,
  onClickOk: func,
  language: string
};

export default BodyRenamePageModal;
