import cn from 'classnames';
import { bool, shape, string, func } from 'prop-types';
import classes from './CustomCard.module.scss';

function CustomCard({ data = null, className = '', isBlur = false, onClick = () => null }) {
  return (
    <div
      className={cn(
        classes.alertsCard,
        isBlur && classes.alertsCard_blur,
        className
      )}
      onClick={onClick}
    >
      <div className={classes.textWrap} data-text="text">
        <div className={classes.textTitle}>{data?.title || ''}</div>
        <div className={classes.textSubTitle}>{data?.subtitle || ''}</div>
      </div>
      <div className={classes.imageWrap} data-image="image" />
    </div>
  );
}

CustomCard.propTypes = {
  className: string,
  isBlur: bool,
  data: shape({}),
  onClick: func
};

export default CustomCard;
