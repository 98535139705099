import { useRef, useState, useEffect, memo } from 'react';
import { string, shape, arrayOf } from 'prop-types';
import t from 'utils/translateFunc';
import { PopoverWindow, Icon, Button, TonePopover, RolePopover } from 'Components';
import { useEditManyArticles } from 'storage/queryHooks';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './EditMentionsPopover.module.scss';

const EditMentionsPopover = memo(({ article_ids = [], language = 'uk', idBind = '' }) => {
  const [mentions, setMentions] = useState([]);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (article_ids?.length) {
      setMentions(article_ids);
    }
  }, [article_ids?.length]);

  const bodyRef = useRef();
  const { mutateAsync: onEditManyArticles } = useEditManyArticles();

  function afterChange(value) {
    value && !visible && setVisible(true);
    !value && visible && setTimeout(() => { setVisible(false); }, 350);
  }

  function onChangeTone({ info, body }) {
    setMentions(state => state.map(el => (el?.keyword_id === info?.id ? ({ ...el, tone: body?.tone }) : el)));
  }
  function onChangeRole({ info, body }) {
    setMentions(state => state.map(el => (el?.keyword_id === info?.id ? ({ ...el, character: body?.role }) : el)));
  }
  function onClose() {
    visible && bodyRef?.current?.onClosePopover();
    visible && setTimeout(() => { setVisible(false); }, 350);
  }
  function handleSubmit() {
    sendFeedEvent({
      category: 'News_Control_Panel',
      event: 'Clicked_Apply_Button',
      properties: {
        isTone: !!mentions?.[0]?.tone,
        isCharacter: !!mentions?.[0]?.character
      }
    });
    const notification = { message: t('notif', 'MENTIONS_CHANGED', language) };
    onEditManyArticles({ article_ids: mentions, notification });
    onClose();
  }
  const disabled = !mentions?.some(el => !!el?.tone || !!el?.character);

  return (
    <PopoverWindow
      ref={bodyRef}
      className={classes.editMentionsBody}
      body={visible && (
        <>
          <div className={classes.editMentionsHeader}>{t('footer', 'EDIT_MENTIONS', language)}</div>
          <ul className={classes.editMentionsBodyList} id="editMentionsPopover">
            {!!mentions?.length && mentions?.map(mention => (
              <li key={mention?.keyword_id} className={classes.editMentionsBodyListItem}>
                <span>{mention?.keyword_name}</span>
                <TonePopover onSelect={onChangeTone} idBind="editMentionsPopover" info={{ tone: mention?.tone || 'neutral', id: mention?.keyword_id }} language={language} />
                <RolePopover onSelect={onChangeRole} idBind="editMentionsPopover" info={{ role: mention?.character || 'subject', id: mention?.keyword_id }} language={language} />
              </li>
            ))}
          </ul>
          <div className={classes.buttonBox}>
            <Button onClick={handleSubmit} textButton={t('btn', 'APPLY', language)} green32 disabled={disabled} />
          </div>
        </>
      )}
      afterChange={afterChange}
      afterClose={() => { setMentions(article_ids); }}
      overflow={{ adjustX: true, adjustY: true }}
      placement="top"
      offset={[0, -5]}
      idBind={idBind}
    >
      <Icon
        type="edit"
        {...(!visible && { role: 'button' })}
        className={classes.iconContainer}
        btnType={classes.icon}
        tooltipText={t('footer', 'EDIT', language)}
        onClick={() => { sendFeedEvent({ category: 'News_Control_Panel', event: 'Clicked_Edit_Mentions_Button' }); }}
      />
    </PopoverWindow>
  );
});

EditMentionsPopover.propTypes = {
  article_ids: arrayOf(shape({})),
  language: string,
  idBind: string
};

export default EditMentionsPopover;
