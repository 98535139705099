import { string, number } from 'prop-types';

export function Post({ width = 12, height = 12, color = '#8086AB', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 12} height={height || 12} viewBox="0 0 12 12" fill="none">
      <path d="M2.66667 9.33333H7.33333V8H2.66667V9.33333ZM2.66667 6.66667H9.33333V5.33333H2.66667V6.66667ZM2.66667 4H9.33333V2.66667H2.66667V4ZM1.33333 12C0.966667 12 0.652778 11.8694 0.391667 11.6083C0.130556 11.3472 0 11.0333 0 10.6667V1.33333C0 0.966667 0.130556 0.652778 0.391667 0.391667C0.652778 0.130556 0.966667 0 1.33333 0H10.6667C11.0333 0 11.3472 0.130556 11.6083 0.391667C11.8694 0.652778 12 0.966667 12 1.33333V10.6667C12 11.0333 11.8694 11.3472 11.6083 11.6083C11.3472 11.8694 11.0333 12 10.6667 12H1.33333ZM1.33333 10.6667H10.6667V1.33333H1.33333V10.6667Z" fill={color || '#8086AB'} />
    </svg>
  );
}
Post.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
