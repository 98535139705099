import { useMemo } from 'react';
import { oneOfType, func, bool, string, shape, arrayOf } from 'prop-types';
import { SelectCustom } from 'Components';
import t from 'utils/translateFunc';
import classes from '../ReportDetails.module.scss';

const ObjectComponent = ({ dataObject = null, keywords = [], pages = [], error = false, onChange = () => null }) => {
  const allKeywords = { id: null, name: t('reports', 'ALL_OBJECTS') };
  const keywordsOptions = useMemo(() => (keywords?.length ? [allKeywords, ...keywords]?.map(kw => ({ value: kw.id, label: kw.name })) : null), [keywords]);
  const pagesOptions = useMemo(() => (pages?.length ? pages?.map(kw => ({ value: kw.id, label: kw.name })) : null), [pages]);
  const groupedOptions = [
    { label: t('reports', 'OBJECTS'), options: keywordsOptions || [] },
    { label: t('reports', 'PAGES'), options: pagesOptions || [] }
  ];

  const styles = {
    container: provided => ({
      ...provided,
      border: '1px solid #FFFFFF',
      borderRadius: '4px',
      ...(error ? {
        borderColor: '#FF2C18'
      } : {})
    }),
    placeholder: provided => ({
      ...provided,
      color: error ? '#FF2C18' : '#5A5E79',
      fontSize: '14px',
      fontWeight: 400,
      cursor: 'pointer'
    }),
    option: (provided, state) => ({
      ...provided,
      marginBottom: '1px',
      padding: '10px 16px',
      height: '40px',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: state.isSelected ? '500' : '400',
      cursor: 'pointer',
      color: state.isSelected ? '#24252D' : '#31254B',
      backgroundColor: state.isSelected ? '#D4EDFF' : '#FFFFFF',
      ':active': {
        backgroundColor: 'rgba(238, 248, 255, 1)',
      },
      paddingLeft: '24px',
      '&:hover': {
        backgroundColor: '#C9FCD7',
        fontWeight: '500',
        color: '#04412E',
      }
    }),
    groupHeading: provided => ({
      ...provided,
      backgroundColor: 'rgba(238, 248, 255, 1)'
    }),
    group: provided => ({
      ...provided,
      paddingTop: 0
    })
  };

  const formatGroupLabel = data => (
    <div className={classes.group}>
      <div>{data.label}</div>
    </div>
  );

  return (
    <SelectCustom
      value={dataObject ? { value: dataObject?.id, label: dataObject?.name } : null}
      placeholder={t('reports', 'CHOOSE_MAILING_OBJECT')}
      onChange={({ value, label }) => { onChange({ id: value, name: label }); }}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      styles={styles}
    />
  );
};

ObjectComponent.propTypes = {
  dataObject: shape({}),
  keywords: arrayOf(shape({})),
  pages: arrayOf(shape({})),
  error: oneOfType([bool, string]),
  onChange: func
};

export default ObjectComponent;
