/* eslint-disable no-unreachable */
import { api } from 'api/apiInstance';
import { checkQueryParams, addQueryParams } from 'utils/common';
import hasOwn from 'lodash/has';

export const getNewsList = async ({ portal_slug, params, signal }) => {
  const queryString = checkQueryParams(params);
  const response = await api.get(`portals/${portal_slug}/articles/newsfeed/${queryString}`, { signal });
  return response?.data;
};

export const getPreviewAricles = async ({ portal_slug, body, params, previewMode, signal }) => {
  const queryString = addQueryParams(params);
  const isTag = hasOwn(body, 'tag') || previewMode === 'tag';
  const testWay = isTag ? 'test-tag' : 'test-keyword';
  const response = await api.post(`${testWay}/${portal_slug}/${queryString}`, body, { signal });
  return response?.data;
};

export const getPreviewInfinityAricles = async ({ portal_slug, body, pageParam, signal }) => {
  const search = pageParam ? new URL(pageParam)?.search : '';
  const response = await api.post(`test-keyword/${portal_slug}/${search}`, body, { signal });
  return response?.data;
};

export const createQuery = async ({ portal_slug, body, filterName }) => {
  const qs = filterName === 'keyword' ? 'keyword-create-query' : 'tag-create-query';
  const response = await api.post(`${qs}/${portal_slug}/`, body);
  return response?.data;
};

export const createQueryForAlert = async (body) => {
  const response = await api.post('keywords/create_query_string', body);
  return response?.data;
};

export const getNewsCountSince = async ({ portal_slug, params }) => {
  const queryString = addQueryParams(params);
  const response = await api.get(`portals/${portal_slug}/articles/newsfeed-count/newsfeed-count-since/${queryString}`);
  return response?.data;
};

export const getNewsAllCount = async ({ portal_slug, params, signal }) => {
  const queryString = addQueryParams(params);
  const response = await api.get(`portals/${portal_slug}/articles/newsfeed-count/${queryString}`, { signal });
  return response?.data;
};

export const getLastUpdateTime = async ({ portal_slug }) => {
  const response = await api.get(`portals/${portal_slug}/lastupdate/`);
  return response?.data;
};

export const getUpdateTime = async ({ portal_slug }) => {
  const response = await api.get(`portals/${portal_slug}/firstupdate/`);
  return response?.data;
};

export const editManyArticles = async ({ portal_slug, body }) => {
  const response = await api.post(`portals/${portal_slug}/articles-keywords-group/update_many/`, body);
  return response?.data;
};

export const deleteManyArticles = async ({ portal_slug, body }) => {
  const response = await api.post(`portals/${portal_slug}/articles-group/delete_many/`, body);
  return response?.data;
};

export const downloadReport = async ({ portal_slug, body }) => {
  const response = await api.post(`portals/${portal_slug}/articles/newsfeed_download/`, body);
  return response?.data;
};

export const downloadReportList = async ({ portal_slug }) => {
  const response = await api.get(`customization_reports/${portal_slug}/`);
  return response?.data;
};

export const getMoreArticles = async ({ params, keyword_id, portal_slug }) => {
  const queryString = checkQueryParams(params);
  const response = await api.get(`portals/${portal_slug}/articles/${keyword_id}/newsfeed_more/${`${queryString}`}`);
  return response?.data;
};

export const getCharts = async ({ params, keyword_id, portal_slug }) => {
  const queryString = checkQueryParams(params);
  const response = await api.get(`portals/${portal_slug}/articles/${keyword_id}/newsfeed-meta/${`${queryString}`}`);
  return response?.data;
};

export const setManyTags = async ({ portal_slug, body }) => {
  const response = await api.post(`filter/portals/${portal_slug}/tags/set_for_articles/`, body);
  return response?.data;
};

export const removeManyTags = async ({ portal_slug, body }) => {
  const response = await api.post(`filter/portals/${portal_slug}/tags/remove_for_articles/`, body);
  return response?.data;
};

export const removeTag = async ({ portal_slug, tag_id, body }) => {
  const response = await api.patch(`filter/portals/${portal_slug}/tags/${tag_id}/remove_from_article/`, body);
  return response?.data;
};

export const getArticleDetails = async ({ article_id, portal_slug }) => {
  const response = await api.get(`portals/${portal_slug}/articles/${article_id}/`);
  return response?.data;
};

export const editArticleDetails = async ({ article_id, portal_slug, body }) => {
  const response = await api.patch(`portals/${portal_slug}/articles/${article_id}/`, body);
  return response?.data;
};

export const editArticleKeywordsDetails = async ({ article_id, portal_slug, keywords_id, body }) => {
  const response = await api.patch(`portals/${portal_slug}/articles/${article_id}/keywords/${keywords_id}/`, body);
  return response?.data;
};

export const deleteArticle = async ({ article_id, keyword_id, portal_slug, body }) => {
  const response = await api.patch(`portals/${portal_slug}/articles/${article_id}/keywords/${keyword_id}/`, body);
  return response?.data;
};

export const getSubscribeChoices = async () => {
  const response = await api.get('alerts-article-duplicate/meta/');
  return response?.data;
};

export const subscribeDublicates = async (body) => {
  const response = await api.post('alerts-article-duplicate/', body);
  return response?.data;
};
export const unsubscribeDublicates = async (subscription_id) => {
  const response = await api.delete(`alerts-article-duplicate/${subscription_id}`);
  return response?.data;
};

export const complaintArticle = async (body) => {
  const response = await api.post('source-issues/', body);
  return response?.data;
};

export const blockSources = async ({ portal_slug, body }) => {
  const response = await api.post(`portals/${portal_slug}/add_unused_source/`, body);
  return response?.data;
};

export const blockCreators = async ({ portal_slug, body }) => {
  const response = await api.post(`portals/${portal_slug}/add_unused_creator/`, body);
  return response?.data;
};

export const blockPublicationPlaces = async ({ portal_slug, body }) => {
  const response = await api.post(`portals/${portal_slug}/add_unused_publication_place/`, body);
  return response?.data;
};

export const unblockSources = async ({ portal_slug, body }) => {
  const response = await api.post(`portals/${portal_slug}/remove_unused_source/`, body);
  return response?.data;
};

export const unblockCreators = async ({ portal_slug, body }) => {
  const response = await api.post(`portals/${portal_slug}/remove_unused_creator/`, body);
  return response?.data;
};

export const unblockPublicationPlaces = async ({ portal_slug, body }) => {
  const response = await api.post(`portals/${portal_slug}/remove_unused_publication_place/`, body);
  return response?.data;
};

export const addToBookmark = async (body) => {
  const response = await api.post('bookmark/', body);
  return response?.data;
};

export const removeFromBookmark = async (id) => {
  const response = await api.delete(`bookmark/${id}`);
  return response?.data;
};

export const removeManyFromBookmark = async (body) => {
  const response = await api.post('bookmark/delete_batch/', body);
  return response?.data;
};

export const addToViewed = async (body) => {
  const response = await api.post('viewed/', body);
  return response?.data;
};

export const addAllToViewed = async ({ portal_slug, body }) => {
  const response = await api.get(`portals/${portal_slug}/viewed_batch/`, body);
  return response?.data;
};

export const removeFromViewed = async (id) => {
  const response = await api.delete(`viewed/${id}`);
  return response?.data;
};

export const addToProcessed = async ({ portal_slug, body }) => {
  const response = await api.post(`processed/${portal_slug}/`, body);
  return response?.data;
};

export const removeFromProcessed = async ({ portal_slug, id }) => {
  const response = await api.delete(`processed/${portal_slug}/${id}/`);
  return response?.data;
};

export const removeManyFromProcessed = async ({ portal_slug, body }) => {
  const response = await api.post(`processed/${portal_slug}/delete_batch/`, body);
  return response?.data;
};

export const sendComplaints = async (body) => {
  const response = await api.post('operator/complaints/', body);
  return response?.data;
};

export const getCardInfo = async ({ id, portal_slug, model_key, params }) => {
  const queryString = checkQueryParams(params);
  const response = await api.get(`portals/${portal_slug}/meta/${model_key}/${id}/${queryString}`);
  return response?.data;
};

export const translateArticle = async ({ id, portal_slug }) => {
  const response = await api.get(`portals/${portal_slug}/articles/translate/${id}/`);
  return response?.data;
};
