import { TextField } from 'Components';
import t from 'utils/translateFunc';
import { func, oneOfType, shape, string } from 'prop-types';
import classes from '../TemplateDrawer.module.scss';
import Logo from './LogoUploader';

function MainComponent({ onChange = () => null, onChangeLogoFile = () => null, onChangeErrors = () => null, data = null, logoFile = null, errors = null }) {
  return (
    <div className={classes.mainComponent}>
      <TextField
        labelText={t('reports', 'TEMPLATE_NAME')}
        value={data?.title || ''}
        classNameLabelText={classes.labelName}
        onChange={({ target }) => {
          onChange({ title: target.value });
          errors?.title && onChangeErrors({ title: '' });
        }}
        placeholder={t('reports', 'ENTER_TEMPLATE_NAME')}
        boxShadow
        errorText={errors?.title}
        additionalInfo={t('reports', 'FIND_AND_APPLY_TEMPLATES')}
      />
      <TextField
        labelText={t('reports', 'DOCUMENT_NAME')}
        value={data?.document_title || ''}
        onChange={({ target }) => {
          onChange({ document_title: target.value });
          !!errors?.document_title && onChangeErrors({ document_title: '' });
        }}
        placeholder={t('reports', 'ENTER_DOCUMENT_NAME')}
        boxShadow
        errorText={errors?.document_title}
        additionalInfo={t('reports', 'FILE_NAME_REPORT')}
      />
      <Logo
        dataLogoUrl={data?.logo_url}
        logoFile={logoFile}
        onChange={(file) => {
          !!data?.logo_url && onChange({ logo_url: '', logo: '' });
          onChangeLogoFile(file || '');
        }}
      />
    </div>
  );
}

MainComponent.propTypes = {
  onChange: func,
  onChangeLogoFile: func,
  onChangeErrors: func,
  data: shape({}),
  logoFile: oneOfType([string, shape]),
  errors: shape({})

};

export default MainComponent;
