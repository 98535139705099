import { useState, useRef, memo } from 'react';
import { string, bool, number, arrayOf, shape, array, oneOfType } from 'prop-types';
import t from 'utils/translateFunc';
import { PopoverWindow, KSTegFilter, Icon, openNotification } from 'Components';
import { useGetTags } from 'storage/queryHooks';
import { openModal } from 'storage/actions';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './TagsPopover.module.scss';

const TagsPopover = memo(({ chekedArticlesArr = [], checkedTags = [], language = 'uk', idBind = '', isButton = false, assignId = '' }) => {
  const [visible, setVisible] = useState(false);
  const bodyRef = useRef();
  const dispatch = useDispatch();
  const { data: dataTags, refetch: getTags } = useGetTags({ enabled: false });
  const [filterList, setFilterList] = useState(checkedTags);
  const isDetails = idBind === 'footer-details' || idBind === 'detailsTagsWrapper';

  function afterChange(value) {
    value && !visible && setVisible(true);
    !value && visible && setTimeout(() => { setVisible(false); }, 350);
  }

  function handleOpenModal(value) {
    dispatch(openModal(value));
    onClose();
  }

  function onClose() {
    visible && bodyRef?.current?.onClosePopover();
    visible && setTimeout(() => { setVisible(false); }, 350);
  }
  function confirmBeforeSubmit() {
    const filterAddTags = filterList.filter(item => item?.count !== checkedTags?.find(el => el?.id === item?.id)?.count);
    const filterRemoveTags = checkedTags.filter(item => filterList?.every(el => item?.id !== el?.id));
    if (filterAddTags?.length || filterRemoveTags?.length) {
      const text = createConfirmText({ add_tags: filterAddTags, remove_tags: filterRemoveTags });
      dispatch(openModal({ action: 'confirmEditTags', info: { body: { article_ids: chekedArticlesArr, remove_tags: filterRemoveTags, tags: filterAddTags }, isDetails, text, openTagsPopover: assignId } }));
    } else {
      openNotification({ message: t('bodyConfirmEditTags', 'NO_ACTIONS', language) });
    }
  }

  function createConfirmText({ add_tags, remove_tags }) {
    let text = '';
    const createTagItem = item => ` <span key=${item?.id} className=${classes.tagsItem} style="background-color: #${item?.color}; height: 20px; border-radius: 20px;  padding: 3px 8px; color: #ffffff; font-size: 11px; line-height: 20px;">${item?.name}</span>`;
    if (add_tags?.length && !remove_tags?.length) {
      text = `${t('bodyConfirmEditTags', add_tags?.length === 1 ? 'ADD_ONE' : 'ADD', language)} ${add_tags.map(item => createTagItem(item))} ${t('bodyConfirmEditTags', isDetails ? 'TO_ARTICLE' : 'TO_ARTICLES', language)} ${t('bodyConfirmEditTags', 'APPLY_CHANGES', language)}`;
    }
    if (!add_tags?.length && remove_tags?.length) {
      text = `${t('bodyConfirmEditTags', remove_tags?.length === 1 ? 'DELETE_ONE' : 'DELETE', language)} ${remove_tags.map(item => createTagItem(item))} ${t('bodyConfirmEditTags', isDetails ? 'FROM_ARTICLE' : 'FROM_ARTICLES', language)} ${t('bodyConfirmEditTags', 'APPLY_CHANGES', language)}`;
    }
    if (add_tags?.length && remove_tags?.length) {
      text = `${t('bodyConfirmEditTags', add_tags?.length === 1 ? 'ADD_ONE' : 'ADD', language)} ${add_tags.map(item => createTagItem(item))} ${t('bodyConfirmEditTags', remove_tags?.length === 1 ? 'AND_DELETE_ONE' : 'AND_DELETE', language)} ${remove_tags.map(item => createTagItem(item))} ${t('bodyConfirmEditTags', isDetails ? 'FROM_ARTICLE' : 'FROM_ARTICLES', language)} ${t('bodyConfirmEditTags', 'APPLY_CHANGES', language)}`;
    }
    return text;
  }

  function addItem(value) {
    const newfilterList = filterList?.filter(listItem => listItem?.id !== value?.id)?.concat({ ...value, count: chekedArticlesArr?.length || 0 });
    setFilterList(newfilterList);
  }

  function removeItem({ id }) {
    const newfilterList = filterList?.filter(listItem => listItem.id !== id);
    setFilterList(newfilterList);
  }

  return (
    <PopoverWindow
      ref={bodyRef}
      className={cn(classes.tagsBody)}
      body={visible && (
        <>
          <div className={cn(classes.tagsHeader)}>{t('footer', 'TAGS', language)}</div>
          <KSTegFilter
            dataFilters={dataTags || []}
            filterType={t('filtrationPanel', 'TAG', language)}
            placeholder={t('kSTegFilter', 'ENTER_TEG', language)}
            filterName="tagPopoup"
            addItem={addItem}
            removeItem={removeItem}
            onApply={confirmBeforeSubmit}
            filterList={isDetails ? filterList.map(item => ({ ...item, count: 0 })) : filterList}
            onOpenModal={handleOpenModal}
            language={language}
            assignId={assignId}
          />
        </>
      )}
      afterChange={afterChange}
      afterClose={() => { setFilterList(checkedTags); }}
      overflow={{ adjustX: true, adjustY: true }}
      offset={[0, -5]}
      placement="top"
      idBind={idBind}

    >
      {!isButton ? (
        <Icon
          id={assignId}
          type="assign"
          {...(!visible && { role: 'button' })}
          className={classes.iconContainer}
          btnType={classes.icon}
          tooltipText={t('footer', 'TAGS', language)}
          onClick={(e) => {
            e?.detail && sendFeedEvent({
              category: idBind === 'footer-details' ? 'Control Panel Full Text' : 'News_Control_Panel',
              event: 'Tags_Button_In_News_Control_Panel'
            });
            getTags();
          }}
        />
      ) : (
        <div
          className={classes.assignTagButton}
          id={assignId}
          onClick={(e) => {
            e?.detail && sendFeedEvent({ category: 'Full text', event: 'Clicked_Tags+_Add_To_Mention_Button' });
            getTags();
          }}
        >
          <span>{`${t('footer', 'TAGS', language)} + `}</span>
        </div>
      )}
    </PopoverWindow>
  );
});

TagsPopover.propTypes = {
  chekedArticlesArr: arrayOf(number),
  checkedTags: oneOfType([arrayOf(shape({})), array]),
  language: string,
  idBind: string,
  isButton: bool,
  assignId: string
};

export default TagsPopover;
