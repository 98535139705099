import { string, func, shape, number, oneOfType } from 'prop-types';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button } from 'Components';
import hasOwn from 'lodash/has';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyConfirmIfManyMentions.module.scss';

const BodyConfirmIfManyMentions = ({
  className = '',
  onCancel = () => null,
  onAddEditKeyword = () => null,
  onAddEditTag = () => null,
  onAddEditAlert = () => null,
  language = 'uk',
  data = { name: '', id: 0 }
}) => {
  const { pathname } = useLocation();
  const onSubmit = (e) => {
    e?.preventDefault();
    if (hasOwn(data, 'keyword_id')) {
      sendFeedEvent({
        category: `${data?.keyword_id ? 'Edit' : 'Add'} keyword ${pathname?.includes('/advanced_builder/') ? 'Advanced' : 'Basic'} QB`,
        event: `Clicked_${e ? 'Save' : 'Change'}_Button_in_Keyword_${!data?.keyword_id ? 'Adding' : 'Editing'}_Popup`
      });
      !!e && onAddEditKeyword({ keyword_id: data?.keyword_id || null, body: data?.body });
    }
    if (hasOwn(data, 'tag_id')) {
      sendFeedEvent({
        category: `${data?.tag_id ? 'Edit' : 'Add'} tag ${pathname?.includes('/advanced_builder/') ? 'Advanced' : 'Basic'} QB`,
        event: `Clicked_${e ? 'Save' : 'Change'}_Button_in_Tag_${!data?.tag_id ? 'Adding' : 'Editing'}_Popup`
      });
      !!e && onAddEditTag({ tag_id: data?.tag_id, body: data?.body, info: data?.assignInfo });
    }
    if (hasOwn(data, 'alert_id')) {
      sendFeedEvent({
        category: `${data?.body?.id ? 'Edit' : 'Add'} Query ${pathname?.includes('/advanced_builder/') ? 'Advanced' : 'Basic'} QB`,
        event: `Clicked_${e ? 'Save' : 'Change'}_Button_in_Search_Query_${!data?.body?.id ? 'Adding' : 'Editing'}_Popup`
      });
      onAddEditAlert(data?.body);
    }
  };

  return (
    <form onSubmit={onSubmit} className={cn(classes.bodyConfirmIfManyMentions, className)}>
      <div className={classes.discription}>
        {hasOwn(data, 'keyword_id') && t('bodyRenamePageModal', !data?.keyword_id ? 'SURE_SAVE_KW' : 'SURE_EDIT_KW', language)}
        {hasOwn(data, 'tag_id') && t('bodyRenamePageModal', !data?.tag_id ? 'SURE_SAVE_TAG' : 'SURE_EDIT_TAG', language)}
        {hasOwn(data, 'alert_id') && t('bodyRenamePageModal', !data?.alert_id ? 'SURE_SAVE_QUERY' : 'SURE_EDIT_QUERY', language)}
      </div>
      <div className={classes.buttonBox}>
        <Button
          type="submit"
          textButton={t('btn', 'SAVE', language)}
          className={classes.buttonCancel}
          onClick={onSubmit}
          purpleTransparent32
        />
        <Button
          onClick={(e) => {
            onSubmit();
            onCancel(e);
          }}
          textButton={t('btn', 'CHANGE', language)}
          green32
        />
      </div>
    </form>
  );
};

BodyConfirmIfManyMentions.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCancel: func,
  onAddEditKeyword: func,
  onAddEditTag: func,
  onAddEditAlert: func,
  language: string
};

export default BodyConfirmIfManyMentions;
