import { string, arrayOf, shape } from 'prop-types';
import cn from 'classnames';
import { FilterOption } from 'Components';
import t from 'utils/translateFunc';
import { openModal } from 'storage/actions';
import { useDispatch } from 'react-redux';
import classes from './FilterContext.module.scss';

const FilterContext = ({ selectData = [], language = 'uk', amplitudeCategory = '', amplitudeEvent = '' }) => {
  const dispatch = useDispatch();
  const onChange = (value) => { dispatch(openModal({ action: 'addPopular', ...value })); };
  const context = [{ id: 'words', title: t('filterContext', 'WORDS', language) }];

  return (
    <div className={cn(classes.filterWithOptions)}>
      <ul className={classes.listOptions}>
        {context.map((option) => {
          const isSelect = selectData?.some(
            selectDataItem => selectDataItem.id === option.id
          );
          return (
            <li key={option.id} className={classes.option}>
              <FilterOption
                text={option?.title}
                icon={option.icon}
                checked={isSelect}
                onChange={() => onChange({ type: option?.id, title: option?.title })}
                optionIconRight="Icn_arrow_forward_nofill"
                amplitudeCategory={amplitudeCategory}
                amplitudeEvent={amplitudeEvent}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FilterContext.propTypes = {
  selectData: arrayOf(
    shape({
      id: string,
      name: string
    })
  ),
  language: string,
  amplitudeCategory: string,
  amplitudeEvent: string
};

export default FilterContext;
