import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  is_open: false,
  in_process: null,
  unread: null,
  history: [],
  next_page: null,
  limits: { limit: 50, usage: 0 }
};

const copilotSlice = createSlice({
  name: 'copilot',
  initialState,
  reducers: {
    openCopilotWindow(state, { payload }) {
      state.is_open = payload?.is_open;
    },
    setCopilotInProcess(state, { payload }) {
      state.in_process = payload?.in_process;
    },
    addCopilotHistory(state, { payload }) {
      const new_questions = payload.history;
      const old_questions = state.history;
      const filtered = old_questions.filter(item => !new_questions.find(el => el.id === item.id));
      state.history = [...new_questions, ...filtered].sort((a, b) => (a.created_at >= b.created_at ? -1 : 1));
    },
    deleteCopilotHistory(state, { payload }) {
      const chat_id = payload?.chat_id;
      const index = state.history.indexOf(state.history.find(item => item?.id === chat_id));
      state.history.splice(index, 1);
    },
    cleanAllCopilotHistory(state) {
      state.history = [];
    },
    setCopilotNextPage(state, { payload }) {
      state.next_page = payload?.next_page;
    },
    setCopilotUnread(state, { payload }) {
      state.unread = payload?.unread;
    },
    setCopilotLimits(state, { payload }) {
      state.limits = { limit: payload?.limit, usage: payload?.limit };
    }
  }
});

export const { openCopilotWindow, setCopilotInProcess,
  addCopilotHistory, setCopilotNextPage,
  setCopilotLimits, deleteCopilotHistory, setCopilotUnread, cleanAllCopilotHistory } = copilotSlice.actions;
export default copilotSlice.reducer;
