import api from 'api/apiAmplitudeFeedInstance';
import { AMPLITUDE_ALERTS_API_KEY, AMPLITUDE_DOWNLOADS_API_KEY, AMPLITUDE_FEED_API_KEY } from 'config';

const sendInfo = async (apiKey, { category, event, properties }) => {
  if (apiKey) {
    return api.post('/2/httpapi', {
      api_key: apiKey,
      events: [{ category, event_type: event, ...(properties ? { properties } : {}) }]
    });
  }
  console.log('Event:', { apiKey, category, event, properties });
  return null;
};

const sendFeedInfo = async data => sendInfo(AMPLITUDE_FEED_API_KEY, data);
const sendAlertsInfo = async data => sendInfo(AMPLITUDE_ALERTS_API_KEY, data);
const sendDownloadsInfo = async data => sendInfo(AMPLITUDE_DOWNLOADS_API_KEY, data);

export { sendFeedInfo, sendAlertsInfo, sendDownloadsInfo };
