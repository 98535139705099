import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { useGetUserInfo, useGetTimeZones, useGetInterfaceLanguages, useUploadImage, useUpdateUserInfo } from 'storage/queryHooks';
import { useSelector } from 'react-redux';
import {
  NavRelease,
  TextField,
  Button,
  Icons,
  UserAvatar,
  SelectCustom,
  Loader,
  AnimatePage
} from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './UserProfile.module.scss';

const UserProfile = () => {
  const history = useNavigate();
  const { language, user, timezones, timezone, interfaceLanguages } = useSelector(state => state?.userInfo);
  const [modeSetting, setModeSetting] = useState('auth');
  const [avatar, setAvatar] = useState(user?.avatar || '');
  const [timeZone, setTimeZone] = useState({ value: timezone, label: timezone });
  const { isLoading } = useGetUserInfo();
  const { isLoading: isLoadingZones, refetch: getTimeZones } = useGetTimeZones();
  const { isLoading: isLoadingInterfaceLanguages, refetch: getInterfaceLanguages } = useGetInterfaceLanguages();
  const { mutateAsync: updateUserInfo } = useUpdateUserInfo();
  const { mutateAsync: uploadImage, data: imageData, isLoading: uploadImageLoading } = useUploadImage();
  const optionsTimezone = timezones.map(zona => ({ value: zona, label: zona }));
  const interfaceLangs = interfaceLanguages?.length ?
    interfaceLanguages?.map(lang => ({ value: lang[0], label: lang[1] }))
    : [{ value: language, label: interfaceLangItem(language) }];

  const [name, setName] = useState({
    username: user?.username || '',
    new_password1: '',
    new_password2: '',
    old_password: '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || ''
  });
  const [wrongText, setWrongText] = useState({
    username: '',
    new_password1: '',
    new_password2: '',
    old_password: '',
    first_name: '',
    last_name: '',
    email: ''
  });
  const [lngChoose, setLngChoose] = useState({ value: language, label: interfaceLangItem(language) });
  const [image, setImage] = useState(null);
  const { username, email, new_password2, first_name, last_name, old_password, new_password1 } = name;
  const [isDisabled, setIsDisabled] = useState(true);

  const dataNav = [
    { name: t('userProfile', 'SETTING_AUTH', language), id: 'auth' },
    { name: t('userProfile', 'PERSONAL_SETTING', language), id: 'personal' }
  ];

  const onClickSetting = (id) => {
    setModeSetting(id);
  };

  function interfaceLangItem(lang) {
    if (lang === 'uk') return 'Українська';
    if (lang === 'ru') return 'Русский';
    if (lang === 'ro') return 'Română';
    return 'English';
  }
  const onSubmit = (e) => {
    e.preventDefault();
    const settingsEvents = ['auth', 'personal'];
    settingsEvents.includes(modeSetting) && sendFeedEvent({ category: 'Profile Settings', event: `profile_${modeSetting}` });

    if (new_password1 !== new_password2 && new_password1.length <= 16) {
      setWrongText({ new_password1: ' ', new_password2: t('error', 'ERR_DIFFERENT_PASS', language) });
      setModeSetting('auth');
      return;
    }

    const body = { };
    username && username !== user?.username && (body.username = username);
    email && email !== user?.email && (body.email = email);
    first_name && (body.first_name = first_name);
    last_name && (body.last_name = last_name);
    imageData?.file_path && (body.avatar = imageData?.file_path);
    timeZone.value && user?.timezone !== timeZone.value && (body.timezone = timeZone.value);
    lngChoose.value && user?.language !== lngChoose.value && (body.language = lngChoose.value);

    if (new_password1 && new_password1 && new_password2) {
      body.new_password1 = new_password1;
      body.new_password2 = new_password2;
      body.old_password = old_password;
    }
    updateUserInfo({ id: user?.id, body });
  };

  const onName = (e) => {
    setName({ ...name, [e.target.name]: e.target.value });
    setIsDisabled(false);
    if (e.target.name === 'new_password1' || e.target.name === 'new_password2') {
      setWrongText({ ...wrongText, new_password1: '', new_password2: '' });
    }
  };

  const onChangeSelectLng = (value) => {
    value?.value && sendFeedEvent({ category: 'Profile Settings', event: `language_${value.value}` });
    setLngChoose(value);
    setIsDisabled(false);
  };

  const onChangeSelectTimeZone = (value) => {
    setTimeZone(value);
    setIsDisabled(false);
  };

  function onCancel() {
    const settingsEvents = ['auth', 'personal'];
    settingsEvents.includes(modeSetting) && sendFeedEvent({
      category: 'Profile Settings',
      event: `profile_close_${modeSetting}`
    });

    history(-1);
  }

  function onChangeAvatar(value) {
    value ? uploadImage({ image: value }) : setAvatar('');
    setImage(value);
    setIsDisabled(false);
  }

  function onOpenLangMenu() {
    sendFeedEvent({ category: 'Profile Settings', event: 'Clicked_Language_Field_In_Profile_Settings' });
    interfaceLanguages?.length < 2 && getInterfaceLanguages();
  }

  function onOpenTimezoneMenu() {
    sendFeedEvent({ category: 'Profile Settings', event: 'Clicked_Timezone_Field_In_Profile_Settings' });
    timezones.length < 2 && getTimeZones();
  }

  return (
    <AnimatePage>
      <Loader fetching={isLoading}>
        <div className={classes.userProfileWrap}>
          <button
            className={classes.exitBtn}
            onClick={onCancel}
            type="button"
          >
            <Icons
              icon="Icn_Clear"
              className={cn(classes.icon)}
            />
          </button>
          <div className={classes.userProfile}>
            <h2 className={classes.title}>
              {t('userProfile', 'SETTING_PROFILE', language)}
            </h2>
            <NavRelease
              dataNav={dataNav}
              onClickChips={onClickSetting}
              modeSetting={modeSetting}
              language={language}
            />
            <form onSubmit={onSubmit} className={classes.form}>
              {modeSetting === 'auth' && (
              <div className={classes.fieldBox}>
                <div className={classes.fieldSection}>
                  <section>
                    <TextField
                      name="username"
                      className={cn(
                        classes.textField,
                        classes.textField_withClarification
                      )}
                      value={username}
                      onChange={onName}
                      labelText={t('LoginPage', 'USERNAME', language)}
                      placeholder={t('userProfile', 'ENTER_USERNAME', language)}
                      errorText={wrongText.username}
                      boxShadow
                    />
                    {!wrongText.username && (
                    <p className={classes.clarification}>
                      {t('userProfile', 'CLARIFICATION', language)}
                    </p>
                    )}
                  </section>
                  <section className={classes.selectSection}>
                    <SelectCustom
                      selectTitle={t('userProfile', 'LANGUAGE', language)}
                      value={lngChoose}
                      isLoading={isLoadingInterfaceLanguages}
                      onChange={onChangeSelectLng}
                      onMenuOpen={onOpenLangMenu}
                      options={interfaceLangs}
                    />
                  </section>
                </div>
                <div className={classes.fieldSection}>
                  <TextField
                    required={
                    !!(new_password1 || old_password || new_password2)
                  }
                    autoComplete="new-password"
                    name="new_password1"
                    className={
                    wrongText.new_password1 && wrongText.new_password1 === ' '
                      ? classes.textField_errorServer
                      : wrongText.new_password1
                        ? classes.textField_error
                        : classes.textField
                  }
                    value={new_password1}
                    onChange={onName}
                    labelText={t('passwordReset', 'NEW_PASS', language)}
                    placeholder={t('passwordReset', 'ENTER_NEW_PASS', language)}
                    type="password"
                    errorText={wrongText.new_password1}
                    boxShadow
                  />
                  <TextField
                    required={
                    !!(new_password1 || old_password || new_password2)
                  }
                    name="new_password2"
                    autoComplete="new-password"
                    className={
                    wrongText.new_password2 && wrongText.new_password2 === ' '
                      ? classes.textField_errorServer
                      : wrongText.new_password2
                        ? classes.textField_error
                        : classes.textField
                  }
                    value={new_password2}
                    onChange={onName}
                    labelText={t('passwordReset', 'CONFIRM_PASS', language)}
                    placeholder={t('passwordReset', 'ENTER_NEW_PASS', language)}
                    type="password"
                    errorText={wrongText.new_password2}
                    boxShadow
                  />
                  <TextField
                    required={
                    !!(new_password1 || old_password || new_password2)
                  }
                    name="old_password"
                    autoComplete="new-password"
                    className={
                    wrongText.old_password
                      ? classes.textField_error
                      : classes.textField
                  }
                    value={old_password}
                    onChange={onName}
                    labelText={t('userProfile', 'PASSWORD_OLD', language)}
                    placeholder={t('userProfile', 'ENTER_PASSWORD_OLD', language)}
                    type="password"
                    errorText={wrongText.old_password}
                    boxShadow
                  />
                </div>
              </div>
              )}

              {modeSetting === 'personal' && (
              <div className={classes.fieldBox}>
                <div className={classes.fieldSection}>
                  <section>
                    <TextField
                      name="first_name"
                      className={classes.textField}
                      value={first_name}
                      onChange={onName}
                      labelText={t('userProfile', 'FIRSTNAME', language)}
                      placeholder={t('userProfile', 'ENTER_FIRSTNAME', language)}
                      errorText={wrongText.first_name}
                      boxShadow
                    />
                    <TextField
                      name="last_name"
                      className={classes.textField}
                      value={last_name}
                      onChange={onName}
                      labelText={t('userProfile', 'LASTNAME', language)}
                      placeholder={t('userProfile', 'ENTER_LASTNAME', language)}
                      errorText={wrongText.last_name}
                      boxShadow
                    />
                  </section>
                  <section className={classes.selectSection}>
                    <SelectCustom
                      selectTitle={t('userProfile', 'TIMEZONE', language)}
                      value={timeZone}
                      onMenuOpen={onOpenTimezoneMenu}
                      isLoading={isLoadingZones}
                      onChange={onChangeSelectTimeZone}
                      options={optionsTimezone}
                      bigList
                    />
                  </section>
                </div>
                <div className={classes.fieldSection}>
                  <TextField
                    name="email"
                    className={classes.textField}
                    value={email}
                    onChange={onName}
                    labelText={t('userProfile', 'EMAIL', language)}
                    placeholder={t('userProfile', 'ENTER_EMAIL', language)}
                    type="email"
                    errorText={wrongText.email}
                    boxShadow
                  />
                  <UserAvatar
                    image={image}
                    avatar={avatar}
                    isLoading={uploadImageLoading}
                    language={language}
                    onChange={onChangeAvatar}
                  />
                </div>
              </div>
              )}
              <div className={classes.buttonBox}>
                <Button
                  textButton={t('createFirstRelease', 'CANCEL', language)}
                  onClick={onCancel}
                  className={classes.cancelButton}
                  purpleTransparent32
                />
                <Button
                  textButton={t('createFirstRelease', 'SAVE', language)}
                  type="submit"
                  disabled={isDisabled}
                  green32
                />
              </div>
            </form>
          </div>
          <button type="button" onClick={onCancel} className={classes.clearBtn}>
            <Icons icon="Icn_Clear-4" className={classes.iconClear} />
          </button>
        </div>
      </Loader>
    </AnimatePage>
  );
};

export default UserProfile;
