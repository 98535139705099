import { useState } from 'react';
import cn from 'classnames';
import { bool, string } from 'prop-types';
import { getIconName, MEDIA_TYPES, SOCIAL_NETWORKS } from 'data/sourceConfig';
import classes from './IconSVG.module.scss';

export function IconSVG({ type = '', src = '', iconWrapClass = '', iconClass = '', colored = false }) {
  const [isBroken, setIsBroken] = useState(false);
  const isSocial = !!type && !!SOCIAL_NETWORKS.includes(type);
  const isMedia = !!type && !!MEDIA_TYPES.includes(type);

  const filledColors = {
    internet: '#6887FF',
    press: '#FFBF00',
    agency: '#F77A99',
    forum: '#2A206A',
    radio: '#B193DC',
    tv: '#7A0E63',
    total: '#2ec7c9',
    default: '#00C784'
  };

  function checkGroup() {
    if (isSocial) {
      return 'socialnetwork';
    }
    if (isMedia) {
      return 'media_type';
    }
    if (type === 'country') {
      return 'country';
    }
    return 'default';
  }

  const typeGroup = type && checkGroup();

  const folders = {
    media_type: '/assets/svg/media_type',
    socialnetwork: '/assets/svg/socialnetwork',
    country: '/assets/svg/country',
    default: '/assets/svg'
  };
  const iconName = type || 'default';
  const path = folders[typeGroup];
  const localPath = `${path}/${getIconName(iconName)}.svg`;
  const outPath = !!src && (isBroken ? localPath : src);

  return (
    <div
      className={cn(
        classes.iconWrap,
        isMedia && classes.iconWrap_media,
        isMedia && colored && classes.iconWrap_white,
        iconWrapClass
      )}
      style={{ ...(isMedia && colored ? { backgroundColor: (filledColors[type] || filledColors.default) } : {}) }}
    >
      <img
        src={outPath || localPath}
        onError={() => { setIsBroken(true); }}
        alt="icon"
        className={cn(iconClass)}
      />
    </div>
  );
}

IconSVG.propTypes = {
  type: string,
  src: string,
  iconWrapClass: string,
  iconClass: string,
  colored: bool
};
