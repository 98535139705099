import { string, func, shape, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, BodyHtml } from 'Components';
import classes from './BodyLoadingArticleModal.module.scss';

const BodyLoadingArticleModal = ({
  className = '',
  onCancel = () => null,
  language = 'uk',
  data = { message: '' }
}) => (
  <div className={cn(classes.bodyLoadModal, className)}>
    <div className={classes.discription}>
      {!!data?.message && <BodyHtml html={data?.message} />}
    </div>
    <div className={classes.buttonBox}>
      <Button
        onClick={onCancel}
        textButton={t('btn', 'CLOSE', language)}
        className={classes.buttonCancel}
        green32
      />
    </div>
  </div>
);

BodyLoadingArticleModal.propTypes = {
  className: string,
  data: oneOfType([shape({ message: string })]),
  onCancel: func,
  language: string
};

export default BodyLoadingArticleModal;
