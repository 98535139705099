import { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { validateQuery } from 'data/validateQuery';
import { Icon, Loader, SkeletonSearchList, SkeletonSearchCharts, NoDataSearch } from 'Components';
import cn from 'classnames';
import { openModal, toggleSearchDuplicates, changeSearchPageSize, changeSearchOrdering, changeCheckedSearchArcticles,
  editSearchQuery, toggleShowInstructions, editArticlesTab, addSearchFilterQuery } from 'storage/actions';
import { useGetMediaArticles, useGetSocialnetworkArticles, useGetSearchAnalyticsCharts, useGetSearchCounts,
  useGetFavoriteSearchCounts, useGetFavoriteMediaArticles, useGetFavoriteSocialnetworkArticles, useDowloadSearchReport,
  useToggleFavoriteManyArticle
} from 'storage/queryHooks';
import t from 'utils/translateFunc';
import classes from './SearchFeed.module.scss';
import SearchQuery from './SearchQuery';
import Instructions from './Instructions';
import SearchResultsHeader from './SearchResultsHeader';
import SearchResultsArticles from './SearchResultsArticles';
import SearchResultsCharts from './SearchResultsCharts';

function SearchFeed() {
  const dispatch = useDispatch();

  const { searchCharts, media_articles, sn_articles, media_favorite_articles, sn_favorite_articles, searchTab, articlesTab, queryTemp, counts,
    page_size, ordering, duplicates, checkedArticles, filters, selectedSearchFilter, show_instructions } = useSelector(state => state?.search);

  const { language, user } = useSelector(state => state?.userInfo);
  const [focusEditor, setFocusEditor] = useState(false);
  const [errorQuery, setErrorQuery] = useState('');
  const [errorValidate, setErrorValidate] = useState(null);
  const [showArrowUp, setShowArrowUp] = useState(false);
  const [page, setPage] = useState(1);
  const ref = useRef();
  const pageRef = useRef();

  const { isError: chartsIsError, isLoading: chartsLoading, isRefetching: chartsRefetching, refetch: refetchCharts } = useGetSearchAnalyticsCharts();
  const { isError: mediaIsError, isLoading: mediaLoading, isRefetching: mediaRefetching, refetch: refetchMedia } = useGetMediaArticles(page);
  const { isError: socialIsError, isLoading: socialLoading, isRefetching: socialRefetching, refetch: refetchSocial } = useGetSocialnetworkArticles(page);
  const { data: articlesCount, isLoading: countLoading, isRefetching: countRefetching } = useGetSearchCounts();

  const { isLoading: favoriteMediaLoading, isRefetching: favoriteMediaRefetching } = useGetFavoriteMediaArticles(page);
  const { isLoading: favoriteSocialLoading, isRefetching: favoriteSocialRefetching } = useGetFavoriteSocialnetworkArticles(page);
  const { data: articlesFavoriteCount, isLoading: favoriteCountLoading, isRefetching: favoriteCountRefetching } = useGetFavoriteSearchCounts();

  const { isLoading: reportLoading, mutateAsync: downloadReport } = useDowloadSearchReport();
  const { mutateAsync: toggleFavoriteMany } = useToggleFavoriteManyArticle();
  const isFetchingCounts = favoriteCountLoading || countLoading || countRefetching || favoriteCountRefetching;
  const isFetchingArticles = mediaLoading || mediaRefetching || socialLoading || socialRefetching || favoriteMediaLoading || favoriteSocialLoading || favoriteSocialRefetching
  || favoriteMediaRefetching;
  const isFetchingCharts = chartsLoading || chartsRefetching;
  const isFetching = isFetchingCharts || isFetchingArticles;

  const articles = useMemo(() => {
    if (articlesTab === 'media') return searchTab === 'favorite' ? media_favorite_articles : media_articles;
    if (articlesTab === 'socialnetwork') return searchTab === 'favorite' ? sn_favorite_articles : sn_articles;
    return null;
  }, [searchTab, articlesTab, media_favorite_articles, media_articles, sn_favorite_articles, sn_articles]);

  const pagginationCount = useMemo(() => {
    if (articlesTab === 'media') return searchTab === 'favorite' ? articlesFavoriteCount?.media : articlesCount?.media;
    if (articlesTab === 'socialnetwork') return searchTab === 'favorite' ? articlesFavoriteCount?.socialnetwork : articlesCount?.socialnetwork;
    return 1;
  }, [searchTab, articlesTab, isFetchingCounts]);

  const hasData = !!(searchCharts || articles);
  useEffect(() => {
    const pageContent = pageRef?.current;
    if (articles?.length && pageContent) {
      pageContent.clientHeight < pageContent.scrollHeight && !showArrowUp && setShowArrowUp(true);
      pageContent.clientHeight >= pageContent.scrollHeight && showArrowUp && setShowArrowUp(false);
    }
    !articles?.length && showArrowUp && setShowArrowUp(false);
  }, [articles?.length]);

  useEffect(() => {
    if (selectedSearchFilter?.id) {
      !!errorQuery && setErrorQuery('');
      !!errorValidate && setErrorValidate(null);
    }
  }, [selectedSearchFilter?.id]);

  useEffect(() => {
    if (show_instructions && (hasData || isFetching)) {
      dispatch(toggleShowInstructions(false));
    }
  }, []);

  useEffect(() => {
    setPage(1);
  }, [searchTab, articlesTab]);

  const onChangeEditor = (text) => {
    errorQuery && setErrorQuery('');
    !focusEditor && setFocusEditor(true);
    dispatch(editSearchQuery(text));
    onCheckValidateErrors(text);
  };

  function onCheckValidateErrors(text) {
    if (text?.length) {
      const err = validateQuery({ query: text, language });
      !isEqual(err, errorValidate) && setErrorValidate(err);
    } else {
      !text.length && setErrorValidate(null);
    }
  }

  function onCheckErrors() {
    queryTemp.length < 1 && setErrorQuery(t('error', 'MIN_SYMBOLS'));
    !queryTemp.length && setErrorQuery(t('error', 'ERR_REQUIRED'));
    queryTemp.length > 4000 && setErrorQuery(t('error', 'MAX_SYMBOLS'));
    return !queryTemp.length || queryTemp.length < 1 || queryTemp.length > 4000 || errorValidate;
  }

  function onFocus() {
    !focusEditor && setFocusEditor(true);
  }
  function onBlur() {
    !!focusEditor && setFocusEditor(false);
    ref.current.view?.scrollDOM?.scrollTo(0, 0);
  }

  function toggleInstructions() {
    const alwaysShow = searchTab === 'search'
    && ((articlesTab === 'analitycs' && !searchCharts) || (articlesTab === 'media' && !media_articles)
    || (articlesTab === 'socialnetwork' && !sn_articles));
    !alwaysShow && dispatch(toggleShowInstructions(!show_instructions));
  }

  function refetchOnError() {
    articlesTab === 'analitycs' && chartsIsError && refetchCharts();
    articlesTab === 'media' && mediaIsError && refetchMedia();
    articlesTab === 'socialnetwork' && socialIsError && refetchSocial();
  }

  function onSearch() {
    const hasErrors = onCheckErrors();
    if (!hasErrors && queryTemp !== filters?.query) {
      const queryTempClear = queryTemp.replace(/\r?\n|\r/g, '');
      dispatch(editSearchQuery(queryTempClear));
      dispatch(addSearchFilterQuery(queryTempClear));
      !!show_instructions && dispatch(toggleShowInstructions(false));
      refetchOnError();
    }
  }

  function onOpenHistory() {
    dispatch(openModal({ action: 'searchHistory', info: null }));
  }
  function handleScrollToTop() {
    pageRef?.current.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function onChangeArticleTab(value) {
    if (value !== articlesTab) {
      dispatch(editArticlesTab(value));
      setPage(1);
    }
  }

  return (
    <div className={cn(classes.searchContent, searchTab === 'favorite' && classes.searchContent_favorite)} ref={pageRef}>
      <SearchQuery
        ref={ref}
        query={queryTemp}
        showSearchQuery={searchTab === 'search'}
        focusEditor={focusEditor}
        showInstructions={show_instructions}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        onOpenHistory={onOpenHistory}
        onChangeEditor={onChangeEditor}
        onChangeInstructions={toggleInstructions}
        errorValidate={errorValidate}
        errorQuery={errorQuery}
      />
      <div
        className={cn(
          classes.main,
          (focusEditor || errorValidate) && classes.main_focusEditor
        )}
        id="search-content-main"
      >
        <Instructions
          show={((show_instructions || !hasData) && !isFetching) && searchTab === 'search'}
          showClose={!!hasData}
          onHide={toggleInstructions}
          language={language}
        />
        <SearchResultsHeader
          show={!show_instructions && !!(hasData || isFetching)}
          duplicates={duplicates}
          isFavorite={searchTab === 'favorite'}
          tab={articlesTab}
          onChangeTab={onChangeArticleTab}
          onToggleDuplicates={(value) => { dispatch(toggleSearchDuplicates(value)); }}
          fetching={isFetchingCounts || isFetching}
          onDownload={downloadReport}
          reportLoading={reportLoading}
          mediaCount={counts?.media || 0}
          socialCount={counts?.socialnetwork || 0}
        />
        {articlesTab !== 'analitycs' && (
        <SkeletonSearchList fetching={isFetchingArticles}>
          <NoDataSearch data={articles}>
            <SearchResultsArticles
              show={!show_instructions}
              isOperator={user?.permissions?.operator_permission}
              isSocial={articlesTab === 'socialnetwork'}
              isFavoriteTab={searchTab === 'favorite'}
              pagginationCount={pagginationCount}
              articles={articles}
              pageSize={page_size}
              page={page}
              ordering={ordering}
              onChangePage={(value) => { setPage(value); }}
              checkedArticles={checkedArticles}
              onToggleFavoriteMany={toggleFavoriteMany}
              onChangeCheckedArticles={(value) => { dispatch(changeCheckedSearchArcticles(value)); }}
              onChangeOrdering={(value) => { dispatch(changeSearchOrdering(value)); }}
              onChangePageSize={(value) => { dispatch(changeSearchPageSize(value)); }}
            />
          </NoDataSearch>
        </SkeletonSearchList>
        )}
        {searchTab === 'search' && articlesTab === 'analitycs' && (
          <SkeletonSearchCharts fetching={isFetchingCharts}>
            <NoDataSearch data={searchCharts?.by_mention_dynamic}>
              <SearchResultsCharts
                show={!show_instructions}
                data={searchCharts}
                isLoading={isFetchingCharts}
              />
            </NoDataSearch>
          </SkeletonSearchCharts>
        )}
      </div>
      {!!reportLoading && (
      <div className={cn(classes.downloadsWrap)}>
        <div className={classes.iconContainer}>
          <Loader type="spin" size={40} fetching={reportLoading} color="#C899F0" className={classes.loader} />
          <Icon
            type="download_icon"
            width={20}
            height={20}
            color={reportLoading ? '#330B6B' : '#ffffff'}
            className={cn(classes.downloadsBtn, reportLoading && classes.downloadsBtn_active)}
          />
        </div>
      </div>
      )}
      {showArrowUp && <Icon type="arrow_forward" color="#97A0BD" onClick={handleScrollToTop} className={cn(classes.topButtonWrapper)} />}
    </div>
  );
}

SearchFeed.propTypes = {};

export default SearchFeed;
