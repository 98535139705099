import {
  string,
  number,
  bool,
  shape,
  oneOfType,
  arrayOf
} from 'prop-types';

export const settingType = shape({
  nameWidget: string
});

const dataTypeOne = shape({
  name: string,
  dateRange: shape({
    start: string,
    end: string
  }),
  comparisonDateRange: shape({
    start: string,
    end: string
  }),
  values: arrayOf(
    shape({
      name: string,
      value: oneOfType([number, string]),
      comparisonValue: number,
      percent: number,
      link: string,
      comparisonLink: string
    })
  )
});

const dataChartValueType = shape({
  id: oneOfType([string, number]),
  data: arrayOf(
    shape({
      x: string,
      y: number,
      link: string
    })
  )
});

const dataTableValuesType = shape({
  counter: number,
  discription: string,
  date: number,
  sourceName: string,
  url: string,
  duplicates: number,
  viewsCount: number,
  link: string
});

const dataTypeTwo = shape({
  name: string,
  value: number,
  comparisonValue: number,
  percent: number,
  link: string,
  comparisonLink: string
});

export const keywordsDataTypes = shape({
  id: oneOfType([string, number]),
  label: string,
  value: string
});

export const dataNavDashboardTypes = shape({
  id: oneOfType([string, number]),
  isDashboard: bool,
  keywords: arrayOf(keywordsDataTypes),
  name: string,
  notepad: oneOfType([string, number]),
  order: number,
  portalSlug: string,
  readOnly: bool,
  tags: arrayOf(keywordsDataTypes)
});

export const dataNavTypes = shape({
  btnWithDropDown: bool,
  counter: string,
  id: oneOfType([string, number]),
  info: string,
  name: string
});

export const widgetDataTypes = shape({
  dataOne: dataTypeOne,
  dataTwo: dataTypeOne,
  dataChart: shape({
    values: arrayOf(dataChartValueType)
  }),
  dataTable: shape({
    values: arrayOf(dataTableValuesType)
  }),
  comparison: bool,
  setting: settingType
});

export const widgetDataDetailTypes = shape({
  dataOne: dataTypeTwo,
  dataTwo: dataTypeTwo,
  value: number,
  comparisonValue: number,
  percent: number,
  link: string,
  comparisonLink: string,
  comparison: bool
});

export const widgetDataModalTypes = shape({
  chartPage: number,
  id: oneOfType([string, number]),
  name: string,
  portalSlug: string,
  tooltip: oneOfType([string, number]),
  type: string
});

export const dataMentionsTypes = shape({
  title: string,
  description: string,
  source: string,
  date: string,
  created_date: string
});

export const datePickerTypes = shape({
  comparison_end: string,
  comparison_start: string,
  end: string,
  start: string,
  by_created: bool
});

export const positionTypes = shape({
  id: oneOfType([string, number]),
  tile: arrayOf(
    shape({
      chosen: bool,
      id: oneOfType([string, number]),
      selected: bool
    })
  )
});

export const widgetTypes = shape({
  chartPage: number,
  id: oneOfType([string, number]),
  name: string,
  portalSlug: string,
  tooltip: string,
  type: string,
  resultFormula: arrayOf(
    shape({
      id: oneOfType([string, number]),
      label: string,
      value: string
    })
  )
});

const itemTypes = shape({
  id: oneOfType([string, number]),
  label: oneOfType([string, number]),
  value: oneOfType([string, number])
});

export const filtersTypes = shape({
  id: oneOfType([string, number]),
  character: itemTypes,
  description: string,
  name: string,
  type: string,
  settingsViewParams: shape({ publication: bool }),
  filterCountry: arrayOf(itemTypes),
  filterLanguage: arrayOf(itemTypes),
  filterLevel: arrayOf(itemTypes),
  filterMediaType: arrayOf(itemTypes),
  filterReference: arrayOf(itemTypes),
  filterRole: arrayOf(itemTypes),
  filterSource: arrayOf(itemTypes),
  filterTone: arrayOf(itemTypes),
  filterTopics: arrayOf(itemTypes),
  resultFormula: arrayOf(itemTypes),
  keywords: arrayOf(itemTypes),
  tags: arrayOf(itemTypes)
});
