/* eslint-disable */
var isStringChar = /[^\s\|\!\+\-\*\?\~\^\&\:\.\/\(\)\[\]\{\}\"\\]/;
var isOperatorChar = /[\|\!\+\-\*\?\~\^\&\(\)\[\]\}\{\.\/]/;
var isOperatorString = /^(OR|AND|NOT)$/;
var isCommentChar = /[\<]/;
var isComments = /<<<.*?>>>/g;
var isGreenFields = /^(publication_place_name|post_type)$/;
var isBlueFields = /^(title|text)$/;
var isDarkYellowFields = /^(language_code)$/;

function isNumber(word) {
  return parseFloat(word).toString() === word;
}

function tokenString(quote) {
  return function(stream, state) {
    var escaped = false, next;
    while ((next = stream.next()) != null) {
      if (next == quote && !escaped) break;
      escaped = !escaped && next == "\\";
    }

    if (!escaped) state.tokenize = tokenBase;
    return "string";
  };
}

function tokenOperator(operator) {
    return function(stream, state) {
    if (operator == "|")
      stream.eat(/\|/);
    else if (operator == "&")
      stream.eat(/\&/);
    state.tokenize = tokenBase;
    if (operator == "(" || operator == ")" || operator == ".")
      return "bracket"
    if (operator == "[" || operator == "]")
      return "squareBracket"
    if (operator == "{" || operator == "}")
      return "angleBracket"
    if (operator == "?")
      return "paren"
    if (operator == "~")
      return "brace"
    if (operator == "/")
      return "escape"
    else
      return "operator";
  };
}

function tokenComment(ch) {
  return function(stream, state) {
    var word = ch;
    while ((ch = stream.peek()) && ch.match(isStringChar) != ">>>") {
      word += stream.next();    }
    state.tokenize = tokenBase;
    if(word.match(isComments))
      return "comment";
    else
      return "string";
  };
}

function tokenWord(ch) {
  return function(stream, state) {
    var word = ch;
    while ((ch = stream.peek()) && ch.match(isStringChar) != null) {
      word += stream.next();
    }
    state.tokenize = tokenBase;

    if (isOperatorString.test(word) && word === "AND") {
      return "compareOperator";
    }
     else if (isOperatorString.test(word) && word === "NOT") {
      return "derefOperator";
    }
    else if (isOperatorString.test(word)) {
      return "operator";
    }
    else if (isNumber(word))
      return "number";
    else if (stream.peek() == ":" && isGreenFields.test(word)) {
      return "typeName";
    }
    else if (stream.peek() == ":" && isBlueFields.test(word)) {
      return "variableName";
    }
      else if (stream.peek() == ":" && isDarkYellowFields.test(word)) {
      return "labelName";
    }
    else if (stream.peek() == ":")
      return "propertyName";
    else
      return "string";
  };
}

function tokenBase(stream, state) {
  var ch = stream.next();
    if (ch == '"')
    state.tokenize = tokenString(ch);
    else if (isOperatorChar.test(ch))
    state.tokenize = tokenOperator(ch);
    else if (isCommentChar.test(ch))
    state.tokenize = tokenComment(ch)
    else if (isStringChar.test(ch))
    state.tokenize = tokenWord(ch);

  return (state.tokenize != tokenBase) ? state.tokenize(stream, state) : null;
}

export const solr = {
  name: "solr",
  startState: function() {
    return {
      tokenize: tokenBase
    };
  },
  token: function(stream, state) {
    if (stream.eatSpace()) return null;
    return state.tokenize(stream, state);
  },
};
