import moment from 'moment';
import { func, shape, string } from 'prop-types';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import classes from './DatePickerButton.module.scss';

const DatePickerButton = ({ datePicker = null, onChange = () => null }) => {
  const isMobile = useCheckMobileScreen();
  return (
    <button
      type="button"
      onClick={onChange}
      className={classes.datepickerOpenBtn}
    >
      {!!datePicker?.start && (
      <p className={classes.date}>
        {moment(datePicker.start).format('YYYY-MM-DD')}
        {!isMobile && (
        <span>
          {moment(datePicker.start).format('HH:mm')}
        </span>
        )}
        {' - '}
        {moment(datePicker.end).format('YYYY-MM-DD')}
        {!isMobile && (
        <span>
          {moment(datePicker.end).format('HH:mm')}
        </span>
        )}
      </p>
      )}
    </button>
  );
};

DatePickerButton.propTypes = {
  onChange: func,
  datePicker: shape({ start: string, end: string })
};
export default DatePickerButton;
