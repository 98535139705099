import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  timezones: [],
  interfaceLanguages: [],
  language: 'en',
  timezone: 'Europe/Kiev',
  refetchTelegram: false,
  telegramData: null,
  refetchWhatsapp: false,
  whatsappData: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserInfo(state, { payload }) {
      state.user = payload;
      state.language = payload?.language;
      state.timezone = payload?.timezone;
      state.timezones = [payload?.timezone];
    },
    addTimeZones(state, { payload }) {
      state.timezones = payload;
    },
    addAvatar(state, { payload }) {
      state.user.avatar = payload?.file_url;
    },
    addInterfaceLanguages(state, { payload }) {
      state.interfaceLanguages = payload;
    },
    updateUserInfo(state, { payload }) {
      state.user = { ...state.user, ...payload };
      state.language = payload?.language;
    },
    toggleRefetchTelegram(state, { payload }) {
      state.refetchTelegram = payload;
    },
    addTelegram(state, { payload }) {
      payload && (state.telegramData = payload);
    },
    toggleRefetchWhatsapp(state, { payload }) {
      state.refetchWhatsapp = payload;
    },
    addWhatsapp(state, { payload }) {
      payload && (state.whatsappData = payload);
    }
  }
});

export const { addUserInfo, updateUserInfo, addAvatar,
  addTimeZones, addInterfaceLanguages, toggleRefetchTelegram, addTelegram, toggleRefetchWhatsapp, addWhatsapp } = userSlice.actions;
export default userSlice.reducer;
