import { useParams } from 'react-router-dom';
import { string, func, bool, shape, number, oneOfType, arrayOf } from 'prop-types';
import cn from 'classnames';
import { BtnDropDown } from 'Components';
import classes from './ChipsButton.module.scss';

const ChipsButton = ({
  name = '',
  id = '',
  onClickChips = () => null,
  modeUser = false,
  modeSetting = '',
  onOpenModal = () => null,
  isPreset = false,
  color = '',
  menuConfig = [],
  isEqualFilters = false,
  order = 0
}) => {
  const params = useParams();

  const onSelectOption = ({ action }) => {
    onOpenModal({ action, info: { id, name, order: order || 0 } });
  };

  return (
    <div
      className={cn(
        classes.underlineChips,
        (id === Number(params?.pageId) || (params?.pageId === 'all' && id === 'all') || (id === modeSetting)) && classes.underlineChips_isChecked
      )}
    >
      <div className={classes.chipsButtonWrap}>
        <button
          onClick={() => onClickChips(id, params?.pageId)}
          type="button"
          className={cn(
            classes.chipsButton
          )}
        >
          <p className={cn(classes.hiddenText, classes.name)}>{name}</p>
        </button>
        {!modeUser && !isPreset && !!menuConfig?.length && (
        <div className={cn(classes.buttonPosition)}>
          <BtnDropDown
            role="button"
            icon="arrow_down"
            menuConfig={(id === Number(params?.pageId) && !isEqualFilters) ? menuConfig : menuConfig?.filter(({ action }) => action !== 'savePageChanges')}
            selectOption={onSelectOption}
            className={cn(
              classes.btnMore,
              (id === Number(params?.pageId) || (params?.pageId === 'all' && id === 'all') || (id === modeSetting)) && classes.btnMore_isChecked
            )}
            iconClass={classes.iconClass}
            selectClass={classes.selectClass}
            color={color}
          />
        </div>
        )}
      </div>
    </div>
  );
};

ChipsButton.propTypes = {
  name: string,
  id: oneOfType([string, number]),
  onClickChips: func,
  modeUser: bool,
  modeSetting: string,
  onOpenModal: func,
  isPreset: bool,
  color: string,
  isEqualFilters: bool,
  menuConfig: arrayOf(shape({ name: string, icon: string, info: bool })),
  order: number
};

export default ChipsButton;
