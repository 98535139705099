import { useState, useEffect } from 'react';
import { func, string, arrayOf, bool, shape } from 'prop-types';
import cn from 'classnames';
import { isFilledArray } from 'utils/common';
import { FilterOption } from 'Components';
import { dataRolePars } from './helpers/helpers';
import classes from './FilterSelectOptions.module.scss';

const FilterSelectOptions = ({
  typeTone = false,
  modeChart = false,
  addItem = () => null,
  removeItem = () => null,
  selectData = [],
  filterType = '',
  className = '',
  optionIconRight = '',
  filterName = '',
  dataFilters = []
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(typeTone ? dataRolePars(dataFilters) : dataFilters);
  }, [dataFilters]);

  const onToggle = (filter, isSelect) => {
    isSelect ? removeItem({ id: filter.id, filterName }) : addItem({
      id: filter.id, name: filter?.name || filter?.title, type: filterType, filterName
    });
  };

  return (
    <div className={cn(className, !modeChart && classes.filterWithOptions)}>
      <ul className={classes.listOptions}>
        {isFilledArray(data) && data.map((option) => {
          const isSelect = selectData?.some(
            selectDataItem => selectDataItem.id === option.id
          );
          return (
            <li key={option.id} className={classes.option}>
              <FilterOption
                text={option?.name || option?.title}
                icon={option.icon}
                checked={isSelect}
                onChange={() => onToggle(option, isSelect)}
                optionIconRight={optionIconRight}
                mode32={modeChart}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FilterSelectOptions.propTypes = {
  selectData: arrayOf(
    shape({
      id: string,
      name: string
    })
  ),
  typeTone: bool,
  dataFilters: arrayOf(
    shape({
      id: string,
      name: string
    })
  ),
  modeChart: bool,
  addItem: func,
  removeItem: func,
  filterType: string,
  filterName: string,
  className: string,
  optionIconRight: string
};

export default FilterSelectOptions;
