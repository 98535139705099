import { Progressbar, Button } from 'Components';
import { string, func, bool, shape, number, oneOfType } from 'prop-types';
import t from 'utils/translateFunc';
import { dataProgress, progressColors } from 'data/persistentData';
import { useGetCardInfo } from 'storage/queryHooks';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyCardCreator.module.scss';

export function BodyCardCreator({ isCard = false, onSubmit = () => null, language = 'uk', info = null, model_key = '' }) {
  const id = {
    source: info?.source_id || info?.source?.id,
    publication_place: info?.publication_place_subscribers_id || null,
    creators: info?.creators[0]?.id || null
  };
  const { data } = useGetCardInfo({ enabled: !!info?.source_id || !!info?.source?.id, model_key, id: id[model_key] });
  const unknown = t('creatorPlaceCard', 'UNDEFINED', language);
  const type = info?.media_type;
  const eventList = ['creators', 'publication_place', 'source'];

  function onHandleSubmit(action) {
    onSubmit({ action, info, body: { type } });
  }
  const details = data?.source || data?.publication_place || data?.creators;
  const isTone = data?.tone && Object.entries(data?.tone).some(el => !!el[1]);
  const progressbarData = isTone ? Object.entries(data?.tone).map(el => ({ value: el[1], color: progressColors[el[0]], text: el[0], link: '#' })) : dataProgress;
  return (
    <div className={classes.creatorBody}>
      <div className={classes.creatorNumberMentions}>
        <div className={classes.creatorInfo}>
          {t('creatorPlaceCard', 'NUMBER_MENTIONS', language)}
          <span><b>{data?.total_count || '0'}</b></span>
        </div>
      </div>
      <Progressbar
        className={classes.creatorProgress}
        language={language}
        progressbarData={progressbarData}
        isPopover
        amplitudeCategory="Article"
        amplitudeEvent="Clicked_Source_News_Count_Button_In_Modal"
      />
      {!(isCard && (type === 'socialnetwork' || type === 'forum')) && (
      <div className={classes.creatorMore}>
        <div className={classes.leftColumn}>
          <div className={classes.creatorInfo}>
            {t('creatorPlaceCard', 'COUNTRY', language)}
            <span>{details?.country || unknown}</span>
          </div>
          {isCard && (
          <div className={classes.creatorInfo}>
            {t('creatorPlaceCard', 'REGION', language)}
            <span>{details?.region || unknown}</span>
          </div>
          )}
          {isCard && (
          <div className={classes.creatorInfo}>
            {t('creatorPlaceCard', 'CITY', language)}
            <span>{details?.city || unknown}</span>
          </div>
          )}
        </div>
        <div className={classes.rightColumn}>
          {isCard && (
          <div className={classes.creatorInfo}>
            {t('creatorPlaceCard', 'LEVEL', language)}
            <span>{details?.level || unknown}</span>
          </div>
          )}
          {isCard && (
          <div className={classes.creatorInfo}>
            {t('creatorPlaceCard', 'SUBJECT', language)}
            <span>{details?.subject || unknown}</span>
          </div>
          )}
          {isCard && type !== 'radio' && (
          <div className={classes.creatorInfo}>
            {t('creatorPlaceCard', 'MEDIA_HOLDING', language)}
            <span>{details?.city || unknown}</span>
          </div>
          )}
          {!isCard && model_key === 'creators' && (
          <div className={classes.creatorInfo}>
            {t('creatorPlaceCard', 'GENDER', language)}
            <span>{details?.gender || unknown}</span>
          </div>
          )}
        </div>
      </div>
      )}
      <div className={classes.buttonBox}>
        <Button
          onClick={() => {
            eventList.includes(model_key) && sendFeedEvent({ category: 'News feed settings', event: `block_source_${model_key}` });
            onHandleSubmit('blockSource');
          }}
          textButton={t('btn', 'BLOCK_SOURCE', language)}
          purpleTransparent32
        />
        <Button
          onClick={() => {
            eventList.includes(model_key) && sendFeedEvent({ category: 'Filters', event: `filter_mentions_${model_key}` });
            onHandleSubmit('filterMentions');
          }}
          textButton={t('btn', 'FILTER_MENTIONS', language)}
          green32
        />
      </div>
    </div>
  );
}

BodyCardCreator.propTypes = {
  language: string,
  onSubmit: func,
  isCard: bool,
  model_key: string,
  info: shape({
    media_type: string,
    country: string,
    region: string,
    city: string,
    age: oneOfType([number, string]),
    gender: string,
    mentions_сount: oneOfType([number, string]) })
};

export default BodyCardCreator;
