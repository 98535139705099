import React from 'react';
import { bool, func, string, number } from 'prop-types';
import cn from 'classnames';
import { Icon } from 'Components';
import { nanoid } from 'nanoid';
import classes from './Checkbox.module.scss';

export function Checkbox({ id = nanoid(), value = '', onChange = () => null, isSelected = false, isSelectedSome = false, size = 16 }) {
  const propsSize = size ? { width: size, height: size } : {};

  return (
    <label htmlFor={id} className={classes.checkboxLabel}>
      <input
        type="checkbox"
        className={classes.visually_hidden}
        onChange={onChange}
        value={value}
        id={id}
      />
      <div
        className={cn(
          classes.checkbox,
          (isSelected || isSelectedSome) && classes.checkbox_selected
        )}
      >
        {isSelected && (<Icon type="checked" {...propsSize} />)}
        {isSelectedSome && (<Icon type="checked_minus" {...propsSize} />)}
      </div>
    </label>
  );
}

Checkbox.propTypes = {
  id: string,
  value: string,
  onChange: func,
  isSelected: bool,
  isSelectedSome: bool,
  size: number
};
