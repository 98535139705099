import { string, shape, bool, func } from 'prop-types';
import { components } from 'react-select';
import { Icons } from 'Components';
import classes from './OptionPart.module.scss';

const OptionPart = ({ isSelected = false, data = { label: '' }, innerProps = { onMouseMove: () => null, onMouseOver: () => null }, ...restProps }) => {
  const { onMouseMove, onMouseOver, ...restInnerProps } = innerProps;
  const newProps = { ...restProps, innerProps: restInnerProps };
  return (
    <components.Option {...newProps} className={classes.optionPart}>
      <p className={classes.optionPart}>
        <span className={classes.optionText} data-name="select-option-part">
          {data.label}
        </span>
        {isSelected && <Icons icon="Icn_Chat" className={classes.icon} />}
      </p>
    </components.Option>
  );
};

OptionPart.propTypes = {
  data: shape({ label: string }),
  isSelected: bool,
  innerProps: shape({ onMouseMove: func, onMouseOver: func })
};

export default OptionPart;
