import { number, string } from 'prop-types';

export function CloseArrow({ width = 6, height = 6, color = 'white' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 6 6" fill="none">
      <path d="M5.86689 0.137657C5.68942 -0.0398179 5.40273 -0.0398179 5.22526 0.137657L3 2.35836L0.774744 0.133106C0.59727 -0.0443686 0.31058 -0.0443686 0.133106 0.133106C-0.0443686 0.31058 -0.0443686 0.59727 0.133106 0.774744L2.35836 3L0.133106 5.22526C-0.0443686 5.40273 -0.0443686 5.68942 0.133106 5.86689C0.31058 6.04437 0.59727 6.04437 0.774744 5.86689L3 3.64164L5.22526 5.86689C5.40273 6.04437 5.68942 6.04437 5.86689 5.86689C6.04437 5.68942 6.04437 5.40273 5.86689 5.22526L3.64164 3L5.86689 0.774744C6.03982 0.60182 6.03982 0.31058 5.86689 0.137657Z" fill={color} />
    </svg>
  );
}

CloseArrow.propTypes = {
  width: number,
  height: number,
  color: string
};
