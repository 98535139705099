import { string, func } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyAddKeywordSuccess.module.scss';

const BodyAddKeywordSuccess = ({ className = '',
  textTitle = '',
  textBody = '',
  onCancel = () => null,
  language = 'uk'
}) => {
  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedEvent({ category: 'Add keyword Basic QB', event: 'Clicked_OK_Button_in_Keyword_Added_Popup' });
    onCancel();
  };
  return (
    <form onSubmit={onSubmit} className={cn(classes.bodySuccessAddModal, className)}>
      <div className={classes.discription}>
        <div className={classes.image} />
        <div className={classes.discription_header}>{ textTitle}</div>
        <div className={classes.discription_body}>{textBody}</div>
      </div>
      <div className={classes.buttonBox}>
        <Button
          type="submit"
          textButton={t('btn', 'GOOD', language)}
          className={classes.buttonGood}
          green32
        />
      </div>
    </form>
  );
};

BodyAddKeywordSuccess.propTypes = { className: string,
  onCancel: func,
  language: string,
  textTitle: string,
  textBody: string

};

export default BodyAddKeywordSuccess;
