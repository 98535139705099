import { string, func, shape, oneOfType } from 'prop-types';
import { useState, useEffect } from 'react';
import { Icon, Loader, TooltipForTruncate } from 'Components';
import cn from 'classnames';
import t from 'utils/translateFunc';
import classes from './LogoUploader.module.scss';

function LogoUploader({ dataLogoUrl = '', logoFile = null, onChange = () => null }) {
  const [loadingErr, setLoadingErr] = useState('');
  const imagePathArr = !logoFile?.name && !!dataLogoUrl?.length ? dataLogoUrl.split('/') : '';
  const lastWord = imagePathArr?.length ? imagePathArr[imagePathArr.length - 1] : '';
  const imageName = logoFile?.name ? logoFile?.name : lastWord;
  const hasImage = logoFile?.name ? URL.createObjectURL(logoFile) : dataLogoUrl;

  useEffect(() => {
    if (loadingErr) {
      setTimeout(() => {
        setLoadingErr('');
      }, 3000);
    }
  }, [loadingErr]);

  const beforeUpload = (file) => {
    if (file?.type && file?.size) {
      const isJpgOrPng = file.type === 'image/jpeg' || file?.type === 'image/png';
      if (!isJpgOrPng) {
        setLoadingErr(t('error', 'ERR_FORMAT_PHOTO'));
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        setLoadingErr(t('error', 'ERR_BIG_PHOTO'));
      }
      return isJpgOrPng && isLt2M;
    }
    return false;
  };

  function onImageChange(event) {
    const file = event?.target?.files[0];
    const goodFile = beforeUpload(file);
    goodFile && onChange(file);
  }

  const clearImage = () => {
    document.getElementById('inputTag').value = '';
    onChange('');
  };

  return (
    <div className={classes.logoWrapper}>
      <div className={classes.title}>{t('reports', 'LOGO')}</div>
      <div className={cn(classes.logo, (hasImage) && classes.logo_image)}>
        <Loader size={56} fetching={false} color="#A7B0C8">
          {hasImage && <img src={hasImage || ''} alt="logo" className={classes.image} />}
        </Loader>
      </div>
      {loadingErr && <div className={classes.loadingErr}>{loadingErr}</div>}
      <div className={classes.photoLabel}>
        <label htmlFor="inputTag">
          <span className={classes.photoLabel_button}>
            {t('btn', 'CHOOSE_FILE')}
            <input type="file" id="inputTag" value="" multiple={false} accept="image/*" onChange={onImageChange} />
          </span>
        </label>
        {!hasImage && (
        <span className={classes.noImageText}>
          {t('reports', 'NO_FILE_SELECTED')}
        </span>
        )}
        {!!imageName && (
        <TooltipForTruncate tooltipContent={imageName} style={{ maxWidth: '320px' }}>
          <span className={classes.noImageText}>
            {imageName}
          </span>
        </TooltipForTruncate>
        )}
        {hasImage && (
        <div
          className={classes.delete}
          onClick={clearImage}
        >
          <Icon type="delete" />
        </div>
        )}
      </div>
    </div>
  );
}

LogoUploader.propTypes = {
  dataLogoUrl: string,
  onChange: func,
  logoFile: oneOfType([string, shape({})])
};
export default LogoUploader;
