import { memo } from 'react';
import { Icon } from 'Components';
import cn from 'classnames';
import { Row, Col, Progress } from 'antd';
import t from 'utils/translateFunc';
import { bool, func, number, shape } from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './AlersStatistics.module.scss';

const AlersStatistics = memo(({
  alertDetailsVisible = false,
  fetchings = { simple: false, negative: false, activity: false },
  counts = {
    alertsSimpleCount: { active: 0, total: 0 },
    alertsNegativeCount: { active: 0, total: 0 },
    alertsActivityCount: { active: 0, total: 0 }
  },
  onEditAlert = () => null
}) => {
  function scrollToBlock(e, elem) {
    !(e?.target instanceof SVGElement) && sendAlertsEvent({ category: 'Alerts', event: `scroll_${elem}` });
    const element = document.getElementById(elem);
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <div className={cn(classes.alersStatistics, alertDetailsVisible && classes.alersStatistics_panelOn)}>
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <div
            className={cn(classes.statisticsCard, classes.statisticsCard_green)}
            onClick={(e) => { scrollToBlock(e, 'alerts-simple-cards'); }}
          >
            <div className={classes.headerText}>
              <Icon
                type="notifications"
                role="icon"
                color="var(--green350metricom)"
                width={24}
                height={24}
              />
              {t('alerts', 'ALERTS')}
              <div
                className={classes.addButton}
                onClick={() => {
                  sendAlertsEvent({ category: 'Add alert', event: 'Clicked_Plus_Button_Add_Alert' });
                  onEditAlert({ alerts_list: 'alerts-simple' });
                }}
              >
                <Icon
                  type="add"
                  role="icon"
                />
              </div>
            </div>
            <div className={classes.progressWrap}>
              <Progress
                percent={fetchings?.simple ? 0 : ((counts?.alertsSimpleCount?.active || 0) / (counts?.alertsSimpleCount?.total || 0)) * 100}
                showInfo={false}
                strokeColor={fetchings?.simple ? '#f6f7fa' : 'var(--green350metricom)'}
              />
              <div className={classes.progressTextWrap}>
                <div>
                  { fetchings?.simple
                    ? <Skeleton baseColor="#f6f7fa" highlightColor="#e0e0f3" className={classes.skeletonCircle} inline />
                    : counts?.alertsSimpleCount?.active}
                  &nbsp;
                  {t('alerts', 'ACTIVE')}
                </div>
                <div>
                  {t('alerts', 'FROM')}
                  &nbsp;
                  { fetchings?.simple
                    ? <Skeleton baseColor="#f6f7fa" highlightColor="#e0e0f3" className={classes.skeletonCircle} inline />
                    : counts?.alertsSimpleCount?.total}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div
            className={cn(classes.statisticsCard, classes.statisticsCard_red)}
            onClick={(e) => { scrollToBlock(e, 'alerts-negative-cards'); }}
          >
            <div className={classes.headerText}>
              <Icon
                type="sad"
                width={24}
                height={24}
                role="icon"
                color="var(--red500)"
              />
              {t('alerts', 'BY_NEGATIVE')}
              <div
                className={classes.addButton}
                onClick={() => {
                  sendAlertsEvent({ category: 'Add alert', event: 'Clicked_Plus_Button_Add_Alert_By_Negative_Button' });
                  onEditAlert({ alerts_list: 'alerts-negative' });
                }}
              >
                <Icon
                  type="add"
                  role="icon"
                />
              </div>
            </div>
            <div className={classes.progressWrap}>
              <Progress
                percent={fetchings?.negative ? 0 : ((counts?.alertsNegativeCount?.active || 0) / (counts?.alertsNegativeCount?.total || 0)) * 100}
                showInfo={false}
                strokeColor={fetchings?.negative ? '#f6f7fa' : 'var(--red500)'}
              />
              <div className={classes.progressTextWrap}>
                <div>
                  { fetchings?.negative
                    ? <Skeleton baseColor="#f6f7fa" highlightColor="#e0e0f3" className={classes.skeletonCircle} inline />
                    : counts?.alertsNegativeCount?.active}
                  &nbsp;
                  {t('alerts', 'ACTIVE')}
                </div>
                <div>
                  {t('alerts', 'FROM')}
                  &nbsp;
                  { fetchings?.negative
                    ? <Skeleton baseColor="#f6f7fa" highlightColor="#e0e0f3" className={classes.skeletonCircle} inline />
                    : counts?.alertsNegativeCount?.total}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div
            className={cn(classes.statisticsCard, classes.statisticsCard_yellow)}
            onClick={(e) => { scrollToBlock(e, 'alerts-activity-cards'); }}
          >
            <div className={classes.headerText}>
              <Icon
                type="flash"
                width={24}
                height={24}
                role="icon"
                color="var(--yellow500)"
              />
              {t('alerts', 'BY_ACTIVITY')}
              <div
                className={classes.addButton}
                onClick={() => {
                  sendAlertsEvent({ category: 'Add alert', event: 'Clicked_Plus_Button_Add_Alert_By_Activity_Button' });
                  onEditAlert({ alerts_list: 'alerts-activity' });
                }}
              >
                <Icon
                  type="add"
                  role="icon"
                />
              </div>
            </div>
            <div className={classes.progressWrap}>
              <Progress
                percent={fetchings?.activity ? 0 : ((counts?.alertsActivityCount?.active || 0) / (counts?.alertsActivityCount?.total || 1)) * 100}
                showInfo={false}
                strokeColor={fetchings?.activity ? '#f6f7fa' : 'var(--yellow500)'}
              />
              <div className={classes.progressTextWrap}>
                <div>
                  { fetchings?.activity
                    ? <Skeleton baseColor="#f6f7fa" highlightColor="#e0e0f3" className={classes.skeletonCircle} inline />
                    : counts?.alertsActivityCount?.active}
                  &nbsp;
                  {t('alerts', 'ACTIVE')}
                </div>
                <div>
                  {t('alerts', 'FROM')}
                  &nbsp;
                  { fetchings?.activity
                    ? <Skeleton baseColor="#f6f7fa" highlightColor="#e0e0f3" className={classes.skeletonCircle} inline />
                    : counts?.alertsActivityCount?.total}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
});

AlersStatistics.propTypes = {
  alertDetailsVisible: bool,
  onEditAlert: func,
  fetchings: shape({
    simple: bool,
    negative: bool,
    activity: bool
  }),
  counts: shape({
    alertsSimpleCount: shape({ active: number, total: number }),
    alertsNegativeCount: shape({ active: number, total: number }),
    alertsActivityCount: shape({ active: number, total: number })
  })
};

export default AlersStatistics;
