import { useState, useEffect } from 'react';
import { oneOfType, func, bool, string } from 'prop-types';
import t from 'utils/translateFunc';
import { TextField } from 'Components';
import isEqual from 'lodash/isEqual';
import classes from '../ReportDetails.module.scss';

const NameComponent = ({ dataName = '', error = false, onChange = () => null }) => {
  const [reportName, setReportName] = useState('');
  const onChangeName = (e) => {
    setReportName(e.target.value);
  };

  useEffect(() => {
    !isEqual(dataName, reportName) && setReportName(dataName);
  }, [dataName]);

  useEffect(() => {
    const handler = setTimeout(() => {
      !isEqual(dataName, reportName) && onChange(reportName || '');
    }, 500);
    return () => { clearTimeout(handler); };
  }, [reportName]);

  return (
    <TextField
      labelText={t('reports', 'REPORT_NAME')}
      value={reportName || ''}
      classNameForInput={classes.inputText}
      onChange={onChangeName}
      placeholder={t('reports', 'ENTER_NAME_REPORT')}
      errorText={error}
    />
  );
};

NameComponent.propTypes = {
  dataName: string,
  error: oneOfType([bool, string]),
  onChange: func
};

export default NameComponent;
