import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alertsSimple: [],
  alertsNegative: [],
  alertsActivity: [],
  alertsSimpleCount: { active: 0, total: 0 },
  alertsNegativeCount: { active: 0, total: 0 },
  alertsActivityCount: { active: 0, total: 0 },
  alertDetails: null,
  alertDetailsVisible: false,
  alertsList: '',
  reports: [],
  reportDetails: null,
  reportDetailsVisible: false,
  reportTemplateVisible: false,
  reportsPortal: null,
  reportTemlate: null,
  temlateMeta: null,
  emptyAlert: {
    editable: true,
    is_enabled: true,
    emails: null,
    telegram_users: [],
    whatsapp_accounts: [],
    name: '',
    query: '',
    query_builder: null,
    keyword: null
  },
  emptyReport: {
    editable: true,
    is_enabled: true,
    emails: null,
    telegram_users: [],
    whatsapp_accounts: [],
    name: '',
    delivery_type: 'email',
    keyword: null
  }
};

const alertsSlice = createSlice({
  name: 'alertsSettings',
  initialState,
  reducers: {
    addAlertsSimple(state, { payload }) {
      if (payload) {
        const cardsList = [{ id: 'keep_hand', type: 'keep_hand' }, { id: 'example_alert', type: 'example_alert' }, { id: 'try', type: 'try' }];
        const alertsList = payload.results?.length ? payload.results : [];
        const alerts = alertsList?.length ? alertsList : [...cardsList, ...alertsList];
        const sortedAlerts = alerts?.length ? [...alerts].sort((a, b) => ((a?.is_enabled === b?.is_enabled) ? 0 : a?.is_enabled ? -1 : 1)) : [state.emptyAlert];
        state.alertsSimple = sortedAlerts;
        state.alertsSimpleCount.active = payload?.count?.active || 0;
        state.alertsSimpleCount.total = payload?.count?.total || 0;
      }
    },
    editAlertsSimple(state, { payload }) {
      state.alertsSimple = payload;
    },
    createAlertSimle(state, { payload }) {
      const alerts = state.alertsSimple.filter(item => !!item?.id && !!item?.last_send_date);
      state.alertsSimple = [payload, ...alerts];
      state.alertsSimpleCount.active += 1;
      state.alertsSimpleCount.total += 1;
    },
    editAlertSimple(state, { payload }) {
      const editedElert = state.alertsSimple.find(alert => alert?.id === payload?.id);
      if (editedElert && payload) {
        !payload?.is_enabled && editedElert?.is_enabled && (state.alertsSimpleCount.active -= 1);
        payload?.is_enabled && !editedElert?.is_enabled && (state.alertsSimpleCount.active += 1);
        state.alertDetails?.id === payload?.id && (state.alertDetails.is_enabled = payload.is_enabled);
      }

      const alerts = state.alertsSimple.map(alert => (alert?.id === payload?.id ? payload : alert));
      const sortedAlerts = [...alerts].sort((a, b) => ((a?.is_enabled === b?.is_enabled) ? 0 : a?.is_enabled ? -1 : 1));
      state.alertsSimple = sortedAlerts;
    },
    deleteAlertSimple(state, { payload }) {
      const deletedAlert = state.alertsSimple.find(alert => alert?.id === payload?.id);
      if (deletedAlert && payload) {
        state.alertsSimple = state.alertsSimple.filter(alert => alert?.id !== payload?.id);
        deletedAlert?.is_enabled && (state.alertsSimpleCount.active -= 1);
        state.alertsSimpleCount.total -= 1;
      }
    },
    addAlertsNegative(state, { payload }) {
      if (payload) {
        const cardsList = [{ id: 'keep_hand', type: 'keep_hand' }, { id: 'can_search', type: 'can_search' }, { id: 'report_issue', type: 'report_issue' }];
        const alertsList = payload.results?.length ? payload.results : [];
        const alerts = alertsList?.length ? alertsList : [...cardsList, ...alertsList];
        const sortedAlerts = alerts?.length ? [...alerts].sort((a, b) => ((a?.is_enabled === b?.is_enabled) ? 0 : a?.is_enabled ? -1 : 1)) : [state.emptyAlert];
        state.alertsNegative = sortedAlerts;
        state.alertsNegativeCount.active = payload?.count?.active || 0;
        state.alertsNegativeCount.total = payload?.count?.total || 0;
      }
    },
    editAlertsNegative(state, { payload }) {
      state.alertsNegative = payload;
    },
    createAlertNegative(state, { payload }) {
      const alerts = state.alertsNegative.filter(item => !!item?.id && !!item?.last_send_date);
      state.alertsNegative = [payload, ...alerts];
      state.alertsNegativeCount.active += 1;
      state.alertsNegativeCount.total += 1;
    },
    editAlertNegative(state, { payload }) {
      const editedElert = state.alertsNegative.find(alert => alert?.id === payload?.id);
      if (editedElert && payload) {
        !payload?.is_enabled && editedElert?.is_enabled && (state.alertsNegativeCount.active -= 1);
        payload?.is_enabled && !editedElert?.is_enabled && (state.alertsNegativeCount.active += 1);
        state.alertDetails?.id === payload?.id && (state.alertDetails.is_enabled = payload.is_enabled);
      }
      const alerts = state.alertsNegative.map(alert => (alert?.id === payload?.id ? payload : alert));
      const sortedAlerts = [...alerts].sort((a, b) => ((a?.is_enabled === b?.is_enabled) ? 0 : a?.is_enabled ? -1 : 1));
      state.alertsNegative = sortedAlerts;
    },
    deleteAlertNegative(state, { payload }) {
      const deletedAlert = state.alertsNegative.find(alert => alert?.id === payload?.id);
      if (deletedAlert && payload) {
        state.alertsNegative = state.alertsNegative.filter(alert => alert?.id !== payload?.id);
        deletedAlert?.is_enabled && (state.alertsNegativeCount.active -= 1);
        state.alertsNegativeCount.total -= 1;
      }
    },
    addAlertsActivity(state, { payload }) {
      if (payload) {
        const cardsList = [{ id: 'surge_activity', type: 'surge_activity' }, { id: 'involvement', type: 'involvement' }, { id: 'other_channel', type: 'other_channel' }];
        const alertsList = payload.results?.length ? payload.results : [];
        const alerts = alertsList?.length ? alertsList : [...cardsList, ...alertsList];
        const sortedAlerts = alerts?.length ? [...alerts].sort((a, b) => ((a?.is_enabled === b?.is_enabled) ? 0 : a?.is_enabled ? -1 : 1)) : [state.emptyAlert];
        state.alertsActivity = sortedAlerts;
        state.alertsActivityCount.active = payload?.count?.active || 0;
        state.alertsActivityCount.total = payload?.count?.total || 0;
      }
    },
    editAlertsActivity(state, { payload }) {
      state.alertsActivity = payload;
    },
    createAlertActivity(state, { payload }) {
      const alerts = state.alertsActivity.filter(item => !!item?.id && !!item?.last_send_date);
      state.alertsActivity = [payload, ...alerts];
      state.alertsActivityCount.active += 1;
      state.alertsActivityCount.total += 1;
    },
    editAlertActivity(state, { payload }) {
      const editedElert = state.alertsActivity.find(alert => alert?.id === payload?.id);
      if (editedElert && payload) {
        !payload?.is_enabled && editedElert?.is_enabled && (state.alertsActivityCount.active -= 1);
        payload?.is_enabled && !editedElert?.is_enabled && (state.alertsActivityCount.active += 1);
        state.alertDetails?.id === payload?.id && (state.alertDetails.is_enabled = payload.is_enabled);
      }
      const alerts = state.alertsActivity.map(alert => (alert?.id === payload?.id ? payload : alert));
      const sortedAlerts = [...alerts].sort((a, b) => ((a?.is_enabled === b?.is_enabled) ? 0 : a?.is_enabled ? -1 : 1));
      state.alertsActivity = sortedAlerts;
    },
    deleteAlertActivity(state, { payload }) {
      const deletedAlert = state.alertsActivity.find(alert => alert?.id === payload?.id);
      if (deletedAlert && payload) {
        state.alertsActivity = state.alertsActivity.filter(alert => alert?.id !== payload?.id);
        deletedAlert?.is_enabled && (state.alertsActivityCount.active -= 1);
        state.alertsActivityCount.total -= 1;
      }
    },
    hideAlertCard(state, { payload }) {
      if (payload?.alertsList === 'alerts-simple') {
        const cards = ['keep_hand', 'example_alert', 'try'];
        const filteredAlert = state.alertsSimple.filter(item => item?.id !== payload?.id && cards.includes(payload.name));
        state.alertsSimple = filteredAlert?.length ? filteredAlert : [state.emptyAlert];
      }
      if (payload?.alertsList === 'alerts-negative') {
        const cards = ['keep_hand', 'can_search', 'report_issue'];
        const filteredAlert = state.alertsNegative.filter(item => item?.id !== payload?.id && cards.includes(payload?.name));
        state.alertsNegative = filteredAlert?.length ? filteredAlert : [state.emptyAlert];
      }
      if (payload?.alertsList === 'alerts-activity') {
        const cards = ['surge_activity', 'involvement', 'other_channel'];
        const filteredAlert = state.alertsActivity.filter(item => item?.id !== payload?.id && cards.includes(payload?.name));
        state.alertsActivity = filteredAlert?.length ? filteredAlert : [state.emptyAlert];
      }
    },

    editAlertDetails(state, { payload }) {
      state.alertDetails = payload;
      if (payload && state.alertsList === 'alerts-simple') {
        !payload?.id && (state.alertsSimple[0] = payload);
      }
      if (payload && state.alertsList === 'alerts-negative') {
        !payload?.id && (state.alertsNegative[0] = payload);
      }
      if (payload && state.alertsList === 'alerts-activity') {
        !payload?.id && (state.alertsActivity[0] = payload);
      }
    },
    openAlertDetails(state, { payload }) {
      state.alertDetails = payload.alertDetails;
      state.alertsList = payload.alertsList;
      state.alertDetailsVisible = true;
    },
    closeAlertDetails(state) {
      state.alertDetails = null;
      state.alertsList = '';
      state.alertDetailsVisible = false;
    },
    addReports(state, { payload }) {
      if (payload) {
        const cardsList = [{ id: 'example', type: 'example' }, { id: 'set_report', type: 'set_report' }, { id: 'try', type: 'try' }, { id: 'performace', type: 'performace' },
          { id: 'full_image', type: 'full_image' }, { id: 'other_channel', type: 'other_channel' }];
        const reportsList = payload.results?.length ? payload.results : [];
        const reports = reportsList?.length ? reportsList : [...cardsList, ...reportsList];
        const sortedReports = reports?.length ? [...reports].sort((a, b) => ((a?.is_enabled === b?.is_enabled) ? 0 : a?.is_enabled ? -1 : 1)) : [state.emptyReport];
        state.reports = sortedReports;
      }
    },
    editReports(state, { payload }) {
      state.reports = payload;
    },
    createReport(state, { payload }) {
      const reports = state.reports.filter(item => !!item?.id && !!item?.report_type);
      state.reports = [payload, ...reports];
    },
    editReport(state, { payload }) {
      const editedReport = state.reports.find(report => report?.id === payload?.id);
      if (editedReport && payload) {
        state.reportDetails?.id === payload?.id && (state.reportDetails.is_enabled = payload.is_enabled);
      }
      const reports = state.reports.map(report => (report?.id === payload?.id ? payload : report));
      const sortedReports = [...reports].sort((a, b) => ((a?.is_enabled === b?.is_enabled) ? 0 : a?.is_enabled ? -1 : 1));
      state.reports = sortedReports;
    },
    deleteReport(state, { payload }) {
      const deletedReport = state.reports.find(report => report?.id === payload?.id);
      if (deletedReport && payload) {
        state.reports = state.reports.filter(report => report?.id !== payload?.id);
      }
      const cards = ['keep_hand', 'example_alert', 'try'];
      if (cards.includes(deletedReport?.name)) {
        state.reports = state.reports.filter(report => report?.name !== deletedReport?.name);
      }
    },
    closeReportDetails(state) {
      state.reportDetails = null;
      state.reportDetailsVisible = false;
    },
    closeReportTemplate(state) {
      state.reportTemlate = null;
      state.reportTemplateVisible = false;
    },
    openReportTemplate(state, { payload }) {
      state.reportTemlate = payload || null;
      state.reportTemplateVisible = true;
    },
    openReportDetails(state, { payload }) {
      state.reportDetails = payload.reportDetails;
      state.reportDetailsVisible = true;
    },
    editReportDetails(state, { payload }) {
      if (state.reportDetails && payload) {
        state.reportDetails = { ...state.reportDetails, ...payload };
      }
      if (state.reportDetails && !payload) {
        state.reportDetails = null;
      }
      if (!state.reportDetails && payload) {
        state.reportDetails = payload;
        !payload?.id && (state.reports[0] = payload);
      }
    },
    hideReportCard(state, { payload }) {
      const cards = ['keep_hand', 'example_alert', 'try'];
      const filteredReport = state.reports.filter(item => item?.id !== payload?.id && cards.includes(payload.name));
      state.reports = filteredReport?.length ? filteredReport : [state.emptyReport];
    },
    changeReportsPortal(state, { payload }) {
      state.reportsPortal = payload;
    },
    changeReportTemlate(state, { payload }) {
      if (state.reportTemlate && payload) {
        state.reportTemlate = {
          ...state.reportTemlate,
          ...payload,
          ...(payload?.portal?.id ? { portal: payload?.portal?.id } : {})
        };
      }
      if (state.reportTemlate && !payload) {
        state.reportTemlate = null;
      }
      if (!state.reportTemlate && payload) {
        state.reportTemlate = { ...payload, ...(payload?.portal?.id ? { portal: payload?.portal?.id } : {}) };
      }
    },
    addTemlateMeta(state, { payload }) {
      state.temlateMeta = payload;
    },
    addTemlateDashboardCharts(state, { payload }) {
      state.temlateDashboardCharts = payload;
    }
  }
});

export const { addAlertsSimple, editAlertsSimple, createAlertSimle, editAlertSimple, deleteAlertSimple, addAlertsNegative,
  editAlertsNegative, createAlertNegative, editAlertNegative, deleteAlertNegative, addAlertsActivity, editAlertsActivity,
  createAlertActivity, editAlertActivity, deleteAlertActivity, editAlertDetails, openAlertDetails, closeAlertDetails, hideAlertCard,
  addReports, editReports, createReport, editReport, deleteReport, closeReportDetails, openReportDetails, editReportDetails, hideReportCard,
  changeReportsPortal, changeReportTemlate, addTemlateMeta, addTemlateDashboardCharts, closeReportTemplate, openReportTemplate
} = alertsSlice.actions;
export default alertsSlice.reducer;
