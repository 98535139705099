import { string, number } from 'prop-types';

export function Pdf({ width = 16, height = 16, color = '#DB1211', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none">
      <mask id="mask0_2212_9259" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2212_9259)">
        <path d="M3.33333 14.0003C2.96667 14.0003 2.65278 13.8698 2.39167 13.6087C2.13056 13.3475 2 13.0337 2 12.667V3.33366C2 2.96699 2.13056 2.6531 2.39167 2.39199C2.65278 2.13088 2.96667 2.00033 3.33333 2.00033H6.13333C6.27778 1.60033 6.51944 1.2781 6.85833 1.03366C7.19722 0.789214 7.57778 0.666992 8 0.666992C8.42222 0.666992 8.80278 0.789214 9.14167 1.03366C9.48056 1.2781 9.72222 1.60033 9.86667 2.00033H12.6667C13.0333 2.00033 13.3472 2.13088 13.6083 2.39199C13.8694 2.6531 14 2.96699 14 3.33366V12.667C14 13.0337 13.8694 13.3475 13.6083 13.6087C13.3472 13.8698 13.0333 14.0003 12.6667 14.0003H3.33333ZM4.66667 11.3337H9.33333V10.0003H4.66667V11.3337ZM4.66667 8.66699H11.3333V7.33366H4.66667V8.66699ZM4.66667 6.00033H11.3333V4.66699H4.66667V6.00033ZM8 2.83366C8.14444 2.83366 8.26389 2.78644 8.35833 2.69199C8.45278 2.59755 8.5 2.4781 8.5 2.33366C8.5 2.18921 8.45278 2.06977 8.35833 1.97533C8.26389 1.88088 8.14444 1.83366 8 1.83366C7.85556 1.83366 7.73611 1.88088 7.64167 1.97533C7.54722 2.06977 7.5 2.18921 7.5 2.33366C7.5 2.4781 7.54722 2.59755 7.64167 2.69199C7.73611 2.78644 7.85556 2.83366 8 2.83366Z" fill={color || '#DB1211'} />
      </g>
    </svg>
  );
}

Pdf.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
