import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { PageNotFound, PrivateRoute, NotAccess, NotAvailable, Maintenance } from 'Components';
import { Mentions, Layout, UserProfile, Auth, FeedSettings, AdvancedBuilder, Alerts, Downloads, Search } from 'Containers';
import * as Sentry from '@sentry/react';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function RoutesWithAnimation() {
  const portal = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return (
    <AnimatePresence>
      <SentryRoutes>
        <Route path="/redirect_login" element={<Auth />} />
        <Route path="/" element={<PrivateRoute component={Layout} />}>
          <Route path="updating" element={<PrivateRoute component={Maintenance} />} />
          <Route path="not_available" element={<PrivateRoute component={NotAvailable} />} />
          <Route index element={<Navigate to={`${portal}/mentions/all`} replace />} />
          <Route path=":portal" element={<Navigate to="mentions/all" replace />} />
          <Route path=":portal/mentions/" element={<PrivateRoute component={Mentions} />}>
            <Route path=":pageId" element={<PrivateRoute component={Mentions} />}>
              <Route path=":articleId" element={<PrivateRoute component={Mentions} />} />
            </Route>
          </Route>
          <Route path="*" element={<PrivateRoute component={PageNotFound} />} />
          <Route path="/not_access" element={<PrivateRoute component={NotAccess} />} />
          <Route path="/userprofile" element={<PrivateRoute component={UserProfile} />} />
          <Route path="/feedsettings" element={<PrivateRoute component={FeedSettings} />} />
          <Route path="/advanced_builder/:filterName/" element={<PrivateRoute component={AdvancedBuilder} />}>
            <Route path=":filterId" element={<PrivateRoute component={AdvancedBuilder} />} />
          </Route>
          <Route path="/search" element={<PrivateRoute component={Search} />} />
          <Route path="/alerts/" element={<PrivateRoute component={Alerts} />} />
          <Route path="/downloads/" element={<PrivateRoute component={Downloads} />} />
        </Route>
        <Route path="*" element={<PrivateRoute component={PageNotFound} />} />
      </SentryRoutes>
    </AnimatePresence>
  );
}
