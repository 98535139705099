import t from 'utils/translateFunc';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'Components';
import { clearFilterList } from 'storage/actions';
import cn from 'classnames';
import { func, bool } from 'prop-types';
import { useEditPeriod } from 'storage/queryHooks';
import moment from 'moment';
import classes from './NoMentions.module.scss';

const NoMentions = ({ isError = false, onReloadNews = () => null }) => {
  const language = useSelector(state => state?.userInfo?.language);
  const filterList = useSelector(state => state?.filters?.filterList);
  const dispatch = useDispatch();
  const date = {
    start: moment(new Date())?.startOf('day')?.subtract(1, 'week')?.format('YYYY-MM-DD HH:mm'),
    end: moment(new Date())?.endOf('minute')?.format('YYYY-MM-DD HH:mm'),
    by_created: false
  };
  const { mutateAsync: editPeriod } = useEditPeriod();
  return (
    <div className={classes.notFound}>
      <div className={classes.notFound_textError}>
        {t('notAvalailable', 'SORRY', language)}
      </div>
      <div className={classes.notFound_image} />
      { !isError ? (
        <div className={classes.notFound_text}>
          {t('notFound', 'NO_MENTIONS', language)}
        </div>
      ) : (
        <>
          <div className={classes.notFound_textError}>
            {t('notAvalailable', 'WE_KNOW', language)}
          </div>
          <div className={classes.notFound_textError_subtitle}>
            {t('notAvalailable', 'WE_WORK', language)}
          </div>
        </>
      )}
      <div>
        {isError && (
        <Button
          onClick={onReloadNews}
          textButton={t('btn', 'TRY_AGAIN', language)}
          className={cn(classes.notFound_btn, classes.tryAgain)}
          green32
        />
        )}
        {!filterList?.length && isError && (
        <Button
          onClick={() => { isError && editPeriod(date); }}
          textButton={t('btn', 'RESET_PERIOD', language)}
          className={classes.notFound_btn}
          purpleTransparent32
        />
        )}
        {!!filterList?.length && (
        <Button
          onClick={() => {
            isError && editPeriod(date);
            dispatch(clearFilterList());
          }}
          textButton={t('btn', 'CLEAR_FILTER', language)}
          className={classes.notFound_btn}
          purpleTransparent32
        />
        )}
      </div>

    </div>
  );
};

NoMentions.propTypes = {
  isError: bool,
  onReloadNews: func
};

export default NoMentions;
