import { api } from 'api/apiInstance';
import { tones, genders, roles, postTypes, mediaTypes, creatorsAge, audience, context } from 'data/persistentData';
import { checkQueryParams } from 'utils/common';
import t from 'utils/translateFunc';

export const getKeywords = async ({ portal_slug }) => {
  const response = await api.get(`keyword/${portal_slug}/`);
  return response?.data;
};

export const getKeyword = async ({ portal_slug, keyword_id }) => {
  const response = await api.get(`keyword/${portal_slug}/${keyword_id}/`);
  return response?.data;
};

export const addKeyword = async ({ portal_slug, body }) => {
  const response = await api.post(`keyword/${portal_slug}/`, body);
  return response?.data;
};

export const editKeyword = async ({ portal_slug, keyword_id, body }) => {
  const response = await api.patch(`keyword/${portal_slug}/${keyword_id}/`, body);
  return response?.data;
};

export const deleteKeyword = async ({ portal_slug, keyword_id }) => {
  const response = await api.delete(`keyword/${portal_slug}/${keyword_id}/`);
  return response?.data;
};

export const getMediaTypes = ({ language }) => Promise.resolve(mediaTypes(language));

export const getTones = ({ language }) => Promise.resolve(tones(language));

export const getTag = async ({ portal_slug, tag_id }) => {
  const response = await api.get(`filter/portals/${portal_slug}/tags/${tag_id}/`);
  return response?.data;
};

export const getTags = async ({ portal_slug }) => {
  const response = await api.get(`filter/portals/${portal_slug}/tags/`);
  return response?.data;
};

export const addTag = async ({ portal_slug, body }) => {
  const response = await api.post(`filter/portals/${portal_slug}/tags/`, body);
  return response?.data;
};

export const editTag = async ({ portal_slug, body }) => {
  const response = await api.put(`filter/portals/${portal_slug}/tags/${body?.id}/`, body);
  return response?.data;
};

export const removeTag = async ({ portal_slug, tag_id }) => {
  const response = await api.delete(`filter/portals/${portal_slug}/tags/${tag_id}/`);
  return response?.data;
};

export const getPostTypes = ({ language }) => Promise.resolve(postTypes(language));

export const getCountries = async ({ query, id }) => {
  const queryString = checkQueryParams(query);
  const countryId = id ? `${id}/` : '';
  const response = await api.get(`filter/country/${countryId}${queryString}`);
  return response?.data;
};

export const getCountry = async ({ query, language }) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`filter/country/${queryString}`);
  return response?.data && [...response.data, { id: null, value: 'not_defined', label: t('nodata', 'UNDEFINED', language) }];
};

export const getPreviewCountries = async ({ query, portal_slug }) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`test-country/${portal_slug}/${queryString}`);
  return response?.data;
};

export const getRegions = async ({ query, id }) => {
  const queryString = checkQueryParams(query);
  const regionId = id ? `${id}/` : '';
  const response = await api.get(`filter/region/${regionId}${queryString}`);
  return response?.data;
};

export const getCities = async ({ query, id }) => {
  const queryString = checkQueryParams(query);
  const cityId = id ? `${id}/` : '';
  const response = await api.get(`filter/city/${cityId}${queryString}`);
  return response?.data;
};

export const getLevelSources = async () => {
  const response = await api.get('filter/levels/');
  return response?.data;
};

export const getSubjectSources = async () => {
  const response = await api.get('filter/subjects/');
  return response?.data;
};

export const getSources = async ({ query, id }) => {
  const queryString = checkQueryParams(query);
  const sourceId = id ? `${id}/` : '';
  const response = await api.get(`filter/source/${sourceId}${queryString}`);
  return response?.data;
};

export const addSource = async (body) => {
  const response = await api.post('source-issues/', body);
  return response?.data;
};

export const getPublicationPlaces = async (query) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`filter/publication-place/${queryString}`);
  return response?.data;
};

export const getCreators = async ({ query, id }) => {
  const queryString = checkQueryParams(query);
  const creatorId = id ? `${id}/` : '';
  const response = await api.get(`filter/creators/${creatorId}${queryString}`);
  return response?.data;
};
export const getLanguages = async ({ query, id }) => {
  const queryString = checkQueryParams(query);
  const langId = id ? `${id}/` : '';
  const response = await api.get(`filter/languages/${langId}${queryString}`);
  return response?.data;
};

export const getCharacters = ({ language }) => Promise.resolve(roles(language));

export const getContexts = () => Promise.resolve(context);

export const getPopularWords = async ({ portal_slug, params }) => {
  const queryString = checkQueryParams(params);
  const response = await api.get(`portals/${portal_slug}/words-cloud/facets/${queryString}`);
  return response?.data;
};

export const getPopularLinks = async ({ portal_slug, params }) => {
  const queryString = checkQueryParams(params);
  const response = await api.get(`portals/${portal_slug}/links-cloud/facets/${queryString}`);
  return response?.data;
};

export const getPopularHashtags = async ({ portal_slug, params }) => {
  const queryString = checkQueryParams(params);
  const response = await api.get(`portals/${portal_slug}/hashtags-cloud/facets/${queryString}`);
  return response?.data;
};

export const getAudiences = () => Promise.resolve(audience);

export const getCreatorsAge = () => Promise.resolve(creatorsAge);

export const getCreatorsGender = ({ language }) => Promise.resolve(genders(language));

export const getSettings = async ({ portal_slug }) => {
  const response = await api.get(`settings/${portal_slug}/`);
  return response?.data;
};

export const createSettings = async ({ portal_slug, body }) => {
  const response = await api.post(`settings/${portal_slug}/`, body);
  return response?.data;
};

export const changeSettings = async ({ id, body, portal_slug }) => {
  const response = await api.patch(`settings/${portal_slug}/${id}/`, body);
  return response?.data;
};

export const reportManager = async ({ portal_slug, body }) => {
  const response = await api.post(`notify-staff/${portal_slug}/`, body);
  return response?.data;
};
