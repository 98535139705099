import { string, number } from 'prop-types';

export function Forum({ width = 14, height = 14, color = '#2A206A', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '14'} height={height || '14'} viewBox="0 0 14 14" fill="none">
      <svg className={className} width={width || 14} height={height || 14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.68664 11.2073C3.46853 11.2073 3.28758 11.1346 3.14381 10.9892C3.00014 10.8438 2.92831 10.6637 2.92831 10.4488V9.02382H11.7105V2.91632H13.1355C13.3504 2.91632 13.5305 2.98898 13.6758 3.13432C13.8211 3.27965 13.8938 3.45976 13.8938 3.67465V13.9098L11.1913 11.2073H3.68664ZM0.10614 10.417V0.848482C0.10614 0.633705 0.178807 0.453594 0.32414 0.308149C0.469473 0.162816 0.649585 0.0901489 0.864473 0.0901489H9.61881C9.8337 0.0901489 10.0138 0.162816 10.1591 0.308149C10.3045 0.453594 10.3771 0.633705 10.3771 0.848482V6.93215C10.3771 7.14704 10.3045 7.32715 10.1591 7.47248C10.0138 7.61782 9.8337 7.69048 9.61881 7.69048H2.83664L0.10614 10.417ZM8.86047 6.17382V1.60682H1.62281V6.17382H8.86047Z" fill={color || '#2A206A'} />
      </svg>
    </svg>
  );
}

Forum.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
