import { useRef, memo } from 'react';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Icon } from 'Components';
import { bool, func } from 'prop-types';
import classes from './SetReportCard.module.scss';

const SetReportCard = memo(({ isBlur = false, onClick = () => null }) => {
  const cardRef = useRef();
  const data = {
    title: t('reports', 'CUSTOMIZE_REPORT'),
    template: t('reports', 'PERSONALIZED_NEEDS'),
    frequency: t('reports', 'EVERY_DAY'),
    time_list: t('reports', 'CERTAIN_DAYS'),
    emails: ['support@metricom']
  };

  return (
    <div
      className={cn(classes.reportsCard, isBlur && classes.reportsCard_blur)}
      ref={cardRef}
      onClick={onClick}
    >
      <div className={classes.reportBody}>
        <div className={classes.textTitle}>
          {data?.title}
        </div>
        <div className={classes.textSmallSubTitle}>
          {t('reports', 'CHOOSE_FORMAT')}
        </div>
        <div className={classes.formatLangWrapper}>
          <div className={classes.formatType}>
            <Icon type="xls" />
            <span className={cn(classes.formatTypeLabel, classes.formatTypeLabel_xls)}>
              XLS
            </span>
          </div>
          <div className={classes.formatType}>
            <Icon type="docx" />
            <span className={cn(classes.formatTypeLabel, classes.formatTypeLabel_docx)}>
              DOCX
            </span>
          </div>
          <div className={classes.formatType}>
            <Icon type="pdf" />
            <span className={cn(classes.formatTypeLabel, classes.formatTypeLabel_pdf)}>
              PDF
            </span>
          </div>
          <div className={classes.formatType}>
            <Icon type="html" />
            <span className={cn(classes.formatTypeLabel, classes.formatTypeLabel_html)}>
              HTML
            </span>
          </div>
        </div>
        <div className={classes.textSmallSubTitle}>
          {t('reports', 'LANGUAGE_REPORT')}
        </div>
        <div className={classes.formatLangWrapper}>
          <div className={cn(classes.language, classes.language_ua)}>
            UA
          </div>
          <div className={cn(classes.language, classes.language_eng)}>
            ENG
          </div>
          <div className={cn(classes.language, classes.language_ru)}>
            RU
          </div>
        </div>
      </div>
    </div>
  );
});

SetReportCard.propTypes = {
  isBlur: bool,
  onClick: func
};

export default SetReportCard;
