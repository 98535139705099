import React from 'react';
import { string, node, number, shape, oneOfType } from 'prop-types';
import { Typography } from 'antd';

const { Paragraph } = Typography;
function TooltipForTruncate({ style = {}, rows = 1, children = '', tooltipContent = '' }) {
  return (
    <Paragraph
      style={{ margin: 0, cursor: 'pointer', ...style }}
      ellipsis={{
        rows: rows || 1,
        tooltip: {
          title: tooltipContent || '',
          placement: 'bottom',
          format: 'text/html'
        } }}
    >
      {children || ''}
    </Paragraph>
  );
}

TooltipForTruncate.propTypes = {
  rows: number,
  tooltipContent: oneOfType([node, string]),
  style: shape({}),
  children: node
};

export default TooltipForTruncate;
