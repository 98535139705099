import React from 'react';
import { string, array, node, oneOfType, bool, func } from 'prop-types';
import { RadioButton } from 'Components';
import cn from 'classnames';
import classes from './RadioButtonGroup.module.scss';

function RadioButtonGroup({
  groupName = '',
  defaultValue = '',
  className = '',
  options = [],
  children = '',
  onChange = () => null
}) {
  return (
    <fieldset className={cn(classes.radioGroupWrapp, className)}>
      {!!groupName && <legend className={classes.label}>{groupName}</legend>}
      {!!options?.length && (
        <div className={cn(classes.optionsWrapp)}>
          { options.map(({ label, value, disabled }) => (
            <RadioButton
              value={value}
              label={label}
              key={label}
              name={groupName}
              disabled={disabled}
              checked={value === defaultValue}
              onChange={onChange}
              small
            />
          ))}
        </div>
      )}
      {!!children && children}
    </fieldset>
  );
}

RadioButtonGroup.propTypes = {
  groupName: string,
  defaultValue: string,
  options: array,
  children: oneOfType([bool, string, node]),
  onChange: func,
  className: string
};

export default RadioButtonGroup;
