import { useState, useEffect } from 'react';
import cn from 'classnames';
import { string, func, bool, shape, arrayOf } from 'prop-types';
import t from 'utils/translateFunc';
import classes from './FilterInterval.module.scss';

function FilterInterval({
  className = '',
  dataFilters = [],
  addItem = () => null,
  removeItem = () => null,
  filterList = [],
  language = 'uk',
  age = false,
  filterTypeTo = '',
  filterTypeFrom = '',
  filterNameFrom = '',
  filterNameTo = ''
}) {
  const idFrom = dataFilters[0]?.id;
  const idTo = dataFilters[1]?.id;
  const textFrom = t('filterAudience', 'FROM', language);
  const textTo = t('filterAudience', 'TO', language);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  useEffect(() => {
    if (from && !isFiltersInclude(idFrom)) {
      setFrom('');
    }
    if (to && !isFiltersInclude(idTo)) {
      setTo('');
    }
  }, [filterList?.length]);

  function isFiltersInclude(id) {
    return filterList?.some(filter => filter?.id === id);
  }

  function onChangeFrom({ target }) {
    if (!age) {
      setFrom(target?.value);
    }
    if (age && target.value.length < 3) {
      setFrom(target?.value);
    }
  }

  function onChangeTo({ target }) {
    if (!age) {
      setTo(target?.value);
    }
    if (age && target?.value.length < 3) {
      setTo(target?.value);
    }
  }

  function onBlurFrom() {
    if (from) {
      addItem({ id: idFrom, name: from, type: filterTypeFrom, filterName: filterNameFrom });
    } else {
      removeItem({ id: idFrom, filterName: filterNameFrom });
    }
    if (+to && +from && +from > +to) {
      setTo(+from + 1);
      addItem({ id: idTo, name: +from + 1, type: filterTypeTo, filterName: filterNameTo });
    }
  }

  function onBlurTo() {
    if (to) {
      addItem({ id: idTo, name: to, type: filterTypeTo, filterName: filterNameTo });
    } else {
      removeItem({ id: idTo, filterName: filterNameTo });
    }
    if (+to && +from && +from > +to) {
      setFrom(+to - 1);
      addItem({ id: idFrom, name: +to - 1, type: filterTypeFrom, filterName: filterNameFrom });
    }
  }

  return (
    <div className={cn(className, classes.filterIntervalWrapper, age && classes.filterIntervalWrapper_inserted)}>
      <input
        value={from}
        className={cn(classes.intervalInput, age && classes.intervalInput_inserted)}
        onChange={onChangeFrom}
        onBlur={onBlurFrom}
        type="number"
        placeholder={textFrom}
      />
      <span>&mdash;</span>
      <input
        value={to}
        className={cn(classes.intervalInput, age && classes.intervalInput_inserted)}
        onChange={onChangeTo}
        onBlur={onBlurTo}
        type="number"
        placeholder={textTo}
      />
    </div>
  );
}

FilterInterval.propTypes = {
  filterTypeTo: string,
  filterTypeFrom: string,
  filterNameFrom: string,
  filterNameTo: string,
  className: string,
  dataFilters: arrayOf(shape({})),
  addItem: func,
  removeItem: func,
  filterList: arrayOf(shape({})),
  language: string,
  age: bool
};

export default FilterInterval;
