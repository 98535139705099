import { useState, useRef, memo } from 'react';
import { sourcePopoversMenu } from 'data/dropDownConfig';
import t from 'utils/translateFunc';
import { PopoverWindow, BodyMenu, Icon, IconSVG } from 'Components';
import cn from 'classnames';
import { string, func, shape } from 'prop-types';
import { sendFeedEvent } from 'services/amplitude/events';
import { getSourceColors } from 'data/colorsConfig';
import { SOCIAL_NETWORKS } from 'data/sourceConfig';
import classes from './SourcePopover.module.scss';

const SourcePopover = memo(({ onSelect = () => null, info = null, language = 'uk', idBind = '' }) => {
  const bodyRef = useRef();
  const [visible, setVisible] = useState(false);
  const popoversMenuConfig = sourcePopoversMenu.map(el => ({ ...el, name: t('sourcePopover', el?.name, language) }));
  const sourceName = info.source?.name || info.source;
  function afterChange(value) {
    value && !visible && setVisible(true);
    !value && visible && setTimeout(() => { setVisible(false); }, 350);
  }
  function onClose() {
    visible && bodyRef?.current?.onClosePopover();
    visible && setTimeout(() => { setVisible(false); }, 350);
  }
  function handleSubmit({ action }) {
    onSelect({ action, info });
    onClose();
  }
  const iconType = info.media_type === 'socialnetwork' && SOCIAL_NETWORKS.includes(sourceName) ? sourceName : info.media_type;
  const { background, text, backgroundActive } = getSourceColors(iconType);

  return (
    <div className={classes.sourceWrapper}>
      <IconSVG type={iconType} iconWrapClass={classes.iconWrap} />
      <div className={classes.sourcePopoverWrapper}>
        <a
          href={info?.internetarticle?.url || info?.socialnetworkarticle?.url || info?.forumarticle?.url || info?.agencyarticle?.url || info?.url}
          onClick={() => {
            sendFeedEvent({ category: 'Article', event: 'Clicked_Source_Link_In_Newsfeed' });
          }}
          target="_blank"
          className={classes.link}
          style={{
            backgroundColor: background,
            color: text,
            '--background-active': backgroundActive
          }}
          rel="noopener noreferrer"
        >
          {!info?.post_type
            ? <b>{info?.source?.name || info?.source || ''}</b>
            : `${t('postTypes', info?.post_type, language)}`}
        </a>
        <PopoverWindow
          ref={bodyRef}
          overflow={{ adjustX: true, adjustY: true }}
          className={classes.sourceBody}
          body={visible && (
            <BodyMenu
              className={classes.bodySelect}
              menuConfig={popoversMenuConfig}
              selectOption={handleSubmit}
            />
          )}
          afterChange={afterChange}
          idBind={idBind}
        >
          <Icon
            type="arrow_drop_down"
            className={cn(classes.iconClass, visible && classes.selectClass)}
            color={text}
            wrapStyles={{
              backgroundColor: background,
              '--background-active': backgroundActive
            }}
          />
        </PopoverWindow>
      </div>
    </div>
  );
});

SourcePopover.propTypes = {
  onSelect: func,
  info: shape({}),
  language: string,
  idBind: string
};
export default SourcePopover;
