import { string, func, shape, number, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyConfirmEditKeyword.module.scss';

const BodyConfirmEditKeyword = ({
  className = '',
  onCancel = () => null,
  onEditKeyword = () => null,
  language = 'uk',
  data = { name: '', id: 0 }
}) => {
  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedEvent({ category: 'Edit keyword Basic QB', event: 'Clicked_Change_Button_in_Keyword_Editing_Popup' });
    onEditKeyword({ keyword_id: data?.keyword_id, body: data?.body, route: data?.route || '' });
  };

  return (
    <form onSubmit={onSubmit} className={cn(classes.bodyConfirmEditKeyword, className)}>
      <div className={classes.discription}>
        {t('bodyRenamePageModal', 'SURE_EDIT_1', language)}
        <span className={classes.bold}>{data?.name}</span>
        {t('bodyRenamePageModal', 'SURE_EDIT_2', language)}
      </div>
      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />
        <Button
          type="submit"
          onClick={onSubmit}
          textButton={t('btn', 'CHANGE', language)}
          green32
        />
      </div>
    </form>
  );
};

BodyConfirmEditKeyword.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCancel: func,
  onEditKeyword: func,
  language: string
};

export default BodyConfirmEditKeyword;
