import { string, func } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button } from 'Components';
import classes from './BodyDuplicateReport.module.scss';

const BodyDuplicateReport = ({ onCancel = () => null, language = 'uk' }) => (
  <div className={cn(classes.bodyMessage)}>
    <div className={classes.discription}>
      {t('bodyDuplicateReport', 'TEXT', language)}
    </div>
    <div className={classes.buttonBox}>
      <Button
        onClick={onCancel}
        textButton={t('btn', 'OKAY', language)}
        green32
      />
    </div>
  </div>
);

BodyDuplicateReport.propTypes = {
  onCancel: func,
  language: string
};

export default BodyDuplicateReport;
