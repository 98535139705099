import { useState, useEffect, useMemo } from 'react';
import { string, func, number, shape, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, SelectCustom } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodySendComplain.module.scss';

const BodySendComplain = ({
  className = '',
  data = { name: '', id: 0 },
  onClickOk = () => null,
  onCancel = () => null,
  language = 'uk'
}) => {
  const params = useMemo(() => [
    { value: 'tone', label: t('bodySendComplain', 'TONE', language) },
    { value: 'character', label: t('bodySendComplain', 'CHARACTER', language) },
    { value: 'irrelevant', label: t('bodySendComplain', 'UNGROUPED', language) },
    { value: 'ungrouped', label: t('bodySendComplain', 'IRRELEVANT', language) }
  ], []);

  const tones = useMemo(() => [
    { value: 'negative', label: t('mentionWidget', 'negative', language) },
    { value: 'neutral', label: t('mentionWidget', 'neutral', language) },
    { value: 'positive', label: t('mentionWidget', 'positive', language) }
  ], []);

  const characters = useMemo(() => [
    { value: 'subject', label: t('characterFilter', 'SUBJECT', language) },
    { value: 'secondary', label: t('characterFilter', 'SECONDARY', language) },
    { value: 'object', label: t('characterFilter', 'OBJECT', language) }
  ], []);

  const [keyword, setKeyword] = useState({ value: '', label: '' });
  const [param, setParam] = useState({ value: '', label: '' });
  const [tone, setTone] = useState({ value: '', label: '' });
  const [character, setCharacter] = useState({ value: '', label: '' });

  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedEvent({ category: 'Full text', event: 'Modal_Report_Problem_With_Encoding_Send_Clicked' });
    const body = { article_keyword: keyword?.value, correction: null, param: param?.value };
    param?.value === 'tone' && (body.correction = tone?.label);
    param?.value === 'character' && (body.correction = character?.label);
    onClickOk({ action: 'sendComplaint', body });
    onCancel();
  };

  useEffect(() => {
    if (param?.value === 'ungrouped' || param?.value === 'irrelevant' || !keyword?.value) {
      tone?.value && setTone({ value: '', label: '' });
      character?.value && setCharacter({ value: '', label: '' });
    }
  }, [param?.value]);

  const disabled = (param?.value === 'tone' && !tone?.value) || (param?.value === 'character' && !character?.value);

  return (
    <form
      onSubmit={onSubmit}
      className={cn(classes.bodySendCopmlain, className)}
    >
      <div className={classes.inputBox}>
        <SelectCustom
          selectTitle={t('bodySendComplain', 'INCORRECT', language)}
          value={keyword}
          placeholder={t('bodySendComplain', 'INCORRECT', language)}
          onChange={setKeyword}
          options={data?.keywords}
        />
      </div>
      {keyword?.value && (
        <div className={classes.inputBox}>
          <SelectCustom
            selectTitle={t('bodySendComplain', 'PARAM', language)}
            value={param}
            placeholder={t('bodySendComplain', 'PARAM', language)}
            onChange={setParam}
            options={params}
          />
        </div>
      )}
      {param?.value === 'tone' && (
        <div className={classes.inputBox}>
          <SelectCustom
            selectTitle={t('bodySendComplain', 'HOW_CORRECT', language)}
            value={tone}
            placeholder={t('bodySendComplain', 'HOW_CORRECT', language)}
            onChange={setTone}
            options={tones}
          />
        </div>
      )}
      {param?.value === 'character' && (
        <div className={classes.inputBox}>
          <SelectCustom
            selectTitle={t('bodySendComplain', 'HOW_CORRECT', language)}
            value={character}
            placeholder={t('bodySendComplain', 'HOW_CORRECT', language)}
            onChange={setCharacter}
            options={characters}
          />
        </div>
      )}
      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />

        <Button
          type="submit"
          disabled={disabled}
          onClick={onSubmit}
          textButton={t('btn', 'SEND', language)}
          green32
        />
      </div>
    </form>
  );
};

BodySendComplain.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCancel: func,
  onClickOk: func,
  language: string
};

export default BodySendComplain;
