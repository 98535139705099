import { string, func } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button } from 'Components';
import classes from './BodyEmptyReport.module.scss';

const BodyEmptyReport = ({ onCancel = () => null, language = 'uk' }) => (
  <div className={cn(classes.bodyConfirmEditTags)}>
    <div className={classes.discription}>
      {t('bodyEmptyReport', 'TEXT', language)}
    </div>
    <div className={classes.buttonBox}>
      <Button
        onClick={onCancel}
        textButton={t('btn', 'BACK_FEED', language)}
        green32
      />
    </div>
  </div>
);

BodyEmptyReport.propTypes = {
  onCancel: func,
  language: string
};

export default BodyEmptyReport;
