export const stylesPeriod = {
  control: (provided, state) => ({
    ...provided,
    width: 'fit-content',
    minWidth: '165px',
    minHeight: '24px',
    maxHeight: '24px',
    backgroundColor: '#F6F7FA',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '30px',
    opacity: state.isDisabled ? '0.8' : '1',
    cursor: 'pointer',
    margin: 0
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: '0px 2px 10px rgba(221, 226, 241, 0.6)',
    width: 'fit-content',
    maxWidth: '250px',
    height: 'auto',
    right: 0
  }),
  menuList: provided => ({
    ...provided,
    paddingBottom: '3px',
    maxHeight: '335px'
  }),
  singleValue: provided => ({
    ...provided,
    margin: '0',
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: '400',
    color: '#8B96B2'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    svg: {
      fill: '#8B96B2 !important'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    marginBottom: '1px',
    padding: '10px 16px',
    height: '39px',
    fontSize: ' 14px',
    lineHeight: '24px',
    fontWeight: state.isSelected ? '500' : '400',
    cursor: 'pointer',
    color: '#4D4476',
    ':nth-of-type(3)': {
      borderBottom: '1px solid #E0E0F3',
      height: '43px'
    },
    ':nth-of-type(5)': {
      borderBottom: '1px solid #E0E0F3',
      height: '43px'
    },
    borderBottom: '1px solid transparent',
    backgroundColor:
      state.isFocused || state.isSelected ? '#FBFBFB' : '#FFFFFF',
    ':active': {
      backgroundColor: '#FBFBFB'
    }
  })
};
