import { string, number, oneOfType } from 'prop-types';

export function Editable({ width = 20, height = 20, color = '#8086AB', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '20'} height={height || '20'} viewBox="0 0 20 20" fill="none">
      <mask id="mask0_420_4504" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_420_4504)">
        <path d="M1.66669 20.0001V16.6667H18.3334V20.0001H1.66669ZM5.00002 13.3334H6.16669L12.6667 6.85425L11.4792 5.66675L5.00002 12.1667V13.3334ZM3.33335 15.0001V11.4584L12.6667 2.14591C12.8195 1.99314 12.9965 1.87508 13.1979 1.79175C13.3993 1.70841 13.6111 1.66675 13.8334 1.66675C14.0556 1.66675 14.2709 1.70841 14.4792 1.79175C14.6875 1.87508 14.875 2.00008 15.0417 2.16675L16.1875 3.33341C16.3542 3.48619 16.4757 3.66675 16.5521 3.87508C16.6285 4.08341 16.6667 4.29869 16.6667 4.52091C16.6667 4.72925 16.6285 4.93411 16.5521 5.1355C16.4757 5.33689 16.3542 5.52092 16.1875 5.68758L6.87502 15.0001H3.33335Z" fill={color || '#8086AB'} />
      </g>
    </svg>
  );
}

Editable.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  color: string,
  className: string
};
