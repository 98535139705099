import { func, shape } from 'prop-types';
import { Loader, ErrorBoundarySentry, Icon } from 'Components';
import t from 'utils/translateFunc';
import { useGetTemplatePreview } from 'storage/queryHooks';
import classes from '../TemplateDrawer.module.scss';

function PreviewComponent({ data = null, onFullScreen = () => null }) {
  const { data: previewData, isLoading: previewLoading, isFetching: previewFetching } = useGetTemplatePreview(data);
  return (
    <Loader size={65} fetching={previewLoading || previewFetching} color="#A7B0C8">
      <div className={classes.previewComponent}>
        <div className={classes.previewHeaderWrap}>
          <div className={classes.previewHeader}>
            {t('reports', 'EXPECTED_RESULT')}
          </div>
          {previewData?.url && (
          <button type="button" onClick={() => { onFullScreen({ url: previewData?.url }); }} className={classes.btnFull}>
            <Icon type="full_screen" />
          </button>
          )}
        </div>
        <div className={classes.previewContent}>
          <ErrorBoundarySentry>

            {previewData?.url && (
              <iframe
                src={previewData?.url}
                referrerPolicy="no-referrer"
                sandbox=""
                title="Preview"
                scrolling="no"
                className={classes.previewContentDocument}
              />
            )}
          </ErrorBoundarySentry>
        </div>
      </div>
    </Loader>
  );
}

PreviewComponent.propTypes = {
  onFullScreen: func,
  data: shape({})

};

export default PreviewComponent;
