import React from 'react';
import cn from 'classnames';
import { bool, func, string } from 'prop-types';
import classes from './Switcher.module.scss';

export function Switcher({ labelText = '', className = '', onСhange = () => null, value = false }) {
  return (
    <div className={cn(classes.switcherWrap, className)}>
      <span className={classes.label}>
        {labelText}
      </span>
      <label className={cn(classes.switch)}>
        <input
          type="checkbox"
          onChange={() => { onСhange(!value); }}
          checked={value}
        />
        <span className={cn(classes.slider, classes.round)} />
      </label>
    </div>
  );
}

Switcher.propTypes = { labelText: string, className: string, onСhange: func, value: bool };
