import InnerHTML from 'dangerously-set-html-content';
import { string } from 'prop-types';

function BodyHtml({ html = '', className = '' }) {
  return (
    html ? <InnerHTML html={`${html}`} className={className} /> : ''
  );
}

BodyHtml.propTypes = {
  html: string,
  className: string
};

export default BodyHtml;
