import { useState, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { Icons, AnimatePage, openNotification } from 'Components';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import t from 'utils/translateFunc';
import { useGetPreviewAricles, useCreateQuery, useGetKeyword, useGetTag, useAddEditKeyword, useAddEditTag } from 'storage/queryHooks';
import useDebounceValue from 'hooks/useDebounce';
import isEqual from 'lodash/isEqual';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { openModal, editAlertDetails } from 'storage/actions';
import Modals from 'Containers/Modals';
import hasOwn from 'lodash/has';
import { useCheckSmallScreens } from 'hooks/useCheckSmallScreens';
import { sendEvent } from 'services/amplitude/events';
import { validateQuery } from 'data/validateQuery';
import classes from './AdvancedBuilder.module.scss';
import QuerySearch from './QuerySearch';
import LeftSide from './LeftSide';
import PreviewSide from './PreviewSide';
import SelectQueryTemplate from './SelectQueryTemplate';
import { templateCompanyKeyword, templateCompanyPerson, templateCompanyTag, templatePersonTag } from './helpers/teplates';

function AdvancedBuilder() {
  const history = useNavigate();
  const isSmallScreen = useCheckSmallScreens();
  const { filterId, filterName } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const builderState = location.state;
  const editorRef = useRef();
  const language = useSelector(state => state?.userInfo?.language);
  const alertDetails = useSelector(state => state?.alerts?.alertDetails);
  const [leftSideVisible, setLeftSideVisible] = useState(true);
  const [previewSideVisible, setPreviewSideVisible] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState(false);
  const [template, setTemplate] = useState('company_search');
  const [errorName, setErrorName] = useState('');
  const [errorQuery, setErrorQuery] = useState('');
  const [errorValidate, setErrorValidate] = useState(null);
  const [period, setPeriod] = useState({ value: 'lastWeek', label: t('bodyAddTagModal', 'LAST_WEEK', language) });
  const lastWeekDayIso = useMemo(() => moment().startOf('day').subtract(1, 'week').toISOString(), [period]);
  const last2WeekDayIso = useMemo(() => moment().startOf('day').subtract(2, 'week').toISOString(), [period]);
  const todayIso = useMemo(() => moment().startOf('day').toISOString(), [period]);
  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [query, setQuery] = useState('');
  const [color, setColor] = useState('#420c8d');
  const [assignFrom, setAssignFrom] = useState({ value: '', label: t('sourceAssignTeg', 'CURRENT_MOMENT', language) });
  const [tagDuplicate, setTagDuplicate] = useState(false);
  const [selected, setSelected] = useState(false);

  const queryDebounce = useDebounceValue(query, 1000);
  const nameDebounce = useDebounceValue(name, 1000);
  const previewBody = useMemo(() => !!queryDebounce && ({ query: queryDebounce.replace(/<<<.*?>>>|[\r\n]+/g, ''), limit: '50' }), [queryDebounce]);
  const { data: keywordData } = useGetKeyword(filterName === 'keyword' && filterId);
  const { data: tagData } = useGetTag(filterName === 'tag' && filterId);
  const { mutateAsync: addEditKeyword, isLoading: kwLoading } = useAddEditKeyword();
  const { mutateAsync: addEditTag, isLoading: tagLoading } = useAddEditTag();
  const { data: createQueryData, mutateAsync: createQuery } = useCreateQuery();
  const portal = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const isAddOrEdit = (filterName === 'alert' && alertDetails?.id) || filterId ? 'Edit' : 'Add';

  const hasError = !!errorValidate || !!errorQuery || !!errorName;
  const previewMode = {
    tag: 'tag',
    keyword: 'keyword',
    default: 'keyword'
  };
  const { data: previewAllData, isError: previewError, isLoading: isPreviewLoading } = useGetPreviewAricles({
    body: !!previewSideVisible && !hasError && previewBody,
    enabled: !!previewSideVisible && !hasError && !!previewBody,
    previewMode: previewMode[filterName] || previewMode.default,
    period: {
      start: period?.value === 'lastWeek' ? lastWeekDayIso : last2WeekDayIso,
      end: todayIso
    } });

  const previewData = useMemo(() => ({
    articles: previewAllData?.articles || [],
    all_count: previewAllData?.all_count || 0,
    error: previewAllData?.error || '',
    originals_count: previewAllData?.originals_count || 0
  }), [previewAllData, isPreviewLoading]);

  useEffect(() => {
    if (!id) {
      const companySearch = {
        tag: templateCompanyTag(language),
        keyword: templateCompanyKeyword(language),
        alert: templateCompanyKeyword(language),
        default: templateCompanyKeyword(language)
      };
      const personSearch = {
        tag: templatePersonTag(language),
        keyword: templateCompanyPerson(language),
        alert: templateCompanyPerson(language),
        default: templateCompanyPerson(language)
      };
      const templateForQuery = {
        company_search: companySearch[filterName] || companySearch.default,
        person_search: personSearch[filterName] || personSearch.default,
        no_template: ' '
      };
      setQuery(templateForQuery[template] || templateForQuery.no_template);
    }
  }, [id, template]);

  useEffect(() => {
    hasError && setPreviewSideVisible(false);
  }, [hasError, query]);

  useEffect(() => {
    if (query) {
      const err = validateQuery({ query, language });
      !isEqual(err, errorValidate) && setErrorValidate(err);
    }
  }, [query, language]);

  useEffect(() => {
    previewAllData?.error && setErrorQuery(t('advancedBuilder', 'QUERY_ERRORS', language));
  }, [previewAllData?.error]);

  useEffect(() => {
    if (keywordData) {
      keywordData?.name && setName(keywordData?.name);
      keywordData?.query && setQuery(keywordData.query);
    }
    if (tagData) {
      tagData?.name && setName(builderState?.name || tagData?.name);
      tagData?.query && setQuery(tagData.query);
      tagData?.color && setColor(`#${tagData.color}`);
      hasOwn(tagData, 'tag_duplicate') && setTagDuplicate(tagData.tag_duplicate);
    }
    if (alertDetails) {
      alertDetails?.name && setName(alertDetails?.name);
      const alertQuery = builderState?.query || alertDetails?.query;
      if (alertQuery) {
        setQuery(alertQuery);
        setSelectTemplate(true);
      }
    }
  }, [alertDetails, keywordData, tagData, builderState]);

  useEffect(() => {
    if (builderState) {
      builderState?.name && setName(builderState.name);
      builderState?.color && setColor(`#${builderState?.color}`);
      builderState?.assignFrom && setAssignFrom(builderState?.assignFrom);
      filterName && builderState?.query_builder && createQuery({ body: builderState?.query_builder, filterName });
    }
  }, [builderState, filterName]);

  useEffect(() => {
    filterId && setId(filterId);
  }, [filterId]);

  useEffect(() => {
    createQueryData?.query && setQuery(createQueryData?.query);
  }, [createQueryData]);

  function onChangePeriod(value) {
    period?.value !== value?.value && sendEvent({
      category: `${isAddOrEdit} ${filterName} Advanced QB`,
      event: `${filterName}_${value?.value}`,
      type: filterName
    });
    setPeriod(value);
  }

  const onChangeName = (e) => {
    !e?.target?.value?.length && !errorName && setErrorName(t('error', 'ERR_REQUIRED', language));
    errorName && setErrorName('');
    setName(e.target.value);
  };

  const onPreview = ({ onBack = false }) => {
    sendEvent({
      category: `${isAddOrEdit} ${filterName} Advanced QB`,
      event: onBack ? 'Clicked_Back_Button' : 'Clicked_Next_Button',
      type: filterName
    });

    if (!hasError && !!name && !!query && query.length < 4001) {
      setPreviewSideVisible(!previewSideVisible);
      isSmallScreen && !!leftSideVisible && !previewSideVisible && setLeftSideVisible(false);
      isSmallScreen && !leftSideVisible && !!previewSideVisible && setLeftSideVisible(true);
    }
    onCheckErrors();
  };

  function onCheckErrors() {
    name.length < 2 && setErrorName(t('error', 'MIN_SYMBOLS', language));
    !name.length && setErrorName(t('error', 'ERR_REQUIRED', language));
    query.length < 2 && setErrorQuery(t('error', 'MIN_SYMBOLS', language));
    !query.length && setErrorQuery(t('error', 'ERR_REQUIRED', language));
    query.length > 4000 && setErrorQuery(t('error', 'MAX_SYMBOLS', language));
  }

  const onSubmit = (e) => {
    e.preventDefault();
    sendEvent({
      category: `${isAddOrEdit} ${filterName} Advanced QB`,
      event: `Clicked_${(filterName === 'alert' && alertDetails?.id) || filterId ? 'Save' : 'Add'}_Button`,
      type: filterName
    });
    onCheckErrors();
    const qError = !!previewError || !!previewData?.error || hasError;
    const noTextErrors = name.length >= 2 && query.length >= 2 && query.length < 4001;
    const manyMentions = previewData.all_count >= 10000;
    const queryClear = query.replace(/<<<.*?>>>|[\r\n]+/g, '');
    if (noTextErrors && filterName === 'keyword' && !qError) {
      const body = { name, query: queryClear };

      if (manyMentions) {
        dispatch(openModal({ action: 'manyMentions', info: { body, keyword_id: id } }));
        return;
      }
      id ? dispatch(openModal({ action: 'editingKeyword', info: { body, keyword_id: id } }))
        : addEditKeyword({ body, keyword_id: id });
    }
    if (noTextErrors && filterName === 'tag' && !qError) {
      const body = {
        name,
        color: color.substring(1),
        query: queryClear,
        set_for: assignFrom?.value,
        tag_duplicate: tagDuplicate,
        notify: selected,
        ...(id ? { id } : {})
      };
      !manyMentions ? addEditTag({ body, tag_id: id }) : dispatch(openModal({ action: 'manyMentions', info: { body, tag_id: id } }));
    }
    if (noTextErrors && filterName === 'alert' && !qError) {
      const newAlertData = { ...alertDetails, name, keyword: null, query: queryClear, query_builder: null };
      if (manyMentions) {
        dispatch(openModal({ action: 'manyMentions', info: { body: newAlertData, alert_id: 'alert' } }));
        return;
      }
      dispatch(editAlertDetails(newAlertData));
      history('/alerts');
    }
  };

  function onCopyText(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        openNotification({ message: t('notif', 'COPY_EXAMPLE', language) });
      });
  }
  const onChangeEditor = (text) => {
    confirmEditig();
    !text.length && setErrorValidate(null);
    errorQuery && setErrorQuery('');
    !builderState && setQuery(text);
  };

  function confirmEditig() {
    builderState?.name && dispatch(openModal({ action: 'editingKeywordAdvanced', info: null }));
  }

  function confirmExit() {
    sendEvent({ category: `${isAddOrEdit} ${filterName} Advanced QB`, event: 'Clicked_Close_Button_Query_Builder', type: filterName });
    let confirm = false;
    if (filterName === 'keyword' && !!keywordData?.id) {
      confirm = keywordData?.name !== name || (!!keywordData?.query && keywordData?.query !== query);
    }
    if (filterName === 'tag' && !!tagData?.id) {
      confirm = tagData?.name !== name
        || (!!tagData?.query && tagData?.query !== query)
        || tagData?.color !== color.substring(1)
        || (hasOwn(tagData, 'tag_duplicate') && tagData.tag_duplicate !== tagDuplicate);
    }
    if (filterName === 'alert' && !!alertDetails?.id) {
      confirm = alertDetails?.name !== name || (!!alertDetails?.query && alertDetails?.query !== query);
    }
    if (filterName === 'keyword' && !keywordData?.id) {
      confirm = !!query || !!name;
    }
    if (filterName === 'tag' && !tagData?.id) {
      confirm = !!query || !!name;
    }
    if (filterName === 'alert' && alertDetails?.query !== query) {
      confirm = !!query || !!name;
    }

    const confirmText = {
      keyword: t('advancedBuilder', 'SURE_EXIT_KW', language),
      tag: t('advancedBuilder', 'SURE_EXIT_TAG', language),
      alert: t('advancedBuilder', 'SURE_EXIT_SEARCH_QUERY', language),
      default: t('advancedBuilder', 'SURE_EXIT_SEARCH_QUERY', language)
    };
    location.hash = confirm ? 'confirm' : '';
    const redirect_link = filterName === 'alert' ? '/alerts' : `/${portal}/mentions/all`;
    confirm
      ? dispatch(openModal({ action: 'exitBuilder', info: { text: confirmText[filterName] || confirmText.default, historyLink: redirect_link } }))
      : history(-1, { state: null });
  }

  function onAddOperatorOrField(text) {
    confirmEditig();
    errorQuery && setErrorQuery('');
    if (editorRef?.current && text && !builderState && query) {
      const view = editorRef.current.view;
      const state = view.viewState.state;
      const range = state.selection.ranges[0];
      const trimText = text.trim();
      view.dispatch({
        changes: {
          from: range.from,
          to: range.to,
          insert: trimText
        },
        selection: { anchor: range.from + trimText.length },
        scrollIntoView: true
      });
      editorRef.current?.view?.focus();
    }
    !query && setQuery(text);
  }

  function onChangeColor(val) {
    sendEvent({ category: `${isAddOrEdit} tag Advanced QB`, event: 'Clicked_Select_Color_Button', type: filterName });
    setColor(val);
  }
  function onChangeSelected() {
    sendEvent({ category: `${isAddOrEdit} tag Advanced QB`, event: 'Toggled_Notify_Tags', type: filterName });
    setSelected(!selected);
  }
  function onConfirmDel(info) {
    dispatch(openModal({ action: 'removeKeyword', info }));
  }
  function onChangeAssign(val) {
    sendEvent({ category: `${isAddOrEdit} tag Advanced QB`, event: 'Selected_Tagging_Peroid', type: filterName });
    setAssignFrom(val);
  }
  const headerText = {
    keyword: t('advancedBuilder', id ? 'EDIT_KEYWORD' : 'ADD_KEYWORD', language),
    tag: t('advancedBuilder', id ? 'EDIT_TAG' : 'ADD_TAG', language),
    alert: t('advancedBuilder', alertDetails?.query ? 'EDIT_QUERY_SEARCH' : 'ADD_QUERY_SEARCH', language),
    default: t('advancedBuilder', id ? 'EDIT_QUERY_SEARCH' : 'ADD_QUERY_SEARCH', language)
  };

  const onChangeTemplate = (templateText) => {
    const templateEvents = ['company_search', 'person_search', 'no_template'];
    templateEvents.includes(templateText) && sendEvent({
      category: `${isAddOrEdit} ${filterName} Advanced QB`,
      event: `template_${templateText}`,
      type: filterName
    });
    setTemplate(templateText);
  };

  return (
    <AnimatePage>
      <div className={classes.advancedWrapper}>
        <div className={classes.headerWrapp}>
          <div className={classes.headerText}>
            {headerText[filterName] || headerText.default}
          </div>
          <button
            className={classes.exitBtn}
            onClick={confirmExit}
            type="button"
          >
            <span className={cn(classes.icon)}> </span>
          </button>
        </div>
        <div className={cn(classes.leftSideWrap, leftSideVisible && classes.leftSideWrap_active)}>
          <LeftSide
            visible={leftSideVisible}
            filterName={filterName}
            onAdd={onAddOperatorOrField}
            onCopyText={onCopyText}
            eventType={isAddOrEdit}
          />
          <button
            className={cn(classes.closeBtn, !leftSideVisible && classes.closeBtn_closed)}
            onClick={() => { setLeftSideVisible(!leftSideVisible); }}
            type="button"
          >
            <Icons icon="Icn_ArrowCircle" className={classes.icon} />
          </button>
        </div>
        <div className={cn(
          classes.querySearchWrap,
          leftSideVisible && !previewSideVisible && classes.querySearchWrap_leftSideOn,
          previewSideVisible && !leftSideVisible && classes.querySearchWrap_previewSideOn,
          leftSideVisible && previewSideVisible && classes.querySearchWrap_bothSideOn
        )}
        >
          {builderState?.name && filterId && (
          <div
            className={classes.switchAdvanced}
            onClick={() => {
              sendEvent({ category: 'Edit tag Basic QB', event: 'Clicked_Switch_to_Basic_Mode_Button', type: filterName });
              history(-1, { state: null });
              const modalBuilder = {
                keyword: 'editKeyword',
                tag: 'editTag',
                alert: 'addEditSearchQuery',
                default: 'addEditSearchQuery'
              };
              dispatch(openModal({ action: modalBuilder[filterName] || modalBuilder.default, info: filterId ? { id: filterId } : null }));
            }}
          >
            {t('advancedBuilder', 'TO_SIMPLE_MODE', language)}
          </div>
          )}
          {(id || selectTemplate) ? (
            <QuerySearch
              data={{ id, isEditAlert: filterName === 'alert' && alertDetails?.id }}
              name={name}
              color={color}
              filterName={filterName}
              query={query}
              selected={selected}
              assignFrom={assignFrom}
              editable={!builderState}
              preview={previewSideVisible}
              errorName={errorName}
              errorQuery={errorQuery}
              errorValidate={errorValidate}
              language={language}
              isLoading={isPreviewLoading || kwLoading || tagLoading}
              onChangeName={onChangeName}
              onChangeEditor={onChangeEditor}
              onConfirmEditig={confirmEditig}
              onChangeColor={onChangeColor}
              onChangeAssign={onChangeAssign}
              onChangeSelected={onChangeSelected}
              onConfirmDel={onConfirmDel}
              onPreview={onPreview}
              onSubmit={onSubmit}
              onCopyText={onCopyText}
              ref={editorRef}
            />
          ) : (
            <SelectQueryTemplate
              language={language}
              template={template}
              templateQuery={query}
              onChangeTemplate={onChangeTemplate}
              onSelect={() => {
                sendEvent({ category: `${isAddOrEdit} ${filterName} Advanced QB`, event: 'Clicked_Select_Template', type: filterName });
                setSelectTemplate(true);
              }}
              filterName={filterName}
            />
          )}

        </div>
        <div className={cn(classes.previewSideWrap, previewSideVisible && !hasError && classes.previewSideWrap_active)}>
          <PreviewSide
            visible={previewSideVisible && !hasError}
            language={language}
            isLoading={isPreviewLoading}
            onChangePeriod={onChangePeriod}
            period={period}
            previewData={previewData}
            name={nameDebounce}
            query={queryDebounce}
            error={!!previewError || !!previewData?.error}
          />
          <button
            className={cn(classes.closeBtn, !previewSideVisible && classes.closeBtn_closed)}
            onClick={() => { !hasError && setPreviewSideVisible(!previewSideVisible); }}
            type="button"
          >
            <Icons icon="Icn_ArrowCircle" className={classes.icon} />
          </button>
        </div>
        <Modals />
      </div>
    </AnimatePage>
  );
}

export default AdvancedBuilder;
