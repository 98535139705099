import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import cn from 'classnames';
import { node, bool } from 'prop-types';
import classes from './SkeletonSearchHistory.module.scss';

function SkeletonSearchHistory({ fetching, children }) {
  const arr = new Array(7).fill(null);
  return (fetching ? (
    <SkeletonTheme baseColor="#e0e0f3" highlightColor="#f6f7fa">
      <div className={cn(classes.searchesQueries)}>
        {arr.map((_, i) => (
          <div className={classes.searchesQueryCard} key={i}>
            <Skeleton count={2} height={18} borderRadius={4} inline containerClassName={classes.header} />
            <Skeleton height={24} borderRadius={4} inline className={classes.query} />
            <Skeleton count={3} height={20} width={40} borderRadius={30} inline containerClassName={classes.filters} />
            <hr />
            <Skeleton height={24} borderRadius={30} inline containerClassName={classes.footer} />
          </div>
        ))}
      </div>
    </SkeletonTheme>
  ) : children);
}

SkeletonSearchHistory.propTypes = {
  fetching: bool,
  children: node
};
export default SkeletonSearchHistory;
