import { useMutation, useQuery } from 'react-query';
import * as requests from 'storage/datePicker/requests';
import * as actions from 'storage/actions';
import { useSelector, useDispatch } from 'react-redux';
import { openNotification } from 'Components';
import t from 'utils/translateFunc';
import moment from 'moment';

export const useEditPeriod = () => {
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'editPeriod',
    mutationFn: ({ start, end, by_created }) => requests.editPeriod({ start: moment(start).toISOString(), end: moment(end).toISOString(), by_created }),
    onSuccess: ({ message }, body) => {
      openNotification({ message });
      dispatch(actions.setDatePicker(body));
    },
    onError: () => {
      openNotification({ message: t('notif', 'SOMETHING_WRONG', language) });
    },
    onSettled: () => { dispatch(actions.closeModal()); }
  });
};

export const useGetPeriod = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  return useQuery({
    queryKey: ['getPeriod'],
    enabled: !!isAuthenticated,
    queryFn: () => requests.getPeriod(),
    onSuccess: ({ start, end, by_created }) => {
      start && end && dispatch(actions.setDatePicker({ start: moment(start)?.format('YYYY-MM-DD HH:mm'), end: moment(end)?.format('YYYY-MM-DD HH:mm'), by_created }));
    }
  });
};
