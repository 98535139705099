import { useState, useEffect, useMemo } from 'react';
import t from 'utils/translateFunc';
import { useDispatch, useSelector } from 'react-redux';
import {
  SearchField, SkeletonFilterList, NoSearchData, FilterList
} from 'Components';
import { useGetLevelSources, useGetSources, useGetSubjectSources } from 'storage/queryHooks';
import { removeFilterItem, addFilterItem, addSources, openModal } from 'storage/actions';
import useDebounceValue from 'hooks/useDebounce';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './SourceFilter.module.scss';

const SourceFilter = () => {
  const dispatch = useDispatch();
  const { filterList, levels, subjects, sources } = useSelector(state => state?.filters);
  const { language } = useSelector(state => state?.userInfo);
  const [filterSearch, setFilterSearch] = useState('');
  const itemTypeSource = t('filtrationPanel', 'SOURCE', language);
  const itemTypeLevel = t('filtrationPanel', 'LEVEL', language);
  const itemTypeSubject = t('filtrationPanel', 'SUBJECT', language);

  const icontains = useDebounceValue(filterSearch, 1000);
  const { isLoading: sourceLoading } = useGetSources({ query: { icontains, ordering: 'relevance' }, enabled: !!icontains });
  const { isLoading: levelLoading } = useGetLevelSources({ enabled: true });
  const { isLoading: subjectLoading } = useGetSubjectSources({ enabled: true });
  const selectedDataSource = useMemo(() => filterList?.filter(listItem => listItem?.filterName === 'source'), [filterList?.length]);
  const selectedDataLevel = useMemo(() => filterList?.filter(listItem => listItem?.filterName === 'level'), [filterList?.length]);
  const selectedDataSubject = useMemo(() => filterList?.filter(listItem => listItem?.filterName === 'subject'), [filterList?.length]);

  function onOpenModal(info) { dispatch(openModal(info)); }
  const addItem = ({ id, name, type, filterName }) => { dispatch(addFilterItem({ id, name, type, filterName })); };
  const removeItem = ({ id, filterName }) => { dispatch(removeFilterItem({ id, filterName })); };

  useEffect(() => {
    if (!filterSearch?.length) {
      dispatch(addSources([]));
    }
  }, [filterSearch?.length]);

  return (
    <div className={classes.sourceFilter}>
      <section className={classes.box}>
        <div className={classes.searchWrap}>
          <SearchField
            placeholder={t('sourceFilter', 'ENTER_SOURCE', language)}
            value={filterSearch}
            setValue={setFilterSearch}
          />
        </div>
        <SkeletonFilterList fetching={sourceLoading}>
          {sources?.length ? (
            <FilterList
              dataFilters={sources}
              selectData={selectedDataSource}
              filterName="source"
              addItem={addItem}
              removeItem={removeItem}
              filterType={itemTypeSource}
              language={language}
            />
          ) : !!filterSearch && <NoSearchData language={language} /> }
        </SkeletonFilterList>
      </section>

      <section className={classes.box}>
        <p className={classes.name}>{t('sourceFilter', 'LEVEL', language)}</p>
        <SkeletonFilterList fetching={levelLoading}>
          { !!levels?.length && (
          <FilterList
            className={classes.list}
            dataFilters={levels}
            selectData={selectedDataLevel}
            addItem={addItem}
            removeItem={removeItem}
            filterType={itemTypeLevel}
            filterName="level"
            language={language}
          />
          ) }
        </SkeletonFilterList>
      </section>
      <section className={classes.box}>
        <p className={classes.name}>{t('sourceFilter', 'SUBJECT', language)}</p>
        <SkeletonFilterList fetching={subjectLoading}>
          {!!subjects?.length && (
          <FilterList
            className={classes.list}
            dataFilters={subjects}
            selectData={selectedDataSubject}
            addItem={addItem}
            removeItem={removeItem}
            filterType={itemTypeSubject}
            filterName="subject"
            language={language}
          />
          ) }
        </SkeletonFilterList>
      </section>
      <section className={classes.box}>
        <button
          type="button"
          onClick={() => {
            sendFeedEvent({ category: 'Filters', event: 'Clicked_Suggest_A_Source_Button' });
            onOpenModal({ action: 'proposeSource', info: null });
          }}
          className={classes.btnAdd}
        >
          <div className={classes.iconAdd}>+</div>
          <p>{t('sourceFilter', 'PROPOSE_SOURCE', language)}</p>
        </button>
      </section>
    </div>
  );
};

export default SourceFilter;
