import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as requests from 'storage/userInfo/requests';
import * as actions from 'storage/actions';
import hasOwn from 'lodash/has';
import { useNavigate, useParams } from 'react-router-dom';
import { openNotification } from 'Components';
import t from 'utils/translateFunc';
import { useSelector, useDispatch } from 'react-redux';
import { updateLanguage, getSearchParams } from 'api/apiInstance';
import { filtersList, findRequestFunc } from 'data/persistentData';
import { editPeriod } from 'storage/datePicker/requests';

export const useGetUserInfo = () => {
  const dispatch = useDispatch();
  const { portal } = useParams();
  const history = useNavigate();
  const { notAvailable, serverUpdating, isAuthenticated } = useSelector(state => state?.auth);
  const language = useSelector(state => state?.userInfo?.language);
  return useQuery({
    queryKey: ['getUserInfo'],
    queryFn: () => requests.getUserInfo(),
    onSuccess: (resp) => {
      const firstPortal = resp.portals[0]?.slug;
      notAvailable && dispatch(actions.changeNotAvailable(false));
      serverUpdating && dispatch(actions.changeServerUpdating(false));
      const isAuthPage = window.location.pathname.includes('redirect_login');
      const searchParams = getSearchParams();
      resp?.language && updateLanguage(resp?.language);
      const findPortal = !!portal && resp?.portals?.find(el => el?.slug === portal);
      dispatch(actions.addPortals({ portalsList: resp.portals, portal: findPortal }));
      resp.portals?.length === 1 && dispatch(actions.changeReportsPortal(resp.portals[0]));
      dispatch(actions.addUserInfo(resp));
      !isAuthenticated && dispatch(actions.authIn());
      const findFilter = name => searchParams.find(item => hasOwn(item, name));
      const getFilterValue = filter => (filter ? Object.values(filter)[0] : null);
      if (isAuthPage && !!searchParams?.length) {
        try {
          const portal_slug = findFilter('portal_slug');
          const findFilterPortal = !!portal_slug && !!resp?.portals?.find(el => el?.slug === getFilterValue(portal_slug));
          const portalSlug = !!portal_slug && getFilterValue(portal_slug);
          const start = findFilter('start');
          const end = findFilter('end');
          const by_created = findFilter('by_created');
          const article_id = findFilter('article_id');
          const articleId = article_id ? `/${getFilterValue(article_id)}` : '';
          const duplicates = findFilter('duplicate');
          duplicates && dispatch(actions.toggleDuplicates(getFilterValue(duplicates)));
          if (start && end && by_created) {
            const periodBody = { start: getFilterValue(start), end: getFilterValue(end), by_created: getFilterValue(by_created) !== 'False' };
            editPeriod(periodBody).then(() => {
              dispatch(actions.setDatePicker({ start: getFilterValue(start), end: getFilterValue(end), by_created: getFilterValue(by_created) !== 'False' }));
            });
          }
          portal_slug && findFilterPortal && dispatch(actions.changeCurrentPortal({ name: getFilterValue(portal_slug), slug: getFilterValue(portal_slug) }));
          dispatch(actions.clearFilterList());
          searchParams.forEach((item) => {
            const key = Object.keys(item)[0];
            const value = Object.values(item)[0];
            if (filtersList.includes(key)) {
              const getRequestFunc = findRequestFunc(key);
              getRequestFunc && getRequestFunc({ portal_slug: portalSlug, language, id: value }).then((response) => {
                const data = response?.results || response;
                const filter = Array.isArray(data) ? !!data?.length && data?.find(el => String(el.id) === value) : data;
                filter && dispatch(actions.addFilterItem({ id: filter.id, name: filter?.name || filter?.title || filter?.label || '', filterName: key, type: t('filtrationPanel', key.toUpperCase(), language) }));
              });
            }
          });
          history(`/${findFilterPortal ? portalSlug : firstPortal}/mentions/all${articleId}`);
        } catch {
          history(`/${firstPortal}/mentions/all`);
        }
      }

      isAuthPage && !searchParams?.length && history(`/${firstPortal}/mentions/all`);
      !isAuthPage && !!portal && !findPortal && history('/not_access');
    },
    refetchIntervalInBackground: true,
    refetchInterval: (notAvailable || serverUpdating) ? 15000 : 0,
    cacheTime: 15000
  });
};

export const useSendUserEvent = ({ body, enabled }) => {
  const portal_slug = useSelector(state => state?.portals.currentPortal?.slug) || '';
  return useQuery({
    queryKey: ['sendUserEvent'],
    queryFn: () => requests.sendUserEvent({ portal_slug, body }),
    enabled
  });
};

export const useGetTimeZones = () => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getTimeZones'],
    queryFn: () => requests.getTimeZones(),
    enabled: false,
    onSuccess: (resp) => { dispatch(actions.addTimeZones(resp)); } });
};

export const useGetInterfaceLanguages = () => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getInterfaceLanguages'],
    queryFn: () => requests.getInterfaceLanguages(),
    enabled: false,
    onSuccess: (resp) => { dispatch(actions.addInterfaceLanguages(resp)); } });
};

export const useUploadImage = () => {
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'uploadImage',
    mutationFn: ({ image }) => requests.uploadImage({ image }),
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useUpdateUserInfo = () => {
  const language = useSelector(state => state?.userInfo?.language);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'updateUserInfo',
    mutationFn: ({ id, body }) => requests.updateUserInfo({ id, body }),
    onSuccess: (resp) => {
      openNotification({ message: t('notif', 'CHANGED_DATA', resp?.language || language) });
      queryClient.refetchQueries({ queryKey: 'getUserInfo', exact: 'true' });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useUploadFeedbackImage = () => {
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'uploadFeedbackImage',
    mutationFn: ({ image }) => requests.uploadFeedbackImage({ image }),
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useSendFeedback = () => {
  const language = useSelector(state => state?.userInfo?.language);
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: 'sendFeedback',
    mutationFn: ({ body }) => requests.sendFeedback({ body }),
    onSuccess: () => {
      openNotification({ message: t('notif', 'FEEDBACK', language) });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); },
    onSettled: () => { dispatch(actions.closeModal()); }
  });
};

export const useGetTelegramAccounts = () => {
  const dispatch = useDispatch();
  const { telegramData, refetchTelegram } = useSelector(state => state?.userInfo);
  const alertDetails = useSelector(state => state?.alerts?.alertDetails);
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  return useQuery({
    queryKey: ['getTelegramAccounts'],
    refetchInterval: refetchTelegram ? 5000 : false,
    refetchIntervalInBackground: true,
    queryFn: () => requests.getTelegramAccounts(),
    onSuccess: (resp) => {
      const newAccounts = resp.accounts;
      const oldAccounts = telegramData?.accounts;
      if (telegramData && newAccounts?.length && oldAccounts?.length !== newAccounts.length) {
        if (alertDetails && !oldAccounts?.length) {
          dispatch(actions.editAlertDetails({ ...alertDetails, telegram_users: newAccounts }));
        }
        if (alertDetails && oldAccounts?.length) {
          const newTelegram = newAccounts.find(item => oldAccounts?.every(el => el?.id !== item?.id)) || null;
          newTelegram && dispatch(actions.editAlertDetails({ ...alertDetails, telegram_users: alertDetails.telegram_users.concat([newTelegram]) }));
        }
        dispatch(actions.addTelegram(resp));
        refetchTelegram && dispatch(actions.toggleRefetchTelegram(false));
        isOpenModal && dispatch(actions.closeModal());
        openNotification({ message: t('notif', 'TELEGRAM_CONNECTED') });
      }
      if (!telegramData) {
        dispatch(actions.addTelegram(resp));
        refetchTelegram && dispatch(actions.toggleRefetchTelegram(false));
      }
    }
  });
};

export const useGetSlackAccounts = () => useQuery({
  queryKey: ['getSlackAccounts'],
  queryFn: () => requests.getSlackAccounts()
});

export const useGetWhatsappAccounts = () => {
  const dispatch = useDispatch();
  const { whatsappData, refetchWhatsapp } = useSelector(state => state?.userInfo);
  const alertDetails = useSelector(state => state?.alerts?.alertDetails);
  const reportDetails = useSelector(state => state?.alerts?.reportDetails);
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  return useQuery({
    queryKey: ['getWhatsappAccounts'],
    refetchInterval: refetchWhatsapp ? 3000 : false,
    refetchIntervalInBackground: true,
    queryFn: () => requests.getWhatsappAccounts(),
    onSuccess: (resp) => {
      const newAccounts = resp.accounts;
      const oldAccounts = whatsappData?.accounts;
      if (whatsappData && newAccounts?.length && oldAccounts?.length !== newAccounts.length) {
        if (alertDetails && !oldAccounts?.length) {
          dispatch(actions.editAlertDetails({ ...alertDetails, whatsapp_accounts: newAccounts }));
        }
        if (alertDetails && oldAccounts?.length) {
          const newWhatsapp = newAccounts.find(item => oldAccounts?.every(el => el?.id !== item?.id)) || null;
          newWhatsapp && dispatch(actions.editAlertDetails({ ...alertDetails, whatsapp_accounts: alertDetails.whatsapp_accounts.concat([newWhatsapp]) }));
        }
        if (reportDetails && !oldAccounts?.length) {
          dispatch(actions.editReportDetails({ ...reportDetails, whatsapp_accounts: newAccounts }));
        }
        if (reportDetails && oldAccounts?.length) {
          const newWhatsapp = newAccounts.find(item => oldAccounts?.every(el => el?.id !== item?.id)) || null;
          newWhatsapp && dispatch(actions.editReportDetails({ ...reportDetails, whatsapp_accounts: reportDetails.whatsapp_accounts.concat([newWhatsapp]) }));
        }
        dispatch(actions.addWhatsapp(resp));
        refetchWhatsapp && dispatch(actions.toggleRefetchWhatsapp(false));
        isOpenModal && dispatch(actions.closeModal());
      }
      if (!whatsappData) {
        dispatch(actions.addWhatsapp(resp));
        refetchWhatsapp && dispatch(actions.toggleRefetchWhatsapp(false));
      }
    }
  });
};

export const useAddWhatsappAccount = () => {
  const dispatch = useDispatch();
  const { whatsappData } = useSelector(state => state?.userInfo);
  const { modalInfo } = useSelector(state => state?.modals);
  const alertDetails = useSelector(state => state?.alerts?.alertDetails);
  const reportDetails = useSelector(state => state?.alerts?.reportDetails);
  return useMutation({
    mutationKey: 'addWhatsappAccounts',
    mutationFn: ({ phone }) => requests.addWhatsappAccounts(phone),
    onSuccess: (resp) => {
      const newAccounts = resp.accounts;
      const oldAccounts = whatsappData?.accounts;
      if (whatsappData && newAccounts?.length && oldAccounts?.length !== newAccounts.length) {
        if (modalInfo?.info === 'report') {
          if (reportDetails && !oldAccounts?.length) {
            dispatch(actions.editReportDetails({ ...reportDetails, whatsapp_accounts: newAccounts }));
          }
          if (reportDetails && oldAccounts?.length) {
            const newWhatsapp = newAccounts.find(item => oldAccounts?.every(el => el?.id !== item?.id)) || null;
            newWhatsapp && dispatch(actions.editReportDetails({ ...reportDetails, whatsapp_accounts: reportDetails.whatsapp_accounts.concat([newWhatsapp]) }));
          }
        }
        if (modalInfo?.info === 'alert') {
          if (alertDetails && !oldAccounts?.length) {
            dispatch(actions.editAlertDetails({ ...alertDetails, whatsapp_accounts: newAccounts }));
          }
          if (alertDetails && oldAccounts?.length) {
            const newWhatsapp = newAccounts.find(item => oldAccounts?.every(el => el?.id !== item?.id)) || null;
            newWhatsapp && dispatch(actions.editAlertDetails({ ...alertDetails, whatsapp_accounts: alertDetails.whatsapp_accounts.concat([newWhatsapp]) }));
          }
        }
        dispatch(actions.addWhatsapp(resp));
        openNotification({ message: t('notif', 'WHATSAPP_CONNECTED') });
      }
      if (!whatsappData) dispatch(actions.addWhatsapp(resp));
    },
    onError: () => { openNotification({ message: t('notif', 'WHATSAPP_ERROR') }); }
  });
};
