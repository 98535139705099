import cn from 'classnames';
import { Icons, Icon, TagsList, CreatorPlacePopover, SourcePopover, IconsListMedia, ErrorBoundarySentry, Tooltip, BodyHtml } from 'Components';
import moment from 'moment';
import t from 'utils/translateFunc';
import { string, func, shape, number, oneOfType, arrayOf } from 'prop-types';
import * as Sentry from '@sentry/react';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './ArticlesList.module.scss';

function ArticlesList({ articles = [], isSelect = () => null, onChange = () => null, onSubmit = () => null,
  onRemoveTag = () => null, onVisible = () => null, language = 'uk', keyword = null, detailsId = '' }) {
  const createEventName = (action, body) => {
    const baseName = 'article_link_';
    if (action === 'changeTone' && body?.tone) return `${baseName}${body.tone}_${action}`;
    if (action === 'changeProcessed') return `${baseName}${body.processed}_${action}`;
    return `${baseName}${action}`;
  };

  function onHandleSubmit({ action, body, info }) {
    const eventList = ['copyLink', 'showCard', 'reportProblem', 'changeTone', 'changeProcessed'];
    const eventWithoutBlockList = ['blockSource', 'filterMentions'];
    (eventList.includes(action) || (eventWithoutBlockList.includes(action) && !body)) && sendFeedEvent({ category: `article_link_${action}`, event: createEventName(action, body) });
    onSubmit({ action, body, info: { ...info, keyword_id: keyword?.id } });
  }

  function onRemove({ article_id, tag_id }) {
    onRemoveTag({ article_id, tag_id });
  }

  function onHandleChange(e, { action, keyword_id, keyword_name, article }) {
    const { tone, url, tags, deleted, processed, bookmarked } = article;
    const toggleInfo = { article_id: +e.target.value, checked: e.target.checked, keyword_name, keyword_id, tone, url, tags, deleted, processed, bookmarked };
    onChange({ action, info: toggleInfo });
  }

  const creatorsPlaces = [
    { subtype: 'creator', tooltip: 'CREATOR', tooltipBtn: 'CREATOR_AUDIENCE' },
    { subtype: 'publication_place', tooltip: 'PLACE_MENTION', tooltipBtn: 'AUDIENCE_PLACE' }
  ];

  function checkSingleHighlight(hl) {
    if (hl) {
      try {
        const reg = /^(\.|\*|\+|\^|\$|\(|\)|\{|\}|\|)$/g;
        const h2 = hl?.split(',')?.map(item => item?.trim().replace(reg, '\\$1')).join('|');
        return hl ? new RegExp(`${h2}`, 'gi') : '';
      } catch (e) {
        return '';
      }
    }
    return '';
  }

  const regex = checkSingleHighlight(keyword?.highlight);

  return (
    <>
      {articles?.length ? articles?.map(article => (
        <div
          className={cn(
            classes.article,
            article.tone === 'positive' && classes.article_positive,
            article.tone === 'negative' && classes.article_negative,
            article.tone === 'neutral' && classes.article_neutral,
            !article?.viewed && classes.article_viewed
          )}
          key={article.id}
        >
          <div
            className={cn(
              classes.contentWrapper,
              article?.id === detailsId && classes.contentWrapper_arrow,
              article?.id === detailsId && !article?.viewed && classes.contentWrapper_arrow_viewed
            )}
            onClick={(e) => {
              const isShow = /contentWrapper|titleWrapper|textContentWrapper|footerWrapper|date/.test(e.target.className);
              isShow && onVisible({ keyword_id: keyword?.id, article_id: article.id, viewed: article?.viewed });
            }}
          >
            {!article?.viewed && (
            <Tooltip text={t('btn', 'UNREAD_MENTION', language)} className={cn(classes.tooltip)}>
              <p className={cn(classes.viewed)} />
            </Tooltip>
            )}
            {!!article?.bookmarked && <Icon type="original_bookmark" className={classes.iconOriginal} />}
            <div
              className={cn(classes.headerWrapper)}
            >
              <div className={cn(classes.titleWrapper)}>
                <label htmlFor={article.id} className={classes.checkboxLabel}>
                  <input
                    type="checkbox"
                    className={classes.visually_hidden}
                    onChange={e => onHandleChange(e, { keyword_id: keyword?.id, keyword_name: keyword?.name, article, action: 'toggle' })}
                    value={article.id}
                    id={article.id}
                  />
                  <div
                    className={cn(
                      classes.checkbox,
                      isSelect({ id: article.id }) && classes.checkbox_selected
                    )}
                  >
                    {isSelect({ id: article.id }) && (
                    <Icons icon="check" className={classes.iconCheck} />
                    )}
                  </div>
                </label>
                <span className={classes.textContentWrapper}>
                  <BodyHtml
                    className={classes.textContentWrapper_body}
                    html={regex ? article.title.replaceAll(regex, match => (`<b class=${classes.titleHighLight}>${match}</b>`)) : article.title}
                  />
                </span>
              </div>
              <div className={cn(classes.date)}>{moment(article?.date).format('DD MMM YYYY HH:mm')}</div>
            </div>
            <TagsList onRemove={onRemove} tags={article?.tags} article_id={article?.id} />
            <div className={cn(classes.footerWrapper)} id={`article-footer-${article?.id}`}>
              <div className={cn(classes.footerWrapper_container)}>
                <ErrorBoundarySentry language={language}>
                  <SourcePopover onSelect={onHandleSubmit} info={article} language={language} />
                </ErrorBoundarySentry>
                <div className={classes.creatorsPlaces}>
                  {!!creatorsPlaces?.length && creatorsPlaces.map((card, i) => (
                    <CreatorPlacePopover
                      key={i}
                      nextArrow={i === 1}
                      info={article}
                      idBind={`article-footer-${article?.id}`}
                      subtype={card.subtype}
                      language={language}
                      tooltip={t('creatorPlaceCard', card.tooltip, language)}
                      tooltipBtn={t('creatorPlaceCard', card.tooltipBtn, language)}
                      onSubmit={onHandleSubmit}
                    />
                  ))}
                </div>
              </div>
              <div className={cn(classes.footerWrapper_container, classes.footerWrapper_icons)}>
                <IconsListMedia info={article} language={language} onSubmit={onHandleSubmit} idBind={`article-footer-${article?.id}`} />
              </div>
            </div>
          </div>
        </div>
      )) : <span className={classes.noArticles}>{t('notFound', 'NO_ARTICLES', language)}</span>}
    </>
  );
}

ArticlesList.propTypes = {
  articles: arrayOf(shape({})),
  isSelect: func,
  onChange: func,
  onSubmit: func,
  onRemoveTag: func,
  onVisible: func,
  language: string,
  keyword: shape({}),
  detailsId: oneOfType([string, number])
};
export default Sentry.withProfiler(ArticlesList);
