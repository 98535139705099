/* eslint-disable prefer-regex-literals */
export const validationSearch = value => /[\\(\\)#$\\^&*+\\[\]\\|\\?]/.test(value);

export const validationNumber = value => /[a-zA-Z_~`\\(\\)!#@$%\\^&*+=\-\\[\]\\';,.\\/{}|\\":<>\\?]/.test(value);

export const validationNumberAndLetters = value => /[a-zA-Z_~`\\1234567890(\\)!#@$%\\^&*+=\-\\[\]\\';,.\\/{}|\\":<>\\?]/.test(
  value
);

export const validationEmail = value => new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'gi').test(value);
