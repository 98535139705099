import { string, func, bool, shape, oneOfType } from 'prop-types';
import cn from 'classnames';
import { InfoBtn } from 'Components';
import classes from './TextAreaField.module.scss';

const TextField = ({
  value = '',
  name = '',
  labelText = '',
  type = 'text',
  onChange = () => null,
  className = '',
  classNameForInput = '',
  classNameLabelText = '',
  borderRadius = false,
  boxShadow = false,
  placeholder = '',
  errorText = '',
  infoButton = false,
  required = false,
  TextTooltip = '',
  autoComplete = 'off',
  readonly = false
}) => (
  <div className={cn(classes.textAreaField, className)}>
    <label className={classes.label}>
      {labelText && (
      <p className={cn(classes.labelText, classNameLabelText)}>
        {labelText}
      </p>
      )}
      {infoButton && (
      <InfoBtn className={classes.info} text={TextTooltip} icon="error_outline" />
      )}
      <div className={classes.inputWrap}>
        <textarea
          autoComplete={autoComplete}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={
              placeholder || ''
            }
          required={required}
          readOnly={readonly}
          className={cn(
            classes.textAreaInput,
            classes.classTextArea,
            borderRadius && classes.textAreaInput_borderRadius,
            boxShadow && classes.textAreaInput_boxShadow,
            errorText && classes.textAreaInput_error,
            infoButton && classes.textAreaInput_infoButton,
            classNameForInput
          )}
        />
      </div>
    </label>
    {errorText && <p className={classes.errorText}>{errorText}</p>}
  </div>
);

TextField.propTypes = {
  value: oneOfType([string, shape({})]),
  name: string,
  labelText: string,
  type: string,
  onChange: func,
  className: string,
  classNameForInput: string,
  classNameLabelText: string,
  borderRadius: bool,
  boxShadow: bool,
  placeholder: string,
  errorText: string,
  infoButton: bool,
  required: bool,
  readonly: bool,
  TextTooltip: string,
  autoComplete: string
};

export default TextField;
