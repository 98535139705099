export const menuConfig = [

  { name: 'DELETE', icon: 'Icn_Delete', action: 'del' },
  { name: 'RENAME', icon: 'Icn_Edit', action: 'edit' }
];

export const widgetMenu = [
  { name: 'DELETE_WIDGET', icon: 'Icn_Delete', action: 'del_widget' },
  {
    name: 'RENAME_WIDGET',
    icon: 'Icn_dashboard_customize',
    action: 'edit_widget'
  },
  { name: 'DOWNLOAD', icon: 'Icn_download', action: 'download_widget' }
];

export const chartMenu = [
  { name: 'DELETE', icon: 'Icn_Delete', action: 'del_chart' },
  { name: 'EDIT', icon: 'Icn_Edit', action: 'edit_chart' },
  { name: 'DOWNLOAD', icon: 'Icn_download', action: 'download_chart' },
  { name: 'COPY_IN', icon: 'Icn_copy_chart', action: 'copy_chart' }
];

export const customChartMenu = [
  { name: 'DELETE', icon: 'Icn_Delete', action: 'del_chart' },
  { name: 'EDIT', icon: 'Icn_Edit', action: 'edit_chart' },
  { name: 'DOWNLOAD', icon: 'Icn_download', action: 'download_chart' },
  { name: 'COPY', icon: 'Icn_copy_chart', action: 'copy_chart' }
];

export const helpMenu = [
  { name: 'Чат', icon: 'Icn_Chat', action: 'chat' },
  { name: 'Что нового в системе', icon: 'Icn_Whats-new', action: 'new' },
  { name: 'База знаний', icon: 'Icn_Helplooqme', action: 'help' },
  { name: 'Блог', icon: 'Icn_Blog', action: 'blog' },
  { name: 'Отправить отзыв', icon: 'Icn_Feedback', action: 'feedback' }
];

export const downloadMenu = [
  { name: 'PDF', icon: 'Icn_PDF', action: 'pdf', info: '' },
  { name: 'HTML', icon: 'Icn_HTML', action: 'html', info: '' },
  { name: 'ZIP', icon: 'Icn_ZIP', action: 'htmlzip', info: '' },
  { name: 'XLS', icon: 'Icn_XLS', action: 'xlsex', info: '' },
  { name: 'DOCX', icon: 'Icn_DOCX', action: 'docx', info: '' }
];
export const downloadSelectMenu = [
  { label: 'PDF', value: 'pdf' },
  { label: 'HTML', value: 'html' },
  { label: 'ZIP', value: 'htmlzip' },
  { label: 'XLS', value: 'xlsex' },
  { label: 'DOCX', value: 'docx' }
];

export const userMenu = [
  { name: 'SETTING', icon: 'Icn_account', action: 'account' },
  { name: 'EXIT', icon: 'Icn_Exit', action: 'exit' }
];

export const sourcePopoversMenu = [
  { name: 'COPY_LINK', icon: 'link', action: 'copyLink' },
  { name: 'FILTER_MENTIONS', icon: 'filter', action: 'filterMentions' },
  { name: 'BLOCK_SOURCE', icon: 'block', action: 'blockSource' },
  { name: 'SHOW_CARD', icon: 'card', action: 'showCard' },
  { name: 'REPORT_PROBLEM', icon: 'report', action: 'reportProblem' }
];

export const toneMenu = [
  { name: 'POSITIVE', icon: 'positive', action: 'changeTone' },
  { name: 'NEGATIVE', icon: 'negative', action: 'changeTone' },
  { name: 'NEUTRAL', icon: 'neutral', action: 'changeTone' }
];

export const roleMenu = [
  { name: 'SUBJECT', value: 'subject', action: 'changeRole' },
  { name: 'SECONDARY', value: 'secondary', action: 'changeRole' },
  { name: 'OBJECT', value: 'object', action: 'changeRole' }
];

export const pageMenuConfig = [
  { name: 'DELETE', icon: 'delete', action: 'delPage' },
  { name: 'RENAME', icon: 'edit', action: 'renamePage' }
];

export const tabMenuConfig = [
  { name: 'ALL_MENTIONS', icon: '', action: '' },
  { name: 'FAVORITE', icon: 'favorites', action: 'bookmarked' },
  { name: 'PROCESSED', icon: 'processed', action: 'processed' },
  { name: 'UNPROCESSED', icon: 'unprocessed', action: 'unprocessed' },
  { name: 'DELETED', icon: 'delete', action: 'deleted' }
];
