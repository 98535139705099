import moment from 'moment';
import { string, func, any } from 'prop-types';
import classes from './DateBox.module.scss';

function List({ dataArr = [], onClick = () => null, type = '' }) {
  const localeData = moment.localeData();
  const dateValue = value => (type === 'month' ? `${localeData.monthsParse(value) + 1}` : String(value));
  return (
    <ul className={classes.timeList}>
      {!!dataArr?.length && dataArr.map(data => (
        <li key={data} className={classes.timeWrap}>
          <button
            type="button"
            className={classes.time}
            onClick={() => onClick({ [type]: dateValue(data) })}
          >
            <p>{data}</p>
          </button>
        </li>
      ))}
    </ul>
  );
}

List.propTypes = {
  dataArr: any,
  onClick: func,
  type: string
};
export default List;
