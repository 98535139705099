import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { func, object, string } from 'prop-types';
import t from 'utils/translateFunc';
import { useGetNewsAllCount } from 'storage/queryHooks';
import { setCopilotUnread } from 'storage/actions';
import { useCopilotCreate, useCopilotUpdate, useCopilotDetail, useCopilotNewQuestion } from 'storage/copilot/requestsHooks';
import { openNotification } from 'Components';
import { Vector, Send, NotDisturb, ThumpUpNDown, Copy } from 'icons';
import BodyHtml from 'dangerously-set-html-content';
import classes from './CopilotMessages.module.scss';

function Messages({ chat = null, set_chat = () => null, questionText = '', setQuestionText = () => null }) {
  const [canSand, setCanSend] = useState(true);
  const [updateWithVerification, setUpdateWithVerification] = useState(true);
  const [showArticlesWasChangedAfter, setShowArticlesWasChangedAfter] = useState(null);
  const in_process = useSelector(state => state?.copilot?.in_process);
  const unread = useSelector(state => state?.copilot?.unread);
  const { language } = useSelector(state => state?.userInfo);
  const chekedArticles = useSelector(state => state?.news?.chekedArticles);
  const dispatch = useDispatch();
  const endOfChatRef = useRef(null);
  const { mutate: copilotDetail } = useCopilotDetail();
  const { mutate: copilotCreate } = useCopilotCreate();
  const { mutate: copilotNewQuestion } = useCopilotNewQuestion();
  const { mutate: copilotUpdate } = useCopilotUpdate();
  const { data: newsAllCount } = useGetNewsAllCount();

  useEffect(() => {
    if (in_process) {
      setCanSend(false);
    } else {
      setCanSend(true);
    }
  }, [in_process]);

  useEffect(() => {
    if ((!chat?.messages && chat?.id) || (chat?.id && chat?.id === unread)) {
      copilotDetail({ chat_id: chat.id }, {
        onSuccess: (resp) => {
          set_chat(resp);
          if (chat?.id === unread) dispatch(setCopilotUnread({ unread: null }));
        },
        onError: () => { console.log('error'); }
      });
    }
    if (chat?.was_articles_changed && chat?.messages?.length && !showArticlesWasChangedAfter) {
      setShowArticlesWasChangedAfter(chat.messages.length - 1);
    }
    endOfChatRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [chat, unread]);

  function onClickLike(msg_num) {
    copilotUpdate({ chat_id: chat.id, liked_messages_ids: [msg_num] }, {
      onSuccess: resp => set_chat(resp)
    });
  }

  function onClickDislike(msg_num) {
    copilotUpdate({ chat_id: chat.id, disliked_messages_ids: [msg_num] }, {
      onSuccess: resp => set_chat(resp)
    });
  }

  async function onClickCopy(msg_num) {
    let copied_text = chat?.messages?.length > msg_num ? (chat?.messages[msg_num]?.content || '') : '';

    copied_text = /^```html(?<content>[\s\S]*)```$/g.exec(copied_text)?.groups?.content || copied_text;
    copied_text = copied_text.replaceAll(/<\/?(\w+)(\s[\w\W]*?)?>/g, '');

    await navigator.clipboard.writeText(copied_text);
    openNotification({ message: 'Copied' });
  }

  function sendQuestion() {
    const q = questionText;

    const body = { question: questionText };
    if (chekedArticles) body.selected_articles_ids = chekedArticles.map(item => item.article_id);

    const messages = chat?.messages || [];
    messages.push({ content: q, role: 'human' });
    set_chat({ ...chat, messages });

    if (chat?.id) {
      body.with_verification = updateWithVerification;
      copilotNewQuestion({ chat_id: chat?.id, body }, {
        onSuccess: (resp) => {
          set_chat({ ...resp });
          setUpdateWithVerification(false);
        },
        onError: () => {
          const err_text = t('COPILOT', 'ERROR_MESSAGE', language);
          set_chat({ ...chat, messages, error: err_text, remote_task_status: 'FAILED' });
        }
      });
    } else {
      copilotCreate({ body }, {
        onSuccess: resp => set_chat({ ...resp }),
        onError: (err) => {
          const err_text = err?.response?.data?.error || t('COPILOT', 'ERROR_MESSAGE', language);
          set_chat({ messages, error: err_text, remote_task_status: 'FAILED' });
        }
      });
    }
    setQuestionText('');
  }

  function filterHTML(text) {
    if (!text) return null;
    text = /^```html(?<content>[\s\S]*)```$/g.exec(text)?.groups?.content || text;
    const allowed_tags = ['a', 'b', 'i', 'p', 'br', 'ul', 'ol', 'li'];
    const tags = new Set([...text.matchAll(/<\/?(\w+)(\s[\w\W]*?)?>/g)].map(item => item[1]));
    const not_allowed_tags = [...tags].filter(item => !allowed_tags.includes(item));

    if (not_allowed_tags?.length) {
      const re = new RegExp(`</?(?:${not_allowed_tags.join('|')})(\\s[\\w\\W]*?)?>`, 'g');
      return text.replaceAll(re, '');
    }
    return text;
  }

  return (
    <>
      <main className={classes.messages}>
        <div className={classes.copilotUser}>
          <Vector />
          <span>Q Insight</span>
        </div>
        <div className={classes.answer}>
          <div className={classes.message}>
            {t('COPILOT', 'INFO_MESSAGE_1', language)}
          </div>
        </div>
        <div className={classes.answer}>
          <div className={classes.message}>
            {t('COPILOT', 'INFO_MESSAGE_2_P1', language)}
            <br />
            &emsp;
            {t('COPILOT', 'INFO_MESSAGE_2_P2', language)}
            <br />
            &emsp;
            {t('COPILOT', 'INFO_MESSAGE_2_P3', language)}
            <br />
            &emsp;
            {t('COPILOT', 'INFO_MESSAGE_2_P4', language)}
            <br />
            <a href="#">{t('COPILOT', 'INFO_MESSAGE_2_P5', language)}</a>
          </div>
        </div>
        <div className={classes.answer}>
          <div className={classes.message}>
            {t('COPILOT', 'INFO_MESSAGE_3_P1', language)}
            <b>
              {newsAllCount?.count || 0}
              {t('COPILOT', 'INFO_MESSAGE_3_P2', language)}
            </b>
            {t('COPILOT', 'INFO_MESSAGE_3_P3', language)}
          </div>
        </div>
        {chat?.messages && (
          chat.messages.map((item, num) => (
            <>
              {item?.role === 'human' && (
                <div key={`${item?.role}-${item?.id}-${num}`} className={classes.question}>
                  <div className={classes.message}>
                    {item?.content}
                  </div>
                </div>
              )}
              {item?.role === 'ai' && (
                <>
                  <div className={classes.copilotUser}>
                    <Vector />
                    <span>Q Insight</span>
                  </div>
                  <div key={`${item?.role}-${item?.id}-${num}`} className={classes.answer}>
                    <BodyHtml
                      allowRerender
                      className={classes.message}
                      html={`${filterHTML(item?.content) || t('COPILOT', 'ERROR_MESSAGE', language)}`}
                    />
                  </div>
                  <div className={classes.actions}>
                    <div className={classes.action} onClick={() => (item?.id ? onClickLike(item?.id) : null)}>
                      <ThumpUpNDown
                        is_active={item?.is_liked === true}
                        color="green"
                      />
                    </div>
                    <div className={classes.action} onClick={() => (item?.id ? onClickDislike(item?.id) : null)}>
                      <ThumpUpNDown
                        is_active={item?.is_liked === false}
                        dislike
                        color="red"
                      />
                    </div>
                    <div className={classes.action} onClick={() => onClickCopy(num)}>
                      <Copy />
                    </div>
                  </div>
                </>
              )}
              {(chat?.id && num === showArticlesWasChangedAfter) && (
                <>
                  <div className={classes.copilotUser}>
                    <Vector />
                    <span>Q Insight</span>
                  </div>
                  <div className={classes.system}>
                    <div className={classes.message}>
                      {t('COPILOT', 'FILTER_WAS_CHANGED', language)}
                    </div>
                  </div>
                  <div className={classes.systemBtn} onClick={() => set_chat(null)}>
                    {t('COPILOT', 'START_NEW_DIALOG', language)}
                  </div>
                </>
              )}
            </>
          ))
        )}
        {chat?.remote_task_status === 'FAILED' && (
          <>
            <div className={classes.copilotUser}>
              <Vector />
              <span>Q Insight</span>
            </div>
            <div className={classes.answer}>
              <div className={classes.message}>
                {chat?.error || t('COPILOT', 'ERROR_MESSAGE', language)}
              </div>
            </div>
          </>
        )}
        {(chat?.remote_task_status === 'SCHEDULED' || chat?.remote_task_status === 'IN_PROGRESS') && (
          <>
            <div className={classes.copilotUser}>
              <Vector />
              <span>Q Insight</span>
            </div>
            <div className={classes.answer}>
              <div className={classes.message}>
                {t('COPILOT', 'WAITING_ANSWER', language)}
              </div>
            </div>
          </>
        )}
        <div ref={endOfChatRef} />
      </main>
      <footer className={classes.sendForm}>
        <form>
          <div className={classes.inputQuestion}>
            <textarea
              onChange={e => setQuestionText(e.target.value)}
              value={questionText}
              name="copilot_msg"
              placeholder={t('COPILOT', 'INPUT_TEXT', language)}
              tabIndex="0"
              disabled={!canSand}
              onKeyDown={(e) => { if (e.key === 'Enter') sendQuestion(); }}
            />
          </div>
          {canSand && (
            <div className={classes.sendButton} onClick={sendQuestion}>
              <Send />
            </div>
          )}
          {!canSand && (
            <div className={classes.sendButton}>
              <NotDisturb />
            </div>
          )}
        </form>
        <div className={classes.footerText}>
          {t('COPILOT', 'CHATGPT_WARNING', language)}
        </div>
      </footer>
    </>
  );
}

Messages.propTypes = {
  chat: object,
  set_chat: func,
  questionText: string,
  setQuestionText: func
};

export default Messages;
