import { string, bool } from 'prop-types';

export function ThumpUpNDown({ is_active = false, dislike = false, color = '#8086AB' }) {
  if (is_active) {
    return (
      <svg style={{ rotate: dislike ? '180deg' : '0deg' }} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0002 14H4.3335V5.33329L9.00016 0.666626L9.8335 1.49996C9.91127 1.57774 9.97516 1.68329 10.0252 1.81663C10.0752 1.94996 10.1002 2.07774 10.1002 2.19996V2.43329L9.36683 5.33329H13.0002C13.3557 5.33329 13.6668 5.46663 13.9335 5.73329C14.2002 5.99996 14.3335 6.31107 14.3335 6.66663V7.99996C14.3335 8.07774 14.3252 8.16107 14.3085 8.24996C14.2918 8.33885 14.2668 8.42218 14.2335 8.49996L12.2335 13.2C12.1335 13.4222 11.9668 13.6111 11.7335 13.7666C11.5002 13.9222 11.2557 14 11.0002 14ZM3.00016 5.33329V14H0.333496V5.33329H3.00016Z" fill={color} />
      </svg>
    );
  }
  return (
    <svg style={{ rotate: dislike ? '180deg' : '0deg' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_33_41357" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_33_41357)">
        <path d="M12.0002 14H4.66683V5.33329L9.3335 0.666626L10.1668 1.49996C10.2446 1.57774 10.3085 1.68329 10.3585 1.81663C10.4085 1.94996 10.4335 2.07774 10.4335 2.19996V2.43329L9.70016 5.33329H14.0002C14.3557 5.33329 14.6668 5.46663 14.9335 5.73329C15.2002 5.99996 15.3335 6.31107 15.3335 6.66663V7.99996C15.3335 8.07774 15.3224 8.16107 15.3002 8.24996C15.2779 8.33885 15.2557 8.42218 15.2335 8.49996L13.2335 13.2C13.1335 13.4222 12.9668 13.6111 12.7335 13.7666C12.5002 13.9222 12.2557 14 12.0002 14ZM6.00016 12.6666H12.0002L14.0002 7.99996V6.66663H8.00016L8.90016 2.99996L6.00016 5.89996V12.6666ZM4.66683 5.33329V6.66663H2.66683V12.6666H4.66683V14H1.3335V5.33329H4.66683Z" fill={color} />
      </g>
    </svg>
  );
}
ThumpUpNDown.propTypes = {
  is_active: bool,
  dislike: bool,
  color: string
};
