import { Suspense, lazy, useMemo } from 'react';
import { Animated, Checkbox, Button, SelectCustom, SkeletonSearchArticle } from 'Components';
import { array, bool, func, number, string } from 'prop-types';
import t from 'utils/translateFunc';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import classes from './SearchResultsArticles.module.scss';
import { stylesOrdering, stylesTabs } from './helpers';
import SearchResultsPaggination from '../SearchResultsPaggination';

const SearchArticleCard = lazy(() => import('../SearchArticleCard'));

function SearchResultsArticles({ show = false, isOperator = false, pagginationCount = 0, pageSize = 20, page = 1, ordering = '-created_date',
  onToggleFavoriteMany = () => null, onChangePageSize = () => null, onChangeOrdering = () => null, onChangeCheckedArticles = () => null, onChangePage = () => null,
  articles = [], checkedArticles = [], isSocial = false, isFavoriteTab = false }) {
  const checkedAll = !!articles?.length && !!checkedArticles?.length
  && isEqual(articles.map(({ id }) => ({ id })), checkedArticles.map(({ solr_id }) => ({ id: solr_id })));
  const isMobile = useCheckMobileScreen();
  function isSelectedId(id) {
    if (!!checkedArticles.length && checkedArticles?.some(item => item.solr_id === id)) return true;
    return false;
  }

  const needRemove = useMemo(() => {
    const removIds = checkedArticles?.filter(item => !!item?.favorited);
    const addIds = checkedArticles.filter(item => !item?.favorited);
    return addIds?.length < removIds?.length;
  });

  function onToggleAll() {
    onChangeCheckedArticles(checkedAll ? [] : articles.map(({ id, created, date, favorited }) => ({ solr_id: id, created_date: created, date, from_lsn: isSocial, favorited })));
  }

  function onToggleArticle({ id, created, date, favorited }) {
    const checkedItem = { solr_id: id, created_date: created, date, from_lsn: isSocial, favorited };
    if (checkedArticles?.length) {
      const isChecked = checkedArticles.some(item => item.solr_id === id);
      onChangeCheckedArticles(isChecked ? checkedArticles.filter(item => item?.solr_id !== id) : checkedArticles.concat(checkedItem));
    } else {
      onChangeCheckedArticles(checkedArticles.concat(checkedItem));
    }
  }

  const optionsOrdering = [
    { value: '-created_date', label: t('ordering', 'BY_DATE_NEWEST_FIRST') },
    { value: 'created_date', label: t('ordering', 'BY_DATE_OLDEST_FIRST') },
    { value: '-duplicates_count', label: t('ordering', 'DUBLICATES') },
    { value: '-source_attendance', label: t('ordering', 'SOURCE_ATTENDANCE') }
  ];
  const optionsOffset = [
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '200', label: '200' }
  ];
  const orderingValue = optionsOrdering.find(el => el.value === ordering);
  const offsetValue = optionsOffset.find(el => el.value === String(pageSize));

  return (
    <Animated visible={!!show} duration={300} className={classes.searchArticlesWrap}>
      <div className={classes.searchArticlesHeader}>
        <div className={cn(classes.checkboxHeaderContainer)}>
          <Checkbox isSelected={checkedAll} size={20} isSelectedSome={!checkedAll && !!checkedArticles?.length} onChange={onToggleAll} />
          {!!checkedArticles?.length && (
          <div>
            {t('search', 'RESULTS_SELECTED')}
            &nbsp;&ndash;&nbsp;
            {checkedAll ? articles.length : checkedArticles.length}
          </div>
          )}
        </div>
        <div className={classes.buttonBox}>
          { !!checkedArticles?.length && (
          <Button
            onClick={onToggleFavoriteMany}
            textButton={t('search', (needRemove || isFavoriteTab) ? 'REMOVE_FAVORITE' : 'ADD_FAVORITE')}
            purpleTransparent32
          />
          )}
          {/* {!!checkedArticles?.length && (
          <Button
            onClick={() => { }}
            textButton={t('btn', 'Завантажити')}
            purpleTransparent32
          />
          )} */}
          <SelectCustom
            value={offsetValue}
            onChange={({ value }) => { onChangePageSize(value); }}
            options={optionsOffset}
            className={classes.selectOffset}
            styles={stylesTabs(isMobile)}
            isRadio
          />
          <SelectCustom
            value={orderingValue}
            onChange={({ value }) => { onChangeOrdering(value); }}
            options={optionsOrdering}
            className={classes.select}
            styles={stylesOrdering(isMobile)}
            isRadio
          />
        </div>
      </div>
      <div className={classes.searchArticlesList}>
        {!!articles?.length && articles.map((article, i) => (
          <Suspense fallback={<SkeletonSearchArticle />} key={i}>
            <SearchArticleCard
              article={article}
              isOperator={isOperator}
              isSelectedId={isSelectedId}
              onToggleArticle={onToggleArticle}
              articleNumber={(page - 1) * pageSize + (i + 1)}
            />
          </Suspense>
        ))}
      </div>
      <SearchResultsPaggination articles={articles} pageSize={pageSize} count={pagginationCount || 0} page={page} onChangePage={onChangePage} />
    </Animated>
  );
}

SearchResultsArticles.propTypes = { show: bool,
  ordering: string,
  articles: array,
  checkedArticles: array,
  onChangePageSize: func,
  onChangeOrdering: func,
  onToggleFavoriteMany: func,
  onChangeCheckedArticles: func,
  onChangePage: func,
  pageSize: number,
  page: number,
  pagginationCount: number,
  isSocial: bool,
  isOperator: bool,
  isFavoriteTab: bool
};

export default SearchResultsArticles;
