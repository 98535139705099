import cn from 'classnames';
import t from 'utils/translateFunc';
import { func, number, shape } from 'prop-types';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from '../TemplateDrawer.module.scss';

function TimeLineComponent({ step = 1, onChangeStep = () => null, body = {} }) {
  const stepsItems = [{
    id: 1,
    name: 'main',
    title: t('reports', 'MAIN'),
    subtitle: t('reports', 'BASIC_REPORT_INFO')
  },
  {
    id: 2,
    name: 'content',
    title: t('reports', 'CHART_SELECTION'),
    subtitle: t('reports', 'CHARTS_INFORMATION')
  },
  {
    id: 3,
    name: 'display',
    title: t('reports', 'DISPLAY'),
    subtitle: t('reports', 'REPORT_DISPLAYED_INFO')
  },
  {
    id: 4,
    name: 'preview',
    title: t('reports', 'PREVIEW'),
    subtitle: t('reports', 'TEMPLATE_PREVIEW_AND_SAVE')
  }
  ];

  return (
    <div className={classes.timeLineWrapp}>
      {stepsItems.map((item, i) => (
        <div key={i} className={classes.timeLineItem}>
          <div className={cn(classes.timeLineTail, step > item.id && classes.timeLineTail_active)} />
          <div className={classes.timeLineHead}>
            <div className={cn(
              classes.dot,
              step === item.id && classes.dot_active,
              step > item.id && classes.dot_filled
            )}
            >
              <div className={cn(classes.smallDot, step > item.id && classes.smallDot_active)} />
            </div>
          </div>
          <div className={classes.timeLineContent}>
            <div
              className={cn(classes.dotlabel, step === item.id && classes.dotlabel_active)}
              onClick={() => {
                sendAlertsEvent({
                  category: `${body?.id ? 'Edit' : 'Add'} template`,
                  event: `template_step_${item?.name}`,
                  ...(item.name === 'preview' ? { properties: body } : {})
                });
                step !== item.id && onChangeStep(item.id);
              }}
            >
              <div>{item.title}</div>
              <div>{item.subtitle}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

TimeLineComponent.propTypes = {
  step: number,
  onChangeStep: func,
  body: shape({})
};

export default TimeLineComponent;
