/* eslint-disable react/jsx-filename-extension */
import t from 'utils/translateFunc';
import classes from './Instructions.module.scss';

export default function instructionLists(lang) {
  const operatorsList = [{
    buttonText: 'OR',
    description: t('operatorExamples', 'OR', lang),
    colorBg: true
  },
  {
    buttonText: 'AND',
    description: t('operatorExamples', 'AND', lang),
    colorBg: true
  },
  {
    buttonText: 'AND NOT',
    description: t('operatorExamples', 'AND_NOT', lang),
    colorBg: true
  },
  {
    buttonText: '" "',
    description: t('operatorExamples', 'QUOTES', lang),
    colorBg: true
  },
  {
    buttonText: '~10',
    description: t('operatorExamples', 'AMOUNT', lang),
    colorBg: true
  },
  {
    buttonText: '( )',
    description: t('operatorExamples', 'BRACKETS', lang),
    colorBg: true
  },
  {
    buttonText: '/ /',
    description: t('operatorExamples', 'SLASHES', lang),
    colorBg: false
  },
  {
    buttonText: '[ ]',
    description: t('operatorExamples', 'SQUARE_QUOTES', lang),
    colorBg: false
  },
  {
    buttonText: '.{a,b}',
    description: t('operatorExamples', 'WORD_END', lang),
    colorBg: false
  },
  {
    buttonText: '?',
    description: t('operatorExamples', 'QUESTION_SIGN', lang),
    colorBg: false
  },
  {
    buttonText: '.',
    description: t('operatorExamples', 'DOT_SIGN', lang),
    colorBg: false
  }
  ];
  const fieldsList = [{
    buttonText: 'title:',
    description: t('filtersExamples', 'TITLE', lang),
    colorBg: true
  },
  {
    buttonText: 'text:',
    description: t('filtersExamples', 'TEXT', lang),
    colorBg: true
  },
  {
    buttonText: 'publication_place_name:',
    description: t('filtersExamples', 'PUBLICATION_PLACE_NAME', lang),
    colorBg: true
  },
  {
    buttonText: 'post_type:',
    description: t('filtersExamples', 'POST_TYPE', lang),
    colorBg: true
  },
  {
    buttonText: 'source_type:',
    description: t('filtersExamples', 'SOURCE_TYPE', lang),
    colorBg: true
  },
  {
    buttonText: 'language_code:',
    description: t('filtersExamples', 'LANG_CODE', lang),
    colorBg: true
  }

  ];
  const templatesCards = [{
    id: 'company_search',
    label: t('selectQueryTemplate', 'COMPANY_LABEL', lang),
    subtext: t('selectQueryTemplate', 'COMPANY_CONTENT_PUBLICATIONS', lang),
    imgClass: classes.companySearch
  },
  {
    id: 'person_search',
    label: t('selectQueryTemplate', 'PERSON_LABEL', lang),
    subtext: t('selectQueryTemplate', 'PERSON_CONTENT_PUBLICATIONS', lang),
    imgClass: classes.personSearch
  }
  // {
  //   id: 'no_template',
  //   label: t('selectQueryTemplate', 'NEWS_LABEL', lang),
  //   subtext: t('selectQueryTemplate', 'NEWS_CONTENT', lang),
  //   imgClass: classes.noTemplate
  // }
  ];

  const fastSearch = {
    operators: [
      { name: <>
        {t('search', 'OPTION', lang)}
        1
        <b>&#8596;</b>
        {t('search', 'OPTION', lang)}
        2
      </>,
      description: t('search', 'OPTIONS_WITH_SPACE', lang) },
      { name: '" "', description: t('search', 'USE_QUOTES', lang) },
      { name: '*', description: t('search', 'USE_ASTERISK', lang) }
    ],
    example: t('search', 'OPTIONS_WITH_SPACE_EXAMPLE', lang)
  };

  const advicesList = [
    {
      headerText: `🔥 ${t('search', 'COMBINATION_OPERATORS', lang)}`,
      cards: [{
        operator: { name: 'AND', description: t('search', 'SEARCH_PUBLICATIONS_CONTAINING_ALL_WORDS', lang) },
        example: t('search', 'SEARCH_PUBLICATIONS_CONTAINING_ALL_WORDS_EXAMPLE', lang)
      },
      { operator: { name: 'AND NOT', description: t('search', 'SEARCH_PUBLICATIONS_EXCLUDING_WORDS', lang) },
        example: t('search', 'SEARCH_PUBLICATIONS_EXCLUDING_WORDS_EXAMPLE', lang)
      }]
    },
    {
      headerText: `🔥 ${t('search', 'GROUPING_OPERATORS', lang)}`,
      cards: [{ operator: { name: '( )', description: t('search', 'GROUPING_WORDS', lang) },
        example: t('search', 'GROUPING_WORDS_EXAMPLE', lang)
      },
      { operator: { name: '" "', description: t('search', 'SEARCH_WORDS_WITHIN_QUOTES', lang) },
        example: t('search', 'SEARCH_WORDS_WITHIN_QUOTES_EXAMPLE', lang) },
      { operator: { name: '" "', name1: '~N', description: t('search', 'SEARCH_ALL_OBJECTS', lang) },
        example: t('search', 'SEARCH_ALL_OBJECTS_EXAMPLE', lang) }]
    },
    {
      headerText: `😇  ${t('search', 'SIMPLE_MORPHOLOGY_OPERATORS', lang)}`,
      cards: [{
        operator: { name: '*', description: t('search', 'REPLACES_ANY_NUMBER_OF_CHARACTERS', lang) },
        example: t('search', 'REPLACES_ANY_NUMBER_OF_CHARACTERS_EXAMPLE', lang)
      },
      { operator: { name: '?', description: t('search', 'REPLACES_ONE_CHARACTER', lang) },
        example: t('search', 'REPLACES_ONE_CHARACTER_EXAMPLE', lang) }]
    },
    {
      headerText: `😈 ${t('search', 'COMPLEX_MORPHOLOGY_OPERATORS', lang)}`,
      subText: t('search', 'DIFFERENCE_MORPHOLOGY', lang),
      cards: [{
        operator: { name: '[ ]', description: t('search', 'PROVIDE_ALTERNATING_LETTER', lang) },
        example: t('search', 'PROVIDE_ALTERNATING_LETTER_EXAMPLE', lang)
      },
      { operator: { name: '.{min,max}', description: t('search', 'COMPLEX_VERSION_OF_ASTERISK', lang) },
        example: t('search', 'COMPLEX_VERSION_OF_ASTERISK_EXAMPLE', lang) },
      { operator: { name: '?', description: t('search', 'MAKES_PRECEDING_CHARACTER_OPTIONAL', lang) },
        example: t('search', 'MAKES_PRECEDING_CHARACTER_OPTIONAL_EXAMPLE', lang) }
      ]
    },
    {
      headerText: `🌏 ${t('search', 'GEOGRAPHY_OPERATOR', lang)}`,
      cards: [{ operator: { name: 'source_country:', description: t('search', 'COUNTRY_NAME', lang) },
        example: t('search', 'GEOGRAPHY_OPERATOR_EXAMPLE', lang) }]
    }
  ];

  const adviceExamples = [
    {
      header: t('search', 'PERSONALITY_ALTERNATION', lang),
      header_example: t('search', 'PERSONALITY_ALTERNATION_HEADER_EXAMPLE', lang),
      header_link: { text: t('search', 'HERE', lang), url: '#' },
      example: t('search', 'PERSONALITY_ALTERNATION_EXAMPLE', lang)
    },
    {
      header: t('search', 'MINISTRY_NAME', lang),
      header_example: t('search', 'MINISTRY_OF_HEALTH_HEADER_EXAMPLE', lang),
      example: t('search', 'MINISTRY_OF_HEALTH_EXAMPLE', lang)
    },
    {
      header: t('search', 'SEARCH_WORDS_DISTANCE', lang),
      header_example: t('search', 'SEARCH_WORDS_DISTANCE_HEADER_EXAMPLE', lang),
      example: t('search', 'SEARCH_WORDS_DISTANCE_EXAMPLE', lang)
    },
    {
      header: t('search', 'SEARCH_PHRASE', lang),
      header_example: t('search', 'SEARCH_PHRASE_HEADER_EXAMPLE', lang),
      example: t('search', 'SEARCH_PHRASE_EXAMPLE', lang)
    }
  ];
  return { advicesList, templatesCards, fieldsList, fastSearch, operatorsList, adviceExamples };
}
