import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonArticle } from 'Components';
import cn from 'classnames';
import { string, node, bool } from 'prop-types';
import classes from './SkeletonMention.module.scss';

export function SkeletonMention({ fetching = false, children = null, className = '' }) {
  const arr = new Array(7).fill(null);
  return (fetching ? (
    <div className={cn(classes.content, className)}>
      <div className={cn(classes.articlesList)}>
        <SkeletonTheme baseColor="#f6f7fa" highlightColor="#e0e0f3">
          <Skeleton count={3} className={classes.header} containerClassName={classes.headerWrapper} inline />
          <Skeleton className={classes.progressBar} containerClassName={classes.headerWrapper} inline />
          {arr.map((_, i) => <SkeletonArticle key={i} />)}
        </SkeletonTheme>
      </div>
    </div>
  ) : children);
}

SkeletonMention.propTypes = {
  fetching: bool,
  children: node,
  className: string
};
export default SkeletonMention;
