import t from 'utils/translateFunc';
import cn from 'classnames';
import { Icon } from 'Components';
import { func, string, bool, number } from 'prop-types';
import classes from './FiltersHeader.module.scss';

function FiltersHeader({
  onResetFilters = () => null,
  addNewFilter = () => null,
  tab = 'search',
  favoriteCount = 0,
  onChangeTab = () => null,
  hasChanges = false }) {
  return (
    <div className={classes.filtersHeader}>
      <div className={classes.tabSwitcher}>
        <div
          className={cn(classes.tab, tab === 'search' && classes.tab_active)}
          onClick={() => { onChangeTab('search'); }}
        >
          <Icon
            type="search"
            width={16}
            height={16}
            color={tab !== 'search' ? '#fff' : '#000'}
            className={classes.tabText}
            text={<span>{t('search', 'SEARCH')}</span>}
          />
        </div>
        <div
          className={cn(classes.tab, tab === 'favorite' && classes.tab_active)}
          onClick={() => { onChangeTab('favorite'); }}
        >
          <Icon
            type="kid_star"
            color={tab !== 'favorite' ? '#fff' : '#000'}
            className={classes.tabText}
            text={(
              <>
                <span>{t('search', 'FAVORITE')}</span>
                <span className={classes.favoriteCount}>{favoriteCount}</span>
              </>
)}
          />
        </div>
        <span className={cn(classes.switchSlider, tab === 'favorite' && classes.switchSlider_right)} />
      </div>
      <div className={classes.actionsBlock}>
        <div>{t('search', 'FILTERS')}</div>
        <div className={classes.buttonsBox}>
          {tab !== 'favorite' && (
          <Icon
            type="add_round"
            role="button"
            color="#7A84A7"
            width={24}
            height={24}
            onClick={addNewFilter}
            tooltipText={t('tooltip', 'CREATE_FILTER')}
            className={classes.actionBtn}
          />
          )}
          <Icon
            type="restart"
            role="button"
            color={hasChanges ? '#5319A5' : '#7A84A7'}
            tooltipText={t('tooltip', 'RESET_FILTERS')}
            className={classes.actionBtn}
            onClick={onResetFilters}
          />
        </div>

      </div>
    </div>
  );
}

FiltersHeader.propTypes = {
  onResetFilters: func,
  addNewFilter: func,
  tab: string,
  onChangeTab: func,
  hasChanges: bool,
  favoriteCount: number
};

export default FiltersHeader;
