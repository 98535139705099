import { number, string } from 'prop-types';

export const Edit = ({ color = '#A7B0C8', width = 13, height = 13 }) => (
  <svg width={width || 13} height={height || 13} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.33333 11.1667H2.26667L8.01667 5.41668L7.08333 4.48334L1.33333 10.2333V11.1667ZM10.8667 4.45001L8.03333 1.65001L8.96667 0.716677C9.22222 0.461121 9.53611 0.333344 9.90833 0.333344C10.2806 0.333344 10.5944 0.461121 10.85 0.716677L11.7833 1.65001C12.0389 1.90557 12.1722 2.2139 12.1833 2.57501C12.1944 2.93612 12.0722 3.24445 11.8167 3.50001L10.8667 4.45001ZM0.666667 12.5C0.477778 12.5 0.319444 12.4361 0.191667 12.3083C0.0638889 12.1806 0 12.0222 0 11.8333V9.95001C0 9.86112 0.0166667 9.77501 0.05 9.69168C0.0833333 9.60834 0.133333 9.53334 0.2 9.46668L7.06667 2.60001L9.9 5.43334L3.03333 12.3C2.96667 12.3667 2.89167 12.4167 2.80833 12.45C2.725 12.4833 2.63889 12.5 2.55 12.5H0.666667Z" fill={color || '#A7B0C8'} />
  </svg>

);

Edit.propTypes = {
  width: number,
  height: number,
  color: string
};
