import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  dateObj: {
    start: moment(new Date())?.startOf('day')?.subtract(1, 'week')?.format('YYYY-MM-DD HH:mm'),
    end: moment(new Date())?.endOf('minute')?.format('YYYY-MM-DD HH:mm'),
    by_created: false
  },
  periodLoaded: false
};

const datePickerSlice = createSlice({
  name: 'datePicker',
  initialState,
  reducers: {
    setDatePicker(state, { payload }) {
      if (payload) {
        state.dateObj = payload;
      }
      !state.periodLoaded && (state.periodLoaded = true);
    }
  }
});

export const { setDatePicker } = datePickerSlice.actions;
export default datePickerSlice.reducer;
