import { useEffect, useRef, useState } from 'react';
import { select } from 'd3-selection';
import ResizeObserver from 'resize-observer-polyfill';

export function useResponsiveSvgSelection(minSize, initialSize, svgAttributes) {
  const elementRef = useRef();
  const [size, setSize] = useState(initialSize);
  const [selection, setSelection] = useState(null);

  useEffect(() => {
    const element = elementRef.current;
    let svg = select(element)
      .append('svg')
      .style('display', 'block');

    if (typeof svgAttributes === 'object') {
      Object.keys(svgAttributes).forEach((key) => {
        svg = svg.attr(key, svgAttributes[key]);
      });
    }
    const selectionSvg = svg.append('g');
    setSelection(selectionSvg);

    function updateSize(width, height) {
      svg.attr('height', height).attr('width', width);
      selectionSvg.attr('transform', `translate(${width / 2}, ${height / 2})`);
      setSize([width, height]);
    }

    let width = 0;
    let height = 0;
    if (initialSize === undefined) {
      width = element.parentElement.offsetWidth;
      height = element.parentElement.offsetHeight;
    } else {
      [width, height] = initialSize;
    }

    width = Math.max(width, minSize[0]);
    height = Math.max(height, minSize[1]);
    updateSize(width, height);

    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries || entries.length === 0) {
        return;
      }

      if (initialSize === undefined) {
        const { width: _width, height: _height } = entries[0].contentRect;
        updateSize(_width, _height);
      }
    });
    resizeObserver.observe(element);

    // Cleanup
    return () => {
      resizeObserver.unobserve(element);
      select(element)
        .selectAll('*')
        .remove();
    };
  }, [initialSize, minSize, svgAttributes]);

  return [elementRef, selection, size];
}
