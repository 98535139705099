import { useState, useMemo } from 'react';
import { string, func, shape, number, arrayOf } from 'prop-types';
import cn from 'classnames';
import { Icon, Tooltip } from 'Components';
import classes from './CheckboxGroup.module.scss';

const CheckboxGroup = ({
  className = '',
  groupName = '',
  onEditData = () => null,
  selectedList = [],
  dataList = []
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const selectedItems = useMemo(() => ((selectedList?.length && dataList?.length)
    ? selectedList?.filter(selectDataItem => dataList.find(el => el?.id === selectDataItem))?.length : 0), [selectedList, dataList]);
  const isSelectAll = selectedItems && dataList?.length === selectedItems;

  const onToggle = (filter, isSelect) => {
    isSelect
      ? onEditData(selectedList?.filter(selectDataItem => selectDataItem !== filter.id))
      : onEditData([...selectedList, filter.id]);
  };

  const onToggleAll = () => {
    const ids = dataList?.length ? dataList?.map(item => item.id) : [];
    !isSelectAll
      ? onEditData(Array.from(new Set([...selectedList, ...ids])))
      : onEditData(selectedList.filter(item => !dataList.some(el => el?.id === item)));
  };

  return (
    <div className={classes.checkboxGroupWrap}>
      <div className={classes.checkboxGroupName}>
        <button
          className={cn(
            classes.openButton,
            isOpen && classes.openButton_open,
            !dataList?.length && classes.openButton_disabled
          )}
          onClick={() => {
            !!dataList?.length && setIsOpen(!isOpen);
          }}
          type="button"
        >
          <Icon type="arrow_down" color="#8086AB" />
        </button>
        <label className={classes.checkboxLabel}>
          <input
            className={classes.visually_hidden}
            type="checkbox"
            disabled={!dataList?.length}
            checked={!!(isSelectAll || selectedItems)}
            onChange={onToggleAll}
          />
          <div className={cn(
            classes.checkbox,
            !!(isSelectAll || selectedItems) && classes.checkbox_selected,
            !dataList?.length && classes.checkbox_disabled
          )}
          >
            {!!(isSelectAll || selectedItems) && (<Icon type={isSelectAll ? 'checked' : 'checked_minus'} className={classes.iconCheck} />)}
          </div>
          {`${groupName} (${selectedItems} / ${dataList?.length || 0})` }
        </label>
      </div>
      <ul
        className={cn(
          classes.checkboxGroup,
          !isOpen && classes.checkboxGroup_close,
          className
        )}
      >
        {dataList?.map((filter) => {
          const isSelect = selectedList?.find(selectDataItem => selectDataItem === filter.id);
          const text = filter.name;
          return (
            <li
              key={filter?.id}
              className={cn(classes.checkboxItem)}
            >
              <label className={classes.checkboxLabel}>
                <input
                  className={classes.visually_hidden}
                  type="checkbox"
                  checked={!!isSelect}
                  onChange={() => onToggle(filter, !!isSelect)}
                />
                <div className={cn(classes.checkbox, !!isSelect && classes.checkbox_selected)}>
                  {!!isSelect && (<Icon type="checked" className={classes.iconCheck} />)}
                </div>
                <Tooltip
                  text={String(text).length > 100 ? text : ''}
                  className={classes.tooltip}
                >
                  {text}
                </Tooltip>
              </label>
            </li>
          );
        })}
      </ul>
    </div>

  );
};

CheckboxGroup.propTypes = {
  className: string,
  groupName: string,
  selectedList: arrayOf(number),
  dataList: arrayOf(shape({ })),
  onEditData: func
};

export default CheckboxGroup;
