import { string } from 'prop-types';

export const Processed = ({ color = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
    <path d="M11.4708 6.14191L7.12803 10.4847C6.82879 10.784 6.3454 10.784 6.04617 10.4847L3.87476 8.31332C3.57552 8.01408 3.57552 7.53069 3.87476 7.23145C4.174 6.93221 4.65739 6.93221 4.95663 7.23145L6.58326 8.85809L10.3813 5.06005C10.6805 4.76081 11.1639 4.76081 11.4632 5.06005C11.7701 5.35929 11.7701 5.84268 11.4708 6.14191ZM1.53456 7.5C1.53456 5.71224 2.31719 4.10862 3.54483 2.98839L4.71877 4.16233C4.95663 4.40019 5.37096 4.23139 5.37096 3.88611V0.594482C5.37096 0.379643 5.20216 0.210842 4.98732 0.210842H1.69569C1.35041 0.210842 1.18161 0.625173 1.42714 0.863029L2.4553 1.89886C0.951427 3.29531 0 5.28256 0 7.5C0 11.1446 2.54737 14.1984 5.95409 14.981C6.43748 15.0884 6.90552 14.7278 6.90552 14.229C6.90552 13.8684 6.65232 13.5615 6.29937 13.4771C3.57552 12.8556 1.53456 10.4157 1.53456 7.5ZM15.3456 7.5C15.3456 3.85542 12.7982 0.801647 9.39151 0.0190217C8.90812 -0.0883975 8.44008 0.272224 8.44008 0.770956C8.44008 1.13158 8.69328 1.43849 9.04623 1.52289C11.7701 2.14439 13.811 4.58434 13.811 7.5C13.811 9.28776 13.0284 10.8914 11.8008 12.0116L10.6268 10.8377C10.389 10.5998 9.97464 10.7686 9.97464 11.1139V14.4055C9.97464 14.6204 10.1434 14.7892 10.3583 14.7892H13.6499C13.9952 14.7892 14.164 14.3748 13.9185 14.137L12.8903 13.1011C14.3942 11.7047 15.3456 9.71744 15.3456 7.5Z" fill={color || '#8086AB'} />
  </svg>
);

Processed.propTypes = {
  color: string
};
