import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { shape, func, arrayOf } from 'prop-types';
import classes from './DrugBlocks.module.scss';
import Block from './Block';

const DrugBlocks = ({ blockOrderOptions = [], onChangeOrder = () => null }) => {
  const [blocks, setBlocks] = useState(blockOrderOptions);
  const moveBlock = (dragIndex, hoverIndex) => {
    const dragBlock = blocks[dragIndex];
    const updatedBlocks = [...blocks];
    updatedBlocks.splice(dragIndex, 1);
    updatedBlocks.splice(hoverIndex, 0, dragBlock);
    setBlocks(updatedBlocks);
    onChangeOrder(updatedBlocks.map(el => el?.value));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.blocksWrap}>
        {blocks.map((block, index) => (
          <Block
            key={index}
            blockValue={block.value}
            blockLabel={block.label}
            index={index}
            moveBlock={moveBlock}
          />
        ))}
      </div>
    </DndProvider>
  );
};

DrugBlocks.propTypes = {
  blockOrderOptions: arrayOf(shape),
  onChangeOrder: func
};

export default DrugBlocks;
