import { api } from 'api/apiInstance';
import { addQueryParams } from 'utils/common';

export const getDownloadReports = async (params) => {
  const queryString = addQueryParams(params);
  const response = await api.get(`downloads/files/${queryString}`);
  return response?.data;
};

export const getDownloadReportId = async (id) => {
  const response = await api.get(`downloads/files/${id}`);
  return response?.data;
};
