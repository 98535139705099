import { Tag } from 'antd';
import cn from 'classnames';
import { useGetDownloadReportId } from 'storage/queryHooks';
import { shape, string, oneOfType, number } from 'prop-types';

function StatusOption({ classes = {}, status = '', id = '', filtersStatus = {} }) {
  useGetDownloadReportId({ status, id });
  return (
    <Tag
      className={cn(
        classes.tag,
        status === 'finished' && classes.tag_ok,
        status === 'failed' && classes.tag_error,
        status === 'cancelled' && classes.tag_error,
        status === 'in queue' && classes.tag_pending,
        status === 'processing' && classes.tag_forming,
        status === 'archived' && classes.tag_pending
      )}
    >
      {filtersStatus[status] || filtersStatus.default }
    </Tag>
  );
}

StatusOption.propTypes = {
  classes: shape({}),
  status: string,
  id: oneOfType([number, string]),
  filtersStatus: shape({})

};
export default StatusOption;
