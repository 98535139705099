import { useState } from 'react';
import { string, func, shape, number, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, TextField } from 'Components';
import classes from './BodyDelKeyword.module.scss';

const BodyDelKeyword = ({ className = '',
  onCancel = () => null,
  onDel = () => null,
  language = 'uk',
  data = { name: '', id: 0 } }) => {
  const [name, setName] = useState('');
  const [errorKw, setErrorKw] = useState('');
  const onSubmit = (e) => {
    e.preventDefault();
    if (checkFields()) {
      onDel({ keyword_id: data?.keyword_id });
      onCancel();
    }
  };

  const onChangeKw = (e) => {
    errorKw && setErrorKw('');
    setName(e.target.value);
  };

  function checkFields() {
    name.length < 2 && setErrorKw(t('error', 'MIN_SYMBOLS', language));
    !name.length && setErrorKw(t('error', 'ERR_REQUIRED', language));
    name.length >= 2 && name !== data?.name && setErrorKw(t('error', 'NAME_ERROR', language));
    return name.length >= 2 && data?.name === name;
  }
  return (
    <form onSubmit={onSubmit} className={cn(classes.bodyDelModal, className)}>
      <div className={classes.discription}>
        {t('bodyDelKeyword', 'CONFIRM_DELETE_1', language)}
        {data?.name ? <strong>{` ${data?.name}. `}</strong> : '. '}
        {t('bodyDelKeyword', 'CONFIRM_DELETE_2', language)}
      </div>
      <TextField
        labelText={t('bodyDelKeyword', 'KEYWORD', language)}
        value={name}
        onChange={onChangeKw}
        placeholder={t('bodyDelKeyword', 'ENTER_KEYWORD_NAME', language)}
        boxShadow
        errorText={errorKw}
        className={classes.kwField}
      />
      <div className={classes.buttonBox}>
        <Button
          type="submit"
          onClick={onSubmit}
          textButton={t('btn', 'DELETE', language)}
          className={classes.buttonDel}
          pink32
        />
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          purpleTransparent32
        />
      </div>
    </form>
  );
};

BodyDelKeyword.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCancel: func,
  onDel: func,
  language: string
};

export default BodyDelKeyword;
