import { useMemo, useRef } from 'react';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { string, func } from 'prop-types';
import { Button, ErrorBoundarySentry, CodeMirror } from 'Components';
import classes from './SelectQueryTemplate.module.scss';

function SelectQueryTemplate({ language = 'uk', template = '', templateQuery = '', onSelect = () => null, onChangeTemplate = () => null }) {
  const ref = useRef();
  const templatesCards = useMemo(() => [{
    id: 'company_search',
    label: t('selectQueryTemplate', 'COMPANY_LABEL', language),
    subtext: t('selectQueryTemplate', 'COMPANY_CONTENT', language),
    imgClass: classes.companySearch
  },
  {
    id: 'person_search',
    label: t('selectQueryTemplate', 'PERSON_LABEL', language),
    subtext: t('selectQueryTemplate', 'PERSON_CONTENT', language),
    imgClass: classes.personSearch
  },
  {
    id: 'no_template',
    label: t('selectQueryTemplate', 'WITHOUT_TEMPLATE_LABEL', language),
    subtext: t('selectQueryTemplate', 'WITHOUT_TEMPLATE_CONTENT', language),
    imgClass: classes.noTemplate
  }
  ], []);

  return (
    <div className={classes.selectQueryTemplate}>
      <div className={classes.selectQueryTemplateBlock}>
        <div className={classes.templatesHeader}>{t('selectQueryTemplate', 'HEADER', language)}</div>
        <div className={classes.templatesCards}>
          {templatesCards.map(tepl => (
            <div
              key={tepl.id}
              className={cn(
                classes.templatesCard,
                template === tepl.id && classes.templatesCard_selected
              )}
              onClick={() => onChangeTemplate(tepl.id)}
            >
              <div className={classes.cardImage}>
                <div className={tepl.imgClass} />
              </div>
              <div className={classes.cardHeader}>{tepl.label}</div>
              <div className={classes.cardText}>{tepl.subtext}</div>
            </div>
          ))}
        </div>
        <div className={classes.templateQueryWrap}>
          <div className={classes.templateQueryLabel}>{t('advancedBuilder', 'QUERY_SEARCH', language)}</div>
          <ErrorBoundarySentry language={language}>
            <CodeMirror
              code={templateQuery}
              editable={false}
              customProps={{
                height: 'calc(100vh - 470px)',
                maxHeight: 'calc(100vh - 470px)'
              }}
              ref={ref}
            />
          </ErrorBoundarySentry>
        </div>
      </div>
      <div className={classes.buttonBox}>
        <Button
          onClick={onSelect}
          textButton={t('btn', 'SELECT_TEMPLATE', language)}
          green32
          className={classes.nextBtn}
        />
      </div>
    </div>
  );
}

SelectQueryTemplate.propTypes = {
  language: string,
  templateQuery: string,
  template: string,
  onSelect: func,
  onChangeTemplate: func
};

export default SelectQueryTemplate;
