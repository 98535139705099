import { iconConfig } from './sourceConfig';

export const sourcePopoverColorsConfig = {
  facebook: {
    background: 'rgba(218, 232, 255, 1)',
    backgroundActive: 'rgb(234,244,255)',
    text: 'rgba(9, 102, 255, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  instagram: {
    background: 'rgba(255, 242, 247, 1)',
    backgroundActive: 'rgb(255,249,252)',
    text: 'rgba(255, 0, 106, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  whatsapp: {
    background: 'rgb(169,243,196)',
    backgroundActive: 'rgba(202, 238, 221, 1)',
    text: 'rgba(0, 101, 96, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  twitter: {
    background: 'rgba(234, 234, 234, 1)',
    backgroundActive: 'rgb(243,243,243)',
    text: 'rgba(59, 59, 59, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  youtube: {
    background: 'rgba(255, 236, 236, 1)',
    backgroundActive: 'rgb(255,248,248)',
    text: 'rgba(255, 3, 2, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  telegram: {
    background: 'rgba(209, 240, 255, 1)',
    backgroundActive: 'rgb(235,248,255)',
    text: 'rgba(39, 165, 228, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  tiktok: {
    background: 'rgba(255, 231, 238, 1)',
    backgroundActive: 'rgb(255,244,247)',
    text: 'rgba(255, 0, 79, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  linkedin: {
    background: 'rgba(218, 232, 255, 1)',
    backgroundActive: 'rgb(241,246,255)',
    text: 'rgba(10, 102, 194, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  google_maps: {
    background: 'rgb(168,250,198)',
    backgroundActive: 'rgba(202, 238, 221, 1)',
    text: 'rgba(0, 101, 96, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  internet: {
    background: 'rgb(217, 224, 249)',
    backgroundActive: 'rgb(233, 237, 253)',
    text: 'rgb(46,87,253)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  agency: {
    background: 'rgb(248,188,203)',
    backgroundActive: 'rgb(251,220,227)',
    text: 'rgb(189,41,77)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  forum: {
    background: 'rgb(202,193,251)',
    backgroundActive: 'rgb(216,211,246)',
    text: 'rgba(42, 32, 106, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  press: {
    background: 'rgb(244,227,174)',
    backgroundActive: 'rgb(251,243,217)',
    text: 'rgb(85,69,27)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  radio: {
    background: 'rgb(221,205,251)',
    backgroundActive: 'rgb(232,225,248)',
    text: 'rgb(62,37,112)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  tv: {
    background: 'rgb(246, 189, 234)',
    backgroundActive: 'rgb(248,222,242)',
    text: 'rgba(122, 14, 99, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  socialnetwork: {
    background: 'rgb(213, 247, 232)',
    backgroundActive: 'rgb(231, 251, 242)',
    text: 'rgb(10, 181, 105)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  },
  default: {
    background: 'rgb(173, 244, 199)',
    backgroundActive: 'rgba(202, 238, 221, 1)',
    text: 'rgba(0, 101, 96, 1)',
    iconColor: 'rgba(0, 0, 0, 0.84)'
  }
};

export const getSourceColors = (sourceName) => {
  for (const [key, values] of Object.entries(iconConfig)) {
    if (values.includes(sourceName)) return sourcePopoverColorsConfig?.[key] || sourcePopoverColorsConfig.default;
  }
  return sourcePopoverColorsConfig?.[sourceName] || sourcePopoverColorsConfig.default;
};
