/* eslint-disable react/require-default-props */
import * as Sentry from '@sentry/react';
import { any } from 'prop-types';
import { FallbackComponent } from 'Components';

function ErrorBoundarySentry({ children, ...props }) {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent {...props} />} showDialog>
      {children || null}
    </Sentry.ErrorBoundary>
  );
}
ErrorBoundarySentry.propTypes = {
  children: false
};
ErrorBoundarySentry.propTypes = {
  children: any
};

export default ErrorBoundarySentry;
