import { useMemo, memo } from 'react';
import cn from 'classnames';
import { string, func, bool, shape } from 'prop-types';
import { Icon, TonePopover } from 'Components';
import { iconConfig } from 'data/sourceConfig';
import t from 'utils/translateFunc';
import classes from './IconsListMedia.module.scss';

const IconsListMedia = memo(({ language = 'uk', info = null, onSubmit = () => null, isDetails = false }) => {
  const sourceName = info?.source?.name || info?.source;
  const involvementCount = `${((info?.repost_count || 0) + (info?.like_count || 0) + (info?.comment_count || 0)) || 0}`;

  const iconsList = useMemo(() => {
    if (iconConfig?.facebook.includes(sourceName) || iconConfig?.twitter.includes(sourceName)) {
      return [
        // { type: 'involvement', role: 'button', tooltipText: involvementText, text: `${involvementCount || 0}` },
        { type: isDetails ? 'has_likes' : 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES', language), text: `${info?.like_count || 0}` },
        { type: isDetails ? 'has_comments' : 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS', language), text: `${info?.comment_count || 0}` },
        { type: 'reposts', role: 'button', tooltipText: t('tooltip', 'REPOSTS', language), text: `${info?.dislike_count || 0}` }
      ];
    }
    if (sourceName === 'youtube' || sourceName === 'instagram') {
      return [
        // sourceName === 'youtube' && { type: 'view', role: 'button', tooltipText: t('tooltip', 'VIEWING', language), text: `${info?.views_count || 0}` },
        // { type: 'involvement', role: 'button', tooltipText: involvementText, text: `${involvementCount || 0}` },
        { type: isDetails ? 'has_likes' : 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES', language), text: `${info?.like_count || 0}` },
        // sourceName === 'youtube' && { type: isDetails ? 'has_dislikes' : 'dislikes', role: 'button', tooltipText: t('tooltip', 'DISLIKES', language), text: `${info?.dislike_count || 0}` },
        { type: isDetails ? 'has_comments' : 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS', language), text: `${info?.comment_count || 0}` },
        { type: 'reposts', role: 'button', tooltipText: t('tooltip', 'REPOSTS', language), text: `${info?.dislike_count || 0}` } // dummy field
        // { type: 'doubles', role: 'button', tooltipText: t('tooltip', 'DOUBLES', language), text: `${info?.duplicates_count || 0}` }
      ];
    }
    if (iconConfig?.telegram.includes(sourceName)) {
      return [
        // { type: 'involvement', role: 'button', tooltipText: involvementText, text: `${involvementCount || 0}` },
        { type: isDetails ? 'has_likes' : 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES', language), text: `${info?.like_count || 0}` }, // dummy field
        { type: isDetails ? 'has_comments' : 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS', language), text: `${info?.comment_count || 0}` },
        { type: 'reposts', role: 'button', tooltipText: t('tooltip', 'REPOSTS', language), text: `${info?.dislike_count || 0}` } // dummy field
      ];
    }
    if (iconConfig?.google_maps.includes(sourceName)) {
      return [
        { type: 'rating', role: 'button', tooltipText: t('tooltip', 'RATING', language), text: `${info?.source?.rating || info?.rating || 5}` },
        // { type: 'involvement', role: 'button', tooltipText: involvementText, text: involvementCount },
        { type: isDetails ? 'has_likes' : 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES', language), text: `${info?.like_count || 0}` },
        { type: isDetails ? 'has_comments' : 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS', language), text: `${info?.comment_count || 0}` },
        { type: 'reposts', role: 'button', tooltipText: t('tooltip', 'REPOSTS', language), text: `${info?.dislike_count || 0}` } // dummy field
      ];
    }
    if (sourceName === 'forums') {
      return [];
    }
    if (iconConfig?.tiktok.includes(sourceName) || iconConfig?.linkedin.includes(sourceName)) {
      return [
        // { type: 'involvement', role: 'button', tooltipText: involvementText, text: `${involvementCount || 0}` },
        { type: isDetails ? 'has_likes' : 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES', language), text: `${info?.like_count || 0}` }, // dummy field
        { type: isDetails ? 'has_comments' : 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS', language), text: `${info?.comment_count || 0}` }, // dummy field
        { type: 'reposts', role: 'button', tooltipText: t('tooltip', 'REPOSTS', language), text: `${info?.dislike_count || 0}` } // dummy field
      ];
    }
    return [
      { type: 'audience', role: 'button', tooltipText: t('tooltip', 'AUDIENCE', language), text: `${info?.source?.attendance || info?.attendance || 0}` },
      { type: 'view', role: 'button', tooltipText: t('tooltip', 'VIEWING', language), text: `${info?.source?.average_attendance || info?.average_attendance || 0}` },
      { type: 'ave', role: 'button', tooltipText: 'AVE', text: `${info?.ave || 0}` }
    ];
  }, [sourceName]);

  return (
    <>
      {info?.media_type === 'socialnetwork'
        ? (
          <div className={isDetails ? classes.socialnetworkWrap : classes.iconWrap}>
            <Icon
              type="involvement"
              role="button"
              tooltipText={t('tooltip', 'INVOLVEMENT', language)}
              text={involvementCount}
              className={cn(classes.iconText)}
              textClass={cn(classes.iconText)}
              btnType={cn(classes.btnIcon)}
            />
            {!isDetails && <div className={classes.pipeChild} />}
            <div className={cn(classes.iconGroupWrap, isDetails && classes.iconGroupWrap_detail)}>
              {iconsList.map((icon, i) => icon && (
                <Icon
                  {...icon}
                  key={i}
                  className={cn(classes.iconText)}
                  textClass={cn(classes.iconText)}
                  btnType={cn(classes.btnIcon)}
                />
              ))}
            </div>
          </div>
        ) : (
          iconsList.map((icon, i) => icon && (
            <div className={cn(classes.iconWrap, isDetails && classes.iconWrap_detail)} key={i}>
              <Icon
                {...icon}
                className={cn(classes.iconText)}
                textClass={cn(classes.iconText)}
                btnType={cn(classes.btnIcon)}
              />
            </div>
          ))
        )}
      <div className={isDetails ? cn(classes.iconWrap, classes.iconWrap_detail) : classes.iconDuplicates}>
        <Icon
          type={info?.duplicates_count || isDetails ? 'has_doubles' : 'doubles'}
          role="button"
          tooltipText={t('tooltip', 'DOUBLES', language)}
          text={info?.duplicates_count || 0}
          className={cn(classes.iconText)}
          textClass={cn(classes.iconText)}
          btnType={cn(classes.btnIcon)}
        />
      </div>
      {!isDetails && (
        <>
          <TonePopover className={classes.iconTone} onSelect={onSubmit} info={info} language={language} idBind={`article-footer-${info?.id}`} />
          <Icon
            type={info?.processed ? 'processed' : 'unprocessed'}
            role="button"
            tooltipText={t('tooltip', info?.processed ? 'ADD_UNPROCESSED' : 'ADD_PROCESSED', language)}
            className={classes.iconText}
            onClick={() => onSubmit({ action: 'changeProcessed', info, body: { processed: !info?.processed } })}
            btnType={cn(
              classes.btnIcon,
              classes.btnIconCenter,
              info?.processed && classes.btnIcon_svgColorGreen
            )}
          />
        </>
      )}
    </>
  );
});

IconsListMedia.propTypes = {
  language: string,
  info: shape({}),
  onSubmit: func,
  isDetails: bool
};

export default IconsListMedia;
