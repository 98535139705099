import { api } from 'api/apiInstance';

export const getPages = async ({ portal_slug }) => {
  const response = await api.get(`page/${portal_slug}/`);
  return response?.data;
};

export const createPage = async ({ portal_slug, body }) => {
  const response = await api.post(`page/${portal_slug}/`, body);
  return response?.data;
};

export const getPageId = async ({ portal_slug, id }) => {
  const response = await api.get(`page/${portal_slug}/${id}/`);
  return response?.data;
};

export const changePage = async ({ portal_slug, id, body }) => {
  const response = await api.patch(`page/${portal_slug}/${id}/`, body);
  return response?.data;
};

export const savePageChanges = async ({ portal_slug, id, body }) => {
  const response = await api.put(`page/${portal_slug}/${id}/`, body);
  return response?.data;
};

export const deletePage = async ({ portal_slug, id }) => {
  const response = await api.delete(`page/${portal_slug}/${id}/`);
  return response?.data;
};
