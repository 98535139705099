import { useState, useRef, memo } from 'react';
import { toneMenu } from 'data/dropDownConfig';
import { string, func, shape, oneOfType, arrayOf, number, bool } from 'prop-types';
import t from 'utils/translateFunc';
import { PopoverWindow, BodyMenu, Icon } from 'Components';
import cn from 'classnames';
import classes from './TonePopover.module.scss';

const TonePopover = memo(({
  onSelect = () => null,
  info = null,
  language = 'uk',
  idBind = '',
  className = '',
  placement = '',
  offset = false
}) => {
  const bodyRef = useRef();
  const [visible, setVisible] = useState(false);
  const popoversMenuConfig = toneMenu.map(el => ({ ...el, name: t('tonePopover', el?.name, language) }));
  function afterChange(value) {
    value && !visible && setVisible(true);
    !value && visible && setTimeout(() => { setVisible(false); }, 350);
  }
  function onClose() {
    visible && bodyRef?.current?.onClosePopover();
    visible && setTimeout(() => { setVisible(false); }, 350);
  }

  function handleSubmit({ action, icon }) {
    onSelect({ action, info, body: { tone: icon } });
    onClose();
  }

  return (
    <PopoverWindow
      ref={bodyRef}
      className={classes.toneBody}
      body={visible && (
        <BodyMenu
          active={info?.tone ? t('tonePopover', info.tone.toUpperCase(), language) : null}
          className={classes.toneBodySelect}
          menuConfig={popoversMenuConfig}
          selectOption={handleSubmit}
        />
      )}
      afterChange={afterChange}
      offset={offset}
      overflow={{ adjustX: true, adjustY: true }}
      placement={placement}
      idBind={idBind}
    >
      <div className={cn(classes.toneWrapper, className)}>
        <Icon
          type={info?.tone || 'neutral'}
          {...(!visible && { role: 'button' })}
          tooltipText={t('tooltip', 'TONE', language)}
          className={cn(classes.iconClass)}
          btnType={cn(classes.iconClassBtn)}
        />
        <Icon
          type="arrow_drop_down"
          className={cn(classes.iconClass, classes.iconClass_arrow)}
        />
      </div>
    </PopoverWindow>
  );
});

TonePopover.propTypes = {
  onSelect: func,
  info: shape({}),
  language: string,
  idBind: string,
  className: string,
  placement: string,
  offset: oneOfType([arrayOf(number), bool])
};

export default TonePopover;
