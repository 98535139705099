import { isFilledArray } from 'utils/common';

const IconGroup = {
  negative: 'negative',
  neutral: 'neutral',
  positive: 'positive'
};

export const dataRolePars = data => isFilledArray(data) && data.map(option => (option.id === 'negative'
  ? { ...option, icon: IconGroup.negative }
  : option.id === 'neutral'
    ? { ...option, icon: IconGroup.neutral }
    : { ...option, icon: IconGroup.positive }));
