import { useSelector, useDispatch } from 'react-redux';
import t from 'utils/translateFunc';
import { Icon } from 'Components';
import { closeFooter, onChangeTab } from 'storage/actions';
import classes from './footer.module.scss';
import IconsBlockFooter from './IconsBlockFooter';

export default function Footer() {
  const dispatch = useDispatch();
  const { isOpenFooter, chekedArticles } = useSelector(state => state?.news);
  const language = useSelector(state => state?.userInfo?.language);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const tabItem = useSelector(state => state?.filters?.tabItem)?.action;

  function onCloseFooter() {
    dispatch(closeFooter());
  }

  function handleChangeTab(value) {
    dispatch(onChangeTab(value));
  }

  return isOpenFooter && (
  <footer className={classes.footer} id="footer">
    <div className={classes.wrapper}>
      <div>
        {t('footer', 'SELECTED', language)}
        <strong>{chekedArticles?.length}</strong>
        {t('footer', 'PUBLICATIONS', language)}
      </div>
      <IconsBlockFooter
        portal_slug={portal_slug}
        language={language}
        chekedArticles={chekedArticles}
        onClose={onCloseFooter}
        onChangeTab={handleChangeTab}
        tabItem={tabItem}
      />
      <Icon
        type="close"
        role="button"
        className={classes.iconContainer}
        btnType={classes.icon}
        tooltipText={t('footer', 'CLOSE', language)}
        onClick={onCloseFooter}
      />
    </div>
  </footer>
  );
}
