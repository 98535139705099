import { useQuery } from 'react-query';
import { getDownloadReports, getDownloadReportId } from 'storage/downloads/requests';
import { addDownloadReports, changeDownloadReport } from 'storage/actions';
import { useDispatch } from 'react-redux';

export const useGetDownloadReports = (params) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getDownloadReports', params],
    queryFn: () => getDownloadReports(params),
    onSuccess: (resp) => { dispatch(addDownloadReports(resp)); } });
};

export const useGetDownloadReportId = ({ id, status }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getDownloadReportId', id, status],
    queryFn: () => getDownloadReportId(id),
    enabled: !!id && (status === 'processing' || status === 'in queue'),
    refetchInterval: 10000,
    // cacheTime: 10000,
    onSuccess: (resp) => { dispatch(changeDownloadReport(resp)); }
  });
};
