import { string, number } from 'prop-types';
import cn from 'classnames';

export const Visibility = ({ width = 12, height = 8, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={cn(className, 'icon icon-copy')}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 1.06667C8.06727 1.06667 9.91091 2.20267 10.8109 4C9.91091 5.79733 8.06727 6.93333 6 6.93333C3.93273 6.93333 2.08909 5.79733 1.18909 4C2.08909 2.20267 3.93273 1.06667 6 1.06667ZM6 0C3.27273 0 0.943636 1.65867 0 4C0.943636 6.34133 3.27273 8 6 8C8.72727 8 11.0564 6.34133 12 4C11.0564 1.65867 8.72727 0 6 0ZM6 2.66667C6.75273 2.66667 7.36364 3.264 7.36364 4C7.36364 4.736 6.75273 5.33333 6 5.33333C5.24727 5.33333 4.63636 4.736 4.63636 4C4.63636 3.264 5.24727 2.66667 6 2.66667ZM6 1.6C4.64727 1.6 3.54545 2.67733 3.54545 4C3.54545 5.32267 4.64727 6.4 6 6.4C7.35273 6.4 8.45455 5.32267 8.45455 4C8.45455 2.67733 7.35273 1.6 6 1.6Z" fill="#A7B0C8" />
  </svg>

);

Visibility.propTypes = {
  width: number,
  height: number,
  className: string
};
