import { func, object, oneOfType, string } from 'prop-types';
import cn from 'classnames';
import { Icons, Tooltip } from 'Components';
import classes from './InfoBtn.module.scss';

const InfoBtn = ({ onClick = () => null, className = '', text = '', icon = '' }) => (
  <Tooltip text={text} className={cn(classes.tooltip, className)}>
    <button type="button" className={classes.btnMore} onClick={onClick}>
      <Icons icon={icon || 'error_outline'} className={classes.iconInfo} />
    </button>
  </Tooltip>
);

InfoBtn.propTypes = {
  className: string,
  onClick: func,
  text: oneOfType([string, object]),
  icon: string
};

export default InfoBtn;
