import { useState } from 'react';
import { string, number, func, oneOfType } from 'prop-types';
import t from 'utils/translateFunc';
import { Button, SelectCustom, TextField, InfoBtn } from 'Components';
import { useGetTelegramAccounts } from 'storage/queryHooks';
import cn from 'classnames';
import { validationEmail } from 'data/validation';
import { openModal } from 'storage/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendFeedEvent } from 'services/amplitude/events';
import hasOwn from 'lodash/has';
import classes from './BodySubscribe.module.scss';

function BodySubscribe({ language = 'uk', headerText = '', onSubmit = () => null, article_id = '' }) {
  const dispatch = useDispatch();
  const { data: telegramAccountsData } = useGetTelegramAccounts();
  const history = useNavigate();
  const optionsTelegram = telegramAccountsData?.accounts?.length
    ? telegramAccountsData?.accounts.map(({ id, telegram_username }) => ({ value: id, label: telegram_username })) : [];
  const telegramBotLink = telegramAccountsData?.telegramBotLink || '';
  const telegramBotLinkWeb = telegramAccountsData?.telegramBotLinkWeb || '';
  const chooseAccounts = { value: '', label: t('subscribeDuplicates', 'SELECT_ACCOUNTS', language) };
  const [emails, setEmails] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [telegram, setTelegram] = useState(chooseAccounts);
  const [slack, setSlack] = useState(chooseAccounts);
  const [deliveryType, setDeliveryType] = useState('email');

  function handleSubmit() {
    const subcribeBody = { article: article_id, is_enable: true };
    if (telegram.value) {
      subcribeBody.telegram_users = [telegram.value];
    }
    if (Array.isArray(telegram)) {
      subcribeBody.telegram_users = telegram.map(({ value }) => value);
    }
    if (slack.value) {
      subcribeBody.slack_accounts = [slack.value];
    }
    if (Array.isArray(slack)) {
      subcribeBody.slack_accounts = slack.map(({ value }) => value);
    }
    if (emails) {
      subcribeBody.emails = emails.split(/[ ,]+/).join(',');
    }
    if (emails?.length && emails.split(/[ ,]+/).some(item => !validationEmail(item))) {
      setValidEmail(false);
      return;
    }

    const eventFields = ['emails', 'telegram_users'];
    eventFields.forEach((field) => {
      hasOwn(subcribeBody, field) && sendFeedEvent({ category: 'Full text', event: `subscribe_${field}` });
    });

    onSubmit(subcribeBody);
  }

  function onChangeDeliveryChoises(id) {
    setDeliveryType(id);
    setEmails('');
    setValidEmail(true);
    setTelegram(chooseAccounts);
    setSlack(chooseAccounts);
  }
  const disabled = (!emails || !validEmail) && (Array.isArray(slack) ? !slack?.length : !slack.value) && (Array.isArray(telegram) ? !telegram?.length : !telegram.value);

  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      width: '100%',
      minHeight: '32px',
      backgroundColor: '#FFFFFF',
      height: '46px',
      borderRadius: '4px',
      outline: 'none',
      boxShadow: 'none !important',
      opacity: state.isDisabled ? '0.8' : '1',
      borderColor: '#7746B1 !important'
    }),
    valueContainer: styles => ({
      ...styles,
      padding: '0 16px'
    }),
    placeholder: styles => ({
      ...styles,
      color: '#e0e0f3',
      fontSize: '14px'
    }),
    singleValue: styles => ({
      ...styles,
      color: '#31254B',
      margin: '0',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: '400',
      cursor: 'pointer'
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#FFFFFF'
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: '#4D4476',
      fontSize: '14px'
    }),
    option: styles => ({
      ...styles,
      cursor: 'pointer',
      fontSize: '14px',
      ':last-child': {
        color: '#7746B1'
      }
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: '#4D4476',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: '#FFFFFF',
        color: 'red',
        fontSize: '14px'
      }
    })
  };

  function openSelectTelegramModal() {
    dispatch(openModal({ action: 'selectTelegram', info: { telegramBotLink, telegramBotLinkWeb } }));
  }

  const deliveryChoices = [
    {
      id: 'email',
      text: 'Email'
    },
    {
      id: 'telegram',
      text: 'Telegram'
    }
  ];

  return (
    <>
      <div className={classes.subscribeDuplicatesHeader}>
        <InfoBtn
          className={cn(classes.infoButton)}
          text={t('subscribeDuplicates', 'INSTRUCTION', language)}
          icon="error_outline"
        />
        {t('subscribeDuplicates', 'SUBSCRIBE_DUBLICATES_MENTIONS', language)}
      </div>
      {!!headerText && <div className={classes.subscribeDuplicatesHeaderText}>{headerText}</div>}
      <div className={classes.subscribeAlertsWrapper}>
        <div className={classes.subscribeAlertsHeader}>{t('subscribeDuplicates', 'SELECT_METHOD', language)}</div>
        <div className={classes.subscribeAlertsList}>
          {deliveryChoices.map(item => (
            <div
              key={item?.id}
              className={cn(
                classes.subscribeAlertsListItem,
                item?.id === deliveryType && classes.subscribeAlertsListItem_selected
              )}
              onClick={() => { onChangeDeliveryChoises(item?.id); }}
            >
              {item?.text}
            </div>
          ))}

        </div>
        <div className={cn(
          classes.subscribeInputBox,
          deliveryType === 'email' && classes.subscribeInputBox_email,
          deliveryType === 'telegram' && classes.subscribeInputBox_telegram,
          deliveryType === 'slack' && classes.subscribeInputBox_slack
        )}
        >
          {deliveryType === 'email' && (
          <TextField
            value={emails}
            className={classes.subscribeInputWrapper}
            classNameForInput={classes.subscribeInput}
            onChange={({ target }) => {
              if (!validEmail) { setValidEmail(true); }
              setEmails(target.value);
            }}
            placeholder={t('subscribeDuplicates', 'ENTER_EMAIL', language)}
            errorText={!validEmail ? t('subscribeDuplicates', 'ENTER_CORRECT_EMAIL', language) : ''}
            infoText={validEmail ? t('subscribeDuplicates', 'ENTER_FEW_EMAILS', language) : ''}
          />
          )}
          {deliveryType === 'telegram' && (
            optionsTelegram?.length ? (
              <div className={classes.selectWrapper}>
                <SelectCustom
                  value={telegram}
                  placeholder={t('subscribeDuplicates', 'ENTER_ACCONT_NAME', language)}
                  onChange={(option) => {
                    const connectOption = Array.isArray(option) ? option?.some(item => item?.value === 'connect_account') : option?.value === 'connect_account';
                    if (connectOption) {
                      openSelectTelegramModal();
                      return;
                    }
                    !connectOption && setTelegram(option);
                  }}
                  options={optionsTelegram.concat({ value: 'connect_account', label: t('subscribeDuplicates', 'CONNECT_TELEGRAM_ACCOUNT_PLUS', language) })}
                  styles={customStyles}
                  isMulti={!!telegram?.value || !!telegram?.length}
                />
              </div>
            ) : (
              <div
                onClick={openSelectTelegramModal}
                className={cn(classes.addBtn, classes.addBtn_telegram)}
              >
                {t('subscribeDuplicates', 'CONNECT_TELEGRAM_ACCOUNT', language)}
              </div>
            )
          )}
        </div>
      </div>
      <div className={classes.buttonBox}>
        <Button onClick={handleSubmit} textButton={t('btn', 'APPLY', language)} green32 disabled={disabled} />
        <div
          onClick={() => {
            sendFeedEvent({ category: 'Full text', event: 'Clicked_All_Your_Subscriptions_Button' });
            history('/alerts');
          }}
          className={classes.buttonBox_mentions}
        >
          {t('subscribeDuplicates', 'ALL_SUBSCRIBES', language)}
        </div>
      </div>
    </>
  );
}

BodySubscribe.propTypes = {
  language: string,
  headerText: string,
  onSubmit: func,
  article_id: oneOfType([string, number])
};

export default BodySubscribe;
