import { translateBase } from 'lang/translation';

const useTranslate = (group, tehnicalText, lang) => {
  const language = lang || translateBase.default;
  if (group && tehnicalText && language) {
    const groupName = translateBase.translate[group];
    const text = groupName?.[tehnicalText];
    if (!groupName) {
      return typeof group === 'string' ? group : 'translateErr';
    }
    if (text?.[language]) {
      return text[language];
    }
    if (text?.en) {
      return text.en;
    }
    return typeof tehnicalText === 'string' ? tehnicalText : 'translateErr';
  }
  return '';
};

export default useTranslate;
