export const AMPLITUDE_URL = 'https://api2.amplitude.com';

const BASE_AMPLITUDE_FEED_API_KEY = '1b86069bc16faebea86f735dd4b7884f';
const BASE_AMPLITUDE_ALERTS_API_KEY = 'd991c0c4f53fded845797ceb529a915f';
const BASE_AMPLITUDE_DOWNLOADS_API_KEY = 'db1cc56a73542cdffd0e2ba5b4f11281';

const hostnameConfig = {
  localhost: {
    API_URL: 'http://localhost:9000',
    DASHBOARD_URL: 'http://localhost:5003',
    AMPLITUDE_FEED_API_KEY: '',
    AMPLITUDE_ALERTS_API_KEY: '',
    AMPLITUDE_DOWNLOADS_API_KEY: '',
    AMPLITUDE_SLUG: 'local'
  },
  'test-newsfeed.looqme.io': {
    API_URL: 'https://test-hub.looqme.io',
    DASHBOARD_URL: 'https://test-dashboard.looqme.io',
    AMPLITUDE_FEED_API_KEY: '',
    AMPLITUDE_ALERTS_API_KEY: '',
    AMPLITUDE_DOWNLOADS_API_KEY: '',
    AMPLITUDE_SLUG: 'test'
  },
  'feed.metricom.io': {
    API_URL: 'https://app.metricom.io',
    DASHBOARD_URL: 'https://dashboard.metricom.io',
    AMPLITUDE_FEED_API_KEY: '',
    AMPLITUDE_ALERTS_API_KEY: '',
    AMPLITUDE_DOWNLOADS_API_KEY: '',
    AMPLITUDE_SLUG: 'metricom'
  },
  default: {
    API_URL: 'https://test-hub.looqme.io',
    DASHBOARD_URL: '',
    AMPLITUDE_FEED_API_KEY: '',
    AMPLITUDE_ALERTS_API_KEY: '',
    AMPLITUDE_DOWNLOADS_API_KEY: '',
    AMPLITUDE_SLUG: ''
  }
};

export const {
  API_URL,
  DASHBOARD_URL,
  AMPLITUDE_FEED_API_KEY,
  AMPLITUDE_ALERTS_API_KEY,
  AMPLITUDE_DOWNLOADS_API_KEY,
  AMPLITUDE_SLUG
} = hostnameConfig?.[window.location.hostname] || hostnameConfig.default;
