import { string, number } from 'prop-types';

export function Reports({ width = 32, height = 32, color = '#8086AB', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 32} height={height || 32} viewBox="0 0 32 32" fill="none">
      <mask id="mask0_2212_4166" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" fill="#fff" />
      </mask>
      <g mask="url(#mask0_2212_4166)">
        <path d="M5.33341 28C4.60008 28 3.9723 27.7389 3.45008 27.2167C2.92786 26.6944 2.66675 26.0667 2.66675 25.3333V6.66667C2.66675 5.93333 2.92786 5.30556 3.45008 4.78333C3.9723 4.26111 4.60008 4 5.33341 4H26.6667C27.4001 4 28.0279 4.26111 28.5501 4.78333C29.0723 5.30556 29.3334 5.93333 29.3334 6.66667V25.3333C29.3334 26.0667 29.0723 26.6944 28.5501 27.2167C28.0279 27.7389 27.4001 28 26.6667 28H5.33341ZM5.33341 25.3333H26.6667V6.66667H5.33341V25.3333ZM8.00008 22.6667H24.0001V20H8.00008V22.6667ZM8.00008 17.3333H13.3334V9.33333H8.00008V17.3333ZM16.0001 17.3333H24.0001V14.6667H16.0001V17.3333ZM16.0001 12H24.0001V9.33333H16.0001V12Z" fill={color || '#8086AB'} />
      </g>
    </svg>
  );
}

Reports.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
