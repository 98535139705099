import { useState, useEffect, useMemo } from 'react';
import {
  SearchField, SkeletonFilterList, NoSearchData, FilterList
} from 'Components';
import { removeFilterItem, addFilterItem, addLanguages } from 'storage/actions';
import { useGetLanguages } from 'storage/queryHooks';
import { useDispatch, useSelector } from 'react-redux';
import t from 'utils/translateFunc';
import useDebounceValue from 'hooks/useDebounce';
import classes from './FilterLang.module.scss';

const FilterLang = () => {
  const dispatch = useDispatch();
  const [filterSearch, setFilterSearch] = useState('');
  const { filterList, languages } = useSelector(state => state?.filters);
  const { language } = useSelector(state => state?.userInfo);
  const filterType = t('filtrationPanel', 'LANGUAGE', language);
  const icontains = useDebounceValue(filterSearch, 1000);
  const { isLoading: langLoading } = useGetLanguages({ enabled: !!icontains, query: { icontains } });
  const addItem = ({ id, name, type, filterName }) => { dispatch(addFilterItem({ id, name, type, filterName })); };
  const removeItem = ({ id, filterName }) => { dispatch(removeFilterItem({ id, filterName })); };

  useEffect(() => {
    if (!filterSearch?.length) {
      dispatch(addLanguages([]));
    }
  }, [filterSearch?.length]);
  const selectedData = useMemo(() => filterList?.filter(listItem => listItem?.filterName === 'language'), [filterList?.length]);
  const isSearchData = useMemo(() => languages?.filter(({ name }) => (name?.length ? name.toLowerCase()?.includes(filterSearch?.toLowerCase()) : '')), [languages?.length, filterSearch]);
  return (
    <div className={classes.autorLngFilter}>
      <div className={classes.searchWrap}>
        <SearchField
          placeholder={t('creatorLngFilter', 'ENTER_LNG', language)}
          value={filterSearch}
          setValue={setFilterSearch}
        />
      </div>
      <SkeletonFilterList fetching={langLoading}>
        {isSearchData?.length ? (
          <FilterList
            dataFilters={languages}
            filterSearch={filterSearch}
            selectData={selectedData}
            filterName="language"
            addItem={addItem}
            removeItem={removeItem}
            filterType={filterType}
            language={language}
          />
        ) : !!filterSearch && <NoSearchData language={language} /> }
      </SkeletonFilterList>
    </div>
  );
};

export default FilterLang;
