import { createSlice } from '@reduxjs/toolkit';
import hasOwn from 'lodash/has';

const initialState = {
  newsList: [],
  articleDetails: null,
  chekedMentions: [],
  chekedArticles: [],
  openedNewsList: [],
  creators: [],
  publicationPlaces: [],
  keywordId: null,
  isOpenFooter: false,
  count: 0,
  time: null,
  needUpdate: false,
  keywords_offset: null
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    addNewsList(state, { payload }) {
      const { data, keywords_offset } = payload;
      state.newsList = [...data]?.sort((a, b) => ((!a?.articles?.length && !b?.articles?.length) || !!b?.articles?.length ? 1 : -1));
      state.keywords_offset = data?.length ? keywords_offset : 0;
    },
    addNewsListOffset(state, { payload }) {
      const { data, keywords_offset } = payload;
      if (data?.length) {
        const news = state.newsList.concat(data).reduce((acc, curr) => (acc?.some(mention => mention?.keyword?.id === curr?.keyword?.id) ? acc : [...acc, curr]), []);
        state.newsList = [...news]?.sort((a, b) => ((!a?.articles?.length && !b?.articles?.length) || !!b?.articles?.length ? 1 : -1));
      }
      state.keywords_offset = data?.length ? keywords_offset : 0;
    },
    addMoreNews(state, { payload }) {
      const { keyword_id, articles, next_split, start_split } = payload;
      state.newsList = state?.newsList.map(mention => (mention?.keyword?.id === keyword_id
        ? { ...mention,
          articles: mention.articles.concat(articles).reduce((acc, curr) => (acc?.some(art => art?.id === curr?.id) ? acc : [...acc, curr]), []),
          next_split,
          start_split } : mention));
    },
    changeNewsCount(state, { payload }) {
      state.count = payload?.count || 0;
      state.needUpdate = false;
    },
    resetNewsCount(state) {
      state.count = 0;
    },
    updateTime(state, { payload }) {
      if (!!state.time && state.time !== payload.time) {
        state.needUpdate = true;
      }
      state.time = payload?.time;
    },
    addArticleDetails(state, { payload }) {
      state.articleDetails = payload;
    },
    clearKeywordsOffset(state) {
      state.keywords_offset = null;
    },
    deleteManyArticles(state) {
      const deletedNews = state.chekedArticles?.reduce((acc, { keyword_id, article_id }) => (acc?.some(el => el?.keyword_id === keyword_id)
        ? acc?.map(el => (el?.keyword_id === keyword_id ? { ...el, article_ids: el?.article_ids?.concat(article_id) } : el))
        : acc?.concat({ keyword_id, article_ids: [article_id] })), []);

      state.newsList = state.newsList.map((mention) => {
        const mentionInclude = deletedNews.find(el => el?.keyword_id === mention?.keyword?.id);
        return mentionInclude ? { ...mention,
          articles: mention.articles.filter(article => !mentionInclude.article_ids.includes(article?.id)) } : mention;
      });
    },
    changeManyValuesInArticles(state, { payload }) {
      function findMention(id) {
        return payload.find(mentionItem => mentionItem.keyword_id === id);
      }
      function getNewValues(mentionObj) {
        const newKews = {};
        for (const key in mentionObj) {
          if (hasOwn(mentionObj, key)) {
            const notParams = ['article_ids', 'keyword_id', 'keyword_name'];
            if (!notParams.includes(key)) {
              newKews[key] = mentionObj[key];
            }
          }
        }
        return newKews;
      }
      state.newsList = state.newsList.map(mention => ({ ...mention,
        articles: findMention(mention.keyword?.id)
          ? mention.articles.map(article => (findMention(mention.keyword?.id).article_ids.includes(article?.id)
            ? { ...article, ...getNewValues(findMention(mention.keyword?.id)) }
            : article))
          : mention?.articles
      }));

      const mentionObj = !!state.articleDetails && payload?.find(mention => mention.article_ids?.includes(state.articleDetails?.id));
      const articleIndex = !!mentionObj && state.articleDetails.keywords.findIndex(item => item?.keyword?.id === mentionObj?.keyword_id);
      typeof articleIndex === 'number' && (state.articleDetails.keywords[articleIndex] = { ...state.articleDetails.keywords[articleIndex], ...getNewValues(mentionObj) });
    },
    changeValueInArticles(state, { payload }) {
      const value = payload?.value;
      const articles = payload?.article_ids.reduce((acc, curr) => (curr?.article_ids ? acc.concat(curr?.article_ids) : acc), []);
      if (value) {
        state.newsList = state.newsList.map(mention => ({ ...mention,
          articles: mention.articles
            .map(article => (articles.includes(article?.id) ? { ...article, ...value } : article)) }));
      }
      if (value && state.articleDetails && articles?.includes(state.articleDetails?.id)) {
        state.articleDetails = { ...state.articleDetails, ...value };
      }
    },
    deleteArticleDetails(state, { payload }) {
      const { article_id } = payload;
      state.newsList = state.newsList
        .map(mention => ({ ...mention,
          articles: mention.articles.filter(article => article?.id !== article_id) }))
        .filter(mention => !!mention.articles?.length);
    },
    addManyTags(state, { payload }) {
      const { article_ids, tags } = payload;
      state.newsList = state.newsList.map(mention => ({ ...mention,
        articles: mention.articles
          .map(article => (article_ids.includes(article?.id)
            ? { ...article, tags: [...article.tags, ...tags].reduce((acc, curr) => (acc?.some(el => el?.id === curr?.id) ? acc : acc.concat(curr)), []) } : article)) }));

      if (state.articleDetails && article_ids.includes(state.articleDetails?.id)) {
        state.articleDetails = { ...state.articleDetails,
          tags: [...state.articleDetails.tags, ...tags].reduce((acc, curr) => (acc?.some(el => el?.id === curr?.id) ? acc : acc.concat(curr)), []) };
      }
    },
    removeManyTags(state, { payload }) {
      const { article_ids, remove_tags } = payload;
      state.newsList = state.newsList.map(mention => ({ ...mention,
        articles: mention.articles
          .map(article => (article_ids.includes(article?.id)
            ? { ...article, tags: article.tags.filter(item => !remove_tags?.some(el => el?.id === item?.id)) } : article)) }));
      if (state.articleDetails && article_ids.includes(state.articleDetails?.id)) {
        state.articleDetails = { ...state.articleDetails,
          tags: state.articleDetails.tags.filter(item => !remove_tags?.some(el => el?.id === item?.id)) };
      }
    },
    removeOnlyTag(state, { payload }) {
      const { article_id, tag_id } = payload;
      state.newsList = state.newsList
        .map(mention => ({ ...mention,
          articles: mention.articles
            .map(article => (article?.id === article_id ? { ...article, tags: article?.tags?.filter(el => el?.id !== tag_id) } : article)) }));
      if (state.articleDetails?.id === article_id) {
        state.articleDetails.tags = state.articleDetails.tags?.filter(el => el?.id !== tag_id);
      }
    },
    removeTag(state, { payload }) {
      const { tag_id } = payload;
      state.newsList = state.newsList
        .map(mention => ({ ...mention,
          articles: mention.articles
            .map(article => ({ ...article, tags: article?.tags?.filter(el => el?.id !== tag_id) })) }));
      if (state.articleDetails?.id) {
        state.articleDetails.tags = state.articleDetails.tags?.filter(el => el?.id !== tag_id);
      }
    },
    changeTone(state, { payload }) {
      const { article_id, keyword_id, body } = payload;
      const indexMention = state.newsList.findIndex(el => el.keyword.id === keyword_id);
      const indexArticle = indexMention >= 0 ? state.newsList[indexMention].articles.findIndex(el => el.id === article_id) : -1;
      indexMention >= 0 && indexArticle >= 0 && (state.newsList[indexMention].articles[indexArticle].tone = body?.tone);
      if (state?.articleDetails?.id === article_id) {
        const tones = { positive: 0, negative: 0, neutral: 0 };
        tones[body.tone] = 1;
        state.articleDetails.tones = tones;
      }
    },
    changeArticle(state, { payload }) {
      const { article_id, keyword_id, keywords_value, body } = payload;
      const indexMention = state.newsList.findIndex(el => el.keyword.id === keyword_id);
      const indexArticle = indexMention >= 0 ? state.newsList[indexMention].articles.findIndex(el => el.id === article_id) : -1;
      const key = Object.keys(body)[0];
      const value = Object.values(body)[0];
      indexMention >= 0 && indexArticle >= 0 && (state.newsList[indexMention].articles[indexArticle][key] = value);
      if (state?.articleDetails?.id === article_id && keywords_value?.id) {
        state.articleDetails.keywords = state.articleDetails.keywords.map(item => (item?.id === keywords_value?.id ? keywords_value : item));
      }
      if (state?.articleDetails?.id === article_id && !keywords_value?.id) {
        const tones = { positive: 0, negative: 0, neutral: 0 };
        tones[body.tone] = 1;
        const keyword = state.articleDetails.keywords.find(el => el?.keyword?.id);
        if (key === 'tone' && keyword) {
          state.articleDetails.tones = tones;
          keyword.tone = body.tone;
          keyword.tones = tones;
        } else if (key === 'role' && keyword) {
          keyword.character = body.role;
        } else {
          state.articleDetails[key] = value;
        }
      }
    },
    toggleMention(state, { payload }) {
      const { keyword_id, keyword_name, checked } = payload;
      const articles = state?.newsList?.find(el => el?.keyword?.id === keyword_id)?.articles || [];
      const removeArticleId = state.chekedArticles.filter(el => el?.keyword_id !== keyword_id);
      const addArticleId = articles?.length
        ? articles?.map(({ id, tone, url, tags, deleted, processed, bookmarked }) => ({
          article_id: id, keyword_id, keyword_name, tone, url, tags, deleted, processed, bookmarked
        }))
          .concat(removeArticleId) : removeArticleId;
      const addKeywordId = state.chekedMentions.concat({ keyword_id });
      const removeKeywordId = state.chekedMentions.filter(el => el?.keyword_id !== keyword_id);
      const chekedArticles = checked ? addArticleId : removeArticleId;
      state.chekedArticles = chekedArticles;
      state.chekedMentions = checked ? addKeywordId : removeKeywordId;
      state.isOpenFooter = !!chekedArticles?.length;
    },
    toggleArticle(state, { payload }) {
      const { article_id } = payload;
      const removeArticleId = state.chekedArticles.filter(el => el?.article_id !== article_id);
      const addArticleId = state.chekedArticles?.concat(payload);
      const checkedSome = !!state.chekedArticles?.length && state.chekedArticles.some(el => el?.article_id === article_id);
      const chekedArticles = !checkedSome ? addArticleId : removeArticleId;
      state.chekedArticles = chekedArticles;
      state.isOpenFooter = !!chekedArticles?.length;
    },
    clearChecked(state) {
      state.chekedArticles = [];
      state.chekedMentions = [];
    },
    closeFooter(state) {
      state.isOpenFooter = false;
      state.chekedArticles = [];
      state.chekedMentions = [];
    },
    onChangeMention(state, { payload }) {
      state.newsList = payload;
    },
    toggleOpenNewsList(state, { payload }) {
      state.openedNewsList = payload;
    },
    onChangeKeywordId(state, { payload }) {
      state.keywordId = payload;
    },
    clearArticleDetails(state) {
      state.articleDetails = null;
    },
    clearNewsList(state) {
      state.newsList = [];
      state.articleDetails = null;
      state.chekedMentions = [];
      state.chekedArticles = [];
      state.isOpenFooter = false;
    },
    addCreatorsList(state, { payload }) {
      const new_creators = payload?.creators?.map(item => ({ id: item[0], name: item[1] })).filter(item => !state.creators.find(v => v.id === item.id));
      state.creators = [...state.creators, ...new_creators];
    },
    addPublicationPlacesList(state, { payload }) {
      const new_places = payload?.publicationPlaces?.map(item => ({ id: item[0], name: item[1] })).filter(item => !state.publicationPlaces.find(v => v.id === item.id));
      state.publicationPlaces = [...state.publicationPlaces, ...new_places];
    }
  }
});

export const { addNewsList, addMoreNews, addNewsListOffset, addArticleDetails, updateTime, changeNewsCount, resetNewsCount, clearNewsList, clearKeywordsOffset,
  deleteManyArticles, deleteArticleDetails, removeOnlyTag, removeTag, addManyTags, removeManyTags, changeTone, changeArticle, changeValueInArticles,
  changeManyValuesInArticles, toggleMention, toggleArticle, clearChecked, closeFooter, onChangeTab, onChangeMention, clearArticleDetails, toggleOpenNewsList, onChangeKeywordId,
  addCreatorsList, addPublicationPlacesList
} = newsSlice.actions;
export default newsSlice.reducer;
