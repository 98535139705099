export const styles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    minHeight: '32px',
    backgroundColor: state?.sort ? '#F6F7FA' : '#FFFFFF',
    height: '30px',
    borderRadius: '4px',
    border: '1px solid #E0E0F3 !important',
    ':focus': {
      backgroundColor: '#FBFBFB'
    },
    outline: 'none !important',
    boxShadow: 'none !important',
    opacity: state.isDisabled ? '0.8' : '1'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 12px'
  }),

  singleValue: provided => ({
    ...provided,
    margin: '0',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '400',
    color: '#4D4476'
  }),

  menu: provided => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: '0px 2px 10px rgba(221, 226, 241, 0.6)',
    zIndex: 9999
  }),

  menuList: provided => ({
    ...provided,
    paddingBottom: '3px',
    maxHeight: '214px'
  }),

  option: (provided, state) => ({
    ...provided,
    marginBottom: '1px',
    padding: '10px 16px',
    height: '40px',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: state.isSelected ? '500' : '400',
    cursor: 'pointer',
    color: '#4D4476',
    backgroundColor:
      state.isFocused || state.isSelected ? '#FBFBFB' : '#FFFFFF',
    ':active': {
      backgroundColor: '#FBFBFB'
    }
  })
};
