import t from 'utils/translateFunc';

export default function getOptions() {
  const stylesMulti = {
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      minHeight: '32px',
      backgroundColor: state?.sort ? '#F6F7FA' : '#FFFFFF',
      borderRadius: '4px',
      boxShadow: '0px 2px 10px rgba(221, 226, 241, 0.6)',
      border: 'none',
      outline: 'none',
      opacity: state.isDisabled ? '0.8' : '1'
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '6px',
      paddingLeft: '12px'
    }),

    multiValue: provided => ({
      ...provided,
      margin: 0,
      padding: '4px 0',
      backgroundColor: '#FFFFFF'
    }),
    multiValueLabel: provided => ({
      ...provided,
      padding: '0 2px',
      paddingLeft: '2px',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: '400',
      color: '#24252D',
      cursor: 'pointer',
      transition: 'all 150ms linear',
      '&:hover + div[role="button"]': {
        opacity: 1
      }
    }),
    multiValueRemove: provided => ({
      ...provided,
      padding: 0,
      cursor: 'pointer',
      opacity: 0,
      backgroundColor: '#FFFFFF',
      color: '#8B96B2',
      transition: 'all 150ms linear',
      '&:hover': {
        opacity: 1,
        backgroundColor: '#FFFFFF',
        color: '#8B96B2'
      }
    })
  };
  const customStyles = {
    control: provided => ({
      ...provided,
      width: '100%',
      minHeight: '40px',
      maxHeight: '40px',
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),

    valueContainer: provided => ({
      ...provided,
      display: 'flex',
      flexWrap: 'no-wrap',
      padding: '0 16px'
    }),

    menu: provided => ({
      ...provided,
      margin: '2px 0 0 0',
      maxHeight: '253px',
      border: 'none',
      outline: 'none',
      borderRadius: '4px',
      boxShadow: '0px 2px 10px rgba(221, 226, 241, 0.6)'
    }),

    menuList: provided => ({
      ...provided,
      paddingTop: '4px',
      paddingBottom: '3px',
      maxHeight: '200px',
      '::-webkit-scrollbar': {
        width: '11px',
        height: '12px'
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#B9C1D5',
        border: '3px solid transparent',
        borderRadius: '20px',
        backgroundClip: 'content-box'
      }
    }),

    option: (provided, state) => ({
      ...provided,
      marginBottom: '1px',
      padding: '6px 12px',
      display: 'flex',
      alignItems: 'center',
      height: '32px',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: '400',
      cursor: 'pointer',
      color: '#31254B',
      backgroundColor: state.isFocused ? '#FBFBFB' : '#FFFFFF',
      ':active': { backgroundColor: '#FFFFFF' }
    }),

    placeholder: provided => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: '400',
      color: '#8086AB'
    }),

    input: provided => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: '400',
      color: '#8086AB'
    }),

    noOptionsMessage: provided => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1px',
      height: '24px',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: '400',
      color: 'rgba(157, 150, 184, 1)'
    })
  };
  const mediaTypesOptions = [
    { value: 'internet', label: t('mediaTypesFilter', 'INTERNET'), icon: 'internet' },
    { value: 'agency', label: t('mediaTypesFilter', 'AGENCY'), icon: 'agency' },
    { value: 'press', label: t('mediaTypesFilter', 'PRESS'), icon: 'press' },
    { value: 'radio', label: t('mediaTypesFilter', 'RADIO'), icon: 'radio' },
    { value: 'socialnetwork', label: t('mediaTypesFilter', 'SOCIALNETWORK'), icon: 'socialnetwork' },
    { value: 'tv', label: t('mediaTypesFilter', 'TV'), icon: 'tv' },
    { value: 'forum', label: t('mediaTypesFilter', 'FORUM'), icon: 'forum' }];
  return { mediaTypesOptions, stylesMulti, customStyles };
}
