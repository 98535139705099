import { string, oneOfType, number } from 'prop-types';

export function ArrowLeft({ color = '#5A5E79', width = '24', height = '24' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '24'} height={height || '24'} viewBox="0 0 24 24" fill="none">
      <mask id="mask0_965_18391" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width={width || '24'} height={height || '24'}>
        <rect width="24" height="24" fill={color} />
      </mask>
      <g mask="url(#mask0_965_18391)">
        <path d="M10.8 12L14.7 15.9C14.8834 16.0833 14.975 16.3167 14.975 16.6C14.975 16.8833 14.8834 17.1167 14.7 17.3C14.5167 17.4833 14.2834 17.575 14 17.575C13.7167 17.575 13.4834 17.4833 13.3 17.3L8.70005 12.7C8.60005 12.6 8.52922 12.4917 8.48755 12.375C8.44588 12.2583 8.42505 12.1333 8.42505 12C8.42505 11.8667 8.44588 11.7417 8.48755 11.625C8.52922 11.5083 8.60005 11.4 8.70005 11.3L13.3 6.7C13.4834 6.51667 13.7167 6.425 14 6.425C14.2834 6.425 14.5167 6.51667 14.7 6.7C14.8834 6.88334 14.975 7.11667 14.975 7.4C14.975 7.68334 14.8834 7.91667 14.7 8.1L10.8 12Z" fill={color} />
      </g>
    </svg>
  );
}

ArrowLeft.propTypes = {
  color: string,
  width: oneOfType([number, string]),
  height: oneOfType([number, string])
};
