import { string, func, number, bool, node, shape, oneOfType } from 'prop-types';
import cn from 'classnames';
import { InfoBtn } from 'Components';
import classes from './TextField.module.scss';

const TextField = ({
  value = '',
  name = '',
  labelText = '',
  type = 'text',
  onChange = () => null,
  onBlur = () => null,
  className = '',
  classNameForInput = '',
  classNameLabelText = '',
  borderRadius = false,
  boxShadow = false,
  placeholder = '',
  errorText = '',
  infoButton = false,
  required = false,
  infoText = '',
  TextTooltip = '',
  autoComplete = 'off',
  labelInfo = '',
  additionalInfo = '',
  readonly = false,
  customIcon = false
}) => (
  <div className={cn(classes.textField, className)}>
    <label className={classes.label}>
      {labelText && (
      <p className={cn(classes.labelText, classNameLabelText)}>
        <span>{labelText}</span>
          {labelInfo && (<InfoBtn text={labelInfo} icon="error_outline" />)}
      </p>

      )}
      <div className={classes.inputWrap}>
        <input
          autoComplete={autoComplete}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={
              placeholder || ''
            }
          required={required}
          readOnly={readonly}
          className={cn(
            classes.textInput,
            classes.classTextArea,
            classNameForInput,
            borderRadius && classes.textInput_borderRadius,
            boxShadow && classes.textInput_boxShadow,
            errorText && classes.textInput_error,
            infoButton && classes.textInput_infoButton
          )}
        />
        {infoButton && (
          <span className={cn(classes.info)}>
            <InfoBtn text={TextTooltip} icon="error_outline" />
          </span>
        )}
        {additionalInfo && (
          <span className={cn(classes.infoAdditional)}>
            <InfoBtn text={additionalInfo} icon="Icn_Help_nofill" />
          </span>
        )}
        {!!customIcon && customIcon}
      </div>
    </label>
    {errorText && typeof errorText === 'string' && <p className={cn(classes.infoText, classes.infoText_error)}>{errorText}</p>}
    {infoText && <p className={cn(classes.infoText)}>{infoText}</p>}
  </div>
);

TextField.propTypes = {
  value: oneOfType([string, number, shape({})]),
  name: string,
  labelText: string,
  labelInfo: string,
  type: string,
  onChange: func,
  onBlur: func,
  className: string,
  classNameForInput: string,
  classNameLabelText: string,
  borderRadius: bool,
  boxShadow: bool,
  placeholder: string,
  errorText: oneOfType([string, bool]),
  infoButton: bool,
  required: bool,
  readonly: bool,
  infoText: string,
  additionalInfo: string,
  TextTooltip: string,
  autoComplete: string,
  customIcon: oneOfType([node, bool])
};

export default TextField;
