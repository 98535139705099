import { string } from 'prop-types';

export function SmallScreen({ color = '#8086AB' }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.4 20L0 18.6L6.6 12H2V10H10V18H8V13.4L1.4 20ZM10 10V2H12V6.6L18.6 0L20 1.4L13.4 8H18V10H10Z" fill={color} />
    </svg>
  );
}
SmallScreen.propTypes = {
  color: string
};
