import { useState, memo } from 'react';
import { string, func, shape, arrayOf, number } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, SelectCustom, Loader } from 'Components';
import { useDownloadReportsList, useDownloadReport } from 'storage/queryHooks';
import isEqual from 'lodash/isEqual';
import { downloadSelectMenu } from 'data/dropDownConfig';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyDownloadPublications.module.scss';
import { styles } from './selectStyles';

const BodyDownloadPublications = memo(({ className = '',
  allArticlesCount = 0,
  language = 'uk',
  onCancel = () => null,
  onShowEmptyReport = () => null,
  data = { article_ids: [] }
}) => {
  const [temlateName, setTemlateName] = useState('default');
  const [format, setFormat] = useState('pdf');
  const [option, setOption] = useState(!data?.article_ids?.length ? 'all' : 'checked');
  const { data: dataResp } = useDownloadReportsList();
  const { mutateAsync: downloadReport, isLoading } = useDownloadReport();
  const reportsMenuCustom = dataResp?.length ? dataResp.map(({ id, title }) => ({ label: title, value: id })) : [];
  const reportsMenu = [{ value: 'default', label: t('reportsMenu', 'DEFAULT', language) }].concat(reportsMenuCustom);

  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedEvent({ category: 'Download', event: 'Report_Download_Button_Clicked' });
    const body = { file_type: format };
    typeof temlateName === 'number' && (body.report_customization_id = temlateName);
    !!data?.article_ids?.length && (body.selected_articles_ids = data?.article_ids);

    allArticlesCount ? downloadReport(body) : onShowEmptyReport();
  };
  function handleChangeTemplate({ value }) {
    setTemlateName(value);
  }
  function handleChangeFormat({ value }) {
    setFormat(value);
  }
  function handleChangeOption(e) {
    setOption(e.target.value);
  }
  return (
    <form
      onSubmit={onSubmit}
      className={cn(classes.bodyCreateModal, className)}
    >
      {!!data?.article_ids?.length && (
      <div className={classes.optionsWrapper}>
        <div className={classes.optionsTitle}>{t('bodyDownloadReports', 'CHOOSE_TYPE', language)}</div>

        <div className={classes.optionBox}>
          <label className={cn(classes.option, option === 'all' && classes.option_checked)}>
            <div className={cn(classes.radioBtn, option === 'all' && classes.radioBtn_checked)}>
              {option === 'all' && <div className={cn(classes.radioBtnInside)} />}
            </div>
            <input
              className={classes.visually_hidden}
              type="radio"
              name="all"
              checked={option === 'all'}
              value="all"
              onChange={handleChangeOption}
            />
            {t('bodyDownloadReports', 'DOWNLOAD_ALL', language)}
          </label>
        </div>

        <div className={classes.optionBox}>
          <label className={cn(classes.option)}>
            <div className={cn(classes.radioBtn, option === 'checked' && classes.radioBtn_checked)}>
              {option === 'checked' && <div className={cn(classes.radioBtnInside)} />}
            </div>
            <input
              className={classes.visually_hidden}
              type="radio"
              name="checked"
              checked={option === 'checked'}
              value="checked"
              onChange={handleChangeOption}
            />
            {`${t('bodyDownloadReports', 'DOWNLOAD_SELECTED', language)} (${data?.article_ids?.length}) ${t('bodyDownloadReports', 'PUBLICATIONS', language)}`}
          </label>
        </div>
      </div>
      )}

      {!!reportsMenu?.length && (
      <div className={classes.selectWrapper} data-name="select-menu">
        <div className={classes.seletMenuTitle}>{t('bodyDownloadReports', 'CHOOSE_TEMPLATE', language)}</div>
        <SelectCustom
          value={reportsMenu?.find(({ value }) => value === temlateName) || reportsMenu[0]}
          onChange={handleChangeTemplate}
          options={reportsMenu}
          className={classes.selectDropDown}
          styles={styles}
        />
      </div>
      )}
      <div className={classes.selectWrapper} data-name="select-menu">
        <div className={classes.seletMenuTitle}>{t('bodyDownloadReports', 'CHOOSE_FORMAT', language)}</div>
        <SelectCustom
          value={downloadSelectMenu?.find(({ value }) => value === format) || downloadSelectMenu[0]}
          onChange={handleChangeFormat}
          options={downloadSelectMenu}
          className={classes.selectDropDown}
          styles={styles}
        />
      </div>
      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />
        <Loader type="spin" size={16} fetching={isLoading} color="#A7B0C8" className={classes.iconContainer}>
          <Button
            type="submit"
            onClick={onSubmit}
            textButton={t('btn', 'DOWNLOAD', language)}
            green32
          />
        </Loader>
      </div>
    </form>
  );
}, (prevProps, nextProps) => isEqual(prevProps?.data, nextProps?.data));

BodyDownloadPublications.propTypes = {
  className: string,
  allArticlesCount: number,
  onCancel: func,
  onShowEmptyReport: func,
  language: string,
  data: shape({ article_ids: arrayOf(number) })
};

export default BodyDownloadPublications;
