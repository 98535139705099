import { API_URL, DASHBOARD_URL } from 'config';

export const COUNT_MENU_ITEMS = 5;

export const navigationConfig = [
  {
    name: 'MENTIONS',
    info: '',
    action: 'mentions'
  },
  {
    name: 'DASHBOARDS',
    info: '',
    action: 'dashboards'
  },
  {
    name: 'SEARCH',
    info: '',
    action: 'search'
  },
  {
    name: 'ALERTS',
    info: '',
    action: 'alerts'
  },
  {
    name: 'DOWNLOADS',
    info: '',
    action: 'downloads'
  },
  {
    name: 'ENCODING',
    info: '',
    action: 'encoding'
  },
  {
    name: 'ENCODING_STAT',
    info: '',
    action: 'encoding_stat'
  },
  {
    name: 'TRACKING',
    info: '',
    action: 'tracking'

  },
  {
    name: 'TRACKING_BOTS',
    info: '',
    action: 'tracking_bots'
  },
  {
    name: 'ADMIN',
    info: '',
    action: 'admin'
  }
  // {
  //   name: 'CHECKIT',
  //   info: 'New',
  //   action: 'checkit'
  // },
];

export const getPathes = ({ portal_slug, serverUpdating, datePickerPeriod }) => {
  const dashboardsUrl = DASHBOARD_URL || process.env.REACT_APP_LOOQME_DASHBOARD_URL;
  const hubUrl = API_URL || process.env.REACT_APP_LOOQME_HUB_URL;
  const appUrl = window.location.origin;
  const { start, end } = datePickerPeriod;
  return ({
    MENTIONS: serverUpdating ? 'updating' : `${appUrl}/${portal_slug}/mentions/all`,
    DASHBOARDS: `${dashboardsUrl}/redirect_dashboard/?portal_slug=${portal_slug}&start=${start}&end=${end}`,
    SEARCH: `${hubUrl}/search/`,
    ALERTS: `${appUrl}/alerts/`,
    DOWNLOADS: `${appUrl}/downloads/`,
    CHECKIT: `${process.env.REACT_APP_LOOQME_CHECKIT_URL}`,
    ENCODING: `${hubUrl}/operator/`,
    ENCODING_STAT: `${hubUrl}/operators-management/`,
    TRACKING: `${hubUrl}/tracking/`,
    TRACKING_BOTS: `${hubUrl}/tracking/#/bot`,
    ADMIN: `${hubUrl}/admin/`,
    LOGO: window.location.href
  });
};

export const helpMenu = [
  {
    name: 'CHAT', icon: 'chat', info: true, action: 'chat'
  },
  {
    name: 'BASE_KNOLEDGES', icon: 'base_knoledges', info: false, action: 'help'
  },
  {
    name: 'BLOG', icon: 'blog', info: false, action: 'blog'
  }
  // {
  //   name: 'WHATS_NEW', icon: 'whats_new', info: true, action: 'new'
  // },
  // {
  //   name: 'FEEDBACK', icon: 'feed_back', info: false, action: 'feedback'
  // }
];

export const userMenu = [
  {
    name: 'ACCOUNT', icon: 'account', info: false, action: 'account'
  },
  {
    name: 'FEED', icon: 'feed', info: false, action: 'feed'
  },
  {
    name: 'EXIT', icon: 'exit', info: false, action: 'exit'
  }
];
