import { string, number } from 'prop-types';
import cn from 'classnames';

export const ArrowUp = ({ width = 14, height = 8, color = '#3D8171', className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={cn(className, 'icon icon-arrow-up')}
    viewBox="0 0 14 8"
    fill="none"
  >
    <path d="M13.7287 7.37697C14.0904 7.03183 14.0904 6.47537 13.7287 6.13022L7.52129 0.206387C7.23343 -0.0683217 6.76842 -0.0683217 6.48056 0.206387L0.265718 6.13726C-0.0885726 6.47537 -0.0885726 7.03887 0.265718 7.37697C0.627389 7.72212 1.21049 7.72212 1.57216 7.37697L6.99723 2.20682L12.4223 7.38402C12.784 7.72212 13.3671 7.72212 13.7287 7.37697Z" fill={color || '#3D8171'} />
  </svg>

);

ArrowUp.propTypes = {
  width: number,
  height: number,
  className: string,
  color: string
};
