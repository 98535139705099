/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import { changeSearchTab, clearSearchFilterList, addNewSearchFilter, selectSavedSearchFilter, editSearchFilterName, restoreDefaultSearchFilter } from 'storage/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSearchFilters, useCreateEditSearchFilter, useDeleteSearchFilter, useGetAllFavoriteSearchCount } from 'storage/queryHooks';
import t from 'utils/translateFunc';
import { searchQuerySelector } from 'storage/selectors';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { Typography } from 'antd';
import classes from './SearchFilters.module.scss';
import FiltersHeader from './FiltersHeader';
import FiltersList from './FiltersList';
import FilterEditable from './FilterEditable';

const { Paragraph } = Typography;
function SearchFilters() {
  const dispatch = useDispatch();
  const searchQuery = useSelector(searchQuerySelector);
  const { userSearchFilters, filters, searchTab, selectedSearchFilter } = useSelector(state => state?.search);
  const { isLoading: filtersLoading } = useGetSearchFilters();
  const { mutateAsync: createEditSearchFilter } = useCreateEditSearchFilter();
  const { mutateAsync: deleteSearchFilter } = useDeleteSearchFilter();
  const { data: allFavoriteCount } = useGetAllFavoriteSearchCount();

  function checkUnsavedChanges() {
    if (!selectedSearchFilter?.id) return true;
    const etalonFilter = !!userSearchFilters?.length && !!selectedSearchFilter?.id && userSearchFilters?.find(item => item?.id === selectedSearchFilter?.id);
    const currentFilter = { ...selectedSearchFilter, ...filters };
    return !isEqual(etalonFilter, currentFilter);
  }
  const hasFiltersChanges = useMemo(() => Object.values(omit(filters, ['query'])).some(item => !!item?.length), [filters]);
  const hasChanges = useMemo(() => !!selectedSearchFilter?.name && hasFiltersChanges && checkUnsavedChanges(), [userSearchFilters, filters, selectedSearchFilter]);

  function onResetFilters() {
    dispatch(clearSearchFilterList());
  }
  function addNewFilter() {
    dispatch(addNewSearchFilter({ id: null, name: t('search', 'NEW_FILTER') }));
  }

  function onSelectFilter(value) {
    !value?.id && onResetFilters();
    dispatch(selectSavedSearchFilter(value));
  }

  function onChangeTab(tabValue) {
    if (tabValue !== searchTab) {
      onResetFilters();
      dispatch(changeSearchTab(tabValue));
    }
  }

  function onSaveChanges(currentSelectedFilter) {
    createEditSearchFilter({ ...currentSelectedFilter, ...searchQuery });
  }
  function onRename({ name }) {
    dispatch(editSearchFilterName({ name }));
  }

  function onRemoveFilter(id) {
    deleteSearchFilter(id);
  }
  return (
    <div className={classes.searchFilters} id="search-filters">
      <FiltersHeader
        tab={searchTab}
        favoriteCount={allFavoriteCount?.count || 0}
        hasChanges={hasChanges}
        onResetFilters={onResetFilters}
        addNewFilter={addNewFilter}
        onChangeTab={onChangeTab}
      />
      <div className={classes.customFiltersListWrapp}>
        { !!userSearchFilters?.length && (
        <div className={classes.customFiltersList}>
          { userSearchFilters.map((filterItem, i) => (
            <div
              key={i}
              className={cn(
                classes.customFiltersItem,
                filterItem?.id === selectedSearchFilter?.id && classes.customFiltersItem_active
              )}
              onClick={() => { onSelectFilter(filterItem); }}
            >
              <Paragraph style={{ maxWidth: '56px' }} ellipsis={{ rows: 2 }}>
                {filterItem?.name}
              </Paragraph>
            </div>
          )) }
        </div>
        )}
        <div className={classes.filtersListWrapp}>
          {selectedSearchFilter && (
          <FilterEditable
            hasChanges={hasChanges}
            onSelectFilter={onSelectFilter}
            onSaveChanges={onSaveChanges}
            onRemoveFilter={onRemoveFilter}
            onRename={onRename}
            onRestoreDefault={() => { dispatch(restoreDefaultSearchFilter()); }}
            filter={selectedSearchFilter}
          />
          )}
          <FiltersList {...filters} />
        </div>
      </div>
    </div>
  );
}

export default SearchFilters;
