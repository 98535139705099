import React from 'react';
import { shape, string } from 'prop-types';
import { Animated, BodyHtml } from 'Components';
import { textReplaced } from 'data/replacedFunc';
import t from 'utils/translateFunc';
import cn from 'classnames';
import classes from './SearchQuery.module.scss';

function Errors({ errorQuery = '', errorValidate = null }) {
  return (
    <Animated
      visible={errorQuery || errorValidate}
      duration={300}
      className={cn(classes.errorWrapp, errorValidate && classes.errorWrapp_validate)}
    >
      {errorValidate ? (
        <div className={classes.errorValidateWrapp}>
          <div className={classes.header}>{errorValidate?.message}</div>
          <div className={classes.example}>
            <BodyHtml html={textReplaced(errorValidate?.example)} />
            <strong>
            &nbsp;&ndash;&nbsp;
              {t('search', 'EXAMPLE_OF_POTENTIAL_ERROR')}
            </strong>
          </div>
        </div>
      ) : <div className={classes.errorMessage}>{errorQuery}</div>}
    </Animated>
  );
}

Errors.propTypes = { errorQuery: string, errorValidate: shape({}) };

export default Errors;
