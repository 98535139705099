import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, AnimatedTab, TooltipForTruncate } from 'Components';
import { useNavigate } from 'react-router-dom';
import { oneOfType, func, shape, bool, string } from 'prop-types';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './AlertDetails.module.scss';

const QueryComponent = ({ name = '', error = false, query = '', queryBuilder = null, onOpenModal = () => null }) => {
  const history = useNavigate();

  function onOpenBuilder() {
    sendAlertsEvent({ category: 'Edit Search Query', event: 'Clicked_Edit_Query_Button' });
    query ? history('/advanced_builder/alert/') : onOpenModal({ action: 'addEditSearchQuery', info: null });
  }

  return (
    <AnimatedTab>
      <div className={cn(
        classes.querySearchWrapper,
        !(query || queryBuilder) && classes.querySearchWrapper_empty,
        error && classes.querySearchWrapper_error
      )}
      >
        <div className={classes.querySearch}>
          <div className={classes.querySearch_image} />
          <div className={classes.querySearch_content}>
            <div className={cn(classes.queryTitle, !(query || queryBuilder) && classes.queryText_example)}>
              {(query || queryBuilder) ? t('alerts', query ? t('alerts', 'ADVANCED') : t('alerts', 'BASIC'))
                : t('alerts', 'REFINE_SEARCH_KEYWORD')}
            </div>
            {(query || queryBuilder) && name && (
            <TooltipForTruncate
              style={{ maxWidth: '375px' }}
              tooltipContent={queryBuilder?.keyword || name}
            >
              <span className={cn(classes.queryText)}>
                {queryBuilder?.keyword || name}
              </span>
            </TooltipForTruncate>
            )}
          </div>
        </div>
        <div className={classes.queryButtonBox}>
          {query || queryBuilder ? (
            <Button
              onClick={onOpenBuilder}
              textButton={t('btn', 'EDIT_QUERY')}
              purpleTransparent32
              noBorder
            />
          )
            : (
              <>
                <Button
                  onClick={() => {
                    sendAlertsEvent({ category: 'Add Query', event: 'Clicked_Customize_Query_Button' });
                    onOpenModal({ action: 'selectConstructorQuery', info: null });
                  }}
                  textButton={t('btn', 'CUSTOMIZE_QUERY')}
                  green32
                />
                <Button
                  onClick={() => {
                    const helpLink = 'http://help.looqme.io/uk/articles/8804681-%D1%8F%D0%BA-%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D1%83%D0%B2%D0%B0%D1%82%D0%B8%D1%81%D1%8F-%D1%80%D0%BE%D0%B7%D1%88%D0%B8%D1%80%D0%B5%D0%BD%D0%B8%D0%BC-%D0%BA%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D0%BE%D0%BC-%D0%B7%D0%B0%D0%BF%D0%B8%D1%82%D1%96%D0%B2';
                    window.open(helpLink, '_blank');
                  }}
                  textButton={t('btn', 'LEARN_MORE')}
                  className={classes.cancelBtn}
                  purpleTransparent32
                  noBorder
                />
              </>
            )}
        </div>
      </div>
    </AnimatedTab>
  );
};

QueryComponent.propTypes = {
  name: string,
  query: string,
  queryBuilder: shape({}),
  error: oneOfType([bool, string]),
  onOpenModal: func
};

export default QueryComponent;
