import t from 'utils/translateFunc';
import { TextField, Icon } from 'Components';
import { func, number, oneOfType, string } from 'prop-types';
import classes from '../FiltersList.module.scss';

function CustomRage({ range = '', label = '', onChange = () => null }) {
  return (
    <div className={classes.selectWrapper}>
      <TextField
        labelText={label}
        value={range}
        classNameLabelText={classes.seletMenuTitle}
        onChange={({ target }) => {
          const pattern = /^\s*\d*$/;
          !target.value && onChange('');
          target.value && pattern.test(target.value) && onChange(target.value);
        }}
        classNameForInput={classes.inputNumber}
        placeholder={t('search', 'FROM')}
        customIcon={(
          <span className={classes.arrowsBox}>
            <Icon
              type="arrow_left"
              width={20}
              height={20}
              color="#8086AB"
              onClick={() => { onChange(+range >= 1000 ? +range - 1000 : 0); }}
            />
            <Icon
              type="arrow_left"
              width={20}
              height={20}
              className={classes.iconRight}
              color="#8086AB"
              onClick={() => { onChange(range ? +range + 1000 : 1000); }}
            />
          </span>
      )}
      />
    </div>
  );
}

CustomRage.propTypes = { range: oneOfType([number, string]), label: string, onChange: func };

export default CustomRage;
