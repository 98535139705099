import { useState, useEffect } from 'react';
import { useGetSearchHistory, useGetSearchHistoryDay, useEditPeriod } from 'storage/queryHooks.js';
import moment from 'moment';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { textReplaced } from 'data/replacedFunc';
import { BodyHtml, openNotification, Icon, Tooltip, Button, SkeletonSearchHistory } from 'Components';
import { mediaTypeOption, postTypeOption } from 'data/selectOptions';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { hasFlag } from 'country-flag-icons';
import { useDispatch } from 'react-redux';
import { selectSavedSearchQuery } from 'storage/actions';
import { func } from 'prop-types';
import classes from './BodySearchHistory.module.scss';

function SearchHistory({ onCancel }) {
  const dispatch = useDispatch();
  const [day, setDay] = useState('');
  const { data: queriesDays } = useGetSearchHistory();
  const { mutateAsync: editPeriod } = useEditPeriod();
  const { data: queries, isLoading: queriesLoading, isRefetching: queriesisRefetching } = useGetSearchHistoryDay(day);

  useEffect(() => {
    queriesDays?.length && setDay(queriesDays[0]?.id);
  }, [queriesDays]);

  function onCopyQuery(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        openNotification({ message: t('notif', 'QUERY_COPIED') });
      });
  }

  function showDateName(date) {
    const differ = moment().diff(date, 'days');
    if (differ === 0) return t('datepicker', 'TODAY');
    if (differ === 1) return t('datepicker', 'YESTERDAY');
    if (differ === 2) return t('datepicker', 'DAY_BEFORE_YESTERDAY');
    return moment(date)?.format('DD.MM.YYYY');
  }
  function onSelectFilter(filter) {
    !!filter?.filters && dispatch(selectSavedSearchQuery({ query: filter?.query, ...filter?.filters }));
    !!filter.search_date_from && editPeriod({ start: filter.search_date_from, end: filter.search_date_to, by_created: false });
    onCancel();
  }

  return (
    <div className={classes.searchHistory}>
      <div className={cn(classes.searchesList)}>
        {!!queriesDays?.length && queriesDays.map((item, i) => (
          !!item?.id && (
          <div
            key={i}
            className={cn(
              classes.searchesItem,
              !!queriesDays && item?.id === day && classes.searchesItem_active,
              !!(queriesLoading || queriesisRefetching) && classes.searchesItem_fetching

            )}
            onClick={() => { !queriesLoading && !queriesisRefetching && setDay(item?.id); }}
          >
            <div>{showDateName(item.id) }</div>
            <div className={classes.searchesCount}>
              {item?.count ?? '-'}
              &nbsp;
              {t('search', item?.count <= 1 ? 'QUERY' : 'QUERIES')}
            </div>
          </div>
          )
        ))}
      </div>
      <SkeletonSearchHistory fetching={queriesLoading || queriesisRefetching}>
        <div className={classes.searchesQueries}>
          {queries?.length
      && queries?.map((item, i) => (
        <div key={i} className={classes.searchesQueryCard}>
          <div className={classes.header}>
            {!!item.search_date_from && (
            <div>
              <Icon type="calendar" />
              <div className={classes.date}>
                {moment(item.search_date_from)?.format('DD/MM/YYYY')}
                &nbsp;
                <span>{moment(item.search_date_from)?.format('HH:mm')}</span>
                {' - '}
                {moment(item.search_date_to)?.format('DD/MM/YYYY')}
                &nbsp;
                <span>{moment(item.search_date_to)?.format('HH:mm')}</span>
              </div>
            </div>
            ) }
            {!!item.created_at && (
              <div className={classes.date}>
                <Icon type="search_checked" />
                {moment(item.created_at)?.format('DD/MM/YYYY')}
                &nbsp;
                <span>{moment(item.created_at)?.format('HH:mm')}</span>
              </div>
            )}
          </div>
          {item?.query && <BodyHtml html={textReplaced(item.query)} className={classes.query} />}
          {item?.filters && (
          <div className={classes.filters}>
            {!!item?.filters?.media_type?.length && item?.filters?.media_type.map((el, ind) => (
              <Tooltip text={mediaTypeOption(el)?.label || ''} key={ind} className={cn(classes.filterBtn, classes.greenBtn)}>
                <Icon type={mediaTypeOption(el)?.icon || ''} />
              </Tooltip>
            ))}
            {!!item?.filters?.media_type_exclude?.length && item?.filters?.media_type_exclude.map((el, ind) => (
              <Tooltip text={mediaTypeOption(el)?.label || ''} key={ind} className={cn(classes.filterBtn, classes.redBtn)}>
                <Icon type={mediaTypeOption(el)?.icon || ''} />
              </Tooltip>
            ))}
            {!!item?.filters?.country?.length && item?.filters?.country.map((el, ind) => {
              const code = el?.value?.toUpperCase();
              const flag = el?.value && hasFlag(code) && getUnicodeFlagIcon(code);
              return (
                <Tooltip text={`${t('search', 'COUNTRY')} ${el?.name || el}`} key={ind} className={cn(classes.filterBtn, classes.greenBtn)}>
                  { flag || el}
                </Tooltip>
              );
            })}
            {!!item?.filters?.country_exclude?.length && item?.filters?.country_exclude.map((el, ind) => {
              const code = el?.value?.toUpperCase();
              const flag = el?.value && hasFlag(code) && getUnicodeFlagIcon(code);
              return (
                <Tooltip text={`${t('search', 'COUNTRY')} ${el?.name || el}`} key={ind} className={cn(classes.filterBtn, classes.redBtn)}>
                  { flag || el}
                </Tooltip>
              );
            })}
            {!!item?.filters?.language?.length && item?.filters?.language.map((el, ind) => (
              <Tooltip text={`${t('search', 'LANGUAGE')} ${el?.name || el}`} key={ind} className={cn(classes.filterBtn, classes.greenBtn)}>
                <b>{ el?.code?.toUpperCase() || el}</b>
              </Tooltip>
            ))}
            {!!item?.filters?.language_exclude?.length && item?.filters?.language_exclude.map((el, ind) => (
              <Tooltip text={`${t('search', 'LANGUAGE')} ${el?.name || el}`} key={ind} className={cn(classes.filterBtn, classes.redBtn)}>
                <b>{ el?.code?.toUpperCase() || el}</b>
              </Tooltip>
            ))}
            {!!item?.filters?.source?.length && (
              <Tooltip text={t('search', 'NUMBER_OF_SOURCES')} className={cn(classes.filterBtn, classes.greenBtn)}>
                {t('search', 'SOURCE_S')}
                <strong>
                  { item.filters.source.length + 1}
                </strong>
              </Tooltip>
            )}
            {!!item?.filters?.source_exclude?.length && (
              <Tooltip text={t('search', 'NUMBER_OF_EXCLUDED_SOURCES')} className={cn(classes.filterBtn, classes.redBtn)}>
                {t('search', 'SOURCE_S')}
                &nbsp;
                <b>{ item.filters.source_exclude.length + 1}</b>
              </Tooltip>
            )}
            {!!item?.filters?.post_type?.length && item?.filters?.post_type.map((el, ind) => (
              <Tooltip text={postTypeOption(el)?.label || ''} key={ind} className={cn(classes.filterBtn, classes.greenBtn)}>
                <Icon type={postTypeOption(el)?.icon || ''} />
              </Tooltip>
            ))}
            {!!item?.filters?.post_type_exclude?.length && item?.filters?.post_type_exclude.map((el, ind) => (
              <Tooltip text={postTypeOption(el)?.label || ''} key={ind} className={cn(classes.filterBtn, classes.redBtn)}>
                <Icon type={postTypeOption(el)?.icon || ''} />
              </Tooltip>
            ))}
            {!!item?.filters?.level?.length && item?.filters?.level.map((el, ind) => (
              <Tooltip text={`${t('search', 'LEVEL')} ${el?.name || el}`} key={ind} className={cn(classes.filterBtn, classes.greenBtn)}>
                { el?.name || el}
              </Tooltip>
            ))}
            {!!item?.filters?.level_exclude?.length && item?.filters?.level_exclude.map((el, ind) => (
              <Tooltip text={`${t('search', 'LEVEL')} ${el?.name || el}`} key={ind} className={cn(classes.filterBtn, classes.redBtn)}>
                { el?.name || el}
              </Tooltip>
            ))}
            {!!item?.filters?.sn_attendance_gte && (
              <Tooltip text={t('search', 'SOCIAL_MEDIA_AUDIENCE')} className={cn(classes.filterBtn)}>
                <Icon type="group" color="#8086AB" />
                  &nbsp;&gt;&nbsp;
                  {t('search', 'SOCIAL_NETWORK_S')}
                  &nbsp;
                <b>{item?.filters?.sn_attendance_gte}</b>
              </Tooltip>
            )}
            {!!item?.filters?.source_attendance_gte && (
              <Tooltip text={t('search', 'SOURCE_AUDIENCE')} className={cn(classes.filterBtn)}>
                <Icon type="group" color="#8086AB" />
                  &nbsp;&gt;&nbsp;
                <b>{item?.filters?.source_attendance_gte}</b>
              </Tooltip>
            )}
          </div>
          )}
          <hr />
          <div className={classes.footer}>
            <Icon
              type="newspaper"
              role="button"
              tooltipText={t('search', 'NUMBER_OF_ARTICLES_IN_MEDIA')}
              text={item?.media_found_count}
              className={cn(classes.icon, classes.iconBtn)}
            />
            <Icon
              type="hub"
              role="button"
              tooltipText={t('search', 'NUMBER_OF_ARTICLES_IN_SN')}
              text={item?.sn_found_count}
              className={cn(classes.icon, classes.iconBtn)}
            />
            <Icon
              type="copy_all"
              role="button"
              tooltipText={t('search', 'COPY_QUERY')}
              className={cn(classes.icon)}
              onClick={() => { !!item?.query && onCopyQuery(item.query); }}
            />
            <Button
              onClick={() => { onSelectFilter(item); }}
              textButton={t('btn', 'SEARCH_AGAIN')}
              className={classes.findButton}
              green32
            />
          </div>
        </div>
      ))}
        </div>
      </SkeletonSearchHistory>
    </div>
  );
}

SearchHistory.propTypes = { onCancel: func };

export default SearchHistory;
