import { useState } from 'react';
import cn from 'classnames';
import Modals from 'Containers/Modals';
import t from 'utils/translateFunc';
import { AnimatePage } from 'Components';
import { useSelector } from 'react-redux';
import { useGetTelegramAccounts, useGetWhatsappAccounts } from 'storage/queryHooks';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './Alerts.module.scss';
import AlertsSettings from './AlertsSettings';
import ReportsSettings from './ReportsSettings';

export default function Alerts() {
  useGetTelegramAccounts();
  useGetWhatsappAccounts();
  const { alertDetailsVisible, reportDetailsVisible } = useSelector(state => state?.alerts);
  const [tab, setTab] = useState('alerts');

  return (
    <AnimatePage>
      <div className={classes.alertsWrap}>
        <div className={cn(
          classes.settingsTabWrapper,
          ((tab === 'alerts' && alertDetailsVisible) || (tab === 'reports' && reportDetailsVisible)) && classes.settingsTabWrapper_detailsOn
        )}
        >
          <div
            className={cn(classes.tab, tab === 'alerts' && classes.tab_active)}
            onClick={() => {
              sendAlertsEvent({ category: 'Alerts', event: 'Clicked_Alert_Settings_Button' });
              tab !== 'alerts' && setTab('alerts');
            }}
          >
            {t('alerts', 'SETTING_ALERTS')}
          </div>
          <div
            className={cn(classes.tab, tab === 'reports' && classes.tab_active)}
            onClick={() => {
              sendAlertsEvent({ category: 'Reports', event: 'Clicked_Report_Settings_Button' });
              tab !== 'reports' && setTab('reports');
            }}
          >
            {t('alerts', 'SETTING_REPORTS')}
          </div>
        </div>
        {tab === 'alerts' && <AlertsSettings />}
        {tab === 'reports' && <ReportsSettings />}
        <Modals />
      </div>
    </AnimatePage>
  );
}
