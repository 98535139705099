import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as requests from 'storage/filters/requests';
import * as actions from 'storage/actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openNotification } from 'Components';
import t from 'utils/translateFunc';

export const useGetKeywords = ({ enabled }) => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals.currentPortal?.slug) || '';
  return useQuery({
    queryKey: ['getKeywords', portal_slug],
    enabled,
    queryFn: () => requests.getKeywords({ portal_slug }),
    onSuccess: (resp) => { dispatch(actions.addKeywords(resp)); } });
};

export const useGetKeyword = (keyword_id) => {
  const portal_slug = useSelector(state => state?.portals.currentPortal?.slug) || '';
  return useQuery({
    queryKey: ['getKeyword', portal_slug, keyword_id],
    enabled: !!keyword_id,
    queryFn: () => requests.getKeyword({ portal_slug, keyword_id })
  });
};

export const useAddEditKeyword = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const { pathname } = useLocation();
  const history = useNavigate();
  const language = useSelector(state => state?.userInfo?.language);
  const dispatch = useDispatch();
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const date = useSelector(state => state?.datePicker?.dateObj);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'addEditKw',
    mutationFn: ({ body, keyword_id }) => (keyword_id ? requests.editKeyword({ portal_slug, body, keyword_id }) : requests.addKeyword({ portal_slug, body })),
    onSuccess: (resp, req) => {
      if (req?.keyword_id) {
        dispatch(actions.editKeyword(resp));
        dispatch(actions.closeModal());
        req.route ? history(req.route, { state: req.body }) : openNotification({ message: `${t('notif', 'KEYWORD_EDITED', language)}` });
      }
      if (!req?.keyword_id) {
        dispatch(actions.openModal({ action: 'successAddKeyword', info: null }));
        dispatch(actions.addKeyword(resp));
        queryClient.refetchQueries({ queryKey: ['getNewsAllCount', portal_slug, filtersQuery, date], exact: true });
        queryClient.refetchQueries({ queryKey: ['getSettings', portal_slug, language], exact: true });
      }
      if (pathname?.includes('advanced_builder')) {
        history(`/${portal_slug}/mentions/all`, { state: null });
      }
    },
    onError: (resp, req) => {
      const respError = resp?.response?.data?.error || '';
      if (respError?.includes('A lot of keywords')) {
        dispatch(actions.openModal({ action: 'increaseKeywords', info: null }));
        return;
      }
      if (!respError?.includes('duplicate')) {
        dispatch(actions.closeModal());
        openNotification({ message: t('notif', 'SOMETHING_WRONG', language) });
      }
      if (respError?.includes('duplicate') && req?.keyword_id) {
        dispatch(actions.closeModal());
        openNotification({ message: t('notif', 'DUPLICATE_NAME', language), isError: true, duration: 5 });
      }
    }
  });
};

export const useRemoveKeyword = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  const { pathname } = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const date = useSelector(state => state?.datePicker?.dateObj);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'deleteKeyword',
    mutationFn: keyword_id => requests.deleteKeyword({ portal_slug, keyword_id }),
    onSuccess: (_, keyword_id) => {
      if (pathname?.includes('advanced_builder')) {
        history(`/${portal_slug}/mentions/all`, { state: null });
      } else {
        queryClient.refetchQueries({ queryKey: ['getNewsAllCount', portal_slug, filtersQuery, date], exact: true });
        queryClient.refetchQueries({ queryKey: ['getSettings', portal_slug, language], exact: true });
        keyword_id && dispatch(actions.removeKeywordFromList({ keyword_id }));
      }
      openNotification({ message: t('notif', 'KEYWORD_DELETED', language) });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useGetMediaTypes = ({ enabled }) => {
  const language = useSelector(state => state?.userInfo?.language);
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getMediaTypes', language],
    enabled,
    queryFn: () => requests.getMediaTypes({ language }),
    onSuccess: (resp) => { dispatch(actions.addMediaTypes(resp)); } });
};

export const useGetTones = ({ enabled }) => {
  const language = useSelector(state => state?.userInfo?.language);
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getTones', language],
    enabled,
    queryFn: () => requests.getTones({ language }),
    onSuccess: (resp) => {
      dispatch(actions.addTones(resp));
    } });
};

export const useGetTags = ({ enabled }) => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  const noTag = { id: null, name: t('filtration', 'NO_TAG', language) };
  return useQuery({
    queryKey: ['getTags', portal_slug],
    enabled,
    queryFn: () => requests.getTags({ portal_slug }),
    onSuccess: (resp) => { dispatch(actions.addTags([noTag, ...resp])); } });
};

export const useGetTag = (tag_id) => {
  const portal_slug = useSelector(state => state?.portals.currentPortal?.slug) || '';
  return useQuery({
    queryKey: ['getTag', portal_slug, tag_id],
    enabled: !!tag_id,
    queryFn: () => requests.getTag({ portal_slug, tag_id })
  });
};

export const useAddEditTag = () => {
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const { pathname } = useLocation();
  const history = useNavigate();
  const language = useSelector(state => state?.userInfo?.language);
  const info = useSelector(state => state?.modals?.modalInfo);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'addEditTag',
    mutationFn: ({ body, tag_id }) => (tag_id ? requests.editTag({ portal_slug, body }) : requests.addTag({ portal_slug, body })),
    onSuccess: (_, req) => {
      if (req?.route && req?.tag_id) {
        history(req.route, { state: req.body });
      } else {
        const textMain = req?.tag_id ? 'TAG_EDITED' : 'TAG_ADDED';
        let textExtra = '';
        req?.info?.period === 'current' && (textExtra = 'TAG_INCLUDED');
        req?.info?.period === 'last' && (textExtra = !req?.info?.notify ? 'TAG_WILL_ADDED' : 'TAG_NOTIF');
        openNotification({ message: `${t('notif', textMain, language)}. ${textExtra ? t('notif', textExtra, language) : ''}` });
      }
      info?.openTagsPopover
        ? document.getElementById(info?.openTagsPopover)?.click()
        : queryClient.refetchQueries({ queryKey: ['getTags', portal_slug], exact: true });
      if (pathname?.includes('advanced_builder')) {
        history(`/${portal_slug}/mentions/all`, { state: null });
      }
      dispatch(actions.closeModal());
    }
  });
};

export const useRemoveTag = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'removeTag',
    mutationFn: tag_id => requests.removeTag({ portal_slug, tag_id }),
    onSuccess: (_, tag_id) => {
      openNotification({ message: t('notif', 'TAG_DELETED', language) });
      dispatch(actions.removeTag({ tag_id }));
      dispatch(actions.removeTagFromList({ tag_id }));
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useGetPostTypes = ({ enabled }) => {
  const language = useSelector(state => state?.userInfo?.language);
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getPostTypes'],
    enabled,
    queryFn: () => requests.getPostTypes({ language }),
    onSuccess: (resp) => { dispatch(actions.addPostTypes(resp)); } });
};

export const useGetCountries = ({ query, enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getCountries', query],
    enabled,
    queryFn: () => requests.getCountries({ query }),
    onSuccess: (resp) => { dispatch(actions.addCountries(resp)); }
  });
};

export const useGetPreviewCountries = ({ query, enabled }) => {
  const portal_slug = useSelector(state => state.portals?.currentPortal?.slug);
  return useQuery({
    queryKey: ['getPreviewCountry', query],
    enabled,
    queryFn: () => requests.getPreviewCountries({ query, portal_slug })
  });
};

export const useGetRegions = ({ query, enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getRegions', query],
    enabled,
    queryFn: () => requests.getRegions({ query }),
    onSuccess: (resp) => { dispatch(actions.addRegions(resp)); } });
};

export const useGetCities = ({ query, enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getCities', query],
    enabled,
    queryFn: () => requests.getCities({ query }),
    onSuccess: (resp) => { dispatch(actions.addCities(resp)); } });
};

export const useGetLevelSources = ({ enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getLevelSources'],
    enabled,
    queryFn: () => requests.getLevelSources(),
    onSuccess: (resp) => { dispatch(actions.addLevels(resp)); } });
};

export const useGetSubjectSources = ({ enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getSubjectSources'],
    enabled,
    queryFn: () => requests.getSubjectSources(),
    onSuccess: (resp) => { dispatch(actions.addSubjects(resp)); } });
};

export const useGetSources = ({ query, enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getSources', query],
    enabled,
    queryFn: () => requests.getSources({ query }),
    onSuccess: (resp) => { dispatch(actions.addSources(resp)); }
  });
};

export const useAddSource = () => {
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'addSource',
    mutationFn: body => requests.addSource(body),
    onSuccess: () => { openNotification({ message: t('notif', 'SOURCE_SENT', language) }); },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useGetPublicationPlaces = ({ query, enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getPublicationPlaces', query],
    enabled,
    queryFn: () => requests.getPublicationPlaces(query),
    onSuccess: (resp) => { dispatch(actions.addPublicationPlaces(resp)); } });
};

export const useGetCreators = ({ query, enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getCreators', query],
    enabled,
    queryFn: () => requests.getCreators({ query }),
    onSuccess: (resp) => { dispatch(actions.addCreators(resp)); } });
};

export const useGetCreatorsAge = ({ enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getCreatorsAge'],
    enabled,
    queryFn: () => requests.getCreatorsAge(),
    onSuccess: (resp) => { dispatch(actions.addCreatorsAge(resp)); } });
};

export const useGetCreatorsGender = ({ language, enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getCreatorsGender', language],
    enabled,
    queryFn: () => requests.getCreatorsGender({ language }),
    onSuccess: (resp) => { dispatch(actions.addCreatorsGender(resp)); } });
};

export const useGetLanguages = ({ query, enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getLanguages', query],
    enabled,
    queryFn: () => requests.getLanguages({ query }),
    onSuccess: (resp) => { dispatch(actions.addLanguages(resp)); } });
};

export const useGetCharacters = ({ enabled }) => {
  const language = useSelector(state => state?.userInfo?.language);
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getCharacters'],
    enabled,
    queryFn: () => requests.getCharacters({ language }),
    onSuccess: (resp) => { dispatch(actions.addCharacters(resp)); } });
};

export const useGetContexts = ({ enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getContexts'],
    enabled,
    queryFn: () => requests.getContexts(),
    onSuccess: (resp) => { dispatch(actions.addContexts(resp)); } });
};

export const useGetPopularWords = ({ enabled, params }) => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useQuery({
    queryKey: ['getPopularWords', portal_slug, params],
    enabled,
    queryFn: () => requests.getPopularWords({ portal_slug, params }),
    onSuccess: (resp) => { dispatch(actions.addWords(resp)); } });
};

export const useGetPopularLinks = ({ enabled, params }) => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useQuery({
    queryKey: ['getPopularLinks', portal_slug, params],
    enabled,
    queryFn: () => requests.getPopularLinks({ portal_slug, params }),
    onSuccess: (resp) => { dispatch(actions.addLinks(resp)); } });
};

export const useGetPopularHashtags = ({ enabled, params }) => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  return useQuery({
    queryKey: ['getPopularHashtags', portal_slug, params],
    enabled,
    queryFn: () => requests.getPopularHashtags({ portal_slug, params }),
    onSuccess: (resp) => { dispatch(actions.addHashtags(resp)); } });
};

export const useGetAudiences = ({ enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getAudiences'],
    enabled,
    queryFn: () => requests.getAudiences(),
    onSuccess: (resp) => { dispatch(actions.addAudiences(resp)); } });
};

export const useGetSettings = () => {
  const dispatch = useDispatch();
  const { portal, pageId } = useParams();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  const portalsList = useSelector(state => state.portals?.portalsList);
  const hasPortal = !!portal && !!portalsList?.find(el => el?.slug === portal);
  const settingsPage = !!window.location.pathname?.includes('feedsettings') && !!portal_slug;
  return useQuery({
    enabled: (hasPortal && pageId === 'all') || settingsPage,
    queryKey: ['getSettings', portal_slug, language],
    queryFn: () => requests.getSettings({ portal_slug }),
    onSuccess: (resp) => { dispatch(actions.addSettings(resp)); }
  });
};

export const useCreateSettings = () => {
  const language = useSelector(state => state?.userInfo?.language);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: 'createSettings',
    mutationFn: body => requests.createSettings({ body, portal_slug }),
    onSuccess: (resp) => {
      dispatch(actions.addSettings(resp));
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useChangeSettings = () => {
  const language = useSelector(state => state?.userInfo?.language);
  const id = useSelector(state => state?.filters?.settings?.id);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: 'changeSettings',
    mutationFn: body => requests.changeSettings({ id, body, portal_slug }),
    onSuccess: (resp) => { dispatch(actions.addSettings(resp)); },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useReportManger = () => {
  const language = useSelector(state => state?.userInfo?.language);
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: 'reportManager',
    mutationFn: body => requests.reportManager({ body, portal_slug }),
    onSuccess: () => {
      dispatch(actions.openModal({ action: 'successReportManager', info: null }));
    },
    onError: () => {
      openNotification({ message: t('notif', 'SOMETHING_WRONG', language) });
    }
  });
};
