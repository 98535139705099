import { Button } from 'Components';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { func, string } from 'prop-types';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './BodyConfirmWhatsapp.module.scss';

const BodyConfirmWhatsapp = ({ onCancel = () => null, language = 'uk' }) => (
  <div className={cn(classes.bodyConnectWhatsapp)}>
    <div className={classes.imageWrapp}>
      <div className={classes.imageMessage} />
      <div className={classes.imagePhone} />
    </div>
    <div className={classes.text}>
      {t('alerts', 'WHATSAPP_CONFIRM', language)}
    </div>
    <div className={classes.buttonBox}>
      <Button
        onClick={() => {
          sendAlertsEvent({ category: 'Recipient WA', event: 'Clicked_Okay_Button_In_Connect_WhatsApp_Modal' });
          onCancel();
        }}
        textButton={t('btn', 'OKAY', language)}
        purpleTransparent32
      />
    </div>
  </div>
);

BodyConfirmWhatsapp.propTypes = {
  onCancel: func,
  language: string
};

export default BodyConfirmWhatsapp;
