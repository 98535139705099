import { Progressbar } from 'Components';
import { number, string, bool, shape, object } from 'prop-types';
import { dataProgress, progressColors } from 'data/persistentData';
import Skeleton from 'react-loading-skeleton';
import classes from './MentionsList.module.scss';
import 'react-loading-skeleton/dist/skeleton.css';

export default function ProgressBarMention({ data = {}, language = 'uk', isLoading = false }) {
  const progressbarData = data?.tone ? Object.entries(data?.tone).map(el => ({ value: el[1], color: progressColors[el[0]], text: el[0], link: '#' })) : dataProgress;
  return (
    <>
      {!isLoading
        ? data?.all_count
          ? progressbarData && <Progressbar className={classes.toneLine} language={language} progressbarData={progressbarData} />
          : <div className={classes.emptyProgressBar} />
        : <Skeleton className={classes.skeletonProgress} containerClassName={classes.skeletonProgressWrapper} baseColor="#f6f7fa" highlightColor="#e0e0f3" />}
    </>
  );
}

ProgressBarMention.propTypes = {
  data: shape({
    tone: object,
    all_count: number.isRequired
  }),
  isLoading: bool,
  language: string
};
