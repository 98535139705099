import React from 'react';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { string, bool, shape, oneOfType } from 'prop-types';
import classes from './FallbackComponent.module.scss';

function FallbackComponent({ language = 'uk', errText = '', style = null, className = '' }) {
  return (
    <div
      className={cn(
        classes.boundaryErrorWrap,
        className
      )}
      style={style}
    >
      <div className={cn(classes.boundaryError)}>
        <div className={classes.main}>
          <div className={classes.main_text}>{errText || t('error', 'ERR_TEXT', language)}</div>
        </div>
      </div>
    </div>
  );
}

FallbackComponent.propTypes = {
  language: string,
  errText: string,
  style: oneOfType([bool, shape({})]),
  className: string
};

export default FallbackComponent;
