import {
  bool, string, oneOfType, func
} from 'prop-types';
import cn from 'classnames';
import { Icons } from 'Components';
import classes from './BtnAddChips.module.scss';

const BtnAddChips = ({
  isNewDashboard = false,
  className = '',
  onClick = () => null,
  isBurger = false,
  textButton = ''

}) => {
  function addClass() {
    if (isBurger) {
      return classes.btnAddChips_burger;
    }
    if (isNewDashboard) {
      return cn(classes.btnAddChips_noburger, classes.btnAddChips_newDashboard);
    }
    return classes.btnAddChips_noburger;
  }
  const addNewClass = addClass();

  return (
    <button
      type="button"
      onClick={() => onClick({ action: 'createPage', info: {} })}
      className={cn(
        classes.btnAddChips,
        addNewClass,
        className
      )}
    >
      {isBurger ? (
        <Icons icon="Icn_Add_10" className={classes.icon} />
      ) : (
        <>
          <Icons icon="Icn_Add_nofill" className={classes.icon} />
          <p className={classes.text}>{textButton}</p>
        </>
      )}
    </button>
  );
};

BtnAddChips.propTypes = {
  textButton: oneOfType([string, bool]),
  onClick: func,
  className: string,
  isBurger: bool,
  isNewDashboard: bool
};

export default BtnAddChips;
