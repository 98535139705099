import { useState } from 'react';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, TextField, Icons } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BodyRenamePageModal.module.scss';

const BodyCreatePageModal = ({
  className = '',
  language = 'uk',
  onCancel = () => null,
  onCreate = () => null,
  showCheckbox = false

}) => {
  const [editName, setEditName] = useState('');
  const [selected, setSelected] = useState(true);

  const onChangeName = (e) => {
    setEditName(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedEvent({ category: 'Page', event: 'Clicked_Save_Button_Create_Page' });
    onCreate({ name: editName, selected });
    onCancel();
  };
  const disabled = !editName?.length;

  return (
    <form
      onSubmit={onSubmit}
      className={cn(classes.bodyCreateModal, className)}
    >
      <div className={cn(classes.inputBox)}>
        <TextField
          value={editName}
          className={classes.modalInput}
          onChange={onChangeName}
          placeholder={t('bodyRenamePageModal', 'ENTER_NAME_PAGE', language)}
        />
        {showCheckbox && (
        <label htmlFor="enable_filters" className={classes.checkboxLabel}>
          <input
            type="checkbox"
            className={classes.visually_hidden}
            onChange={() => { setSelected(!selected); }}
            value={!!selected}
            id="enable_filters"
          />
          <div
            className={cn(
              classes.checkbox,
              selected && classes.checkbox_selected
            )}
          >
            {selected && (<Icons icon="check" className={classes.iconCheck} />)}
          </div>
          <div className={classes.checkedText}>{t('bodyRenamePageModal', 'CHOOSE_FILTERS', language)}</div>
        </label>
        )}
      </div>

      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />

        <Button
          type="submit"
          disabled={disabled}
          onClick={onSubmit}
          textButton={t('btn', 'SAVE', language)}
          green32
        />
      </div>
    </form>
  );
};

BodyCreatePageModal.propTypes = {
  className: string,
  onCancel: func,
  onCreate: func,
  language: string,
  showCheckbox: bool
};

export default BodyCreatePageModal;
