import t from 'utils/translateFunc';
import { useSelector } from 'react-redux';
import classes from './NotAccess.module.scss';

const NotAccess = () => {
  const language = useSelector(state => state?.userInfo?.language);
  return (
    <div className={classes.notAccess}>
      <div className={classes.notAccess_image} />
      <div className={classes.notAccess_text_subtitle}>
        {t('notFound', 'PORTAL_DENIED', language)}
      </div>

    </div>
  );
};

export default NotAccess;
