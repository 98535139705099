import { memo } from 'react';
import cn from 'classnames';
import { Icon } from 'Components';
import { string, func, shape, number, oneOfType, arrayOf } from 'prop-types';
import classes from './TagsList.module.scss';

const TagsList = memo(({ tags = [], article_id = '', onRemove = () => null }) => !!tags?.length && (
  <div className={cn(classes.tagsList)}>
    {!!tags.length && tags.map(tag => (
      <div
        key={tag?.id}
        className={cn(classes.tagsItem)}
        style={{ background: `#${tag?.color || '420c8d'}` }}
      >
        <span>{tag?.name}</span>
        <Icon
          type="close_arrow"
          className={classes.btnClose}
          onClick={() => {
            onRemove({ article_id, tag_id: tag?.id });
          }}
        />
      </div>
    ))}
  </div>
));

TagsList.propTypes = {
  tags: arrayOf(shape({})),
  article_id: oneOfType([number, string]),
  onRemove: func
};

export default TagsList;
