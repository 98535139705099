import { api } from 'api/apiInstance';
import { addQueryParams, checkQueryParams } from 'utils/common';

export const getSimpleAlerts = async (query) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`alerts/settings/${queryString}`);
  return response?.data;
};
export const getSimpleAlertById = async (id) => {
  const response = await api.get(`alerts/settings/${id}`);
  return response?.data;
};
export const createSimpleAlert = async (body) => {
  const response = await api.post('alerts/settings/', body);
  return response?.data;
};
export const changeSimpleAlert = async ({ id, body }) => {
  const response = await api.patch(`alerts/settings/${id}/`, body);
  return response?.data;
};
export const deleteSimpleAlert = async (id) => {
  const response = await api.delete(`alerts/settings/${id}/`);
  return response?.data;
};

export const getNegativeAlerts = async (query) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`alerts/negative_settings/${queryString}`);
  return response?.data;
};
export const getNegativeAlertById = async (id) => {
  const response = await api.get(`alerts/negative_settings/${id}`);
  return response?.data;
};
export const createNegativeAlert = async (body) => {
  const response = await api.post('alerts/negative_settings/', body);
  return response?.data;
};
export const changeNegativeAlert = async ({ id, body }) => {
  const response = await api.patch(`alerts/negative_settings/${id}/`, body);
  return response?.data;
};
export const deleteNegativeAlert = async (id) => {
  const response = await api.delete(`alerts/negative_settings/${id}/`);
  return response?.data;
};

export const getActivityAlerts = async (query) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`alerts/activity_settings/${queryString}`);
  return response?.data;
};
export const getActivityAlertById = async (id) => {
  const response = await api.get(`alerts/activity_settings/${id}`);
  return response?.data;
};
export const createActivityAlert = async (body) => {
  const response = await api.post('alerts/activity_settings/', body);
  return response?.data;
};
export const changeActivityAlert = async ({ id, body }) => {
  const response = await api.patch(`alerts/activity_settings/${id}/`, body);
  return response?.data;
};
export const deleteActivityAlert = async (id) => {
  const response = await api.delete(`alerts/activity_settings/${id}/`);
  return response?.data;
};

export const hideAlertCard = async (card_id) => {
  const response = await api.get(`alerts/cards/hide/${card_id}/`);
  return response?.data;
};

export const getReports = async (query) => {
  const queryString = query?.portal_id ? addQueryParams(query) : '';
  const response = await api.get(`reports/settings/${queryString}`);
  return response?.data;
};

export const getReportById = async (id) => {
  const response = await api.get(`reports/settings/${id}`);
  return response?.data;
};

export const createReport = async (body) => {
  const response = await api.post('reports/settings/', body);
  return response?.data;
};

export const changeReport = async ({ id, body }) => {
  const response = await api.patch(`reports/settings/${id}/`, body);
  return response?.data;
};

export const deleteReport = async (id) => {
  const response = await api.delete(`reports/settings/${id}/`);
  return response?.data;
};

export const getTemplates = async (query) => {
  const queryString = query?.portal_id ? addQueryParams(query) : '';
  const response = await api.get(`reports/customization${queryString}`);
  return response?.data;
};

export const hideReportCard = async (card_id) => {
  const response = await api.get(`reports/cards/hide/${card_id}/`);
  return response?.data;
};

export const getUserKeywords = async ({ params }) => {
  const queryString = addQueryParams(params);
  const response = await api.get(`user/keywords${queryString}`);
  return response?.data;
};

export const getKeywords = async ({ portal_slug }) => {
  const response = await api.get(`keyword/${portal_slug}/`);
  return response?.data;
};

export const getPages = async ({ portal_slug }) => {
  const response = await api.get(`page/${portal_slug}/`);
  return response?.data;
};

export const getTemplateById = async (id) => {
  const response = await api.get(`reports/customization/${id}`);
  return response?.data;
};

export const getTemplateMeta = async () => {
  const response = await api.get('reports/customization/meta/');
  return response?.data;
};

export const getTemplateDashboardCharts = async (query) => {
  const queryString = query?.portal_id ? addQueryParams(query) : '';
  const response = await api.get(`reports/customization/dashboard_charts${queryString}`);
  return response?.data;
};

export const getTemplatePreview = async (body) => {
  const form = new FormData();
  body && Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.length === 0
        ? form.append(key, '')
        : value.forEach((item, i) => form.append(`${key}[${i}]`, item));
    } else {
      form.append([key], value);
    }
  });
  const response = await api.post('reports/customization/preview/', form, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response?.data;
};

export const createTemplate = async (body) => {
  const form = new FormData();
  body && Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.length === 0
        ? form.append(key, '')
        : value.forEach((item, i) => form.append(`${key}[${i}]`, item));
    } else {
      form.append([key], value);
    }
  });
  const response = await api.post('reports/customization/', form, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response?.data;
};

export const editTemplate = async ({ id, body }) => {
  const form = new FormData();
  body && Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.length === 0
        ? form.append(key, '')
        : value.forEach((item, i) => form.append(`${key}[${i}]`, item));
    } else {
      form.append([key], value);
    }
  });
  const response = await api.patch(`reports/customization/${id}/`, form, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response?.data;
};

export const deleteReportTemplate = async (id) => {
  const response = await api.delete(`reports/customization/${id}/`);
  return response?.data;
};
