import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import cn from 'classnames';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import classes from './SkeletonArticle.module.scss';

export default function SkeletonArticle() {
  const isMobile = useCheckMobileScreen();
  return (
    <SkeletonTheme baseColor="#f6f7fa" highlightColor="#e0e0f3">
      <div className={cn(classes.article)}>
        <div className={cn(classes.contentWrapper)}>
          <div className={cn(classes.headerWrapper)}>
            <div className={cn(classes.titleWrapper)}>
              <Skeleton className={classes.checkbox} containerClassName={classes.checkboxLabel} inline />
              <Skeleton count={2} className={classes.text} containerClassName={classes.textWrapper} inline />
            </div>
            <Skeleton className={classes.date} containerClassName={classes.dateWrapper} inline />
          </div>
          <Skeleton count={isMobile ? 5 : 3} className={classes.footer} containerClassName={classes.footerWrapper} inline />
        </div>
      </div>
    </SkeletonTheme>
  );
}
