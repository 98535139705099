/* eslint-disable no-unused-vars */
import { memo, useMemo, useState, useEffect, useRef } from 'react';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useGetReports, useCreateReport, useChangeReport } from 'storage/queryHooks';
import {
  openModal, editReportDetails, openReportDetails, closeReportDetails, editReports, toggleRefetchTelegram,
  changeReportsPortal, changeReportTemlate, closeReportTemplate, openReportTemplate
} from 'storage/actions';
import isEqual from 'lodash/isEqual';
import { CSSTransition } from 'react-transition-group';
import { customAlphabet } from 'nanoid';
import classes from './ReportsSettings.module.scss';
import ReportDetails from './ReportDetails';
import ReportsTable from './ReportsTable';

const ReportsSettings = memo(() => {
  const dispatch = useDispatch();
  const detailsRef = useRef(null);
  const nodeRef = useRef(null);
  const nanoid = customAlphabet('0123456789', 5);
  const { reports, reportsPortal, reportDetails, reportTemlate, reportDetailsVisible, reportTemplateVisible } = useSelector(state => state?.alerts);
  const { refetchTelegram, telegramData, whatsappData, user } = useSelector(state => state?.userInfo);
  const { isLoading: reportsLoading, isRefetching: reportsRefetching } = useGetReports();
  const { mutateAsync: editReport, isLoading: editReportLoading, error: editReportError } = useChangeReport();
  const { mutateAsync: createReport, isLoading: createReportLoading, error: createReportError } = useCreateReport();
  const [portalError, setPortalError] = useState(false);
  const errorRequest = editReportError || createReportError;
  const reportsFiltered = useMemo(() => reports.filter(item => !!item?.id), [reports, reportDetails?.id]);
  const userPortals = user?.portals;
  const portalsOptions = useMemo(() => (userPortals?.length ?
    userPortals?.map(portal => ({ value: portal.id, label: portal.name, slug: portal.slug })) : null), [userPortals]);
  const onEditReports = (newReportsList) => {
    !isEqual(reports, newReportsList) && dispatch(editReports(newReportsList));
  };

  useEffect(() => {
    !reportsPortal && userPortals?.length === 1 && userPortals[0]?.slug !== reportsPortal?.slug && dispatch(changeReportsPortal(userPortals[0]));
  }, [userPortals, reportsPortal]);

  const emptyReport = {
    editable: true,
    name: '',
    delivery_type: 'email',
    is_enabled: true,
    keyword: null,
    page: null,
    emails: user?.email ? [user.email] : null,
    telegram_users: telegramData?.accounts?.length ? [telegramData.accounts[0]] : [],
    whatsapp_accounts: whatsappData?.accounts?.length ? [whatsappData.accounts[0]] : []
  };
  function checkUnsavedChanges(value) {
    if (!reportDetails) return false;
    const reportId = reportDetails?.id;
    const detailsReport = { ...reportDetails };
    !!detailsReport?.last_send_date && (delete detailsReport.last_send_date);
    if (detailsReport && !reportId) {
      const { keyword, frequency, report_customization, report_type, template_language, time_list } = reportDetails;
      return !!keyword || !!frequency || !!report_customization || !!report_type || !!template_language || !!time_list;
    }
    if (detailsReport && reportId) {
      const findReport = !!reports?.length && reports?.find(item => item?.id === reportId);
      const report = findReport || {};
      const reportEtalon = { ...report, name: detailsReport?.name };
      !!reportEtalon?.last_send_date && (delete reportEtalon.last_send_date);
      if (isEqual(detailsReport, reportEtalon) && report?.name !== detailsReport?.name) {
        value === 'only_close' ? onClose() : editReportWithoutConfirm(detailsReport);
      }
      return !isEqual(detailsReport, reportEtalon);
    }
    return false;
  }

  function editReportWithoutConfirm(data) {
    const reportBody = {
      id: data?.id,
      body: {
        ...data,
        name: data?.name || t('reports', 'NO_NAME'),
        emails: data?.delivery_type === 'email' && data?.emails?.length ? data?.emails : null,
        delivery_type: (data?.delivery_type === 'telegram' && data?.telegram_users?.length ? 'telegram' : null) || (data?.delivery_type === 'whatsapp' && data?.whatsapp_accounts?.length ? 'whatsapp' : null) || 'email',
        report_customization: !!data?.report_customization?.id && data.report_customization.id !== 'default' ? data.report_customization.id : null,
        keyword: data?.keyword?.id || null,
        page: data?.page?.id || data?.page || null,
        telegram_users: data?.delivery_type === 'telegram' && data?.telegram_users?.length ? data?.telegram_users.map(item => item?.id) : [],
        whatsapp_accounts: data?.delivery_type === 'whatsapp' && data?.whatsapp_accounts?.length ? data?.whatsapp_accounts.map(item => item?.id) : [],
        portal: data?.portal?.id || null
      }
    };
    editReport(reportBody);
  }

  function onOpenDetailsEditor({ report_data }) {
    if (userPortals.length > 1 && !reportsPortal) {
      setPortalError(true);
      return;
    }
    if (checkUnsavedChanges()) {
      detailsRef?.current?.warningMessage();
      return;
    }
    if (report_data?.id === reportDetails?.id && reportDetailsVisible) return;
    onEditReports(!report_data?.id ? [emptyReport, ...reportsFiltered] : reportsFiltered);
    dispatch(openReportDetails({ reportDetails: report_data || emptyReport }));
  }

  function onChangeToggleReport({ report_data }) {
    const editedData = {
      id: report_data?.id,
      body: { is_enabled: !report_data?.is_enabled }
    };
    report_data?.id && editReport(editedData);
  }

  function onEditReport({ report_data, isToggle }) {
    !isToggle ? onOpenDetailsEditor({ report_data }) : onChangeToggleReport({ report_data });
  }

  function onChangeReport(newReportDetailsData) {
    !isEqual(reportDetails, newReportDetailsData) && dispatch(editReportDetails(newReportDetailsData));
  }

  function onChangeReportTemlate(newReportTemplate) {
    !isEqual(reportTemlate, newReportTemplate) && dispatch(changeReportTemlate(newReportTemplate));
  }
  function onRemoveEmptyReports() {
    !isEqual(reports, reportsFiltered) && dispatch(editReports(reportsFiltered?.length ? reportsFiltered : [emptyReport]));
  }

  function onClose() {
    dispatch(closeReportDetails());
    onRemoveEmptyReports();
  }

  function onCreateReport(data) {
    const fileredTimeList = !!data?.time_list?.length && data.time_list.filter(item => !!item?.time && item?.time?.length === 5);
    const removeDuplicates = fileredTimeList?.length && [...new Map(fileredTimeList.map(obj => [JSON.stringify({ day: obj.day, time: obj.time }), obj])).values()];
    const removeDuplicatesWithIds = removeDuplicates?.length && removeDuplicates.map(item => ({ ...item, id: item?.id || nanoid() }));
    const uniqueTimeList = removeDuplicatesWithIds?.length ? removeDuplicatesWithIds.sort((a, b) => (a.day - b.day || a.time.localeCompare(b.time))) : null;
    const deliveryType = (data?.delivery_type === 'telegram' && data?.telegram_users?.length ? 'telegram' : null) || (data?.delivery_type === 'whatsapp' && data?.whatsapp_accounts?.length ? 'whatsapp' : null) || 'email';

    const reportBody = {
      is_enabled: true,
      editable: true,
      name: data?.name || t('reports', 'NO_NAME'),
      emails: data?.delivery_type === 'email' && data?.emails?.length ? data?.emails : null,
      keyword: data?.keyword?.id || null,
      report_type: data?.report_type || 'report_html',
      delivery_type: deliveryType,
      frequency: data?.frequency || '',
      page: data?.page?.id || null,
      report_customization: !!data?.report_customization?.id && data.report_customization.id !== 'default' ? data.report_customization.id : null,
      template_language: data?.template_language || 'uk',
      telegram_users: data?.delivery_type === 'telegram' && data?.telegram_users?.length ? data?.telegram_users.map(item => item?.id) : [],
      whatsapp_accounts: data?.delivery_type === 'whatsapp' && data?.whatsapp_accounts?.length ? data?.whatsapp_accounts.map(item => item?.id) : [],
      portal: reportsPortal?.id || null,
      user: user?.id || null,
      time_list: uniqueTimeList
    };
    createReport({ body: reportBody });
  }
  const onOpenModal = (modalData) => { dispatch(openModal(modalData)); };
  const onStartRefetchTelegram = (value) => { dispatch(toggleRefetchTelegram(value)); };

  return (
    <div className={classes.reportsSettingsWrap}>
      <div className={cn(classes.main, reportDetailsVisible && classes.main_detailsOn)}>
        <ReportsTable
          detailsVisible={reportDetailsVisible}
          userContacts={{ email: user?.email || '', telegram_accounts: telegramData?.accounts || [], whatsapp_accounts: whatsappData?.accounts || [] }}
          onEditReport={onEditReport}
          reports={reports || []}
          reportData={reportDetails}
          fetchings={reportsLoading || reportsRefetching}
          reportsPortal={reportsPortal}
          portalsOptions={portalsOptions}
          portalError={portalError}
          onChangePortal={(newPortal) => {
            onClose();
            portalError && setPortalError(false);
            dispatch(changeReportsPortal(newPortal));
          }}
        />
      </div>
      <div className={cn(classes.details, reportDetailsVisible && classes.details_activeDetails)}>
        <CSSTransition
          in={reportDetailsVisible}
          timeout={350}
          classNames={{ enter: classes.enter, enterActive: classes.enterActive, exit: classes.exit, exitActive: classes.exitActive }}
          unmountOnExit
          nodeRef={nodeRef}
        >
          <ReportDetails
            ref={detailsRef}
            visible={reportDetailsVisible}
            templateVisible={reportTemplateVisible}
            data={reportDetails}
            reportEtalon={reports?.length ? reports.find(report => report?.id === reportDetails?.id) : null}
            refetchTelegram={refetchTelegram}
            reportTemlate={reportTemlate}
            reportsPortal={reportsPortal}
            onChangeReportTemlate={onChangeReportTemlate}
            unsavedChanges={value => checkUnsavedChanges(value || null)}
            errorRequest={errorRequest?.response?.data || null}
            onClose={onClose}
            onCreate={onCreateReport}
            onChangeReport={onChangeReport}
            onOpenModal={onOpenModal}
            onStartRefetchTelegram={onStartRefetchTelegram}
            onCloseTemplate={() => { dispatch(closeReportTemplate()); }}
            onOpenReportTemlate={(id) => { dispatch(openReportTemplate(id ? { id } : null)); }}
            telegramData={telegramData || null}
            whatsappData={whatsappData || null}
            isLoading={editReportLoading || createReportLoading}
          />
        </CSSTransition>
      </div>
    </div>
  );
});

export default ReportsSettings;
