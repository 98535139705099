import { string, number, oneOfType } from 'prop-types';

export function Checked({ width = '12', height = '13' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '12'} height={height || '13'} viewBox="0 0 12 13" fill="none">
      <mask id="mask0_2422_4981" style={{ masktype: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="13">
        <rect y="0.5" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2422_4981)">
        <path d="M4.77508 9.2877C4.70841 9.2877 4.64591 9.27728 4.58758 9.25645C4.52924 9.23561 4.47508 9.2002 4.42508 9.1502L2.27508 7.0002C2.18341 6.90853 2.13966 6.78978 2.14383 6.64395C2.14799 6.49811 2.19591 6.37936 2.28758 6.2877C2.37924 6.19603 2.49591 6.1502 2.63758 6.1502C2.77924 6.1502 2.89591 6.19603 2.98758 6.2877L4.77508 8.0752L9.01258 3.8377C9.10424 3.74603 9.22299 3.7002 9.36883 3.7002C9.51466 3.7002 9.63341 3.74603 9.72508 3.8377C9.81674 3.92936 9.86258 4.04811 9.86258 4.19395C9.86258 4.33978 9.81674 4.45853 9.72508 4.5502L5.12508 9.1502C5.07508 9.2002 5.02091 9.23561 4.96258 9.25645C4.90424 9.27728 4.84174 9.2877 4.77508 9.2877Z" fill="white" />
      </g>
    </svg>
  );
}

Checked.propTypes = {
  width: oneOfType([string, number]),
  height: oneOfType([string, number])
};
