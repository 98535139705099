import { string, bool, node, number, oneOfType } from 'prop-types';
import { Icons } from 'Components';
import { notification } from 'antd';
import cn from 'classnames';
import classes from './openNotification.module.scss';

const openNotification = ({
  loader = false,
  message = '',
  className = '',
  duration = 2,
  redirectText = false,
  cancelBtn = false,
  isError = false
}) => {
  notification.destroy();
  const messageBlock = (
    <>
      <p className={classes.textMessage}>
        {message}
        {!!redirectText && redirectText}
        {!!cancelBtn && cancelBtn}
      </p>
      {loader && <Icons icon="Icn_Loader" className={classes.loaderMessage} />}
    </>
  );
  const closeIcon = (
    <span className={cn(classes.closeBtn, isError && classes.closeBtnError)}>
      <Icons icon="Icn_Clear_notif" className={cn(classes.close)} />
    </span>
  );
  return notification.open({
    message: messageBlock,
    duration: duration || 3,
    onClose: () => notification.destroy(),
    closeIcon,
    className: cn(
      classes.message,
      isError && classes.message_error,
      className
    ),
    bottom: 10
  });
};

openNotification.propTypes = {
  message: string,
  loader: bool,
  className: string,
  duration: number,
  redirectText: oneOfType([node, bool]),
  cancelBtn: oneOfType([node, bool]),
  isError: bool
};

export default openNotification;
