import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  portalsList: [],
  currentPortal: { }
};

const portalsSlice = createSlice({
  name: 'portals',
  initialState,
  reducers: {
    addPortals(state, { payload }) {
      const portals = !!payload?.portalsList?.length && payload.portalsList;
      const checkValue = (a, b) => (a > b ? 1 : -1);
      const sortedPortals = !!portals && [...portals].sort((a, b) => checkValue(a.name, b.name));
      state.portalsList = sortedPortals || portals || [];
      if ((!state.currentPortal?.slug || payload?.portal) && portals) {
        state.currentPortal = payload?.portal || sortedPortals[0];
      }
    },
    changeCurrentPortal(state, { payload }) {
      state.currentPortal = payload;
    }
  }
});

export const { addPortals, changeCurrentPortal } = portalsSlice.actions;
export default portalsSlice.reducer;
