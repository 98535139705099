import t from 'utils/translateFunc';
import cn from 'classnames';
import { bool, func } from 'prop-types';
import classes from './ReportIssueCard.module.scss';

function ReportIssueCard({ isBlur = false, onClick = () => null }) {
  const users = ['M', 'M', 'M', 'M', 'M', 'M', 'M', 'M', 'M'];
  return (
    <div className={cn(classes.alertsCard, isBlur && classes.alertsCard_blur)} onClick={onClick}>
      <div className={classes.alertBody}>
        <div className={classes.textWrap}>
          <div className={classes.textTitle}>{t('alerts', 'REPORT_TEAM')}</div>
          <div className={classes.subtitleWrap}>
            <div className={classes.textSmallSubTitle}>
              {t('alerts', 'BY_KEYWORD')}
            </div>
            <div className={classes.textSubTitle}>
              {t('alerts', 'COMPANY_NAME_SYMBOLS')}
            </div>
          </div>
          <div className={classes.image} />
        </div>
      </div>
      <div className={classes.alertFooter}>
        {users.map((item, i) => (
          <div className={classes.postItem} key={i}>
            <span className={classes.postAvar} style={{ background: 'var(--pink500)' }}>{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

ReportIssueCard.propTypes = {
  isBlur: bool,
  onClick: func
};

export default ReportIssueCard;
