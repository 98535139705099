import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import cn from 'classnames';
import { string, node, bool } from 'prop-types';
import classes from './SkeletonFilterList.module.scss';

export function SkeletonFilterList({ fetching = false, children = null, className = '' }) {
  return (fetching ? (
    <div className={cn(classes.filterList, className)}>
      <SkeletonTheme baseColor="#f6f7fa" highlightColor="#e0e0f3">
        {new Array(6).fill(null).map((_, i) => <Skeleton key={i} count={2} className={classes.filterListItem} containerClassName={classes.filterListItemWrapper} inline />)}
      </SkeletonTheme>
    </div>
  ) : children);
}

SkeletonFilterList.propTypes = {
  fetching: bool,
  children: node,
  className: string
};
export default SkeletonFilterList;
