import { string, bool, func, oneOfType, arrayOf, shape, number } from 'prop-types';
import { Icon } from 'Components';
import cn from 'classnames';
import classes from './BodyMenu.module.scss';

const BodyMenu = ({ active = '', info = '', selectOption = () => null, menuConfig = [], color = '', className = '' }) => (
  <ul className={cn(classes.bodyManuChips, className)}>
    {!!menuConfig?.length && menuConfig.map(el => (
      <li className={cn(classes.chipsWrap, (el?.name === active || el?.slug === active) && classes.chipsWrap_active)} key={el?.name}>
        {el?.href ? (
          <a
            onClick={(e) => {
              e.preventDefault();
              selectOption(el);
            }}
            className={classes.chips}
            rel="noopener noreferrer"
            href={el?.href}
            target="_blank"
          >
            <div className={classes.text}>{el?.name}</div>
          </a>
        ) : (
          <button
            className={classes.chips}
            onClick={() => selectOption(el)}
          >
            {el?.icon && <div className={classes.icon}><Icon type={el.icon} color={color} /></div>}
            <div className={classes.text}>
              {el?.name}
              {el?.info && info && <div className={classes.info}>{info}</div>}
            </div>
          </button>
        )}
      </li>
    ))}
  </ul>
);

BodyMenu.propTypes = {
  menuConfig: arrayOf(shape({ name: string, icon: string, info: oneOfType([bool, string]) })),
  className: string,
  selectOption: func,
  active: string,
  info: oneOfType([number, string]),
  color: string
};

export default BodyMenu;
