import { createSlice } from '@reduxjs/toolkit';
import { updateToken } from 'api/apiInstance';

const initialState = {
  isAuthenticated: false,
  accessToken: null,
  serverUpdating: false,
  notAvailable: false,
  authOutUrl: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authIn(state, { payload }) {
      if (payload?.token) {
        updateToken(payload.token);
      }
      state.accessToken = payload?.token || null;
      state.isAuthenticated = true;
    },
    clearState() {
      return { isAuthenticated: false, accessToken: null, serverUpdating: false, notAvailable: false, authOutUrl: '' };
    },
    changeServerUpdating(state, { payload }) {
      state.serverUpdating = payload;
    },
    changeNotAvailable(state, { payload }) {
      state.notAvailable = payload;
    },
    authOut(state, { payload }) {
      updateToken(null);
      return { accessToken: null, isAuthenticated: false, serverUpdating: false, notAvailable: false, authOutUrl: payload };
    }
  }
});

export const { authIn, clearState, authOut, changeServerUpdating, changeNotAvailable } = authSlice.actions;
export default authSlice.reducer;
