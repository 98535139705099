import React, { useCallback, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import useClickOutside from 'hooks/clickOutside';
import cn from 'classnames';
import { string, func } from 'prop-types';
import classes from './PopoverColorPicker.module.scss';

const PopoverColorPicker = ({ color = '', className = '', onChange = () => null, sendEvent = () => null }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className={cn(classes.colorPicker)}>
      <div
        className={classes.swatch}
        style={{ backgroundColor: color }}
        onClick={() => {
          sendEvent();
          toggle(true);
        }}
      />

      {isOpen && (
        <div className={cn(classes.popover, classes.popover_arrow, className)} ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

PopoverColorPicker.propTypes = {
  color: string,
  className: string,
  onChange: func,
  sendEvent: func
};

export default PopoverColorPicker;
