import { useSelector } from 'react-redux';
import { Loader, Maintenance } from 'Components';
import t from 'utils/translateFunc';
import { useGetUserInfo } from 'storage/queryHooks';
import { browserLanguage } from 'utils/common';
import cn from 'classnames';
import classes from './AuthPage.module.scss';

const AuthPage = () => {
  const { isError, isLoading, error } = useGetUserInfo();
  const { isAuthenticated, authOutUrl } = useSelector(state => state?.auth);
  const language = useSelector(state => state?.userInfo?.language);
  const lang = isAuthenticated ? language : browserLanguage();
  return (
    <div className={cn(classes.loginPage, (isError || isLoading) && classes.loginPageError)}>
      {!isError && isLoading && (
        <div className={classes.loginPage_block}>
          {!isAuthenticated ? <div>{t('auth', authOutUrl ? 'LEAVE' : 'REDIRECT', lang)}</div> : <div>{t('auth', 'BEGIN', lang)}</div>}
          <div>
            <Loader size={45} fetching color="#A7B0C8" />
          </div>
        </div>
      ) }
      {isError && !isLoading && error?.response?.status !== 401 && <Maintenance />}
    </div>

  );
};

export default AuthPage;
