import { useState, useCallback, useEffect } from 'react';
import { string, func, shape, number, bool, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button } from 'Components';
import { Trans } from 'react-i18next';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './BodyConfirmEditAlert.module.scss';

const BodyConfirmEditAlert = ({
  className = '',
  onCancel = () => null,
  onClose = () => null,
  onCreateEditAlert = () => null,
  findAlertById = () => null,
  language = 'uk',
  typeAlert = '',
  data = { name: '', id: 0 },
  isConfirm = false
}) => {
  const [text, setText] = useState('');

  const checkSearcByChanges = useCallback(() => {
    if (data?.id && data?.searchByTab) {
      const alertEtalon = findAlertById(data?.id);
      if (!!data?.keyword && !!alertEtalon?.keyword && !(data?.query || data?.query_builder)) return null;
      if (!!(data?.query || data?.query_builder) && !!(alertEtalon?.query || alertEtalon?.query_builder) && !data?.keyword) return null;
      if (!!data?.keyword && !!(alertEtalon?.query || alertEtalon?.query_builder)) return 'query-by-keyword';
      if (!!(data?.query || data?.query_builder) && !!alertEtalon.keyword) return 'keyword-by-query';
    }
    return null;
  }, [data]);

  const checkReplace = checkSearcByChanges();

  const showConfirm = useCallback(beforeSearch => (
    <Trans
      i18nKey={beforeSearch === 'query-by-keyword' ? 'warnEditAlertSearchByQuery' : 'warnEditAlertSearchByKeyword'}
      values={{ alert_name: data?.name }}
      components={{ span: <span style={{ color: '#8086ab' }} /> }}
    />
  ), []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (data?.errors) {
      onCancel();
    }
    if (!data?.errors) {
      const alertBody = {
        id: data?.id,
        body: {
          ...data,
          name: data?.name || t('alerts', 'NO_NAME'),
          emails: data?.emails?.length ? data?.emails : null,
          keyword: data?.keyword?.id || null,
          telegram_users: data?.telegram_users?.length ? data?.telegram_users.map(item => item?.id) : [],
          whatsapp_accounts: data?.whatsapp_accounts?.length ? data?.whatsapp_accounts.map(item => item?.id) : []
        }
      };
      if (checkReplace === 'query-by-keyword') {
        alertBody.body.query = '';
        alertBody.body.query_builder = null;
      }
      if (checkReplace === 'keyword-by-query') {
        alertBody.body.keyword = null;
      }

      !!alertBody.body.searchByTab && delete alertBody.body.searchByTab;
      const properties = {
        isEditable: alertBody?.body?.editable,
        isEmails: !!alertBody?.body?.emails?.length,
        isEnabled: alertBody?.body?.is_enabled,
        isKeyword: !!alertBody?.body?.keyword,
        isQuery: !!alertBody?.body?.query,
        isQueryBuilder: !!alertBody?.body?.query_builder,
        isTelegramUsers: !!alertBody?.body?.telegram_users?.length,
        isWhatsappUsers: !!alertBody?.body?.whatsapp_accounts?.length,
        lastSendDate: alertBody?.body?.last_send_date || '',
        user: alertBody?.body?.user || {}
      };
      typeAlert && sendAlertsEvent({ category: 'Edit alert', event: `confirm_edit_alert_${typeAlert}`, properties });
      onCreateEditAlert(alertBody);
    }
  };

  useEffect(() => {
    let bodyText = `${t('alerts', 'SAVE_CHANGES', language)}${data?.name}?`;
    isConfirm && (bodyText = t('alerts', 'HAVE_UNSAVED_CHANGES', language));

    setText(bodyText);
  }, [isConfirm, data?.name]);

  function handleClose() {
    onClose(data?.id);
  }

  return (
    <form className={cn(classes.bodyConfirmEditAlert, className)}>
      <div className={classes.discription}>
        {checkReplace ? showConfirm(checkReplace) : text}
      </div>
      <div className={classes.buttonBox}>
        <Button
          onClick={isConfirm ? handleClose : onCancel}
          textButton={t('btn', isConfirm ? 'CANCEL_CHANGES' : 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />
        <Button
          type="submit"
          onClick={onSubmit}
          textButton={t('btn', 'CONFIRM_CHANGES', language)}
          green32
        />
      </div>
    </form>
  );
};

BodyConfirmEditAlert.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCancel: func,
  onClose: func,
  findAlertById: func,
  onCreateEditAlert: func,
  language: string,
  typeAlert: string,
  isConfirm: bool
};

export default BodyConfirmEditAlert;
