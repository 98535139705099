import cn from 'classnames';
import { array, string, shape } from 'prop-types';
import { BodyHtml } from 'Components';
import { textReplaced } from 'data/replacedFunc';
import classes from './Instructions.module.scss';

function AdviceCard({ operators = [], example = '', operator = null, exampleAdditional = '', additional = '' }) {
  return (
    <div className={cn(classes.card, classes.card_operator)}>
      {operators?.length ? operators?.map((item, i) => (
        <div className={cn(classes.operatorWrap)} key={i}>
          <div className={cn(classes.operator)}>
            {typeof item?.name === 'string'
              ? <BodyHtml className={cn(classes.operatorBtn, classes.operatorBtn_custom)} html={textReplaced(item?.name)} />
              : <span className={cn(classes.operatorBtn, classes.operatorBtn_custom)}>{item?.name}</span>}
          </div>
          <div className={cn(classes.size14)}>{item?.description}</div>
        </div>
      )) : (
        <div className={cn(classes.operatorWrap)}>
          <div className={cn(classes.operator)}>
            <BodyHtml className={cn(classes.operatorBtn, classes.operatorBtn_custom)} html={textReplaced(operator?.name)} />
          </div>
          {operator?.name1 && (
            <>
              <span>+</span>
              <div className={cn(classes.operator)}>
                <BodyHtml className={cn(classes.operatorBtn, classes.operatorBtn_custom)} html={textReplaced(operator?.name1)} />
              </div>
            </>
          )}
          <div className={cn(classes.size14)}>{operator?.description}</div>
        </div>
      )}
      <div className={cn(classes.operatorExample)}>
        <BodyHtml html={textReplaced(example)} />
        {exampleAdditional && <div>{exampleAdditional}</div>}
      </div>
      <div className={cn(classes.size14)}>
        {additional && <BodyHtml html={additional} />}
      </div>
    </div>
  );
}

AdviceCard.propTypes = { operators: array, operator: shape({}), example: string, additional: string, exampleAdditional: string };

export default AdviceCard;
